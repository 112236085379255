/* eslint-disable indent */
import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { strings } from '../../../strings/StringsProvider';

const activeColor = '#41aea6';
const inactiveColor = 'white';

const positiveStyle = {
  backgroundColor: activeColor,
  border: '0px',
  minWidth: '25vw',
  marginLeft: '5vw',
  marginRight: '5vw',
  fontWeight: '600'
};

const negativeStyle = {
  backgroundColor: inactiveColor,
  color: activeColor,
  borderColor: activeColor,
  minWidth: '25vw',
  marginRight: '5vw',
  marginLeft: '5vw',
  fontWeight: '600'
};

export function ScreeningButton({
  label,
  isChecked,
  onClickFnc,
  wasAlreadyChoosed
}) {
  return (
    <Button
      type="primary"
      icon={wasAlreadyChoosed === true ? 'check' : null}
      shape="round"
      style={isChecked === true ? positiveStyle : negativeStyle}
      onClick={onClickFnc}
    >
      {label}
    </Button>
  );
}

ScreeningButton.propTypes = {
  label: PropTypes.string,
  isChecked: PropTypes.bool,
  uncheckedLabel: PropTypes.string,
  onClickFnc: PropTypes.func,
  wasAlreadyChoosed: PropTypes.bool
};

export function ScreeningButtonGroup({
  leftLabel,
  rightLabel,
  onLeftClickFnc,
  onRightClickFnc,
  selectedValue
}) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'row',
        margin: 'auto',
        marginTop: '1vw'
      }}
    >
      <>
        <ScreeningButton
          isChecked={selectedValue === strings.yes ? true : false}
          label={leftLabel}
          onClickFnc={onLeftClickFnc}
        />
        <ScreeningButton
          isChecked={selectedValue === strings.no ? true : false}
          label={rightLabel}
          onClickFnc={onRightClickFnc}
        />
      </>
    </div>
  );
}

ScreeningButtonGroup.propTypes = {
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  onLeftClickFnc: PropTypes.func,
  onRightClickFnc: PropTypes.func,
  positiveAnswerIsYes: PropTypes.bool,
  wasAlreadyChoosed: PropTypes.bool,
  selectedValue: PropTypes.oneOf([strings.yes, strings.no])
};
