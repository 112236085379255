import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Menu, Dropdown, Icon, TimePicker, Button, Input, Select } from 'antd';
import PropTypes from 'prop-types';
import jwt from 'jsonwebtoken';
import { api } from '../../conf';
import axios from 'axios';
import moment from 'moment';
import imageCompression from 'browser-image-compression';
import classNames from 'classnames';

// Local imports
import {
  getOmamaClientsAction
} from '../../actions/omama.actions';
import { strings } from '../../strings/StringsProvider';
import { setLoading } from '../../actions/status.actions';
import './AddNewAction.scss';

import { getOmamasAction } from '../../actions/users.actions';

function AddNewAction({
  getOmamasAction,
  getOmamaClientsAction,
  setLoading,
  history,
  orderedClients,
  omamas,
}) {

  const [actionDate, setActionDate] = useState('');
  const [actionTime, setActionTime] = useState(moment());
  const [clientKey, setClientKey] = useState('');
  const [client, setClient] = useState(strings.chooseClient);
  const [actionKey, setActionKey] = useState('');
  const [actionName, setActionName] = useState(strings.chooseAction);
  const [typKey, setTypKey] = useState('client');
  const [typ, setTyp] = useState(strings.lesson);
  const [timeSpent, setTimeSpent] = useState(1);
  const [clubKey, setClubKey] = useState('');
  const [clubNames, setClubNames] = useState('');
  const [comment, setComment] = useState('');
  const [base64ImagePhoto, setBase64ImagePhoto] = useState('');
  const [base64ImageThumbnail, setBase64ImageThumbnail] = useState('');
  const [imageDateTaken, setImageDateTaken] = useState(null);

  useEffect(() => {
    getOmamasAction();
    getOmamaClientsAction();
  }, [getOmamasAction, getOmamaClientsAction]);

  const push = (link) => {
    history.push(link);
  };

  const addAction = async () => {
    setLoading(true);
    const redirectUrl = '/omama/plan';

    const time = moment(actionTime).format('HH:mm');
    const date = moment(actionDate + 'T' + time).format();

    let bodyData;

    switch (typKey) {
      case 'club':
        bodyData = {
          date,
          client: true,
          id: clubKey,
          differentAction: 'club',
          clubIds: clubKey,
          clubNames,
          status: 'active',
          timeSpent: parseFloat(timeSpent)
        };
        break;
      case 'client':
        bodyData = {
          date,
          client: true,
          id: clientKey,
          differentAction: '',
          status: 'active',
          timeSpent: 1.2
        };
        break;
      default:
        bodyData = {
          date,
          client: false,
          id: actionName,
          differentAction: '',
          status: 'active',
          timeSpent: parseFloat(timeSpent),
          comment
        };
        break;
    }

    try {
      await axios.post(api.addNewAction, bodyData);
      setLoading(false);
      push(redirectUrl);
    } catch (error) {
      setLoading(false);
    }
  };

  const onTypeChange = ({ key, item }) => {
    setTypKey(key);
    setTyp(item.props.children);
  };

  const onClientChange = ({ key, item }) => {
    setClientKey(key);
    setClient(item.props.children);
  };

  const onActionChange = ({ key, item }) => {
    setActionKey(key);
    setActionName(item.props.children);
  };

  const typOptions = (
    <Menu onClick={onTypeChange}>
      <Menu.Item key="client">{strings.lesson}</Menu.Item>
      <Menu.Item key="club">{strings.preschoolClub}</Menu.Item>
      <Menu.Item
        key={
          process.env.REACT_APP_LANGUAGE.toLowerCase() === 'cz'
            ? 'akce'
            : 'akcia'
        }
      >
        {strings.action}
      </Menu.Item>
      <Menu.Item key="photo">{strings.addPhoto}</Menu.Item>
    </Menu>
  );

  const clientsOptions = () => (
    <Menu onClick={onClientChange}>
      {Object.values(orderedClients).map(client => (
        <Menu.Item
          key={client._id}
          style={client.lessonsAlarm && { backgroundColor: 'red' }}
        >
          {client.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  const actionOptions = () => {
    const username = jwt.decode(localStorage.getItem('access-token')).username;
    const omama = omamas.find(
      omama => omama._id === username
    );
    const omamaLevel = omama && Number(omama.level);
    return (
      <Menu onClick={onActionChange}>
        <Menu.ItemGroup
          title={`${strings.work}:`}
          className="action-group-title"
        >
          {omamaLevel >= 3 ? (
            <Menu.Item className="actionItem" key="mentoringSenior">
              {strings.mentorTillSenior}
            </Menu.Item>
          ) : (
            <Menu.Item className="actionItem" key="mentoring">
              {strings.mentoring}
            </Menu.Item>
          )}
          <Menu.Item className="actionItem" key="parentalClub">
            {strings.parentalClub1}
          </Menu.Item>
          <Menu.Item className="actionItem" key="supervision">
            {strings.supervision}
          </Menu.Item>
          <Menu.Item className="actionItem" key="training">
            {strings.training}
          </Menu.Item>
          <Menu.Item className="actionItem" key="interNDA">
            {strings.interNDA}
          </Menu.Item>
          <Menu.Item className="actionItem" key="otherJob">
            {strings.otherJob}
          </Menu.Item>
          <Menu.Item className="actionItem" key="education">
            {strings.education}
          </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup
          title={strings.notBeingInJob}
          className="action-group-title"
        >
          <Menu.Item className="actionItem" key="vacation">
            {strings.vacation}
          </Menu.Item>
          <Menu.Item className="actionItem" key="doctor">
            {strings.doctor}
          </Menu.Item>
          <Menu.Item className="actionItem" key="familyDoctor">
            {strings.familyDoctor}
          </Menu.Item>
          <Menu.Item className="actionItem" key="workUnable">
            {strings.workUnable}
          </Menu.Item>
          <Menu.Item className="actionItem" key="OCR">
            {strings.OCR}
          </Menu.Item>
          <Menu.Item className="actionItem" key="extraTimeOff">
            {strings.extraTimeOff}
          </Menu.Item>
          <Menu.Item className="actionItem" key="other">
            {strings.other}
          </Menu.Item>
        </Menu.ItemGroup>
      </Menu>
    );
  };

  const checkButton = () => {
    if (typKey === 'client') {
      if (
        !actionDate ||
        !actionTime ||
        !clientKey
      ) {
        return true;
      }
    } else if (typKey === 'club') {
      if (
        !actionDate ||
        !actionTime ||
        !clubKey
      ) {
        return true;
      }
    } else if (typKey === strings.action.toLowerCase()) {
      if (
        !actionDate ||
        !actionTime ||
        !actionName ||
        actionName === strings.chooseAction ||
        !timeSpent
      ) {
        return true;
      }
    }
    return false;
  };

  const compressImage = async (event, useWebWorker = true) => {
    setLoading(true);
    const file = event.target.files[0];
    if (!file) {
      setLoading(false);
      return;
    }

    const reader = new FileReader();
    // TO DO delete
    // reader.onload = () => {
    //   try {
    //     this.setState({ imageDateTaken: file.lastModified });
    //   } catch (error) {
    //     this.setState({ imageDateTaken: null });
    //   }
    // }
    reader.readAsArrayBuffer(file);

    const orientation = await imageCompression.getExifOrientation(file);

    let options = {
      maxSizeMB: 1,
      useWebWorker: useWebWorker,
      exifOrientation: orientation
    };
    const output = await imageCompression(file, options);
    options = {
      maxSizeMB: 0.2,
      useWebWorker: useWebWorker,
      exifOrientation: orientation
    };
    const thumbnail = await imageCompression(file, options);

    const imagePhoto = await imageCompression.getDataUrlFromFile(output);
    const imageThumbnail = await imageCompression.getDataUrlFromFile(
      thumbnail
    );

    setBase64ImagePhoto(imagePhoto);
    setBase64ImageThumbnail(imageThumbnail);
    setImageDateTaken(file.lastModified || null);

    setLoading(false);
  };

  const uploadImage = async () => {
    setLoading(true);
    const body = {
      photo: base64ImagePhoto,
      thumbnail: base64ImageThumbnail,
      clientID: clientKey,
      actionID: '',
      imageDateTaken
    };

    try {
      await axios.post(api.addNewPhoto, body);
      setLoading(false);
      setBase64ImagePhoto('');
      setBase64ImageThumbnail('');
      setImageDateTaken(null);
    } catch (error) {
      setLoading(false);
    }
  };

  const checkPhotoButton = () =>
    !base64ImagePhoto ||
    !base64ImageThumbnail ||
    !clientKey;

  const deleteImage = () => () => {
    setBase64ImagePhoto('');
    setBase64ImageThumbnail('');
    setImageDateTaken(null);
  };

  const renderSelectOptions = () => {
    const { Option } = Select;
    const children = [];
    orderedClients.forEach(e => {
      if (e.predskolskyKlub) {
        children.push(
          <Option className="selectRow" key={e.name + '%%' + e._id}>
            {e.name}
          </Option>
        );
      }
    });
    return children;
  };


  const handleChange = value => {
    const noIndex = value.map(e => e.split('%%'));
    const ids = noIndex.map(e => {
      return e[1];
    });
    const names = noIndex
      .map(e => {
        return e[0];
      })
      .sort()
      .join(', ');

    setClubKey(ids);
    setClubNames(names);
  };

  if (typKey === 'photo') {
    return (
      <div>
        <div style={{ paddingTop: '60px' }}>
          <div className="pridatContainer">
            <div className="typeDropdown">
              <Dropdown
                trigger={['click']}
                className="actionsDropdown"
                overlay={typOptions}
                placement="bottomCenter"
              >
                <div>
                  {typ} <Icon type="down" />
                </div>
              </Dropdown>
            </div>
            <br />
            <div
              className={classNames('photoButton', {
                disabledPhotoButton: base64ImagePhoto
              })}
            >
              <label htmlFor="photogallery">
                <img
                  alt="empty"
                  className="photoIcon"
                  src="/images/Icons/Galeria_ikona.png"
                />
                <div style={{ textAlign: 'center' }}>{strings.gallery}</div>
              </label>
            </div>
            <input
              id="photogallery"
              value={[]}
              type="file"
              accept="image/*"
              onChange={compressImage}
              style={{ display: 'none' }}
            ></input>

            {base64ImageThumbnail.length > 0 && (
              <div className="tempPhotos">
                <div>
                  <img
                    alt="empty"
                    className="clientEndImg"
                    src={base64ImageThumbnail}
                  ></img>
                  <div className="xButton" onClick={deleteImage()}>
                    <Icon type="close" />
                  </div>
                </div>
              </div>
            )}
            <br />
            <Dropdown
              trigger={['click']}
              className="actionsDropdown"
              overlay={clientsOptions}
            >
              <div>
                {client} <Icon type="down" />
              </div>
            </Dropdown>
            <br />
            <Button
              className="addButton"
              size="large"
              onClick={uploadImage}
              disabled={checkPhotoButton()}
            >
              {strings.add}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div style={{ paddingTop: '60px' }}>
        <div className="pridatContainer">
          <div className="typeDropdown">
            <Dropdown
              trigger={['click']}
              className="actionsDropdown"
              overlay={typOptions}
              placement="bottomCenter"
            >
              <div>
                {typ}{' '}
                <Icon type="down" style={{ fontSize: '20px' }} />
              </div>
            </Dropdown>
          </div>
          <br />
          <div className="addNewActionPicker">
            <h3 className="pickerHeader">{strings.date}</h3>
            <Input
              type="date"
              value={actionDate}
              onChange={e => setActionDate(e.target.value)}
              className="picker"
            />
          </div>
          <div className="addNewActionPicker">
            <h3 className="pickerHeader">{strings.time}</h3>
            <br />
            <TimePicker
              defaultValue={moment('12:00', 'HH:mm')}
              type="time"
              format={'HH:mm'}
              minuteStep={15}
              onChange={value => setActionTime(value)}
              value={actionTime}
              className="picker"
              placeholder="Vyber čas"
            />
          </div>
          <div className="addNewAction-spentTimePicker">
            <div>
              {typKey === strings.action.toLowerCase() &&
                strings.spentTime1}
            </div>
            <br />
            {typKey === strings.action.toLowerCase() && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input
                  type="number"
                  id="timeSpent"
                  onChange={e => setTimeSpent(e.target.value)}
                  value={timeSpent}
                  step={0.5}
                  min={0}
                />
                <div>h</div>
              </div>
            )}
          </div>
          <br />
          <br />
          {typKey === strings.action.toLowerCase() && (
            <div>
              <p className="actionHeader">{strings.chooseAction1}</p>
              <div className="dropdownSelect">
                <Dropdown
                  trigger={['click']}
                  overlay={actionOptions}
                  placement="bottomCenter"
                >
                  <div>
                    {actionName} <Icon type="down" />
                  </div>
                </Dropdown>
              </div>
              {actionKey && (
                <div className="activityFormField">
                  <div>
                    <Input
                      placeholder={strings.comment}
                      value={comment}
                      onChange={e => setComment(e.target.value)}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          {typKey === 'client' && (
            <div className="dropdownSelect">
              <Dropdown trigger={['click']} overlay={clientsOptions}>
                <div>
                  {client} <Icon type="down" />
                </div>
              </Dropdown>
            </div>
          )}
          {typKey === 'club' && (
            <div>
              <Select
                mode="multiple"
                allowClear
                dropdownClassName="selectMenu"
                className="SelectInputMulti"
                placeholder={strings.chooseClients}
                onChange={handleChange}
              >
                {renderSelectOptions()}
              </Select>
            </div>
          )}
          <Button
            className="addButton"
            size="large"
            onClick={addAction}
            disabled={checkButton()}
          >
            {strings.add}
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ omama, menu, users }) => {
  let sortable = [];
  for (let item in omama.clients) {
    sortable.push(omama.clients[item]);
  }

  const orderedClients = sortable.sort((s1, s2) => {
    return s1.lastFirstName.localeCompare(s2.lastFirstName, 'sk', {
      sensitivity: 'accent'
    });
  });

  const filteredClients = orderedClients.filter(
    client => client.active === true
  );

  //to do actions refactor
  return {
    orderedClients: filteredClients,
    showMenu: menu.showMenu,
    omamas: users.omamas
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setLoading,
      getOmamasAction,
      getOmamaClientsAction,
      changePage: location => push(location)
    },
    dispatch
  );

AddNewAction.propTypes = {
  getOmamasAction: PropTypes.func,
  getOmamaClientsAction: PropTypes.func,
  setLoading: PropTypes.func,
  history: PropTypes.object,
  orderedClients: PropTypes.array,
  omamas: PropTypes.array
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewAction);
