import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Local imports
import './ScreeningDetailAdmin.scss';
import { strings } from '../strings/StringsProvider';

const collumns = [
  'motorika',
  'socialneSpravanie',
  'kognicia',
  'porozumenieReci',
  'hovorenie',
  'sebaobsluha',
  'internal',
  'external',
  'senzoricke'
];

const ssLayout = {
  ŠS1: {
    socialneSpravanie: true
  },
  ŠS2: {
    socialneSpravanie: true,
    internal: true
  },
  ŠS3: {
    kognicia: true,
    external: true
  },
  ŠS4: {
    external: true
  },
  ŠS5: {
    external: true
  },
  ŠS6: {
    socialneSpravanie: true,
    external: true
  },
  ŠS7: {
    internal: true
  },
  ŠS8: {
    internal: true
  },
  ŠS9: {
    socialneSpravanie: true,
    hovorenie: true,
    internal: true
  },
  ŠS10: {
    socialneSpravanie: true
  },
  ŠS11: {
    socialneSpravanie: true
  },
  ŠS12: {
    socialneSpravanie: true,
    senzoricke: true
  },
  ŠS13: {
    socialneSpravanie: true,
    external: true
  },
  ŠS14: {
    hovorenie: true
  },
  ŠS15: {
    socialneSpravanie: true
  }
};

const oLayout = {
  O1: {
    motorika: true,
    socialneSpravanie: true,
    kognicia: true,
    porozumenieReci: true,
    hovorenie: true,
    sebaobsluha: true,
    internal: true,
    external: true,
    senzoricke: true
  },
  O2: {
    senzoricke: true
  },
  O3: {
    senzoricke: true
  },
  O4: {
    hovorenie: true
  },
  O5: {
    porozumenieReci: true,
    senzoricke: true
  },
  O6: {
    socialneSpravanie: true,
    external: true
  },
  O7: {
    kognicia: true
  },
  O8: {
    socialneSpravanie: true,
    kognicia: true
  },
  O9: {
    motorika: true
  },
  O10: {
    motorika: true
  },
  O11: {
    socialneSpravanie: true,
    internal: true
  },
  O12: {
    socialneSpravanie: true
  },
  O13: {
    motorika: true,
    socialneSpravanie: true,
    kognicia: true,
    porozumenieReci: true,
    hovorenie: true,
    sebaobsluha: true,
    internal: true,
    external: true,
    senzoricke: true
  }
};

function ScreeningDetailAdmin({screening}) {

  const [shortenedAnswers, setShortenedAnswers] = useState(null);
  const [ssAnswers, setSsAnswers] = useState(null);
  const [oAnswers, setOAnswers] = useState(null);
  const [mAnswers, setMAnswers] = useState(null);
  const [socAnswers, setSocAnswers] = useState(null);
  const [kAnswers, setKAnswers] = useState(null);
  const [pAnswers, setPAnswers] = useState(null);
  const [hAnswers, setHAnswers] = useState(null);
  const [soAnswers, setSoAnswers] = useState(null);
  const [zAnswers, setZAnswers] = useState(null);

  

  useEffect(() => {
    
    setShortenedAnswers(screening.originalAnswers.map(
      element => ({ ...element, id: element.id.split('_')[1] })
    ));

  }, [screening.originalAnswers]);

  useEffect(() => {
    if (!shortenedAnswers) return;

    setSsAnswers(shortenedAnswers.filter(element =>
      element.id.startsWith('ŠS')
    ));
    setOAnswers(shortenedAnswers.filter(element =>
      element.id.startsWith('O')
    ));

    const tempmAnswers = shortenedAnswers.filter(element =>
      element.id.startsWith('M')
    );
    
    setMAnswers(Object.assign(
      ...tempmAnswers.map(v => ({ [v.id]: v.answer }))
    ));
    const tempsocAnswers = shortenedAnswers.filter(element =>
      element.id.startsWith('SS')
    );
    setSocAnswers(Object.assign(
      ...tempsocAnswers.map(v => ({ [v.id]: v.answer }))
    ));
    const tempkAnswers = shortenedAnswers.filter(element =>
      element.id.startsWith('K')
    );
    setKAnswers(Object.assign(
      ...tempkAnswers.map(v => ({ [v.id]: v.answer }))
    ));
    const temppAnswers = shortenedAnswers.filter(element =>
      element.id.startsWith('PR')
    );
    setPAnswers(Object.assign(
      ...temppAnswers.map(v => ({ [v.id]: v.answer }))
    ));
    const temphAnswers = shortenedAnswers.filter(element =>
      element.id.startsWith('H')
    );
    setHAnswers(Object.assign(
      ...temphAnswers.map(v => ({ [v.id]: v.answer }))
    ));
    const tempsoAnswers = shortenedAnswers.filter(element =>
      element.id.startsWith('SE')
    );
    setSoAnswers(Object.assign(
      ...tempsoAnswers.map(v => ({ [v.id]: v.answer }))
    ));
    const tempzAnswers = shortenedAnswers.filter(element =>
      element.id.startsWith('Z')
    );
    setZAnswers(Object.assign(
      ...tempzAnswers.map(v => ({ [v.id]: v.answer }))
    ));

  }, [shortenedAnswers]);

  const renderSpecifickeSpravanie = () => {
    return Object.keys(ssLayout).map((item, i) =>
      renderSsRow(ssLayout[item], item, i)
    );
  };

  const renderSsRow = (settings, question, index) => {
    return (
      <div key={index} className="screeningRow">
        <div className="screeningCell lightGrey">{question}</div>
        {collumns.map((item, i) => {
          const color = settings[item]
            ? ssAnswers.find(item => item.id === question).answer
            : 'empty';
          return (
            <div
              className={classNames(
                'screeningCell',
                { green: color === true },
                { red: color === false }
              )}
              key={i}
            ></div>
          );
        })}
      </div>
    );
  };

  const renderORow = (settings, question, index) => {
    return (
      <div key={index} className="screeningRow">
        <div className="screeningCell lightGrey">{question}</div>
        {collumns.map((item, i) => {
          const color = settings[item]
            ? oAnswers.find(item => item.id === question).answer
            : 'empty';
          return (
            <div
              className={classNames(
                'screeningCell',
                { green: color === true },
                { red: color === false }
              )}
              key={i}
            ></div>
          );
        })}
      </div>
    );
  };

  const renderObavy = () => {
    return Object.keys(oLayout).map((item, i) =>
      renderORow(oLayout[item], item, i)
    );
  };

  if (!shortenedAnswers) return null;

  return (
    <div>
      <div className="screeningRow">
        <div className="screeningCell lightGrey">{strings.funcionality}</div>
        <div className="screeningCell darkGrey">{strings.motorics}</div>
        <div className="screeningCell darkGrey">{strings.social}</div>
        <div className="screeningCell darkGrey">{strings.cognitive}</div>
        <div className="screeningCell darkGrey">{strings.understanding1}</div>
        <div className="screeningCell darkGrey">{strings.talking}</div>
        <div className="screeningCell darkGrey">{strings.self}</div>
        <div className="screeningCell darkGrey" style={{ width: '20%' }}>
          {strings.behaviour}
        </div>
        <div className="screeningCell darkGrey">{strings.risks}</div>
      </div>
      {mAnswers &&
        socAnswers &&
        kAnswers &&
        pAnswers &&
        hAnswers &&
        soAnswers &&
        zAnswers && (
          <>
            <div className="screeningRow">
              <div className="screeningCell lightGrey"></div>
              <div
                className={classNames(
                  'screeningCell',
                  { green: mAnswers.M1 === true },
                  { red: mAnswers.M1 === false }
                )}
              >
                M1
              </div>
              <div
                className={classNames(
                  'screeningCell',
                  { green: socAnswers.SS1 === true },
                  { red: socAnswers.SS1 === false }
                )}
              >
                Ss1
              </div>
              <div
                className={classNames(
                  'screeningCell',
                  { green: kAnswers.K1 === true },
                  { red: kAnswers.K1 === false }
                )}
              >
                K1
              </div>
              <div
                className={classNames(
                  'screeningCell',
                  { green: pAnswers.PR1 === true },
                  { red: pAnswers.PR1 === false }
                )}
              >
                P1
              </div>
              <div
                className={classNames(
                  'screeningCell',
                  { green: hAnswers.H1 === true },
                  { red: hAnswers.H1 === false }
                )}
              >
                H1
              </div>
              <div
                className={classNames(
                  'screeningCell',
                  { green: soAnswers.SE1 === true },
                  { red: soAnswers.SE1 === false }
                )}
              >
                So1
              </div>
              <div className="screeningCell"></div>
              <div className="screeningCell"></div>
              <div className="screeningCell"></div>
            </div>
            <div className="screeningRow">
              <div className="screeningCell lightGrey"></div>
              <div
                className={classNames(
                  'screeningCell',
                  { green: mAnswers.M2 === true },
                  { red: mAnswers.M2 === false }
                )}
              >
                M2
              </div>
              <div
                className={classNames(
                  'screeningCell',
                  { green: socAnswers.SS2 === true },
                  { red: socAnswers.SS2 === false }
                )}
              >
                Ss2
              </div>
              <div
                className={classNames(
                  'screeningCell',
                  { green: kAnswers.K2 === true },
                  { red: kAnswers.K2 === false }
                )}
              >
                K2
              </div>
              <div
                className={classNames(
                  'screeningCell',
                  { green: pAnswers.PR2 === true },
                  { red: pAnswers.PR2 === false }
                )}
              >
                P2
              </div>
              <div
                className={classNames(
                  'screeningCell',
                  { green: hAnswers.H2 === true },
                  { red: hAnswers.H2 === false }
                )}
              >
                H2
              </div>
              <div
                className={classNames(
                  'screeningCell',
                  { green: soAnswers.SE2 === true },
                  { red: soAnswers.SE2 === false }
                )}
              >
                So2
              </div>
              <div className="screeningCell"></div>
              <div className="screeningCell"></div>
              <div className="screeningCell"></div>
            </div>
            <div className="screeningRow">
              <div className="screeningCell lightGrey"></div>
              <div
                className={classNames(
                  'screeningCell',
                  { green: mAnswers.M3 === true },
                  { red: mAnswers.M3 === false }
                )}
              >
                M3
              </div>
              <div
                className={classNames(
                  'screeningCell',
                  { green: socAnswers.SS3 === true },
                  { red: socAnswers.SS3 === false }
                )}
              >
                Ss3
              </div>
              <div
                className={classNames(
                  'screeningCell',
                  { green: kAnswers.K3 === true },
                  { red: kAnswers.K3 === false }
                )}
              >
                K3
              </div>
              <div
                className={classNames(
                  'screeningCell',
                  { green: pAnswers.PR3 === true },
                  { red: pAnswers.PR3 === false }
                )}
              >
                P3
              </div>
              <div
                className={classNames(
                  'screeningCell',
                  { green: hAnswers.H3 === true },
                  { red: hAnswers.H3 === false }
                )}
              >
                H3
              </div>
              <div
                className={classNames(
                  'screeningCell',
                  { green: soAnswers.SE3 === true },
                  { red: soAnswers.SE3 === false }
                )}
              >
                So3
              </div>
              <div className="screeningCell"></div>
              <div className="screeningCell"></div>
              <div className="screeningCell"></div>
            </div>
            <div className="screeningRow">
              <div className="screeningCell lightGrey"></div>
              <div className="screeningCell"></div>
              <div className="screeningCell"></div>
              <div className="screeningCell"></div>
              <div className="screeningCell"></div>
              <div className="screeningCell"></div>
              <div
                className={classNames(
                  'screeningCell',
                  { green: zAnswers.Z1 === true },
                  { red: zAnswers.Z1 === false }
                )}
              >
                Z1
              </div>
              <div className="screeningCell"></div>
              <div className="screeningCell"></div>
              <div className="screeningCell"></div>
            </div>
          </>
        )}
      {/* KONIEC PRVEJ SEKCIE */}
      <div className="screeningRow">
        <div className="screeningCell lightGrey" />
        <div className="screeningCell darkGrey" />
        <div className="screeningCell darkGrey" />
        <div className="screeningCell darkGrey" />
        <div className="screeningCell darkGrey" />
        <div className="screeningCell darkGrey" />
        <div className="screeningCell darkGrey" />
        <div className="screeningCell darkGrey">{strings.internalRisks}</div>
        <div className="screeningCell darkGrey">{strings.externalRisks}</div>
        <div className="screeningCell darkGrey" />
      </div>
      <div className="screeningRow">
        <div className="screeningCell lightGrey">
          {strings.specificBehaviour}
        </div>
        <div className="screeningCell" />
        <div className="screeningCell" />
        <div className="screeningCell" />
        <div className="screeningCell" />
        <div className="screeningCell" />
        <div className="screeningCell" />
        <div className="screeningCell" />
        <div className="screeningCell" />
        <div className="screeningCell" />
      </div>
      {ssAnswers && renderSpecifickeSpravanie()}
      <div className="screeningRow">
        <div className="screeningCell lightGrey">{strings.worries}</div>
        <div className="screeningCell" />
        <div className="screeningCell" />
        <div className="screeningCell" />
        <div className="screeningCell" />
        <div className="screeningCell" />
        <div className="screeningCell" />
        <div className="screeningCell" />
        <div className="screeningCell" />
        <div className="screeningCell" />
      </div>
      {oAnswers && renderObavy()}
    </div>
  );
}

ScreeningDetailAdmin.propTypes = {
  screening: PropTypes.object
};

export default ScreeningDetailAdmin;
