import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Descriptions, Input, Icon } from 'antd';
import Button from './Button';
import jwt from 'jsonwebtoken';
import { strings } from '../strings/StringsProvider';
import imageCompression from 'browser-image-compression';
import { v4 as uuidv4 } from 'uuid';
import { getDevelopmentStage, editDevelopmentStage } from 'actions/admin.actions';

import '../index.scss';
import './DevelopmentStageInfo.scss';

const { TextArea } = Input;

function DevelopmentStageInfo({ type }) {
  const [developmentStageData, setDevelopmentStageData] = useState(null);
  const [description, setDescription] = useState('');
  const [uploadedPhotos, setUploadedPhotos] = useState([]);
  const [newPhotos, setNewPhotos] = useState([]);
  const [photosToRemove, setPhotosToRemove] = useState([]);
  const [videos, setVideos] = useState([]);
  const [videoUrl, setVideoUrl] = useState('');
  const [videoErrorMessage, setVideoErrorMessage] = useState('');
  const [edit, setEdit] = useState(false);
  const isAdmin = jwt.decode(localStorage.getItem('access-token')).role === 'admin';

  const dispatch = useDispatch();

  const developmentStage = useSelector(state => state.admin.developmentStage);

  useEffect(() => {
    dispatch(getDevelopmentStage(type));
  }, [dispatch, type]);

  useEffect(() => {
    if (developmentStage) {
      const { description, uploadedPhotos, videos, ...rest } = developmentStage;
      setDevelopmentStageData(rest);
      setDescription(description);
      setUploadedPhotos(uploadedPhotos);
      setVideos(videos);
    }
  }, [developmentStage]);

  const setTitle = timeRange => {
    let title = `${strings.psychomotoricsGrouwth} ${timeRange} `;

    switch (type) {
      case '2.PP':
        title += strings.week3;
        break;
      case '3.PP':
      case '4.PP':
        title += strings.week4;
        break;
      default:
        title += strings.oneMonth;
    }

    return title;
  };

  const getParsedYoutubeLinks = youtubeVideos => {
    const youtubeLinksParsed = youtubeVideos.map(video => {
      let videoID = video.split('/').pop();
      if (videoID.includes('watch')) {
        videoID = videoID.split('watch?v=').pop();
      }
      return videoID;
    });

    return youtubeLinksParsed;
  };

  const compressImage = async (event, useWebWorker = true) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    const orientation = await imageCompression.getExifOrientation(file);

    let options = {
      maxSizeMB: 1,
      useWebWorker: useWebWorker,
      exifOrientation: orientation
    };
    const output = await imageCompression(file, options);

    const base64ImagePhoto = await imageCompression.getDataUrlFromFile(output);
    setNewPhotos(prev => [...prev, base64ImagePhoto]);
  };

  const removeUploadedPhoto = item => {
    const updatedUploadedPhotos = uploadedPhotos.filter(photo => photo.photoId !== item.photoId);
    setPhotosToRemove(prevPhotos => [...prevPhotos, item.photoId]);
    setUploadedPhotos(updatedUploadedPhotos);
  };

  const removeNewPhoto = item => {
    const updatedNewPhotos = newPhotos.filter(photo => photo !== item);
    setNewPhotos(updatedNewPhotos);
  };

  const isValidYoutubeUrl = (url, videos) => {
    const youtubeUrlRegEx = /^(https?:\/\/)?(www\.)?((youtube\.com\/watch\?v=)|(youtu.be\/))([a-zA-Z0-9\-_]+)/;
    if (!youtubeUrlRegEx.test(url)) {
      setVideoErrorMessage(`${strings.wrongUrlFormatMessage} YouTube video`);
      return false;
    }

    if (videos.includes(url)) {
      setVideoErrorMessage(strings.duplicateVideoUrlMessage);
      return false;
    }

    return true;
  };

  const removeVideo = item => {
    const newVideos = videos.filter(video => video !== item);
    setVideos(newVideos);
  };

  const addNewVideo = item => {
    if (isValidYoutubeUrl(item, videos)) {
      const newVideos = [...videos, item];
      setVideos(newVideos);
      setVideoUrl('');
      setVideoErrorMessage('');
    }
  };

  const saveChanges = async () => {
    const newPhotoIds = [];

    for (let i = 0; i < newPhotos.length; i++) {
      const imageId = uuidv4();
      newPhotoIds.push(imageId);
    }

    const photoIds = [];
    uploadedPhotos.forEach(photo => photoIds.push(photo.photoId));

    photoIds.push(...newPhotoIds);

    const body = {
      ...developmentStageData,
      description,
      photosToRemove,
      photoIds,
      newPhotoIds,
      videos
    };

    dispatch(editDevelopmentStage(body, newPhotos)).then(() => {
      dispatch(getDevelopmentStage(type));
      setEdit(false);
      setNewPhotos([]);
      setPhotosToRemove([]);
    });
  };

  return (
    <div className="developmentStageInfo-main">
      {developmentStageData && !edit && (
        <>
          <Descriptions className="descriptions">
            <Descriptions.Item className="stageTitle displayItem">
              {setTitle(developmentStageData.timeRange)}
            </Descriptions.Item>
            <Descriptions.Item className="stageItem" label="Popis">
              {description}
            </Descriptions.Item>
            <Descriptions.Item className="stageItem" label="Fotografie">
              {uploadedPhotos.map(photo => (
                <img className="stagePhoto" alt="" src={photo.photoUrl} key={photo.photoId} />
              ))}
            </Descriptions.Item>
            <Descriptions.Item className="stageItem" label="Video">
              {getParsedYoutubeLinks(videos).map(youtubeLink => (
                <iframe
                  height="315px"
                  width="100%"
                  title="youtubevideo"
                  src={`https://www.youtube.com/embed/${youtubeLink}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  key={youtubeLink}
                ></iframe>
              ))}
            </Descriptions.Item>
          </Descriptions>
          {isAdmin && (
            <Button className="stageButton" onClick={() => setEdit(true)}>
              {strings.edit}
            </Button>
          )}
        </>
      )}

      {developmentStageData && edit && (
        <>
          <Descriptions className="descriptions">
            <Descriptions.Item className="stageTitle displayItem">
              {setTitle(developmentStageData.timeRange)}
            </Descriptions.Item>
            <Descriptions.Item className="stageItem" label="Popis">
              <div className="editStageFormField">
                <div>
                  <TextArea
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    placeholder={strings.developmentStageDescription}
                    autoSize={{ minRows: 3, maxRows: 6 }}
                  />
                </div>
              </div>
            </Descriptions.Item>
            <Descriptions.Item className="stageItem" label="Fotografie">
              <div className="accessory-detail-add-photo-field">
                <div className="upload-photo-button-container">
                  <label htmlFor="photo">
                    <div className="choose-photo">
                      <img alt="" className="photo-icon" src="/images/Icons/Galeria_ikona.png" />
                      <div>{strings.choosePhoto}</div>
                    </div>
                  </label>
                  <input
                    id="photo"
                    value={[]}
                    type="file"
                    accept="image/*"
                    onChange={compressImage}
                    style={{ display: 'none' }}
                  />
                </div>

                {uploadedPhotos.map(photo => (
                  <div className="upload-photo-container" key={photo.photoId}>
                    <img alt="empty" className="uploaded-photo" src={photo.photoUrl}></img>
                    <div className="xButton" onClick={() => removeUploadedPhoto(photo)}>
                      <Icon type="close" />
                    </div>
                  </div>
                ))}

                {newPhotos.map(photo => (
                  <div className="upload-photo-container" key={photo}>
                    <img alt="empty" className="uploaded-photo" src={photo}></img>
                    <div className="xButton" onClick={() => removeNewPhoto(photo)}>
                      <Icon type="close" />
                    </div>
                  </div>
                ))}
              </div>
            </Descriptions.Item>
            <Descriptions.Item className="stageItem" label="Video">
              <div className="editStageAddPhotoFormField">
                <div>
                  {videos.map(item => (
                    <div className="stageYoutubeItem" key={item}>
                      <div className="youtubeUrl">
                        <a href={item} target="_blank" rel="noopener noreferrer">
                          {item}
                        </a>
                      </div>
                      <Icon onClick={() => removeVideo(item)} type="minus-circle" />
                    </div>
                  ))}
                  <div className="stageYoutubeItem">
                    <Input
                      type="link"
                      value={videoUrl}
                      onChange={e => setVideoUrl(e.target.value)}
                      placeholder={strings.youtubeURL}
                    />
                    <Icon onClick={() => addNewVideo(videoUrl)} type="plus-circle" />
                  </div>
                  {videoErrorMessage !== '' && <div className="urlErrorMessage">{videoErrorMessage}</div>}
                </div>
              </div>
            </Descriptions.Item>
          </Descriptions>
          {isAdmin && (
            <Button className="stageButton" onClick={() => saveChanges()}>
              {strings.save}
            </Button>
          )}
        </>
      )}

      {!developmentStageData && <div>{strings.noDataFound}</div>}
    </div>
  );
}

DevelopmentStageInfo.propTypes = {
  type: PropTypes.string
};

export default DevelopmentStageInfo;
