import { keyBy } from 'lodash';

export const GET_STATISTICS = 'GET_STATISTICS';
export const GET_STATISTICS_CLIENTS = 'GET_STATISTICS_CLIENTS';
export const GET_STATISTICS_OMAMAS = 'GET_STATISTICS_OMAMAS';
export const GET_STATISTICS_OTHER = 'GET_STATISTICS_OTHER';
export const GET_TRENDS = 'GET_TRENDS';

const initialState = {
  allClients: null,
  activeClients: null,
  inactiveClients: null,
  allOmamas: null,
  activeOmamas: null,
  inactiveOmamas: null,
  allMentors: null,
  activeMentors: null,
  inactiveMentors: null,
  allSupervisors: null,
  activeSupervisors: null,
  inactiveSupervisors: null,  
  clientsByBirthDate: [],
  clientsByCity: [],
  doneLessons: 0,
  doneClubs: 0,
  totalLessons: 0,
  totalClubs: 0,
  totalParentClubs: 0,
  preSchoolClubCount: 0,
  tempActionsByOmamas: null,
  tempParentalCLubsByOmamas: null,
  activitiesStats: null,
  omamasStats: {
    allTime: null,
    thisYear: null
  },
  // evaluation: null, // TO DO nepouzite, stare
  // evaluationTotal: null, // TO DO nepouzite, stare
  actionAnswers: null,
  screeningAnswers: null,
  screeningTestsByID: null,
  filledScreeningsByType: null,
  omamas: null,
  omamasList: null,
  activitiesByID: null,
  omamasByID: null,
  screeningStatsTableData: null,
  clientList: null,
  omamaList: null,
  mentorList: null,
  supervisorList: null,
  clientsByID: null,
  countDoneLections: null,
  countDoneClubs: null,
  countParentalClub: null,
  counts: null,
  screenings: null,
  parentEngagement: null
};

export default (state = initialState, action) => {
  switch (action.type) {
      case GET_STATISTICS:
        return {
          ...state,
          allClients: action.payload.allClients,
          activeClients: action.payload.activeClients,
          inactiveClients: action.payload.inactiveClients,
          allOmamas: action.payload.allOmamas,
          activeOmamas: action.payload.activeOmamas,
          inactiveOmamas: action.payload.inactiveOmamas,
          allMentors: action.payload.allMentors,
          activeMentors: action.payload.activeMentors,
          inactiveMentors: action.payload.inactiveMentors,
          allSupervisors: action.payload.allSupervisors,
          activeSupervisors: action.payload.activeSupervisors,
          inactiveSupervisors: action.payload.inactiveSupervisors,            
          clientList: action.payload.clientList,
          clientsByID: keyBy(action.payload.clientList.total, '_id'),
          omamaList: action.payload.omamaList,
          mentorList: action.payload.mentorList,
          supervisorList: action.payload.supervisorList,
          countDoneLections: action.payload.countDoneLections,
          countDoneClubs: action.payload.countDoneClubs,
          countParentalClub: action.payload.countParentalClub
        };
      case GET_STATISTICS_CLIENTS:
        return {
          ...state,
          clientsByBirthDate: action.payload.clientsByBirthDate,
          clientsByCity: action.payload.clientsByCity
        };
      case GET_STATISTICS_OMAMAS:
        return {
          ...state,
          omamasStats: {
            ...state.omamasStats,
            ...action.payload.data
          }
        };
      case GET_STATISTICS_OTHER:
        return {
          ...state,
          doneLessons: action.payload.doneLessons,
          doneClubs: action.payload.doneClubs,
          totalLessons: action.payload.totalLessons,
          totalClubs: action.payload.totalClubs,
          totalParentClubs: action.payload.totalParentClubs,
          preSchoolClubCount: action.payload.preSchoolClubCount,
          tempActionsByOmamas : action.payload.tempActionsByOmamas,
          tempParentalCLubsByOmamas : action.payload.tempParentalCLubsByOmamas,
          activitiesStats: action.payload.tempActivities,
          omamas: action.payload.allOmamas,
          // evaluation: action.payload.evaluation,  // TO DO nepouzite, stare
          // evaluationTotal: action.payload.evaluationTotal, // TO DO nepouzite, stare
          actionAnswers: action.payload.actionAnswers,
          screeningAnswers: action.payload.screeningAnswers,
          activitiesByID: action.payload.activitiesByID,
          screeningTestsByID: action.payload.screeningTestsByID,
          filledScreeningsByType: action.payload.filledScreeningsByType,
          omamasByID: action.payload.omamasByID,  
          screeningStatsTableData: action.payload.screeningStatsTableData        
        };
      case GET_TRENDS:
        return {
          ...state,
          counts: action.payload.counts,
          screeningsByYear: action.payload.screeningsByYear,
          screeningsByLessonsCount: action.payload.screeningsByLessonsCount,
          parentEngagementByYear: action.payload.parentEngagementByYear,
          parentEngagementByLessonsCount: action.payload.parentEngagementByLessonsCount
        };
      default:
        return state;
  }
};
