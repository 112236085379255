import React from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import MentorActions from './MentorActions';
import './MentorPlan.scss';

const MentorPlan = () => {
  return (
    <div className="statsBackground">
      <div className="clientsBox">
        <div className="activities-main mentorPlanMobileVersion" data-test-id="mentorPlan-actions">
          <MentorActions />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: location => push(location),
    },
    dispatch
  );

MentorPlan.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(MentorPlan);
