import {
  GET_USERS,
  GET_OMAMAS,
  GET_REGIONS,
  GET_BIRTHDAY_MODAL,
  GET_CLOSED_BIRTHDAY_MODAL
} from '../reducers/users.reducer';
import axios from 'axios';

// Local imports
import { api } from '../conf';

export const getUsersAction = () => {
  return async dispatch => {
    const { data } = await axios.get(api.getUsers);
    return dispatch({
      type: GET_USERS,
      payload: data.users
    });
  };
};

export const getOmamasAction = () => {
  return async dispatch => {
    const { data } = await axios.get(api.getOmamas);
    return dispatch({
      type: GET_OMAMAS,
      payload: data.users
    });
  };
};

export const getRegions = () => async dispatch => {
  const { data } = await axios.get(api.getRegions);
  dispatch({ type: GET_REGIONS, payload: data.data });
};

export const getDisplayBirthdayModal = shouldShow => {
  return async dispatch => {
    return dispatch({
      type: GET_BIRTHDAY_MODAL,
      payload: shouldShow
    });
  };
};

export const getClosedBirthdayModal = shouldClose => {
  return async dispatch => {
    return dispatch({
      type: GET_CLOSED_BIRTHDAY_MODAL,
      payload: shouldClose
    });
  };
};
