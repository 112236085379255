import React, { useCallback, useEffect, useState } from 'react';
import { Descriptions, Input, Icon, Button, Dropdown, Menu, Modal } from 'antd';
import { strings } from 'strings/StringsProvider';
import jwt from 'jsonwebtoken';
import PropTypes from 'prop-types';
import { getOmamasAction } from 'actions/users.actions';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import imageCompression from 'browser-image-compression';
import { sortNames } from 'tools/utils';
import OmamaCheckbox from 'components/OmamaCheckbox';
import RentedAccessoryHistory from './RentedAccessoryHistory';
import { providedByMap } from 'data/enums';

import './AccessoryDetail.scss';

const { TextArea } = Input;

const providedByOptions = [
  { key: 'centralCoordinator', name: strings.centralCoordinator },
  { key: 'regionalCoordinator', name: strings.regionalCoordinator },
  { key: 'omamaOrParent', name: strings.omamaParent }
];

function AccessoryDetail({ accessory, edit, createAccessoryHandler, updateAccessoryHandler, removeAccessoryHandler }) {
  const [name, setName] = useState(accessory?.name || '');
  const [photoUrl, setPhotoUrl] = useState(accessory?.photoUrl || '');
  const [base64ImagePhoto, setBase64ImagePhoto] = useState('');
  const [description, setDescription] = useState(accessory?.description || '');
  const [providedBy, setProvidedBy] = useState(accessory?.providedBy || '');
  const [photoAdded, setPhotoAdded] = useState(accessory ? true : false);
  const [omamaIds, setOmamaIds] = useState(accessory?.users ? accessory.users.map(user => user._id) : []);
  const [addedTo, setAddedTo] = useState(0);
  const [removedFrom, setRemovedFrom] = useState(0);
  // const [photoErrorMessage, setPhotoErrorMessage] = useState('');
  const [removeWindowVisible, setRemoveWindowVisible] = useState(false);
  const [rentedAccessoryHistoryVisible, setRentedAccessoryHistoryVisible] = useState(null);
  const isAdmin = jwt.decode(localStorage.getItem('access-token')).role === 'admin';
  const initialOmamaIds = accessory?.users ? accessory.users.map(user => user._id) : [];

  const allOmamas = useSelector(state => state.users.omamas);
  const activeOmamas = allOmamas.filter(omama => omama.active === true);
  const omamas = sortNames(activeOmamas);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOmamasAction());
  }, [dispatch]);

  const removePhoto = () => {
    setPhotoUrl('');
    setPhotoAdded(false);
  };

  const compressImage = async (event, useWebWorker = true) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    const orientation = await imageCompression.getExifOrientation(file);

    let options = {
      maxSizeMB: 1,
      useWebWorker: useWebWorker,
      exifOrientation: orientation
    };
    const output = await imageCompression(file, options);

    const base64ImagePhoto = await imageCompression.getDataUrlFromFile(output);
    setBase64ImagePhoto(base64ImagePhoto);
  };

  const deleteImage = () => {
    setBase64ImagePhoto('');
  };

  const showProvidedByOptions = () => {
    return (
      <Menu onClick={onProvidedByChange}>
        {providedByOptions.map(answer => (
          <Menu.Item key={answer.key}>{answer.name}</Menu.Item>
        ))}
      </Menu>
    );
  };

  const onProvidedByChange = ({ key }) => {
    setProvidedBy(key);
  };

  const saveChanges = () => {
    const body = {
      name,
      description,
      providedBy,
      omamaIds
    };

    if (!edit) {
      createAccessoryHandler(body, base64ImagePhoto);
    } else {
      body.isPhotoChanged = base64ImagePhoto !== '';
      updateAccessoryHandler(body, accessory?._id, base64ImagePhoto);
    }
  };

  const removeAccessory = () => {
    removeAccessoryHandler(accessory?._id);
  };

  const updateOmamaIds = (omamaId, add, wasChecked) => {
    if (add) {
      setOmamaIds([...omamaIds, omamaId]);

      if (!wasChecked) setAddedTo(added => added + 1);
      else setRemovedFrom(removed => removed - 1);
    } else {
      const updatedOmamaIds = omamaIds.filter(id => id !== omamaId);
      setOmamaIds(updatedOmamaIds);

      if (wasChecked) setRemovedFrom(removed => removed + 1);
      else setAddedTo(added => added - 1);
    }
  };

  const addToAllOmamas = () => {
    const newOmamaIds = omamas.map(omama => omama._id);
    const totalAddedTo = newOmamaIds.length - initialOmamaIds.length;
    setOmamaIds(newOmamaIds);
    setAddedTo(totalAddedTo);
    setRemovedFrom(0);
  };

  const removeFromAllOmamas = () => {
    setOmamaIds([]);
    setAddedTo(0);
    setRemovedFrom(initialOmamaIds.length);
  };

  const displayMessage = () => {
    let addedMessage = '';
    let removedMessage = '';

    if (addedTo !== 0) {
      if (addedTo === 1) addedMessage += `${strings.accessoryAddToMessage} ${addedTo} ${strings.toOmama}.`;
      else addedMessage += `${strings.accessoryAddToMessage} ${addedTo} ${strings.toOmamas}.`;
    }

    if (removedFrom !== 0) {
      if (removedFrom === 1)
        removedMessage += `${strings.accessoryRemoveFromMessage} ${removedFrom} ${strings.toOmama}.`;
      else removedMessage += `${strings.accessoryRemoveFromMessage} ${removedFrom} ${strings.toOmamas}.`;
    }

    return (
      <div className="accessory-detail-message">
        <div>{addedMessage}</div>
        <div>{removedMessage}</div>
      </div>
    );
  };

  const openRemoveWindow = () => {
    setRemoveWindowVisible(true);
  };

  const closeRemoveWindow = () => {
    setRemoveWindowVisible(false);
  };

  const openRentedAccessoryHistoryWindow = (omamaId, omamaName) => {
    setRentedAccessoryHistoryVisible({ omamaId, omamaName });
  };

  const closeRentedAccessoryHistoryWindow = () => {
    setRentedAccessoryHistoryVisible(null);
  };

  const checkFields = useCallback(() => {
    if (name === '' || (!photoAdded && base64ImagePhoto === '') || description === '' || providedBy === '') {
      return true;
    }

    return false;
  }, [base64ImagePhoto, description, name, photoAdded, providedBy]);

  return (
    <>
      <Descriptions className="descriptions">
        <Descriptions.Item className="accessory-detail-item" label={strings.toolName}>
          <Input
            className="accessories-input"
            value={name}
            onChange={e => setName(e.target.value)}
            placeholder={strings.toolName}
          />
        </Descriptions.Item>
        <Descriptions.Item className="accessory-detail-item" label={strings.onePhoto}>
          <div className="accessory-detail-add-photo-field">
            <div>
              {photoAdded ? (
                <div className="upload-photo-container">
                  <img alt="empty" className="uploaded-photo" src={photoUrl}></img>
                  <div className="xButton" onClick={removePhoto}>
                    <Icon type="close" />
                  </div>
                </div>
              ) : (
                <div className="accessory-detail-photo">
                  {base64ImagePhoto === '' && (
                    <div className="upload-photo-button-container">
                      <label htmlFor="photo">
                        <div className="choose-photo">
                          <img alt="" className="photo-icon" src="/images/Icons/Galeria_ikona.png" />
                          <div>{strings.choosePhoto}</div>
                        </div>
                      </label>
                      <input
                        id="photo"
                        value={[]}
                        type="file"
                        accept="image/*"
                        onChange={compressImage}
                        style={{ display: 'none' }}
                      ></input>
                    </div>
                  )}

                  {base64ImagePhoto !== '' && (
                    <div className="upload-photo-container">
                      <img alt="empty" className="uploaded-photo" src={base64ImagePhoto}></img>
                      <div className="xButton" onClick={deleteImage}>
                        <Icon type="close" />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </Descriptions.Item>
        <Descriptions.Item className="accessory-detail-item" label={strings.providedBy}>
          <div className="accessory-detail-dropdown">
            <Dropdown overlay={showProvidedByOptions()} trigger={['click']}>
              <div>
                {providedBy === '' ? strings.chooseProvider : providedByMap[providedBy]}
                <Icon type="down" style={{ paddingLeft: '10px' }} />
              </div>
            </Dropdown>
          </div>
        </Descriptions.Item>
        <Descriptions.Item className="accessory-detail-item" label={strings.description}>
          <div className="accessory-detail-textarea">
            <div>
              <TextArea
                value={description}
                onChange={e => setDescription(e.target.value)}
                placeholder={strings.accessoryDescription}
                autoSize={{ minRows: 3, maxRows: 6 }}
              />
            </div>
          </div>
        </Descriptions.Item>
        <Descriptions.Item className="accessory-detail-item" label={strings.omamy}>
          <div>
            <Button className="accessories-detail-button" onClick={addToAllOmamas}>
              {strings.addToAll}
            </Button>
            <Button className="accessories-detail-button second-button" onClick={removeFromAllOmamas}>
              {strings.removeFromAll}
            </Button>
          </div>
          <div className="omama-list">
            {!isEmpty(omamas) &&
              omamas.map(omama => {
                return (
                  <div key={omama._id} className="omama-checkbox-container">
                    <OmamaCheckbox
                      id={omama._id}
                      name={omama.name}
                      wasChecked={initialOmamaIds.includes(omama._id)}
                      isChecked={omamaIds.includes(omama._id)}
                      updateOmamaIds={updateOmamaIds}
                      showRentedAccessoryHistory={openRentedAccessoryHistoryWindow}
                      newAccessory={!edit}
                    />
                  </div>
                );
              })}
          </div>
          {displayMessage()}
        </Descriptions.Item>
      </Descriptions>
      {isAdmin && (
        <div>
          <Button className="accessories-detail-button-save" onClick={saveChanges} disabled={checkFields()}>
            {strings.save}
          </Button>
          {edit && (
            <Button className="accessories-detail-button-remove second-button" onClick={openRemoveWindow}>
              {strings.remove}
            </Button>
          )}
          <Modal
            centered
            title={strings.removeAccessory}
            visible={removeWindowVisible}
            onCancel={closeRemoveWindow}
            onOk={removeAccessory}
          >
            {strings.removeAccessoryMessage}
          </Modal>
          <Modal
            centered
            width={600}
            className="rented-accessory-history-modal"
            title={`${strings.rentedAccessoryHistoryTitle} ${rentedAccessoryHistoryVisible?.omamaName}`}
            visible={rentedAccessoryHistoryVisible !== null}
            onCancel={closeRentedAccessoryHistoryWindow}
            footer={null}
          >
            {rentedAccessoryHistoryVisible && (
              <RentedAccessoryHistory accessory={accessory._id} omama={rentedAccessoryHistoryVisible?.omamaId} />
            )}
          </Modal>
        </div>
      )}
    </>
  );
}

AccessoryDetail.propTypes = {
  accessory: PropTypes.object,
  edit: PropTypes.bool,
  createAccessoryHandler: PropTypes.func,
  updateAccessoryHandler: PropTypes.func,
  removeAccessoryHandler: PropTypes.func
};

export default AccessoryDetail;
