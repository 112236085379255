import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ConfigProvider } from 'antd';
import store, { history } from './store';
import App from './containers/app';
import ScrollToTop from 'components/ScrollToTop';
import { ImageUrlProvider } from './context/ImageUrlContext';
import skSK from 'antd/es/locale/sk_SK';

import 'sanitize.css/sanitize.css';
import './index.scss';

const target = document.querySelector('#root');

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ImageUrlProvider>
        <div>
          <ConfigProvider locale={skSK}>
            <ScrollToTop />
            <App />
          </ConfigProvider>
        </div>
      </ImageUrlProvider>
    </ConnectedRouter>
  </Provider>,
  target
);
