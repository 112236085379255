import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Icon, Menu, Dropdown, Table, Tooltip } from 'antd';
import { Column, Line } from '@ant-design/plots';
import classNames from 'classnames';
import moment from 'moment';

// Local imports
import './Statistics.scss';
import {
  getStatistics,
  getStatisticsClients,
  getStatisticsOmamas,
  getStatisticsOther,
  getTrends
} from '../../actions/statistics.actions';
import { strings } from '../../strings/StringsProvider';
import ActionAnswersStats from '../../components/ActionAnswersStats';
import ScreeningAnswersStats from '../../components/ScreeningAnswersStats';

import { omamaLevelMap } from '../../data/enums';
import { stringSorter } from 'tools/utils';

const tableNames = [
  `S-PMV 2  (do 4. ${strings.week3})`,
  `S-PMV 3  (5-7. ${strings.week4})`,
  `S-PMV 4  (8-10. ${strings.week4})`,
  `S-PMV 5  (3-4. ${strings.oneMonth})`,
  `S-PMV 6  (5-6. ${strings.oneMonth})`,
  `S-PMV 7  (7-8. ${strings.oneMonth})`,
  `S-PMV 8  (9-10. ${strings.oneMonth})`,
  `S-PMV 9  (11-12. ${strings.oneMonth})`,
  `S-PMV 10  (15-18. ${strings.oneMonth})`,
  `S-PMV 11  (26-35. ${strings.oneMonth})`,
  `S-PMV 12  (36-40. ${strings.oneMonth})`
];

function Statistics() {
  const [clientsTab, setClientsTab] = useState(false);
  const [communitiesTab, setCommunitiesTab] = useState(false);
  const [othersTab, setOthersTab] = useState(false);
  const [trendsTab, setTrendsTab] = useState(false);
  const [omamasTab, setOmamasTab] = useState(false);
  const [initialClients, setInitialClients] = useState(true);
  const [initialCommunities, setInitialCommunities] = useState(true);
  const [initialOthers, setInitialOthers] = useState(true);
  const [initialTrends, setInitialTrends] = useState(true);
  const [initialOmamas, setInitialOmamas] = useState(true);
  const [screeningTableFor, setScreeningTableFor] = useState('all');
  const [statYear, setStatYear] = useState(parseInt(moment().format('YYYY')));

  const dispatch = useDispatch();

  const clientsByBirthDate = useSelector(state => state.statistics.clientsByBirthDate);
  const clientsByCity = useSelector(state => state.statistics.clientsByCity);
  const omamasStats = useSelector(state => state.statistics.omamasStats);
  const totalLessons = useSelector(state => state.statistics.totalLessons);
  const totalClubs = useSelector(state => state.statistics.totalClubs);
  const totalParentClubs = useSelector(state => state.statistics.totalParentClubs);
  const preSchoolClubCount = useSelector(state => state.statistics.preSchoolClubCount);
  const omamasByID = useSelector(state => state.statistics.omamasByID);
  const clientsByID = useSelector(state => state.statistics.clientsByID);
  const omamasCatalogue = useSelector(state => state.statistics.omamas);
  const actionAnswers = useSelector(state => state.statistics.actionAnswers);
  const screeningAnswers = useSelector(state => state.statistics.screeningAnswers);
  const filledScreeningsByType = useSelector(state => state.statistics.filledScreeningsByType);
  const screeningTestsByID = useSelector(state => state.statistics.screeningTestsByID);
  const screeningStatsTableData = useSelector(state => state.statistics.screeningStatsTableData);
  const clientList = useSelector(state => state.statistics.clientList);
  const omamaList = useSelector(state => state.statistics.omamaList);
  const mentorList = useSelector(state => state.statistics.mentorList);
  const supervisorList = useSelector(state => state.statistics.supervisorList);
  const countDoneLections = useSelector(state => state.statistics.countDoneLections);
  const countDoneClubs = useSelector(state => state.statistics.countDoneClubs);
  const countParentalClub = useSelector(state => state.statistics.countParentalClub);
  const counts = useSelector(state => state.statistics.counts);
  const screeningsByYear = useSelector(state => state.statistics.screeningsByYear);
  const screeningsByLessonsCount = useSelector(state => state.statistics.screeningsByLessonsCount);
  const parentEngagementByYear = useSelector(state => state.statistics.parentEngagementByYear);
  const parentEngagementByLessonsCount = useSelector(state => state.statistics.parentEngagementByLessonsCount);
  const users = useSelector(state => [
    {
      name: strings.omamy,
      total: state.statistics.allOmamas,
      active: state.statistics.activeOmamas,
      inactive: state.statistics.inactiveOmamas
    },
    {
      name: strings.mentors,
      total: state.statistics.allMentors,
      active: state.statistics.activeMentors,
      inactive: state.statistics.inactiveMentors
    },
    {
      name: strings.supervisors,
      total: state.statistics.allSupervisors,
      active: state.statistics.activeSupervisors,
      inactive: state.statistics.inactiveSupervisors
    },
    {
      name: strings.clients,
      total: state.statistics.allClients,
      active: state.statistics.activeClients,
      inactive: state.statistics.inactiveClients
    }
  ]);

  useEffect(() => {
    dispatch(getStatistics(moment().format('YYYY')));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getStatisticsOther(statYear.toString()));
    dispatch(getStatistics(statYear.toString()));
    dispatch(getStatisticsOmamas(statYear.toString(), omamasStats.allTime));
    dispatch(getStatisticsClients(statYear.toString()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statYear]);

  const renderUsers = (element, index) => {
    const omamasStatsTooltipTitle =
      element.name === 'Omamy' &&
      (statYear === moment().year() ? strings.tooltipStatsOmamasThisYear : strings.tooltipStatsOmamasOtherYears) +
        (omamasStats?.thisYear?.length === 1
          ? `${strings.wasActive1} ${omamasStats?.thisYear?.length} ${strings.oneOmama}.`
          : omamasStats?.thisYear?.length > 1 && omamasStats?.thisYear?.length < 5
          ? `${strings.wasActive2} ${omamasStats?.thisYear?.length} ${strings.twoAndMoreOmamas}.`
          : `${strings.wasActive3} ${omamasStats?.thisYear?.length} ${strings.fiveAndMoreOmamas}.`);

    let list = null;
    switch (element.name) {
      case strings.clients:
        list = clientList;
        break;
      case strings.omamy:
        list = omamaList;
        break;
      case strings.mentors:
        list = mentorList;
        break;
      case strings.supervisors:
        list = supervisorList;
        break;
      default:
        break;
    }

    if (clientList && omamaList && mentorList && supervisorList) {
      return (
        <div key={index} className="userCounter">
          <div className="counterMainTitle" data-test-id={`statistics-${element.name}`}>
            {element.name}
          </div>
          <Tooltip title={omamasStatsTooltipTitle} placement="left">
            <div
              className="firstCounter pointer"
              onClick={() => showList(list.active, element.name)}
              data-test-id={`statistics-${element.name}-firstCounter`}
            >
              <div className="firstNumber pointer" data-test-id={`statistics-${element.name}-firstNumber`}>
                {element.active}
              </div>

              {process.env.REACT_APP_LANGUAGE.toLowerCase() === 'sk' && (
                <div className="userCounterInnerText" data-test-id={`statistics-${element.name}-innerText`}>
                  {((element.name === 'Mentorky' && element.total === 1) ||
                    (element.name === 'Omamy' && element.total === 1)) &&
                    strings.isActive}
                  {((element.name === 'Mentorky' && element.total > 1) ||
                    (element.name === 'Omamy' && element.total > 1)) &&
                    strings.active2}
                  {((element.name === 'Klienti' && element.total === 1) ||
                    (element.name === 'Supervízori' && element.total === 1)) &&
                    strings.active}
                  {((element.name === 'Klienti' && element.total > 1) ||
                    (element.name === 'Supervízori' && element.total > 1) ||
                    (element.name === 'Supervízori' && element.total === 0)) &&
                    strings.active4}
                  {((element.name === 'Mentorky' && element.total === 0) ||
                    (element.name === 'Omamy' && element.total === 0) ||
                    (element.name === 'Klienti' && element.total === 0)) &&
                    strings.active2}
                </div>
              )}

              {process.env.REACT_APP_LANGUAGE.toLowerCase() === 'cz' && (
                <div className="userCounterInnerText" data-test-id={`statistics-${element.name}-innerText`}>
                  {((element.name === 'Mentorky' && element.total === 1) ||
                    (element.name === 'Omamy' && element.total === 1) ||
                    (element.name === 'Mentorky' && element.total > 1) ||
                    (element.name === 'Omamy' && element.total > 1) ||
                    (element.name === 'Klienti' && element.total === 1) ||
                    (element.name === 'Supervizoři' && element.total === 1) ||
                    (element.name === 'Klienti' && element.total > 1) ||
                    (element.name === 'Supervizoři' && element.total > 1)) &&
                    strings.active}
                  {((element.name === 'Mentorky' && element.total === 0) ||
                    (element.name === 'Omamy' && element.total === 0) ||
                    (element.name === 'Klienti' && element.total === 0) ||
                    (element.name === 'Supervizoři' && element.total === 0)) &&
                    strings.active3}
                </div>
              )}
            </div>
          </Tooltip>
          <div className="secondaryCounters">
            <div onClick={() => showList(list.total, element.name)}>
              <div
                className="secondNumber pointer"
                style={{ color: '#41aea6' }}
                data-test-id={`statistics-${element.name}-secondNumber-total`}
              >
                {element.total}
              </div>
              <div
                style={{
                  fontSize: '0.8em',
                  fontWeight: 'bold',
                  borderTop: '0.5px solid gray',
                  padding: '0 5px'
                }}
              >
                {strings.together}
              </div>
            </div>
            <div onClick={() => showList(list.inactive, element.name)}>
              <div className="secondNumber pointer" data-test-id={`statistics-${element.name}-secondNumber-nonActive`}>
                {element.inactive}
              </div>
              <div
                style={{
                  fontSize: '0.8em',
                  fontWeight: 'bold',
                  borderTop: '0.5px solid gray',
                  padding: '0 5px'
                }}
              >
                {element.name === strings.clients || element.name === strings.supervisors
                  ? strings.notActive
                  : strings.notActive1}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const renderAgeGroups = data => {
    const config = {
      data,
      xField: 'range',
      yField: 'count',
      label: {
        position: 'middle',
        style: {
          fill: '#FFFFFF',
          opacity: 0.6
        }
      },
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: true
        }
      },
      tooltip: {
        title: strings.listOfClients,
        customContent: (title, data) => {
          const clientList = data[0] ? data[0].data.clientList.toString().replaceAll(',', '<br>') : '';
          return `<div><strong>${title}</strong></div><br><div>${clientList}</div>`;
        }
      }
    };
    return data.length === 0 ? <div></div> : <Column {...config} />;
  };

  const renderTrendsCounts = data => {
    const config = {
      data,
      isGroup: true,
      xField: 'year',
      yField: 'count',
      seriesField: 'name',
      appendPadding: 50,
      label: {
        position: 'middle',
        style: {
          fill: '#FFFFFF',
          opacity: 0.6
        }
      },
      legend: {
        position: 'right'
      },
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: true
        },
        title: {
          text: strings.year
        }
      },
      yAxis: {
        title: {
          text: strings.numberOfActions
        }
      }
    };
    return !data || data.length === 0 ? <div></div> : <Column {...config} />;
  };

  const renderTrendsScreenings = data => {
    const config = {
      data,
      isGroup: true,
      xField: 'year',
      yField: 'count',
      seriesField: 'name',
      appendPadding: 50,
      color: ({ name }) => {
        if (name === strings.upperGreenZone) {
          return 'green';
        }
        if (name === strings.upperOrangeZone) {
          return 'orange';
        }
        if (name === strings.upperRedZone) {
          return 'red';
        }
        return 'gray';
      },
      label: {
        content: data => {
          return data.percentage + '%';
        },
        position: 'middle',
        style: {
          fill: '#FFFFFF',
          opacity: 0.6
        }
      },
      legend: {
        position: 'right'
      },
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: true
        },
        title: {
          text: 'rok'
        }
      },
      yAxis: {
        title: {
          text: strings.amountOfChildrens.toLowerCase()
        }
      },
      tooltip: {
        title: strings.amountOfChildrens,
        customContent: (title, data) => {
          let list = '';
          data.forEach(row => {
            list += row.data.name + ': ' + row.data.count + '<br>';
          });
          return `<div><strong>${title}</strong></div><br><div>${list}</div>`;
        }
      }
    };
    return !data || data.length === 0 ? <div></div> : <Column {...config} />;
  };

  const renderTrendsParentEngagement = data => {
    const config = {
      data,
      isGroup: true,
      isStack: true,
      xField: 'year',
      yField: 'count',
      seriesField: 'name',
      groupField: 'type',
      appendPadding: 50,
      color: ({ name }) => {
        if (name.includes(strings.veryOften.toLowerCase())) {
          return 'green';
        }
        if (name.includes(strings.sometimes.toLowerCase())) {
          return 'orange';
        }
        return 'red';
      },
      label: {
        content: data => {
          return data.percentage + '%';
        },
        position: 'middle',
        style: {
          fill: '#FFFFFF',
          opacity: 0.6
        }
      },
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: true
        },
        title: {
          text: strings.year.toLowerCase()
        }
      },
      yAxis: {
        title: {
          text: strings.amountOfChildrens.toLowerCase()
        }
      },
      tooltip: {
        title: strings.amountOfChildrens,
        customContent: (title, data) => {
          let list = '';
          data.forEach(row => {
            list += row.data.name + ': ' + row.data.count + '<br>';
          });
          return `<div><strong>${title}</strong></div><br><div>${list}</div>`;
        }
      }
    };
    return !data || data.length === 0 ? <div></div> : <Column {...config} />;
  };

  const renderTrendsParentEngagementLineGraph = data => {
    const config = {
      data,
      xField: 'year',
      yField: 'count',
      seriesField: 'name',
      appendPadding: 50,
      color: ({ name }) => {
        if (name.includes(strings.veryOften.toLowerCase())) {
          return 'green';
        }
        if (name.includes(strings.sometimes.toLowerCase())) {
          return 'orange';
        }
        return 'red';
      },
      label: {
        position: 'middle',
        style: {
          fill: '#FFFFFF',
          opacity: 0.6
        }
      },
      legend: {
        position: 'right'
      },
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: true
        },
        title: {
          text: strings.year.toLowerCase()
        }
      },
      yAxis: {
        title: {
          text: strings.countDoneLections.toLowerCase()
        }
      },
      tooltip: {
        formatter: data => ({
          name: data.name,
          value: data.count
        })
      }
    };
    return !data || data.length === 0 ? <div></div> : <Line {...config} />;
  };

  const renderParentEngagementByClientLessonsCount = data => {
    data.sort((a, b) => a.countDoneLections - b.countDoneLections);
    const config = {
      data,
      xField: 'countDoneLections',
      yField: 'parentEngagementCount',
      seriesField: 'parentEngagementQuestionName',
      appendPadding: 50,
      color: ({ parentEngagementQuestionName }) => {
        if (parentEngagementQuestionName.includes(strings.veryOften.toLowerCase())) {
          return 'green';
        }
        if (parentEngagementQuestionName.includes(strings.sometimes.toLowerCase())) {
          return 'orange';
        }
        return 'red';
      },
      label: {
        position: 'middle',
        style: {
          fill: '#FFFFFF',
          opacity: 0.6
        }
      },
      legend: {
        position: 'right'
      },
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: true
        },
        title: {
          text: strings.amountOfAbsolvedLectures.toLowerCase()
        }
      },
      yAxis: {
        title: {
          text: strings.amountOfParents.toLowerCase()
        }
      },
      tooltip: {
        formatter: data => ({
          name: data.parentEngagementQuestionName,
          value: data.parentEngagementCount
        })
      }
    };
    return !data || data.length === 0 ? <div></div> : <Line {...config} />;
  };

  const renderScreeningsByClientLessonsCount = data => {
    const config = {
      data,
      isGroup: true,
      xField: 'countDoneLections',
      yField: 'screeningPoints',
      seriesField: 'screeningType',
      appendPadding: 50,
      color: ({ screeningType }) => {
        if (screeningType === strings.upperGreenZone) {
          return 'green';
        }
        if (screeningType === strings.upperOrangeZone) {
          return 'orange';
        }
        return 'red';
      },
      label: {
        position: 'middle',
        style: {
          fill: '#FFFFFF',
          opacity: 0.6
        }
      },
      legend: {
        position: 'right'
      },
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: true
        },
        title: {
          text: strings.amountOfAbsolvedLectures.toLowerCase()
        }
      },
      yAxis: {
        title: {
          text: strings.amountOfPoints.toLowerCase()
        }
      },
      tooltip: {
        formatter: data => ({
          name: data.screeningType,
          value: data.screeningPoints
        })
      }
    };
    return !data || data.length === 0 ? <div></div> : <Column {...config} />;
  };

  const renderCommunities = (element, index) => {
    return (
      <div key={index} className="userCounter comCounter">
        <div className="counterMainTitle counterCommunityTitle">{element.city}</div>
        <div className="firstCounter">
          <div onClick={() => listOfClients(element.clients.active, true)} className="firstNumber pointer">
            {element.countActive}
          </div>
          {process.env.REACT_APP_LANGUAGE.toLowerCase() === 'sk' && (
            <div className="userCounterInnerText">
              {element.countTotal > 1 && strings.active4}
              {element.countTotal === 1 && strings.active}
              {element.countTotal === 0 && strings.active2}
            </div>
          )}
          {process.env.REACT_APP_LANGUAGE.toLowerCase() === 'cz' && (
            <div className="userCounterInnerText">
              {element.countTotal > 1 && strings.active}
              {element.countTotal === 1 && strings.active}
              {element.countTotal === 0 && strings.active3}
            </div>
          )}
        </div>
        <div className="secondaryCounters">
          <div>
            <div
              onClick={() => listOfClients([...element.clients.active, ...element.clients.nonActive], true)}
              className="secondNumber pointer"
              style={{ color: '#41aea6' }}
            >
              {element.countTotal}
            </div>
            <div
              style={{
                fontSize: '0.8em',
                fontWeight: 'bold',
                borderTop: '0.5px solid gray',
                padding: '0 5px'
              }}
            >
              {strings.together}
            </div>
          </div>
          <div>
            <div onClick={() => listOfClients(element.clients.nonActive, true)} className="secondNumber pointer">
              {element.countNonActive}
            </div>
            <div
              style={{
                fontSize: '0.8em',
                fontWeight: 'bold',
                borderTop: '0.5px solid gray',
                padding: '0 5px'
              }}
            >
              {strings.notActive}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderOmamaDesktopTable = data => {
    const omamaTableColumns = [
      {
        title: strings.omama,
        dataIndex: 'name',
        key: 'name',
        sorter: stringSorter('name')
      },
      {
        title: strings.region,
        dataIndex: 'region',
        key: 'region',
        sorter: stringSorter('region')
      },
      {
        title: strings.location,
        dataIndex: 'city',
        key: 'city',
        sorter: stringSorter('city')
      },
      {
        title: strings.level,
        dataIndex: 'level',
        key: 'level',
        render: level => <span>{omamaLevelMap[level]}</span>,
        sorter: (a, b) => a.level - b.level
      },
      {
        title: strings.activeClients,
        dataIndex: 'activeClients',
        key: 'activeClients',
        className: 'centeredColumn',
        sorter: (a, b) => a.activeClients - b.activeClients
      },
      {
        title: strings.nonClosedLessons,
        dataIndex: 'lessons',
        key: 'lessons',
        className: 'centeredColumn',
        render: (lessons, row) => (
          <div>
            <span>{lessons}</span>
            <span className="hidePercentage">{` (${Math.round(row.actionsPercentage)} %)`}</span>
          </div>
        ),
        sorter: (a, b) => a.lessons - b.lessons
      },
      {
        title: strings.preschoolClubs1,
        dataIndex: 'preschoolClubs',
        key: 'preschoolClubs',
        className: 'centeredColumn',
        sorter: (a, b) => a.preschoolClubs - b.preschoolClubs
      },
      {
        title: strings.parentClubs1,
        dataIndex: 'parentClubs',
        key: 'parentClubs',
        className: 'centeredColumn',
        sorter: (a, b) => a.parentClubs - b.parentClubs
      },
      {
        title: strings.supervisions2,
        dataIndex: 'supervisions',
        key: 'supervisions',
        className: 'centeredColumn',
        sorter: (a, b) => a.supervisions - b.supervisions
      },
      {
        title: strings.supervisionRatingAverage1,
        dataIndex: 'supervisionRatingAverage',
        key: 'supervisionRatingAverage',
        className: 'centeredColumn',
        render: supervisionRatingAverage => (supervisionRatingAverage ? `${supervisionRatingAverage}%` : '—'),
        sorter: (a, b) => a.supervisionRatingAverage - b.supervisionRatingAverage
      }
    ];
    let sortedData = data && data.sort((a, b) => a.level - b.level || b.lessons - a.lessons);
    return (
      <div className="omamaStatisticsTable">
        <Table
          size="small"
          className="table desktopOmamaStatsTable"
          rowKey={record => record._id}
          pagination={false}
          dataSource={sortedData}
          columns={omamaTableColumns}
          indentSize={0}
          scroll={{ y: 400 }}
        />
      </div>
    );
  };

  const filterSameLevel = data => {
    const levelsMap = {};
    data.forEach(omama => {
      const level = omama.level;
      if (!levelsMap[level]) {
        levelsMap[level] = [];
      }
      levelsMap[level].push(omama);
    });

    return levelsMap;
  };

  const renderMobileStatRow = (title, primaryData, secondaryData) => {
    return (
      <div className="statTable-statRow">
        <span className="statTable-statRow-title">{title}</span>
        <span className="statTable-statRow-number">
          {primaryData}
          {secondaryData !== '' && <span className="hidePercentage">{` (${Math.round(secondaryData)} %)`}</span>}
        </span>
      </div>
    );
  };

  const renderOmamaMobileTable = data => {
    const omamaMobileTableColumns = [
      {
        title: '',
        dataIndex: 'name',
        key: 'name'
      }
    ];
    const sameLevel = filterSameLevel(data);
    return (
      <div>
        {Object.keys(sameLevel).map(key => {
          return (
            <div key={key} className="mobileOmamaStatsTotal">
              <div className="mobileOmamaLevel">{omamaLevelMap[key]}</div>
              <Table
                className="mobileOmamaTable"
                size="small"
                scroll={{ y: 200 }}
                pagination={false}
                rowKey={record => record._id}
                columns={omamaMobileTableColumns}
                dataSource={sameLevel[key]}
                expandedRowRender={record => (
                  <div className="statTable">
                    {renderMobileStatRow(record.city, '', '')}
                    {renderMobileStatRow(strings.nonClosedLessons, record.lessons, record.actionsPercentage)}
                    {renderMobileStatRow(strings.activeClients, record.activeClients, '')}
                    {renderMobileStatRow(strings.preschoolClubs1, record.preschoolClubs, '')}
                    {renderMobileStatRow(strings.parentClubs1, record.parentClubs, '')}
                    {renderMobileStatRow(strings.supervisions2, record.supervisions, '')}
                    {renderMobileStatRow(
                      strings.supervisionRatingAverage1,
                      record.supervisionRatingAverage ? `${record.supervisionRatingAverage}%` : '—',
                      ''
                    )}
                  </div>
                )}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const renderSkriningRow = (rowData, key, i) => {
    return (
      <div className="skriningStatsTable-flexRow" key={i}>
        <div className="skriningStatCell screeningName" style={{ width: '200px' }}>
          {tableNames[i]}
        </div>
        <div
          className="skriningStatCell"
          style={{ width: '150px', backgroundColor: '#19ff62' }}
          onClick={() => listOfClients(rowData.greenClients)}
        >
          {rowData.totalClients ? ((rowData.green / rowData.totalClients) * 100).toFixed() : 0} % ({rowData.green}{' '}
          {rowData.green === 1
            ? strings.clients1
            : rowData.green > 1 && rowData.green < 5
            ? strings.clients2
            : strings.clients3}
          )
        </div>
        <div
          className="skriningStatCell"
          style={{ width: '150px', backgroundColor: '#ffcf30' }}
          onClick={() => listOfClients(rowData.orangeClients)}
        >
          {rowData.totalClients ? ((rowData.orange / rowData.totalClients) * 100).toFixed() : 0} % ({rowData.orange}{' '}
          {rowData.orange === 1
            ? strings.clients1
            : rowData.orange > 1 && rowData.orange < 5
            ? strings.clients2
            : strings.clients3}
          )
        </div>
        <div
          className="skriningStatCell"
          style={{ width: '150px', backgroundColor: '#ff5252' }}
          onClick={() => listOfClients(rowData.redClients)}
        >
          {rowData.totalClients ? ((rowData.red / rowData.totalClients) * 100).toFixed() : 0} % ({rowData.red}{' '}
          {rowData.red === 1
            ? strings.clients1
            : rowData.red > 1 && rowData.red < 5
            ? strings.clients2
            : strings.clients3}
          )
        </div>
        <div
          className="skriningStatCell"
          style={{ width: '150px', backgroundColor: 'white' }}
          onClick={() => listOfClients(rowData.worriesClients)}
        >
          {rowData.totalClients ? ((rowData.worries / rowData.totalClients) * 100).toFixed() : 0} % ({rowData.worries}{' '}
          {rowData.worries === 1
            ? strings.clients1
            : rowData.worries > 1 && rowData.worries < 5
            ? strings.clients2
            : strings.clients3}
          )
        </div>
      </div>
    );
  };

  const rednerLastSkriningRow = (green, orange, red, worries, total) => {
    return (
      <div className="skriningStatsTable-flexRow">
        <div className="skriningStatCell screeningName" style={{ width: '200px' }}>
          {strings.together}
        </div>
        <div className="skriningStatCell" style={{ width: '150px', backgroundColor: '#19ff62' }}>
          {total ? ((green / total) * 100).toFixed() : 0} % ({green}{' '}
          {green === 1 ? strings.clients1 : green > 1 && green < 5 ? strings.clients2 : strings.clients3})
        </div>
        <div className="skriningStatCell" style={{ width: '150px', backgroundColor: '#ffcf30' }}>
          {total ? ((orange / total) * 100).toFixed() : 0} % ({orange}{' '}
          {orange === 1 ? strings.clients1 : orange > 1 && orange < 5 ? strings.clients2 : strings.clients3})
        </div>
        <div className="skriningStatCell" style={{ width: '150px', backgroundColor: '#ff5252' }}>
          {total ? ((red / total) * 100).toFixed() : 0} % ({red}{' '}
          {red === 1 ? strings.clients1 : red > 1 && red < 5 ? strings.clients2 : strings.clients3})
        </div>
        <div className="skriningStatCell" style={{ width: '150px', backgroundColor: 'white' }}>
          {total ? ((worries / total) * 100).toFixed() : 0} % ( {worries}{' '}
          {worries === 1 ? strings.clients1 : worries > 1 && worries < 5 ? strings.clients2 : strings.clients3})
        </div>
      </div>
    );
  };

  const renderSkriningTable = data => {
    let greenCount, orangeCount, redCount, worriesCount, totalCount;
    greenCount = orangeCount = redCount = worriesCount = totalCount = 0;

    Object.keys(data).forEach(key => {
      greenCount += data[key].green;
      orangeCount += data[key].orange;
      redCount += data[key].red;
      worriesCount += data[key].worries;
      totalCount += data[key].totalClients;
    });
    return (
      <div className="skriningStatsTable">
        <div id="skriningStatsTableHeader" className="skriningStatsTable-flexRow" key={'header'}>
          <div className="skriningStatCell" style={{ width: '200px', fontWeight: 'bold', textAlign: 'center' }}>
            {strings.screening1}
          </div>
          <div
            className="skriningStatCell"
            style={{
              width: '150px',
              backgroundColor: '#19ff62',
              fontWeight: 'bold',
              textAlign: 'center'
            }}
          >
            {strings.upperGreenZone}
          </div>
          <div
            className="skriningStatCell"
            style={{
              width: '150px',
              backgroundColor: '#ffcf30',
              fontWeight: 'bold',
              textAlign: 'center'
            }}
          >
            {strings.upperOrangeZone}
          </div>
          <div
            className="skriningStatCell"
            style={{
              width: '150px',
              backgroundColor: '#ff5252',
              fontWeight: 'bold',
              textAlign: 'center'
            }}
          >
            {strings.upperRedZone}
          </div>
          <div
            className="skriningStatCell"
            style={{
              width: '150px',
              backgroundColor: 'white',
              fontWeight: 'bold',
              textAlign: 'center'
            }}
          >
            {strings.worries}
          </div>
        </div>
        {Object.keys(data).map((key, i) => renderSkriningRow(data[key], key, i))}
        {rednerLastSkriningRow(greenCount, orangeCount, redCount, worriesCount, totalCount)}
      </div>
    );
  };

  const renderActionAnswers = () => {
    return <ActionAnswersStats actionAnswers={actionAnswers} omamasByID={omamasByID} />;
  };

  const renderScreeningAnswers = () => {
    return (
      <ScreeningAnswersStats
        screeningAnswers={screeningAnswers}
        screeningTestsByID={screeningTestsByID}
        filledScreeningsByType={filledScreeningsByType}
      />
    );
  };

  const sortListOfNames = (list, type) => {
    if (type !== strings.clients) {
      return list;
    }
    const names = [];
    list.map(e => {
      return names.push(`${e.lastName} ${e.firstName}`);
    });
    return names.sort();
  };

  const handleTab = tab => () => {
    switch (true) {
      case tab === 'clientsTab':
        if (initialClients) dispatch(getStatisticsClients(statYear.toString()));
        setClientsTab(!clientsTab);
        setInitialClients(false);
        break;
      case tab === 'omamasTab':
        if (initialOmamas) dispatch(getStatisticsOmamas(statYear.toString()));
        setOmamasTab(!omamasTab);
        setInitialOmamas(false);
        break;
      case tab === 'communitiesTab':
        if (initialCommunities) dispatch(getStatisticsClients(statYear.toString()));
        setCommunitiesTab(!communitiesTab);
        setInitialCommunities(false);
        break;
      case tab === 'othersTab':
        if (initialOthers) dispatch(getStatisticsOther(statYear.toString()));
        setOthersTab(!othersTab);
        setInitialOthers(false);
        break;
      case tab === 'trendsTab':
        if (initialTrends) dispatch(getTrends());
        setTrendsTab(!trendsTab);
        setInitialTrends(false);
        break;
      default:
        break;
    }
  };

  const showList = (list, type) => {
    const names = sortListOfNames(list, type);
    Modal.info({
      title:
        type === strings.clients
          ? strings.listOfClients
          : type === strings.omamy
          ? strings.listOfOmamas
          : type === strings.supervisors
          ? strings.listOfSupervisors
          : strings.listOfMentors,
      centered: true,
      maskClosable: true,
      content: (
        <div>
          {names.map((item, i) => {
            return (
              <div key={i} style={{ fontWeight: 'bold', color: '#41aea6' }}>
                {type === strings.clients ? `${item}` : item.name}
              </div>
            );
          })}
          {list.length === 0 && <div>{strings.listIsEmpty}</div>}
        </div>
      )
    });
  };

  const listOfClients = (list, formated = false) => {
    Modal.info({
      title: strings.listOfClients,
      centered: true,
      maskClosable: true,
      content: (
        <div>
          {list.map((id, i) => (
            <div key={i} style={{ fontWeight: 'bold', color: '#41aea6' }}>
              {formated ? id : `${clientsByID[id].firstName} ${clientsByID[id].lastName}`}
            </div>
          ))}
          {list.length === 0 && <div>{strings.listIsEmpty}</div>}
        </div>
      )
    });
  };

  const onOmamaChange = item => {
    setScreeningTableFor(item.key);
  };

  const omamaOptions = () => {
    if (omamasCatalogue) {
      let options = [];
      options = [{ _id: 'all', name: strings.all1 }, ...omamasCatalogue];
      return (
        <Menu onClick={onOmamaChange}>
          {options.map(omama => (
            <Menu.Item key={omama._id}>{omama.name}</Menu.Item>
          ))}
        </Menu>
      );
    }
  };

  const changeStatYear = flag => {
    if (flag === '-') {
      setStatYear(statYear - 1);
    } else if (flag === '+') {
      setStatYear(statYear + 1);
    }
  };

  let sortedClientsByCity = clientsByCity.sort((a, b) => a.city.localeCompare(b.city));

  return (
    <div className="statsBackground">
      <div className="boxStats">
        <div className="admin-main">
          <div className="navigationStats">
            <Icon type="left" onClick={() => changeStatYear('-')} data-test-id="statistics-leftArrow" />
            <div style={{ margin: 'auto' }} data-test-id="statistics-statYear">
              {statYear}
            </div>
            <Icon type="right" onClick={() => changeStatYear('+')} data-test-id="statistics-rightArrow" />
          </div>

          <div className="mainStats">
            {/* Omamy, mentorky, supervizori, klienti */}
            <div className="firstSection">
              {users.map((element, i) => renderUsers(element, i))}
              {
                <div className="userCounter">
                  <div className="counterMainTitle" data-test-id="statistics-actions">
                    {strings.actionOneUpperCase}
                  </div>
                  <div className="firstCounter" data-test-id="statistics-actions-firstCounter">
                    <div className="firstNumber" data-test-id="statistics-actions-firstNumber">
                      {countDoneLections + countDoneClubs + countParentalClub}
                    </div>
                    <div className="userCounterInnerText" data-test-id="statistics-actions-firstNumber">
                      {strings.inThisYear}
                    </div>
                  </div>
                  <div className="secondaryCounters" data-test-id="statistics-actions-secondaryCounter">
                    <div>
                      <div
                        className="secondNumber"
                        style={{ color: '#41aea6' }}
                        data-test-id="statistics-actions-lections"
                      >
                        {countDoneLections}
                      </div>
                      <div
                        style={{
                          fontSize: '0.8em',
                          fontWeight: 'bold',
                          borderTop: '0.5px solid gray',
                          padding: '0 5px'
                        }}
                      >
                        {strings.moreThenFiveLessons}
                      </div>
                    </div>
                    <div>
                      <div
                        className="secondNumber"
                        style={{ color: '#41aea6' }}
                        data-test-id="statistics-actions-clubs"
                      >
                        {countDoneClubs}
                      </div>
                      <div
                        style={{
                          fontSize: '0.8em',
                          fontWeight: 'bold',
                          borderTop: '0.5px solid gray',
                          padding: '0 5px'
                        }}
                      >
                        {strings.clubs1}
                      </div>
                    </div>
                    <div>
                      <div className="secondNumber" data-test-id="statistics-actions-familyClubs">
                        {countParentalClub}
                      </div>
                      <div
                        style={{
                          fontSize: '0.8em',
                          fontWeight: 'bold',
                          borderTop: '0.5px solid gray',
                          padding: '0 5px'
                        }}
                      >
                        {strings.clubs2}
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
            {/* Komunity */}
            <div
              className="secondSection"
              style={{ borderTop: '1px solid #41aea6' }}
              data-test-id="statistics-community-tab"
            >
              <div className="statsTab" onClick={handleTab('communitiesTab')}>
                <div>{strings.comunities}</div>
                <Icon
                  type={classNames({ right: !communitiesTab }, { down: communitiesTab })}
                  style={{ marginLeft: '15px' }}
                />
              </div>
              {communitiesTab && (
                <div className="communitiesSection">
                  <div className="communities" data-test-id="statistics-community-counter">
                    {sortedClientsByCity.length !== 0 ? (
                      sortedClientsByCity.map((element, i) => renderCommunities(element, i))
                    ) : (
                      <span className="noDataInfo">{strings.noData}</span>
                    )}
                  </div>
                </div>
              )}
            </div>
            {/* Klienti */}
            <div className="secondSection" style={{ borderTop: '1px solid #41aea6' }}>
              <div className="statsTab" onClick={handleTab('clientsTab')} data-test-id="statistics-clients-tab">
                <div>{strings.clients}</div>
                <Icon type={classNames({ right: !clientsTab }, { down: clientsTab })} style={{ marginLeft: '15px' }} />
              </div>

              {clientsTab && (
                <div className="clientsStats" data-test-id="statistics-clients-stats">
                  <h4 className="clientsStats-header">{strings.ageGroups}</h4>
                  <div className="ageGroups">{renderAgeGroups(clientsByBirthDate)}</div>
                </div>
              )}
              {/* Omamy */}
              <div className="secondSection" style={{ borderTop: '1px solid #41aea6' }}>
                <div className="statsTab" onClick={handleTab('omamasTab')} data-test-id="statistics-omamas-tab">
                  <div>{strings.omamy}</div>
                  <Icon type={classNames({ right: !omamasTab }, { down: omamasTab })} style={{ marginLeft: '15px' }} />
                </div>
                {omamasTab && (
                  <div>
                    <div className="desktopOmamaStats" data-test-id="statistics-omamas-stats-desktop">
                      <div>
                        <h4>{strings.allUpper}</h4>
                        <div className="statsOverall" data-test-id="statistics-omamas-statsOverall-desktop">
                          {renderOmamaDesktopTable(omamasStats.allTime)}
                        </div>
                      </div>
                      <div>
                        <h4>{strings.thisYearSoFar}</h4>
                        <div className="statsThisYear" data-test-id="statistics-omamas-statsThisYear-desktop">
                          {renderOmamaDesktopTable(omamasStats.thisYear)}
                        </div>
                      </div>
                    </div>
                    <div className="mobileOmamaStats" data-test-id="statistics-omamas-stats-mobile">
                      <div>
                        <h4 className="mobileTableHeader">{strings.allUpper}</h4>
                        <div className="statsOverall" data-test-id="statistics-omamas-statsOverall-mobile">
                          {omamasStats?.allTime && renderOmamaMobileTable(omamasStats.allTime)}
                        </div>
                      </div>
                      <div>
                        <h4 className="mobileTableHeader">{strings.thisYearSoFar}</h4>
                        <div className="statsThisYear" data-test-id="statistics-omamas-statsThisYear-mobile">
                          {omamasStats?.thisYear && renderOmamaMobileTable(omamasStats.thisYear)}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div
                className="statsTab"
                style={{ borderTop: '1px solid #41aea6' }}
                onClick={handleTab('othersTab')}
                data-test-id="statistics-othesTab"
              >
                <div>{strings.otherStatistics}</div>
                <Icon type={classNames({ right: !othersTab }, { down: othersTab })} style={{ marginLeft: '15px' }} />
              </div>
              {othersTab && (
                <>
                  <div className="clientsStats" data-test-id="statistics-othersTab-clientsStats1">
                    <h3 className="clientsStats-info">
                      {strings.allAmountOfLessons}: &nbsp;&nbsp;
                      {totalLessons}
                    </h3>
                    <h3 className="clientsStats-info">
                      {strings.allAmountOfPreschoolClubs}: &nbsp;&nbsp;
                      {totalClubs}
                    </h3>
                    <h3 className="clientsStats-info">
                      {strings.allAmountOfParentClubs}: &nbsp;&nbsp;
                      {totalParentClubs}
                    </h3>
                    {moment().year() === statYear && (
                      <h3 className="clientsStats-info">
                        {strings.clientGoingToPreschoolClub}: &nbsp;&nbsp;
                        {preSchoolClubCount}
                      </h3>
                    )}
                  </div>
                  <div className="clientsStats" data-test-id="statistics-othersTab-clientsStats2">
                    <h4 className="clientsStats-header">{strings.growthScreening} </h4>
                    {omamasByID && (
                      <div
                        className="editProfile-dropdown"
                        data-test-id="statistics-othersTab-clientsStats2-omamaOption"
                      >
                        <Dropdown overlay={omamaOptions()} trigger={['click']} placement="bottomCenter">
                          <div>
                            {omamasByID[screeningTableFor] ? omamasByID[screeningTableFor].name : strings.all1}
                            <Icon type="down" />
                          </div>
                        </Dropdown>
                      </div>
                    )}
                    {screeningStatsTableData && renderSkriningTable(screeningStatsTableData[screeningTableFor])}
                    <h4 className="clientsStats-header">{strings.growthScreeningScore}</h4>
                    {filledScreeningsByType && screeningAnswers && screeningTestsByID && renderScreeningAnswers()}
                    <h4 className="clientsStats-header">{strings.parentEngagement}</h4>
                    {omamasByID && actionAnswers && renderActionAnswers()}
                  </div>
                </>
              )}
              <div className="statsTab" style={{ borderTop: '1px solid #41aea6' }} onClick={handleTab('trendsTab')}>
                <div data-test-id="statistics-trends-tab">{strings.trends}</div>
                <Icon type={classNames({ right: !trendsTab }, { down: trendsTab })} style={{ marginLeft: '15px' }} />
              </div>
              {trendsTab && (
                <div>
                  <div className="clientsStats" data-test-id="statistics-trends-amountActions">
                    <h4 className="clientsStats-header">{strings.amountOfActions}</h4>
                    <div data-test-id="statistics-trends-amountActions-graph1">
                      {counts ? (
                        renderTrendsCounts(counts.filter(a => a.name === strings.amountOfLessonsAndPreschoolClubs))
                      ) : (
                        <div></div>
                      )}
                    </div>
                    <div data-test-id="statistics-trends-amountActions-graph2">
                      {counts ? (
                        renderTrendsCounts(counts.filter(a => a.name === strings.countParentalClub))
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                  <div className="clientsStats" data-test-id="statistics-trends-screening">
                    <h4 className="clientsStats-header">{strings.developmentalScreening}</h4>
                    <div>{renderTrendsScreenings(screeningsByYear)}</div>
                    <div>
                      {screeningsByLessonsCount ? (
                        renderScreeningsByClientLessonsCount(screeningsByLessonsCount)
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                  <div className="clientsStats" data-test-id="statistics-trends-parentEngagement">
                    <h4 className="clientsStats-header">{strings.parentEngagement}</h4>
                    <div>{renderTrendsParentEngagement(parentEngagementByYear)}</div>
                    <h4 className="clientsStats-header">{strings.parentEngagement1}</h4>
                    <div>
                      {parentEngagementByYear ? (
                        renderTrendsParentEngagementLineGraph(
                          parentEngagementByYear.filter(a => a.type === 'question1')
                        )
                      ) : (
                        <div></div>
                      )}
                    </div>
                    <div>
                      {parentEngagementByLessonsCount ? (
                        renderParentEngagementByClientLessonsCount(
                          parentEngagementByLessonsCount.filter(a => a.parentEngagementQuestionNumber === 1)
                        )
                      ) : (
                        <div></div>
                      )}
                    </div>
                    <h4 className="clientsStats-header">{strings.parentEngagement2}</h4>
                    <div>
                      {parentEngagementByYear ? (
                        renderTrendsParentEngagementLineGraph(
                          parentEngagementByYear.filter(a => a.type === 'question2')
                        )
                      ) : (
                        <div></div>
                      )}
                    </div>
                    <div>
                      {parentEngagementByLessonsCount ? (
                        renderParentEngagementByClientLessonsCount(
                          parentEngagementByLessonsCount.filter(a => a.parentEngagementQuestionNumber === 2)
                        )
                      ) : (
                        <div></div>
                      )}
                    </div>
                    <h4 className="clientsStats-header">{strings.parentEngagement3}</h4>
                    <div>
                      {parentEngagementByYear ? (
                        renderTrendsParentEngagementLineGraph(
                          parentEngagementByYear.filter(a => a.type === 'question3')
                        )
                      ) : (
                        <div></div>
                      )}
                    </div>
                    <div>
                      {parentEngagementByLessonsCount ? (
                        renderParentEngagementByClientLessonsCount(
                          parentEngagementByLessonsCount.filter(a => a.parentEngagementQuestionNumber === 3)
                        )
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Statistics;
