import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Descriptions, Tag, Progress } from 'antd';
import { isEmpty } from 'lodash';
import { api } from '../../../conf';
import axios from 'axios';
import { strings } from '../../../strings/StringsProvider';

import './PreschoolScreeningResults.scss';

function PreschoolScreeningResults({ preschoolScreeningAnswers, showInMilestone = false }) {
  const [questions, setQuestions] = useState(null);

  const measurementQuestions = useSelector(state => state.measurements.preschoolScreeningQuestions);

  useEffect(() => {
    async function getPreschoolScreeningQuestions() {
      try {
        const preschoolScreeningForm = await axios.get(api.getMeasurementQuestions('preschoolScreening'));
        const preschoolScreeningQuestions = preschoolScreeningForm.data.data[0];
        setQuestions(preschoolScreeningQuestions);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }

    if (isEmpty(measurementQuestions)) {
      getPreschoolScreeningQuestions();
    } else {
      setQuestions(measurementQuestions);
    }
  }, [measurementQuestions]);

  const countPositiveAnswers = (categoryQuestions, group) => {
    let positiveAnswers = 0;
    categoryQuestions.forEach(questionNumber => {
      if (group === 'fineMotor' && questionNumber.key === 'question5') {
        if (preschoolScreeningAnswers[group][questionNumber.key].value === 1) {
          positiveAnswers += 1;
        }
      } else {
        if (preschoolScreeningAnswers[group][questionNumber.key].value) {
          positiveAnswers += 1;
        }
      }
    });

    return positiveAnswers;
  };

  return (
    <>
      {showInMilestone ? (
        <div className="preschool-screening-results-table-milestone">
          {questions &&
            questions.table.map(category => {
              const { title, group } = category;
              const positiveAnswers = countPositiveAnswers(category.questions, group);
              const totalQuestions = category.questions.length;
              const positivePercentage = (positiveAnswers / totalQuestions) * 100;
              let progressClassname = '';
              if (positivePercentage === 0 || positivePercentage === 100) {
                progressClassname = 'no-border';
              }
              return (
                <div key={title} className="preschool-screening-category">
                  <div>{title}</div>
                  <div>
                    <Progress
                      percent={positivePercentage}
                      className={progressClassname}
                      showInfo={false}
                      strokeWidth={8}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        <div>
          <div className="preschool-screening-results-table desktop-view">
            <Descriptions bordered title={strings.results} column={{ md: 1 }}>
              <Descriptions.Item className="first-row" label={strings.category}>
                {strings.measurementAnswers}
              </Descriptions.Item>
              {questions &&
                questions.table.map(category => {
                  const { title, group } = category;
                  const categoryLetter = title.startsWith('P') ? title.slice(0, 2) : title.slice(0, 1);
                  return (
                    <Descriptions.Item label={title} key={title}>
                      {category.questions.map((questionNumber, i) => {
                        const tag = categoryLetter + (i + 1);
                        const color = preschoolScreeningAnswers[group][questionNumber.key].value ? 'green' : 'red';
                        if (group === 'fineMotor' && questionNumber.key === 'question5') {
                          const gradeTag = tag + ' - ' + preschoolScreeningAnswers[group][questionNumber.key].value;
                          const color =
                            preschoolScreeningAnswers[group][questionNumber.key].value === 1 ? 'green' : 'red';
                          return (
                            <Tag color={color} key={gradeTag}>
                              {gradeTag}
                            </Tag>
                          );
                        } else {
                          return (
                            <Tag color={color} key={tag}>
                              {tag}
                            </Tag>
                          );
                        }
                      })}
                    </Descriptions.Item>
                  );
                })}
            </Descriptions>
          </div>
          <div className="preschool-screening-results-table mobile-view">
            {questions &&
              questions.table.map(category => {
                const { title, group } = category;
                const categoryLetter = title.startsWith('P') ? title.slice(0, 2) : title.slice(0, 1);
                return (
                  <div key={title} className="preschool-screening-category">
                    <div>{title}</div>
                    <div>
                      {category.questions.map((questionNumber, i) => {
                        const tag = categoryLetter + (i + 1);
                        const color = preschoolScreeningAnswers[group][questionNumber.key].value ? 'green' : 'red';
                        if (group === 'fineMotor' && questionNumber.key === 'question5') {
                          const gradeTag = tag + ' - ' + preschoolScreeningAnswers[group][questionNumber.key].value;
                          const color =
                            preschoolScreeningAnswers[group][questionNumber.key].value === 1 ? 'green' : 'red';
                          return (
                            <Tag color={color} key={gradeTag}>
                              {gradeTag}
                            </Tag>
                          );
                        } else {
                          return (
                            <Tag color={color} key={tag}>
                              {tag}
                            </Tag>
                          );
                        }
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
}

PreschoolScreeningResults.propTypes = {
  preschoolScreeningAnswers: PropTypes.object,
  showInMilestone: PropTypes.bool
};

export default PreschoolScreeningResults;
