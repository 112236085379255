import axios from 'axios';
import { requestLogger, responseErrorLogger } from './loggers';

axios.interceptors.request.use(request => requestLogger(request));
axios.interceptors.response.use(response => response, error => responseErrorLogger(error));

export const MAX_LIMIT = 10000;
let b;

// Supports requests from local network as well as from localhost

switch (process.env.NODE_ENV) {
  case 'development':
    b = `http://${window.location.hostname}:4000/api/`;
    break;
  case 'production':
  default:
    b = '/api/';
    break;
}

export const api = {
  signIn: b + 'users/sign-in',
  registerDevice: b + 'users/register-device',
  refreshToken: b + 'users/refresh',
  registerUser: b + 'v2/users/register',
  getUsers: b + 'users',
  getClient: clientId => b + `clients/${clientId}`,
  getThisOmamaInfo: b + 'omama',
  getActivities: (from, to) => b + `omama/from/${from}/to/${to}`,
  getFinishedLessonsCount: (from, to) => b + `omama/count/finished-lessons/${from}/${to}`,
  getFinishedClubsCount: (from, to) => b + `omama/count/finished-clubs/${from}/${to}`,
  addNewActivity: b + 'omama/add-new-activity',
  editActivity: b + 'admin/edit-activity',
  removeActivity: b + 'admin/remove-activity',
  getOmamaClients: b + 'omama/clients',
  getOmamaMilestones: omamaId => `${b}v2/omamas/milestones/${omamaId}`,
  getALLActivities: b + 'omama/activities',
  getBase64Image: (type, imageID) => `${b}omama/get-image/${type}/${imageID}`,
  getGalleryFolders: clientID => `${b}omama/get-gallery-folders/${clientID}`,
  addNewAction: b + 'v2/omamas/add-new-action',
  addNewPhoto: b + 'omama/add-new-photo',
  removePhoto: b + 'omama/remove-photo',
  addNewActionPhoto: b + 'omama/add-new-action-photo',
  removeActionPhoto: b + 'omama/remove-action-photo',
  getActionPhotos: (userType, actionID) => b + `actionPhotos/${userType}/${actionID}`,
  getOmamaActionGallery: omamaID => b + `omama/${omamaID}/action-gallery`,
  getAdminActivities: b + 'omama/activities',
  addNewClient: b + 'add-new-client',
  getOmamas: b + 'users/omamas',
  getSupervisors: () => b + 'supervisor/supervisors',
  getSupervisorsReport: () => b + 'v2/supervisor/all',
  getClients: (query = `limit=${MAX_LIMIT}`) => b + `clients?${query}`,
  getAllClients: b + 'clients',
  getSupervisionsOmama: (from, to) => b + `supervisor/supervisions/from/${from}/to/${to}`,
  getOmamaSupervisionsByOmamaID: (omamaID, from, to) =>
    b + `supervisor/supervisions/omama/${omamaID}/from/${from}/to/${to}`,
  postCheckedActivities: b + 'omama/update-action',
  getScreeningQuestions: b + 'skrining/questions',
  persistScreeningQuestions: b + 'skrining',
  getClientScreenings: b + 'skrining',
  getAllScreenings: (from, to) => b + `skrinings/from/${from}/to/${to}`,
  getOneTypeOneClientSkrinings: (clientId, type) => b + `skrining/${clientId}/${type}`,
  getClientsInitialScreenings: clientId => b + `skrining/initial/${clientId}`,
  getAllLatestScreenings: (from, to) => b + `skrining/latests/from/${from}/to/${to}`,
  // getClientsLatestScreenings: (clientId) => b + `skrining/${clientId}`,
  getClientScreeningQuestions: b + 'skrining/questions',
  getAllScreeningTests: b + '/skrining/allquestions',
  getStatistics: year => b + `base/stats/${year}`,
  getStatisticsClients: year => b + `client/stats/${year}`,
  getStatisticsOther: (from, to) => b + `other/stats/from/${from}/to/${to}`,
  getOmamasStatsRange: (fromDate, toDate) => b + `v2/omamas/stats/from/${fromDate}/to/${toDate}`,
  getOmamasRatingStatsRange: (fromDate, toDate) => b + `v2/omamas/stats/rating/from/${fromDate}/to/${toDate}`,
  getMentors: () => b + 'v2/users/mentors',
  getMentorsWorkRatingRange: () => b + 'mentor/work/rating',
  getOmamasStatsAll: b + 'v2/omamas/stats/all',
  getMentorClients: b + 'mentor/omami',
  updateMentorAction: actionId => b + `mentor/action/${actionId}`,
  deleteMentorAction: b + 'mentor/delete-mentor-action',
  changePassword: b + 'users/change/password',
  resetUserPassword: userId => b + `v2/users/${userId}/reset-password`,
  changeProfilePicture: b + 'users/change/profile/photo',
  getAdminOmamaClients: b + 'admin/omama/clients',
  getSpecificMentorAction: actionId => b + `mentor/action/${actionId}`,
  getSpecificSupervisorAction: actionId => b + `supervisor/action/${actionId}`,
  updateSupervisorAction: actionId => b + `supervisor/action/${actionId}`,
  getAdminMentorActions: (mentorId, from, to) => b + `admin/mentor/actions/${mentorId}/from/${from}/to/${to}`,
  getAdmiSupervisorActions: (mentorId, from, to) => b + `admin/supervisor/actions/${mentorId}/from/${from}/to/${to}`,
  getAdminSupervisorActions: (supervisorId, from, to) =>
    b + `admin/supervisor/actions/${supervisorId}/from/${from}/to/${to}`,
  getAdminOmamaActions: (omamaId, from, to) => b + `admin/omama/actions/${omamaId}/from/${from}/to/${to}`,
  getAdminOmamaAllParentalClubs: (community, from, to) =>
    b + `/admin/omama/actions/parentalClubs/community/${community}/from/${from}/to/${to}`,
  getAdminClientActions: (clientId, from, to) => b + `admin/client/actions/${clientId}/from/${from}/to/${to}`,
  getOmamaClientActions: (clientId, from, to) => b + `omama/client/actions/${clientId}/from/${from}/to/${to}`,
  getWorkSchedule: (from, to, reportTypeUrl) => `${b}reports/${reportTypeUrl}/work/schedules/from/${from}/to/${to}`,
  getAdminClientActionsCount: clientId => b + `admin/client/actions-count/${clientId}`,
  getTrends: b + 'admin/trends',
  adminAddNewActivity: b + '/add-new-activity',
  getAdminClient: clientId => `${b}admin/client/${clientId}`,
  getAdminUser: userId => `${b}admin/user/${userId}`,
  updateAction: b + 'omama/update-action',
  getOmamaReport: (from, to) => `${b}reports/omama/work/schedules/from/${from}/to/${to}`,
  getMentorReport: (from, to) => `${b}reports/mentor/work/schedules/from/${from}/to/${to}`,
  getScreeningsResultsReport: (year, type) => `${b}reports/clients/screenings/year/${year}/type/${type}`,
  getScreeningsDoneReport: b + 'reports/clients/screenings-status',
  editClient: b + 'admin/edit-client',
  getUsersReport: b + 'reports/users',
  getClientsReport: b + 'reports/clients',
  getTwoRedScreeningsReport: b + 'reports/clients/two-red-screenings',
  deactivateClient: b + 'admin/deactivate-client',
  editAdminUser: b + 'admin/edit/user',
  adminAddNewAction: b + 'admin/add-new-action',
  adminAddNewMentorAction: b + 'mentor/add-new-mentor-action',
  deleteAction: b + 'delete-action',
  deleteScreening: b + 'delete-screening',
  deleteClient: b + 'delete-client',
  deleteUser: b + 'delete-user',
  getActions: (from, to) => b + `omama/from/${from}/to/${to}`,
  getLastXClientActions: (clientID, status) => b + `omama/actions/${clientID}/${status}`,
  getLentTools: userId => b + `omama/lent-tools/${userId}`,
  notifications: b + 'notifications',
  getOmamasSupervisions: omamaId => b + `supervisor/${omamaId}/supervisions`,
  adminAddNewSupervisorAction: b + 'supervisor/add-new-supervisor-action',
  updateSupervision: supervisionId => b + `supervisor/supervisions/${supervisionId}`,
  getSupervision: supervisionId => b + `supervisor/supervisions/${supervisionId}`,
  findOmamaLection: (datetime, omamaId) => b + `supervisor/supervisions/find-lection/${datetime}/${omamaId}`,
  deleteSupervisorAction: b + 'supervisor/delete-supervisor-action',
  backendBaseUrl: process.env.NODE_ENV === 'development' ? 'http://localhost:4000' : '',
  getComprehensiveReport: (from, to, region) => `${b}reports/comprehensive/${from}/${to}/${region}`,
  getAllMeasurements: (type, region) => b + `reports/measurements/${type}/${region}`,
  getChecklistsInfo: b + 'supervisor/checklists-info',
  getChecklist: id => `${b}supervisor/checklist/${id}`,
  getRegions: b + 'other/regions',
  getDevelopmentStage: id => b + `development-stage/${id}`,
  editDevelopmentStage: b + 'development-stage',
  getClientMeasurement: (measurementType, clientId) => b + `measurements/${measurementType}/client/${clientId}`,
  getMeasurementQuestions: measurementType => b + `measurements/${measurementType}`,
  saveMeasurement: b + 'measurements/save',
  removeMeasurement: b + 'measurements/remove',
  updateClientMilestones: b + 'update-milestones',
  addMilestonePhoto: b + 'add-milestone-photo',
  removeMilestonePhoto: b + 'remove-milestone-photo',
  getAccessories: b + 'v2/accessories',
  getAccessory: accessoryId => b + `v2/accessories/${accessoryId}`,
  createAccessory: b + 'v2/accessories',
  updateAccessory: id => b + `v2/accessories/${id}`,
  removeAccessory: id => b + `v2/accessories/${id}`,
  getMissingAccessories: b + 'v2/accessories/missing',
  getMissingAccessoriesCount: b + 'v2/accessories/missing/count',
  getMissingAccessoriesHistory: b + 'v2/accessories/missing/history',
  getRentedAccessories: userId => b + `v2/accessories/all/user/${userId}`,
  getRentedAccessoryStatus: (accessoryId, userId) => b + `v2/accessories/${accessoryId}/user/${userId}/status`,
  changeOmamaAccessoryStatus: (accessoryId, userId) => b + `v2/accessories/${accessoryId}/user/${userId}/status`,
  sendErrorReport: b + 'v2/error-reports'
};
