import React from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';

import './SearchBar.scss';

function SearchBar({ placeholder, className, value, onChange, type, min, max, dataTestId }) {
  return (
    <div className={className ? `search-bar ${className}` : 'search-bar'}>
      <img src="/images/Icons/search-icon.svg" alt="Search icon" />
      <Input
        placeholder={placeholder}
        className="search-input"
        value={value}
        onChange={onChange}
        type={type}
        min={min}
        max={max}
        data-test-id={dataTestId}
      />
    </div>
  );
}

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  dataTestId: PropTypes.string
};

export default SearchBar;
