import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PropTypes from 'prop-types';
import { Icon, Modal } from 'antd';
import Base64Image from 'components/Base64Image';
import moment from 'moment';
import { strings } from 'strings/StringsProvider';
import axios from 'axios';
import { api } from '../../../conf';
import { resetUserPassword } from '../../../actions/admin.actions';
import { setLoading } from '../../../actions/status.actions';
import { getUsersAction } from '../../../actions/users.actions';
import Button from 'components/Button';
import { omamaLevelMap } from 'data/enums';

// Local imports
import './UserInfoPanel.scss';

function UserInfoPanel({ user, userRole, userName, switchEdit, doneLessons, doneClubs }) {
  const [showDeleteUser, setShowDeleteUser] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const history = useHistory();
  const displayedUserRole = user.role;

  const activeClients = useSelector(state => state.admin.clientsByStatus.active || []);
  const inactiveClients = useSelector(state => state.admin.clientsByStatus.inactive || []);

  const dispatch = useDispatch();

  const openDeleteUser = () => {
    setShowDeleteUser(true);
  };

  const cancelDeleteUser = () => {
    setShowDeleteUser(false);
  };

  const deleteUser = async () => {
    const body = {
      id: user._id,
      role: user.role
    };
    try {
      dispatch(setLoading(true));
      await axios.post(api.deleteUser, body);
      dispatch(getUsersAction()).then(() => {
        dispatch(setLoading(false));
        history.goBack();
      });
    } catch (error) {
      dispatch(setLoading(false));
    }
  };

  const openResetPassword = () => {
    setShowResetPassword(true);
  };

  const cancelResetPassword = () => {
    setShowResetPassword(false);
  };

  const resetPassword = async () => {
    cancelResetPassword();
    dispatch(resetUserPassword(user._id));
  };

  return (
    <div className="admin-userDetail-panelCont">
      {/* Desktop panel */}
      <div className="user-info-panel">
        <Base64Image
          className="user-info-photo"
          type="user"
          backUpImage="/images/cestavonLOGO_unofficial.png"
          imageID={user._id}
        />
        <b className="user-info-name">{user.name}</b>
        <div className="user-info-region">{user.region}</div>
        <div className="user-info-city">{user.city}</div>
        <div className="user-info">
          {displayedUserRole === 'omama' && (
            <div className="user-info-data">
              <div className="user-info-title">{strings.level}: </div>
              <div>{omamaLevelMap[user.level]}</div>
            </div>
          )}
          {displayedUserRole === 'omama' && (
            <div className="user-info-data">
              <div className="user-info-title">{strings.onPositionFrom}:</div>
              <div data-test-id="adminOmama-positionDate">
                {user.onLevelSince
                  ? moment(user.onLevelSince).format('D. M. YYYY')
                  : moment(user.created).format('D. M. YYYY')}
              </div>
            </div>
          )}
          <div className="user-info-data">
            <div className="user-info-title">{strings.inProgramFrom}</div>
            <div>{moment(user.created).format('D. M. YYYY')}</div>
          </div>
          {displayedUserRole !== 'admin' && (
            <div className="user-info-data">
              <div className="user-info-title">{strings.status}:</div>
              <div data-test-id="adminOmama-activeStatus">{user.active ? strings.isActive : strings.isNotActive}</div>
            </div>
          )}
          {displayedUserRole !== 'admin' && !user.active && (
            <div className="user-info-data">
              <div className="user-info-title">{strings.notActiveFrom}:</div>
              <div>{moment(user.deactivationDate).format('D. M. YYYY')}</div>
            </div>
          )}
          {displayedUserRole !== 'admin' && (
            <div className="user-info-data" data-test-id="userInfo-phoneNumber">
              <div className="user-info-title">{strings.telNumber1}: </div>
              <div>{user.phoneNumber}</div>
            </div>
          )}
          {displayedUserRole !== 'admin' && (
            <div className="user-info-data" data-test-id="userInfo-email">
              <div className="user-info-title">{strings.email}: </div>
              <div>{user.email}</div>
            </div>
          )}

          {displayedUserRole === 'omama' && (
            <div className="user-info-data">
              <div className="user-info-title">{strings.amountOfClients}:</div>
              <div className="admin-omama-clientcount-container" data-test-id="adminOmama-clientsStatus-activeInactive">
                <div data-test-id="adminOmama-clientsStatus-active">
                  <div className="admin-omama-clientcount">{activeClients.length}</div>
                  <div className="admin-omama-clientcount-text">{strings.active3}</div>
                </div>
                <div data-test-id="adminOmama-clientsStatus-inactive">
                  <div className="admin-omama-clientcount">{inactiveClients.length}</div>
                  <div className="admin-omama-clientcount-text">{strings.nonActive}</div>
                </div>
              </div>
            </div>
          )}
          {displayedUserRole === 'omama' && (
            <div className="user-info-data">
              <div className="user-info-title">{strings.amountOfLessons}:</div>
              <div className="admin-omama-clientcount-container">
                <div className="admin-omama-clientcount" data-test-id="adminOmama-clientsStatus-doneLections">
                  {doneLessons?.length}
                </div>
              </div>
            </div>
          )}
          {displayedUserRole === 'omama' && (
            <div className="user-info-data" data-test-id="adminOmama-preschoolsClubs">
              <div className="user-info-title">{strings.amountOfPreschoolClubs}:</div>
              <div className="admin-omama-clientcount-container">
                <div className="admin-omama-clientcount">{doneClubs?.length}</div>
              </div>
            </div>
          )}
        </div>

        {userRole === 'admin' && (
          <div className="clientEditButtons desktopOnly">
            <div className="user-info-edit-buttons-container">
              <div
                className="user-info-edit-button-container doNotShowOnMobile"
                data-test-id="userInfo-editProfileButton"
              >
                <Button className="user-info-edit-button" onClick={() => switchEdit(true)} type="secondary">
                  {strings.editProfile}
                </Button>
              </div>
              {user._id !== userName && (
                <div className="user-info-edit-button-container doNotShowOnMobile">
                  <Button className="user-info-edit-button" onClick={openDeleteUser} type="secondary">
                    {strings.deleteUser}
                  </Button>
                </div>
              )}
              <div className="user-info-edit-button-container doNotShowOnMobile">
                <Button className="user-info-edit-button" onClick={openResetPassword} type="secondary">
                  {strings.resetPassword}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Mobile panel */}
      <div className="editProfile-header showJustOnMobile" data-test-id="userInfo-backButton-mobile">
        <h3 style={{ cursor: 'pointer' }} onClick={history.goBack}>
          <div className="back-button" data-test-id="userInfo-backButton">
            <Icon type="left" /> {strings.backToTheList}
          </div>
        </h3>
      </div>
      <div className="user-info-panel user-info-panel-mobile" data-test-id="userInfo-panel-mobile">
        <Base64Image
          className="user-info-photo"
          type="user"
          backUpImage="/images/cestavonLOGO_unofficial.png"
          imageID={user._id}
        />
        <div className="user-info-panel-mobile_left" data-test-id="userInfo-info-mobile">
          <span className="user-info-name">{user.name}</span>
          {user.role === 'supervisor' ? (
            <b className="user-info-role">{strings.supervision1}</b>
          ) : (
            <b className="user-info-role">{user.role}</b>
          )}

          {user.role === 'omama' && (
            <b className="admin-omama-name" style={{ fontSize: '14px', fontWeight: 'normal' }}>
              {omamaLevelMap[user.level]}
            </b>
          )}
          {user.role === 'omama' && (
            <div className="admin-omama-inProgram">
              {strings.onPositionFrom}
              {': '}
              <span className="user-info-value-mobile">
                {user.onLevelSince
                  ? moment(user.onLevelSince).format('D. M. YYYY')
                  : moment(user.created).format('D. M. YYYY')}
              </span>
            </div>
          )}
          <div className="user-info-inProgram">
            {strings.inProgramFrom}{' '}
            <span className="user-info-value-mobile">{moment(user.created).format('D. M. YYYY')}</span>
          </div>
          {user.role !== 'admin' && (
            <div>
              <span>{strings.status}: </span>
              <span className="user-info-value-mobile">
                {user.active === true ? strings.isActive : strings.isNotActive}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="admin-generalUserInfoMobile showJustOnMobile" data-test-id="userInfo-mobile">
        <div className="admin-generalUserInfoMobile_row">
          <img alt="Ikona miesta" src="/images/Icons/location_icon.svg" />
          <span>{user.city}</span>
          {user.region && <span>, {user.region}</span>}
        </div>
        <div className="admin-generalUserInfoMobile_row">
          <img alt="Ikona e-mailu" src="/images/Icons/mail_icon.svg" />
          <span>{user.email}</span>
        </div>
        <div className="admin-generalUserInfoMobile_row">
          <img alt="Ikona telefónu" src="/images/Icons/phone_icon.svg" />
          <span>{user.phoneNumber}</span>
        </div>
      </div>

      {user.role === 'omama' && (
        <div className="admin-omama-clientCountMobile showJustOnMobile" data-test-id="adminOmama-clientCount-mobile">
          <div className="admin-omama-clientCountMobile-cont">
            <h3>{strings.clients}:</h3>
            <div className="admin-omama-clientCountMobile-cont-row">
              <div className="admin-omama-clientCountMobile-cont-row-cell">
                <div className="admin-omama-clientCountMobile-cont-row-cell-value">{activeClients.length}</div>
                <span>{strings.activeUpper}</span>
              </div>
              <div className="admin-omama-clientCountMobile-cont-row-cell">
                <div className="admin-omama-clientCountMobile-cont-row-cell-value" style={{ background: ' #BDBDBD' }}>
                  {inactiveClients.length}
                </div>
                <span>{strings.nonActiveUpper}</span>
              </div>
            </div>
          </div>
          <div className="admin-omama-clientCountMobile-cont">
            <h3>{strings.amountOfLessons}:</h3>
            <div className="admin-omama-clientCountMobile-cont-row">
              <div className="admin-omama-clientCountMobile-cont-row-cell">
                <div className="admin-omama-clientCountMobile-cont-row-cell-value">{doneLessons?.length}</div>
              </div>
            </div>
          </div>
          <div className="admin-omama-clientCountMobile-cont">
            <h3>{strings.amountOfPreschoolClubs}:</h3>
            <div className="admin-omama-clientCountMobile-cont-row">
              <div className="admin-omama-clientCountMobile-cont-row-cell">
                <div className="admin-omama-clientCountMobile-cont-row-cell-value">{doneClubs?.length}</div>
              </div>
            </div>
          </div>
        </div>
      )}

      {userRole === 'admin' && (
        <div
          data-test-id="userInfo-editSwitchButton-mobile"
          className="user-info-edit-buttons-container-mobile showJustOnMobile"
        >
          <div
            className="user-info-edit-button-mobile"
            style={{ flex: '1 1 45%' }}
            shape="round"
            onClick={() => switchEdit(true)}
          >
            {strings.edit}
          </div>
          {user._id !== userName && (
            <div className="user-info-edit-button-mobile" shape="round" onClick={openDeleteUser}>
              {strings.remove}
            </div>
          )}
          <div className="user-info-edit-button-mobile" shape="round" onClick={openResetPassword}>
            {strings.resetPassword}
          </div>
        </div>
      )}

      <Modal
        cancelText={strings.close}
        okText={strings.remove}
        title={strings.deletingUser}
        visible={showDeleteUser}
        onOk={() => deleteUser(cancelDeleteUser())}
        onCancel={cancelDeleteUser}
      >
        <p>{strings.deletingUserQuestion}</p>
      </Modal>

      <Modal
        cancelText={strings.no}
        okText={strings.yes}
        title={strings.resetPassword}
        visible={showResetPassword}
        onOk={resetPassword}
        onCancel={cancelResetPassword}
      >
        <p>{strings.resetPasswordConfirmation}</p>
      </Modal>
    </div>
  );
}

UserInfoPanel.propTypes = {
  user: PropTypes.object,
  userRole: PropTypes.string,
  userName: PropTypes.string,
  switchEdit: PropTypes.func,
  doneLessons: PropTypes.array,
  doneClubs: PropTypes.array
};

export default UserInfoPanel;
