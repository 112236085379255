const MAX_ARRAY_SIZE = 300;

let errorDetected = false;

const showBugButton = flag => (errorDetected = flag);

const saveLog = (log, noDuplicatesCheck = false) => {
  const localStorageData = localStorage.getItem('logs');
  const logs = localStorageData ? JSON.parse(localStorageData) : [];
  if (logs.length === MAX_ARRAY_SIZE) {
    logs.shift();
  }
  // if duplicate check is active, exit if last entry is the same
  const lastLog = logs.at(-1);
  if (noDuplicatesCheck && !(logs.length === 0 || lastLog?.type !== 'url' || lastLog?.url !== log.url)) {
    return;
  }
  if (
    noDuplicatesCheck === false &&
    lastLog?.type === 'error' &&
    lastLog?.message === log.message &&
    lastLog?.stack === log.stack
  ) {
    return;
  }

  logs.push(log);
  localStorage.setItem('logs', JSON.stringify(logs));
};

const requestLogger = request => {
  request.headers['access-token'] = localStorage.getItem('access-token');
  let requestData = undefined;
  const requestApiUrl = request.url.split('/api')[1];
  if (request.data && requestApiUrl) {
    requestData = { ...request.data };
    delete requestData.photo;
    delete requestData.thumbnail;
    delete requestData.password;
    delete requestData.oldPassword;
  }

  const requestRecord = {
    type: 'request',
    method: request.method,
    url: requestApiUrl || '/photo-upload',
    data: requestData,
    datetime: new Date()
  };
  saveLog(requestRecord);

  return request;
};

const responseErrorLogger = error => {
  const responseErrorRecord = {
    type: 'responseError',
    method: error.config.method,
    url: error.config.url.split('/api')[1],
    statusCode: error.response?.status,
    statusMessage: error.response?.data?.message || '',
    stack: error.response?.data?.stack || null,
    datetime: new Date()
  };
  saveLog(responseErrorRecord);
  errorDetected = true;

  return Promise.reject(error);
};

const urlLogger = url => {
  const urlRecord = {
    type: 'url',
    url,
    datetime: new Date()
  };
  saveLog(urlRecord, true);
};

const errorLogger = error => {
  const errorRecord = {
    type: 'error',
    message: error?.message || error?.[0]?.message,
    stack: error?.error?.stack || error?.[0]?.stack,
    datetime: new Date()
  };
  if (errorRecord.message || errorRecord.stack) {
    saveLog(errorRecord);
  }
  errorDetected = true;
};

export { requestLogger, responseErrorLogger, urlLogger, errorLogger, showBugButton, errorDetected };
