import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Icon, Button, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, uniqBy } from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import imageCompression from 'browser-image-compression';
import exifr from 'exifr';
import axios from 'axios';
import { api } from '../conf';
import { Modal, Popconfirm } from 'antd';
import Base64Image from 'components/Base64Image';
import DevelopmentStageInfo from './DevelopmentStageInfo';
import { getDateDiff } from 'tools/date.tools';
import { strings } from '../strings/StringsProvider.js';
import { getDevelopmentStage } from 'tools/date.tools';

import {
  getActivitiesAction,
  postActivitiesEnd,
  setClientLoading,
  handleActionQuestion
} from '../actions/clientActivities.action';

import { removePhotoAction } from 'actions/omama.actions';

//Local imports
import './ClientEnd.scss';
moment.locale('sk');

const actionQuestions = [strings.questionParentJoined, strings.questionParentPraised, strings.questionParentDecicated];

function ClientEnd({ showClientModal, actions, action, clients, client, changeComment }) {
  const [photos, setPhotos] = useState([]);
  const [thumbnails, setThumbnails] = useState([]);
  const [datesTaken, setDatesTaken] = useState([]);
  const [actionPhotos, setActionPhotos] = useState(null);
  const [photoDetail, setPhotoDetail] = useState({ visible: false, image: null });
  const [missingClient, setMissingClient] = useState({});
  const [hasPhoto, setHasPhoto] = useState(false);
  const [developmentStageVisible, setDevelopmentStageVisible] = useState(false);
  const [clientDevelopmentStage, setClientDevelopmentStage] = useState('');
  const firstLoading = useRef(true);

  const activities = useSelector(state => state.clientActivities.activitiesAll);
  const loading = useSelector(state => state.status.loading);
  const startWeek = useSelector(state => state.omama.startWeek);
  const endWeek = useSelector(state => state.omama.endWeek);

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      dispatch(getActivitiesAction());
      try {
        const actionList = Object.values(actions);
        const clientActions = actionList.filter(
          clientAction => clientAction.date === action.date && clientAction.clubNames === action.clubNames
        );
        let loadedActionPhotos = [];
        let promises = [];
        clientActions.forEach(clientAction => {
          promises.push(axios.get(api.getActionPhotos('client', clientAction._id)));
        });
        const resolvedPromises = await Promise.all(promises);
        resolvedPromises.forEach(res => {
          loadedActionPhotos = [...loadedActionPhotos, ...res.data.data];
        });
        loadedActionPhotos = loadedActionPhotos.filter(photo => photo._id.endsWith('_photo'));
        setActionPhotos(loadedActionPhotos);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error in ClientEnd useEffect', error);
      }

      if (!client) {
        const { data } = await axios.get(api.getClient(action.id));
        const client = data.data;
        client.name = `${client.firstName} ${client.lastName}`;
        setMissingClient(data.data);
      }
    }
    if (firstLoading.current) {
      fetchData();
      firstLoading.current = false;
    }
  }, [dispatch, action, actions, client]);

  const submitCheck = () => {
    let cansubmit = true;
    if (action.differentAction === 'club') {
      return true;
    }
    const values = Object.values(action.evaluation);
    for (let i = 0; i < values.length; i++) {
      if (values[i] === null) cansubmit = false;
    }
    return cansubmit;
  };

  const submitFinal = () => {
    if (action.differentAction === 'club') {
      const arrActions = Object.values(actions);
      arrActions.forEach(arrAction => {
        if (
          arrAction.differentAction === 'club' &&
          arrAction.date === action.date &&
          arrAction.clubIds.join() === action.clubIds.join()
        ) {
          return dispatch(
            postActivitiesEnd(arrAction, arrAction.id, photos, thumbnails, datesTaken, startWeek, endWeek, hasPhoto)
          );
        }
      });
      return;
    } else {
      dispatch(postActivitiesEnd(action, action.id, photos, thumbnails, datesTaken, startWeek, endWeek, hasPhoto));
    }
  };

  const compressImage = async (event, useWebWorker = true) => {
    dispatch(setClientLoading(true));
    const file = event.target.files[0];

    if (!file) {
      dispatch(setClientLoading(false));
      return;
    }

    const reader = new FileReader();

    reader.readAsArrayBuffer(file);

    const exif = await exifr.parse(file, { pick: ['CreateDate', 'DateTimeOriginal'] });
    const exifCreateDate = exif?.CreateDate?.getTime() || null;
    const exifDateTimeOriginal = exif?.DateTimeOriginal?.getTime() || null;

    const orientation = await imageCompression.getExifOrientation(file);

    const imageOptions = {
      maxSizeMB: 1,
      useWebWorker: useWebWorker,
      exifOrientation: orientation
    };
    const thumbnailOptions = {
      maxSizeMB: 0.2,
      useWebWorker: useWebWorker,
      exifOrientation: orientation
    };

    const output = await imageCompression(file, imageOptions);
    const thumbnail = await imageCompression(file, thumbnailOptions);

    const base64ImagePhoto = await imageCompression.getDataUrlFromFile(output);
    const base64ImageThumbnail = await imageCompression.getDataUrlFromFile(thumbnail);

    setPhotos([...photos, base64ImagePhoto]);
    setThumbnails([...thumbnails, base64ImageThumbnail]);
    setDatesTaken([...datesTaken, exifCreateDate || exifDateTimeOriginal || file.lastModified || null]);
    setHasPhoto(true);
    dispatch(setClientLoading(false));
  };

  const deleteImage = () => photo => {
    const tempPhotos = [...photos];
    const tempThumbnails = [...thumbnails];
    const tempDatesTaken = [...datesTaken];
    const index = photos.findIndex(element => element === photo);
    tempPhotos.splice(index, 1);
    tempThumbnails.splice(index, 1);
    tempDatesTaken.splice(index, 1);
    setPhotos(tempPhotos);
    setThumbnails(tempThumbnails);
    setDatesTaken(tempDatesTaken);
  };

  const formatChildWeek = birthDate => {
    const b_date = new Date(birthDate || client.birthDate);
    const a_date = new Date(action.date);
    const { months, weeks } = getDateDiff(b_date, a_date);

    const childWeek = months + 'm/' + weeks + 't';
    return childWeek;
  };

  const showDevelopmentStageDetail = (birthDate, actionDate) => {
    const developmentStage = getDevelopmentStage(birthDate, actionDate);

    setDevelopmentStageVisible(true);
    setClientDevelopmentStage(developmentStage);
  };

  const developmentStageDetailClose = () => {
    setDevelopmentStageVisible(false);
    setClientDevelopmentStage('');
  };

  const renderActivityList = checkedActivities => {
    const activitiesToRender = activities.filter(item => checkedActivities.includes(item._id));
    const sortedActivitiesToRender = activitiesToRender.sort((a, b) => a.code - b.code);

    if (isEmpty(sortedActivitiesToRender)) {
      return null;
    } else {
      return (
        <div style={{ width: '100%' }}>
          <div style={{ textAlign: 'center' }} className="groupList">
            {sortedActivitiesToRender.map((activity, i) => (
              <span key={i} style={{ marginRight: '6px', textAlign: 'center' }}>
                <div className="activityName">
                  <span className="monthWeek">{`${activity.code} - ${activity.mesiac}m/${activity.tyzden}t - `}</span>
                  {activity.name}
                </div>
                {i < activitiesToRender.length - 1 && ','}
              </span>
            ))}
          </div>
        </div>
      );
    }
  };

  const showPhotoDetail = image => {
    setPhotoDetail({ visible: true, image });
  };

  const hidePhotoDetail = () => {
    setPhotoDetail({ visible: false, image: null });
  };

  // Remove duplicate photos caused by having
  // multiple omama_actions per 1 preschool club
  const uniqPhotos = uniqBy(actionPhotos, 'date');

  const clientsObj = [];
  if (action.differentAction === 'club') {
    const clientsValue = Object.values(clients);
    action.clubIds.forEach(clubId => {
      clientsValue.forEach(e => {
        if (e._id === clubId) {
          clientsObj.push(e);
        }
      });
    });
  }

  const actionVals = Object.values(actions);
  const actionIDs = actionVals.filter(e => {
    if (action.differentAction === 'club') {
      if (e.differentAction === 'club' && e.clubIds.join() === action.clubIds.join()) {
        return e._id;
      }
    }
    return false;
  });

  const actionClient = client || missingClient;

  return (
    <div className="clientsActivities2">
      <div className="clientHeader">
        {strings.assessment}{' '}
        {action.differentAction !== 'club' ? strings.lessons1.toLowerCase() : strings.preschoolClub2}
      </div>
      <div className="actionHeader">
        <Icon type="close" className="actionCloseIcon" onClick={() => showClientModal('', action._id, 'clientEnd')} />
        <div className="action-info">{moment(action.date).format('dddd D. M. YYYY, HH:mm')}</div>
        {action.differentAction !== 'club' && actionClient ? (
          <div className="actionClientInfo">
            <div
              onClick={() => showDevelopmentStageDetail(actionClient.birthDate, action.date)}
              className="openDevStageModal"
            >
              {actionClient.name}{' '}
              {actionClient.birthDate && (
                <>
                  <span>{moment(actionClient.birthDate).format('D. M. YYYY')} </span>
                  <span>({formatChildWeek(actionClient.birthDate)})</span>
                </>
              )}
            </div>
            <div className="devStageTooltipIcon">
              <Tooltip title={strings.clientDevelopmentStageTooltip}>
                <Icon type="info-circle" />
              </Tooltip>
            </div>
          </div>
        ) : (
          ''
        )}
        <Modal
          centered
          title={strings.developmentStageDescription}
          visible={developmentStageVisible}
          onCancel={developmentStageDetailClose}
          onOk={developmentStageDetailClose}
          footer={null}
        >
          {clientDevelopmentStage !== '' && <DevelopmentStageInfo type={clientDevelopmentStage} />}
        </Modal>
        {clientsObj.map((e, i) => (
          <div key={i}>
            {e.name}
            <span style={{ paddingLeft: '10px' }}>{formatChildWeek(e.birthDate)}</span>
          </div>
        ))}
        {!actionClient.active && (
          <p className="missingClientWarning">
            {strings.thisClientDeactivatedOrOmama}
            <br />
          </p>
        )}
        {!clientsObj.every(clientObj => clientObj.name) && (
          <p className="missingClientWarning">
            {strings.someClientDeactivatedOrOmama}
            <br />
          </p>
        )}
      </div>

      <div className="mainClient">
        <div className="activityHeadline" style={{ paddingTop: '0', marginBottom: '10px' }}>
          {strings.activities}
        </div>
        <div
          className={
            action.differentAction !== 'club' ? 'clientEnd-activitiesList' : 'clientEnd-activitiesListNoBorder'
          }
        >
          {renderActivityList(action.checkedActivities)}
        </div>
        <div className="ratingSection">
          {action.differentAction !== 'club' && (
            <div>
              <div className="clientEnd-header">{strings.assessment}</div>
              {actionQuestions.map((question, i) => (
                <div key={i}>
                  <div className="clientend-question">{question}</div>
                  <div className="clientend-question__buttons">
                    <div
                      className={classNames({
                        selected: action.evaluation['question' + (i + 1)] === 'notAtAll'
                      })}
                      onClick={() => dispatch(handleActionQuestion(action._id, 'question' + (i + 1), 'notAtAll'))}
                    >
                      {strings.almostNotAtAll}
                    </div>
                    <div
                      className={classNames({
                        selected: action.evaluation['question' + (i + 1)] === 'sometimes'
                      })}
                      onClick={() => dispatch(handleActionQuestion(action._id, 'question' + (i + 1), 'sometimes'))}
                    >
                      {strings.sometimes}
                    </div>
                    <div
                      className={classNames({
                        selected: action.evaluation['question' + (i + 1)] === 'often'
                      })}
                      onClick={() => dispatch(handleActionQuestion(action._id, 'question' + (i + 1), 'often'))}
                    >
                      {strings.veryOften}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className="addphotoSection">
            <div className="activityHeadline">{strings.addPhoto}</div>
            <div className="photoButtons">
              <div
                className={classNames('photoButton', {
                  disabledPhotoButton: photos.length >= 3
                })}
              >
                <label htmlFor="photogallery">
                  <img alt="addphotofromgallery" className="photoIcon" src="/images/Icons/Galeria_ikona.png" />
                  <div>{strings.gallery}</div>
                </label>
              </div>
              <input
                id="photogallery"
                value={[]}
                type="file"
                accept="image/*"
                onChange={compressImage}
                style={{ display: 'none' }}
              ></input>
              <div
                className={classNames('photoButton', {
                  disabledPhotoButton: photos.length >= 3
                })}
              >
                <label htmlFor="photoCamera">
                  <img alt="addphotofromcamera" className="photoIcon" src="/images/Icons/Galeria_ikona.png" />
                  <div>{strings.camera}</div>
                </label>
              </div>
              <input
                id="photoCamera"
                value={[]}
                type="file"
                accept="image/*;capture=camera"
                onChange={compressImage}
                style={{ display: 'none' }}
              ></input>
            </div>
            {thumbnails && (
              <div className="tempPhotos">
                {photos.map((item, i) => (
                  <div key={i} className="photo-container">
                    <img className="clientEndImg" alt="" src={item}></img>
                    <div className="xButton" onClick={deleteImage(item)}>
                      <Icon type="close" />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="clientProfileAction-headline">
            {strings.photo}
            {action.differentAction === 'club' ? ' ' + strings.fromPreviousClub : ' ' + strings.fromPreviousLesson}
          </div>
          {uniqPhotos ? (
            <div className="ActionDetailAdmin-tempPhotos">
              {uniqPhotos.map((photo, i) => (
                <div key={i} className="ActionDetailAdmin-photo" onClick={() => showPhotoDetail(photo)}>
                  <Base64Image
                    className="imageWrapper"
                    key={i}
                    type="gallery-client"
                    imageID={photo._id}
                    showDate={true}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div style={{ marginBottom: '15px' }}>{strings.noPhotosFound}</div>
          )}

          <Modal
            className="modalGallery"
            visible={photoDetail.visible}
            onCancel={hidePhotoDetail}
            footer={null}
            closable={true}
          >
            <div id="modalGallery">
              <Base64Image
                key={photoDetail.image?._id}
                type="gallery-client"
                imageID={photoDetail.image?._id}
                showDate={true}
              />
              <Popconfirm
                title={strings.deletePhotoQuestion}
                okText={strings.yes}
                cancelText={strings.no}
                okType="default"
                onConfirm={() => {
                  const photosToRemove = actionPhotos.filter(photo => photo.date === photoDetail.image?.date);
                  photosToRemove.forEach(photo => {
                    const clientID = photo.clientID;
                    const photoID = photo._id.replace('_photo', '');
                    dispatch(removePhotoAction(clientID, photoID, action.omamaID));
                  });

                  const newActionPhotos = actionPhotos.filter(photo => photo?.date !== photoDetail.image?.date);

                  setActionPhotos(newActionPhotos);

                  hidePhotoDetail();
                }}
              >
                <Button type="primary" shape="round">
                  {strings.deletePicture}
                </Button>
              </Popconfirm>
            </div>
          </Modal>

          <div className="commentSection">
            <div className="activityHeadline">{strings.comment}</div>
            <textarea
              onChange={changeComment(actionIDs)}
              maxLength="300"
              value={action.comment ? action.comment : ''}
            />
            <div className="charCount">
              {action.comment ? action.comment.length : 0}
              /300
            </div>
          </div>

          <div className="client-button">
            <Button shape="round" disabled={!submitCheck() || loading} onClick={() => submitFinal(clientsObj)}>
              {strings.save}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

ClientEnd.propTypes = {
  showClientModal: PropTypes.func,
  changeComment: PropTypes.func,
  action: PropTypes.object,
  actions: PropTypes.object,
  client: PropTypes.object,
  clients: PropTypes.object
};

export default ClientEnd;
