import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Button, Icon, Dropdown, Menu, Popover } from 'antd';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { DatePicker } from 'antd';
import Autocomplete from '../components/Autocomplete';
import { Locations } from '../data/locations';
import { omamaLevelMap, employmentTypesMap, levels, employmentTypes } from 'data/enums';
import { strings } from '../strings/StringsProvider';
import { editAdminUser } from '../actions/admin.actions';
import { getUsersAction, getRegions } from '../actions/users.actions';
import { sortNames } from '../tools/utils';
import CustomButton from 'components/Button';

// Local imports
import './EditProfile.scss';

function EditProfile({ role, bckButton, fetchUser }) {
  const user = useSelector(state => state.admin.oneUser);
  const regions = useSelector(state => state.users.regions);
  const usersById = useSelector(state => state.users.byId);
  const allMentors = useSelector(state => state.users.byArr.filter(user => user.role === 'mentor'));

  const [formData, setFormData] = useState(() => {
    if (role === 'omama') {
      return {
        name: user.name,
        region: user.region,
        city: user.city,
        phoneNumber: user.phoneNumber,
        email: user.email,
        mentorID: user.mentorID || null,
        active: user.active,
        deactivationDate: user.deactivationDate,
        tfa: user.tfa,
        ipUmbrella: user.ipUmbrella,
        level: user.level || null,
        onLevelSince: user.onLevelSince || user.created,
        expectedClientCount: user.expectedClientCount || '',
        employmentType: user.employmentType || '',
        birthday: user.birthday
      };
    } else {
      return {
        name: user.name,
        region: user.region,
        city: user.city,
        phoneNumber: user.phoneNumber,
        email: user.email,
        accessoriesCoordinator: user.accessoriesCoordinator,
        active: user.active,
        deactivationDate: user.deactivationDate,
        tfa: user.tfa,
        ipUmbrella: user.ipUmbrella,
        birthday: user.birthday
      };
    }
  });
  const [formErrors, setFormErrors] = useState({
    city: '',
    phoneNumber: '',
    email: '',
    name: '',
    region: '',
    accessoriesCoordinatorType: ''
  });
  const [valid, setValid] = useState(true);
  const [isCoordinator, setIsCoordinator] = useState(user.accessoriesCoordinator ? true : false);
  const initialCheck = useRef(false);

  const dispatch = useDispatch();

  const validateInputField = (inputName, inputValue) => {
    switch (inputName) {
      case 'name':
        if (inputValue === '') {
          setFormErrors(prevFormErrors => ({ ...prevFormErrors, name: strings.fieldIsRequired }));
        } else {
          const inputValid = inputValue.match(/^[a-zA-Zá-žÁ-Ž\s]+$/);
          const previousErrorMessage = formErrors.name;
          const errorMessage = inputValid ? '' : strings.wrongNameFormat;
          if (errorMessage !== previousErrorMessage) {
            setFormErrors(prevFormErrors => ({ ...prevFormErrors, name: errorMessage }));
          }
        }
        break;
      case 'city':
        if (inputValue === '') {
          setFormErrors(prevFormErrors => ({ ...prevFormErrors, city: strings.fieldIsRequired }));
        } else {
          const previousErrorMessage = formErrors.city;
          const errorMessage = '';
          if (errorMessage !== previousErrorMessage) {
            setFormErrors(prevFormErrors => ({ ...prevFormErrors, city: errorMessage }));
          }
        }
        break;
      case 'phoneNumber':
        if (inputValue === '') {
          setFormErrors(prevFormErrors => ({ ...prevFormErrors, phoneNumber: strings.fieldIsRequired }));
        } else {
          const inputValid = inputValue.match(/^(\+421\s?|\+420\s?)(\s?[0-9]){9}$/);
          const previousErrorMessage = formErrors.phoneNumber;
          const errorMessage = inputValid ? '' : strings.phoneNumberNotValid;
          if (errorMessage !== previousErrorMessage) {
            setFormErrors(prevFormErrors => ({ ...prevFormErrors, phoneNumber: errorMessage }));
          }
        }
        break;
      case 'email':
        if (inputValue === '') {
          setFormErrors(prevFormErrors => ({ ...prevFormErrors, email: strings.fieldIsRequired }));
        } else {
          const inputValid = inputValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
          const previousErrorMessage = formErrors.email;
          const errorMessage = inputValid ? '' : strings.wrongEmailFormat;
          if (errorMessage !== previousErrorMessage) {
            setFormErrors(prevFormErrors => ({ ...prevFormErrors, email: errorMessage }));
          }
        }
        break;
      case 'region':
        if (isCoordinator) {
          if (!inputValue) {
            setFormErrors(prevErrors => ({ ...prevErrors, region: strings.requiredForAccessoriesCoordinator }));
          } else {
            const previousErrorMessage = formErrors.region;
            const errorMessage = '';
            if (errorMessage !== previousErrorMessage) {
              setFormErrors(prevFormErrors => ({ ...prevFormErrors, region: errorMessage }));
            }
          }
        }
        break;
      default:
        break;
    }
  };

  if (!initialCheck.current) {
    validateInputField('name', user.name);
    validateInputField('city', user.city);
    validateInputField('phoneNumber', user.phoneNumber);
    validateInputField('email', user.email);
    if (isCoordinator) {
      validateInputField('region', user.region);
    }
    initialCheck.current = true;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getRegions());
  }, [dispatch]);

  useEffect(() => {
    if (isEmpty(allMentors)) {
      dispatch(getUsersAction());
    }
  }, [dispatch, allMentors]);

  useEffect(() => {
    let formValid = true;
    Object.values(formErrors).forEach(errorMessage => {
      if (errorMessage !== '') {
        formValid = false;
      }
    });

    setValid(formValid);
  }, [formErrors]);

  const changeInputField = stateName => value => {
    setFormData({ ...formData, [stateName]: value.target.value });
    validateInputField(stateName, value.target.value);
  };

  const changeCityField = value => {
    setFormData({ ...formData, city: value });
    validateInputField('city', value);
  };

  const toggleValue = (stateName, flag) => () => {
    setFormData(prevFormData => ({ ...prevFormData, [stateName]: flag }));
    if (stateName === 'active') {
      if (flag) {
        setFormData(prevFormData => ({
          ...prevFormData,
          deactivationDate: null
        }));
      } else {
        setFormData(prevFormData => ({
          ...prevFormData,
          deactivationDate: user.deactivationDate || moment().format()
        }));
      }
    }
  };

  const checkRegion = () => {
    if (!formData.region) {
      setFormErrors(prevFormErrors => ({ ...prevFormErrors, region: strings.requiredForAccessoriesCoordinator }));
    }
  };

  const changeAccessoryCoordinatorStatus = setCoordinator => {
    if (setCoordinator) {
      setIsCoordinator(true);
      setFormErrors(prevFormErrors => ({
        ...prevFormErrors,
        accessoriesCoordinatorType: strings.chooseCoordinatorType
      }));
      checkRegion();
    } else {
      setIsCoordinator(false);
      setFormData({ ...formData, accessoriesCoordinator: null });
      setFormErrors(prevFormErrors => ({ ...prevFormErrors, region: '' }));
    }
  };

  const changeAccessoryCoordinatorType = coordinatorType => {
    if (coordinatorType === 'regionalCoordinator') {
      toggleValue('accessoriesCoordinator', 'regionalCoordinator')();
    } else {
      toggleValue('accessoriesCoordinator', 'centralCoordinator')();
    }

    setFormErrors(prevFormErrors => ({ ...prevFormErrors, accessoriesCoordinatorType: '' }));
  };

  const onMentorChange = item => {
    setFormData({ ...formData, mentorID: item.key });
  };

  const mentorOptions = () => {
    const activeMentors = allMentors.filter(mentor => mentor.active);
    const sortedMentors = sortNames(activeMentors);

    return (
      <Menu onClick={onMentorChange}>
        {sortedMentors.map(mentor => (
          <Menu.Item key={mentor._id}>{mentor.name}</Menu.Item>
        ))}
      </Menu>
    );
  };

  const onLevelChange = item => {
    setFormData({ ...formData, level: Number(item.key) });
  };

  const levelOptions = () => (
    <Menu onClick={onLevelChange}>
      {levels.map(l => (
        <Menu.Item key={l.key}>{l.level}</Menu.Item>
      ))}
    </Menu>
  );

  const changeLevelDate = () => date => {
    setFormData({ ...formData, onLevelSince: date });
  };

  const changeBirthdayDate = () => date => {
    setFormData({ ...formData, birthday: date });
  };

  const getDateObject = date => {
    return moment(date).isValid() ? moment(date) : null;
  };

  const onClientCountChange = clientCount => {
    setFormData({ ...formData, expectedClientCount: Number(clientCount.key) });
  };

  const clientCountOptions = () => (
    <Menu onClick={onClientCountChange}>
      {[...Array(31).keys()].map(x => (
        <Menu.Item key={x}>{x}</Menu.Item>
      ))}
    </Menu>
  );

  const onEmploymentTypeChange = employmentType => {
    setFormData({ ...formData, employmentType: Number(employmentType.key) });
  };

  const employmentTypeOptions = () => (
    <Menu onClick={onEmploymentTypeChange}>
      {employmentTypes.map(type => (
        <Menu.Item key={type.key}>{type.value}</Menu.Item>
      ))}
    </Menu>
  );

  const save = callback => {
    const isValidOnLevelSince = moment(formData.onLevelSince).isValid();
    if (!formData.onLevelSince || !isValidOnLevelSince) {
      formData.onLevelSince = new Date().toISOString();
    }
    if (role === 'mentor' || role === 'supervisor') {
      delete formData.accessoriesCoordinator;
    }
    dispatch(editAdminUser(user._id, formData));
    callback();
  };

  const regionList = (
    <div>
      <div>
        <b>{strings.existingRegions}</b>
      </div>
      {regions.map(region => (
        <div key={region}>{region}</div>
      ))}
    </div>
  );

  return (
    <div>
      <div className="editProfile-header">
        <h3 style={{ cursor: 'pointer' }} onClick={() => bckButton(false)}>
          <div className="back-button">
            <Icon type="left" /> {strings.back1}
          </div>
        </h3>
        <h2>{strings.editUser}</h2>
      </div>
      <div className="submenu-container ">
        <div className="admin-clients-submenu clientDesktopView ">
          <CustomButton
            type="secondary"
            icon={<img src="/images/Icons/go-back.svg" alt="back-button" />}
            label={strings.goBack}
            className="go-back-button"
            onClick={() => bckButton(false)}
          />
        </div>
      </div>
      <div className="editProfile-container">
        <div className="editProfile-forms " style={{ textAlign: 'center' }}>
          <div className="editProfile-label">{strings.nameAndSurname}</div>
          <Input
            className={formErrors.name === '' ? 'editProfile-input' : 'editProfile-input errorInput'}
            placeholder={strings.nameAndSurname}
            value={formData.name}
            onChange={changeInputField('name')}
            data-test-id="editProfile-nameSurnameInput"
          />
          <p className="editProfile-showErrors">{formErrors.name}</p>
          <div className="editProfile-label">
            {`${strings.region} ${' '}`}
            <Popover content={regionList} placement="right">
              <Icon type="info-circle" style={{ fontSize: '20px', color: '#41aea6' }} />
            </Popover>
          </div>
          <Input
            className={formErrors.region === '' ? 'editProfile-input' : 'editProfile-input errorInput'}
            placeholder={strings.region}
            value={formData.region}
            onChange={changeInputField('region')}
            data-test-id="editProfile-regionInput"
          />
          <p className="editProfile-showErrors">{formErrors.region}</p>
          <div className="editProfile-label">{strings.city}</div>
          <div className="editProfile-cityWrapper">
            <Autocomplete
              placeholder={strings.city}
              className={formErrors.city === '' ? 'editProfile-autocomplete' : 'editProfile-autocomplete errorInput'}
              formValue={formData.city}
              suggestions={Locations}
              fieldChange={value => changeCityField(value)}
              data-test-id="editProfile-cityInput"
            />
          </div>
          <p className="editProfile-showErrors">{formErrors.city}</p>
          <div className="editProfile-label">{strings.phoneNumber}</div>
          <Input
            className={formErrors.phoneNumber === '' ? 'editProfile-input' : 'editProfile-input errorInput'}
            placeholder="Telefon"
            value={formData.phoneNumber}
            onChange={changeInputField('phoneNumber')}
            data-test-id="editProfile-phoneNumberInput"
          />
          <p className="editProfile-showErrors">{formErrors.phoneNumber}</p>
          <div className="editProfile-label">{strings.email}</div>
          <Input
            className={formErrors.email === '' ? 'editProfile-input' : 'editProfile-input errorInput'}
            placeholder="Email"
            value={formData.email}
            onChange={changeInputField('email')}
            data-test-id="editProfile-emailInput"
          />
          <div className="editProfile-label mobileLabel">{strings.birthdayDate}</div>
          <div className="editProfile-datePicker">
            <DatePicker
              onChange={changeBirthdayDate()}
              name="birthdayDate"
              value={formData.birthday ? getDateObject(formData.birthday) : null}
              className="register_datepicker"
              placeholder={strings.birthdayDate}
            />
          </div>
          <p className="editProfile-showErrors">{formErrors.email}</p>
          <div className="editProfile-toolsCont">
            {role === 'omama' && !isEmpty(usersById) && !isEmpty(allMentors) && (
              <div className="editProfile-dropdown editProfile-dropdown-chooseMentor">
                <div className="editProfile-label">{strings.mentor}</div>
                <Dropdown overlay={mentorOptions()} trigger={['click']} placement="bottomCenter">
                  <div>
                    {usersById[formData.mentorID] ? usersById[formData.mentorID].name : strings.chooseMentor}
                    <Icon type="down" />
                  </div>
                </Dropdown>
              </div>
            )}
          </div>
        </div>
        {role === 'omama' && !isEmpty(usersById) && !isEmpty(allMentors) && (
          <div className="editProfile-mentorMobile" data-test-id="editProfile-mentorOptions-mobile">
            <div className="editProfile-dropdown">
              <Dropdown overlay={mentorOptions()} trigger={['click']} placement="bottomCenter">
                <div>
                  {usersById[formData.mentorID] ? usersById[formData.mentorID].name : strings.chooseMentor}
                  <Icon type="down" />
                </div>
              </Dropdown>
            </div>
          </div>
        )}
        {role === 'admin' && (
          <div>
            <div className="editProfile-label mobileLabel">{strings.accessoriesCoordinator}</div>
            <div className="editProfile-statusButtons">
              <div
                className={classNames({ selected: isCoordinator })}
                shape="round"
                onClick={() => changeAccessoryCoordinatorStatus(true)}
              >
                {strings.yes}
              </div>
              <div
                className={classNames({ selected: !isCoordinator })}
                shape="round"
                onClick={() => changeAccessoryCoordinatorStatus(false)}
              >
                {strings.no}
              </div>
            </div>
          </div>
        )}
        {role === 'admin' && isCoordinator && (
          <div>
            <br className="editProfile-desktop" />
            <div className="editProfile-statusButtons">
              <div
                className={classNames({ selected: formData.accessoriesCoordinator === 'regionalCoordinator' })}
                shape="round"
                onClick={() => changeAccessoryCoordinatorType('regionalCoordinator')}
              >
                {strings.regional}
              </div>
              <div
                className={classNames({ selected: formData.accessoriesCoordinator === 'centralCoordinator' })}
                shape="round"
                onClick={() => changeAccessoryCoordinatorType('centralCoordinator')}
              >
                {strings.central}
              </div>
            </div>
          </div>
        )}
        <div className="editProfile-label mobileLabel" data-test-id="editProfile-status-mobile">
          {strings.status}
        </div>
        <div className="editProfile-statusButtons">
          <div
            className={classNames({ selected: formData.active })}
            shape="round"
            onClick={toggleValue('active', true)}
          >
            {strings.active}
          </div>
          <div
            className={classNames({ selected: !formData.active })}
            shape="round"
            onClick={toggleValue('active', false)}
          >
            {strings.neactive}
          </div>
        </div>
        <div className="editProfile-label mobileLabel">{strings.tfaStatus}</div>
        <div className="editProfile-statusButtons">
          <div className={classNames({ selected: formData.tfa })} shape="round" onClick={toggleValue('tfa', true)}>
            {strings.isActive}
          </div>
          <div className={classNames({ selected: !formData.tfa })} shape="round" onClick={toggleValue('tfa', false)}>
            {strings.isNotActive}
          </div>
        </div>
        <div className="editProfile-label mobileLabel">{strings.onlySkCzLogin}</div>
        <div className="editProfile-statusButtons">
          <div
            className={classNames({ selected: formData.ipUmbrella })}
            shape="round"
            onClick={toggleValue('ipUmbrella', true)}
          >
            {strings.active}
          </div>
          <div
            className={classNames({ selected: !formData.ipUmbrella })}
            shape="round"
            onClick={toggleValue('ipUmbrella', false)}
          >
            {strings.neactive}
          </div>
        </div>
        {role === 'omama' && (
          <>
            <div className="editProfile-dropdown">
              <div className="editProfile-label">{strings.level}</div>
              <Dropdown overlay={levelOptions()} trigger={['click']} placement="bottomCenter">
                <div>
                  {formData.level ? omamaLevelMap[formData.level] : strings.chooseLevel}
                  <Icon type="down" />
                </div>
              </Dropdown>
            </div>
            <div className="editProfile-label">{strings.onLevelFrom}</div>
            <div className="editProfile-datePicker">
              <DatePicker
                onChange={changeLevelDate()}
                name="onLevelSince"
                value={getDateObject(formData.onLevelSince)}
                className="register_datepicker"
                placeholder={strings.onLevelFrom}
              />
            </div>
            <div className="editProfile-dropdown">
              <div className="editProfile-label">{strings.expectedAmountOfChildrens}</div>
              <Dropdown overlay={clientCountOptions()} trigger={['click']} placement="bottomCenter">
                <div>
                  {formData.expectedClientCount
                    ? formData.expectedClientCount
                    : strings.chooseExpectedAmountOfChildrens}
                  <Icon type="down" />
                </div>
              </Dropdown>
            </div>
            <div className="editProfile-dropdown">
              <div className="editProfile-label">{strings.obligationType}</div>
              <Dropdown overlay={employmentTypeOptions()} trigger={['click']} placement="bottomCenter">
                <div>
                  {formData.employmentType ? employmentTypesMap[formData.employmentType] : strings.chooseObligationType}
                  <Icon type="down" />
                </div>
              </Dropdown>
            </div>{' '}
          </>
        )}
        <div className="editProfile-saveButton">
          <Button
            disabled={!valid}
            shape="round"
            onClick={() =>
              save(() => {
                bckButton(false);
                fetchUser();
                getUsersAction();
              })
            }
          >
            {strings.save}
          </Button>
        </div>
      </div>
    </div>
  );
}

EditProfile.propTypes = {
  bckButton: PropTypes.func,
  role: PropTypes.string,
  fetchUser: PropTypes.func
};

export default EditProfile;
