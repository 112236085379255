import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import PersonCard from '../../components/PersonCard';
import { orderBy } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

// Local imports
import {
  getOmamaClientsAction,
  getActionsAction
} from '../../actions/omama.actions';

function Clients() {
  const changePage = location => dispatch(push(location));
  const clients = useSelector(state =>
    orderBy(state.omama.clientsArr, ['lastFirstName'], ['asc'])
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOmamaClientsAction());
    dispatch(getActionsAction());
  }, [dispatch]);

  const navigateToProfile = id => {
    changePage('/omama/clientProfiles/' + id);
  };

  return (
    <div>
      <div className="clientsScreen"></div>
      <div style={{ paddingTop: '100px', paddingBottom: '10px' }}>
        {clients.map(client => (
          <PersonCard
            key={client._id}
            id={client._id}
            photo={client._id}
            changePage={() => navigateToProfile(client._id)}
            name={client.name}
            birthDate={client.birthDate}
          />
        ))}
      </div>
    </div>
  );
}

Clients.propTypes = {
  getActionsAction: PropTypes.func,
  activities: PropTypes.arrayOf(PropTypes.object),
  getOmamaClientsAction: PropTypes.func,
  clients: PropTypes.arrayOf(PropTypes.object),
  changePage: PropTypes.func
};

export default Clients;
