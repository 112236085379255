import React, { useState, useEffect } from 'react';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { Button, Dropdown, Icon, Menu, Input, Popover } from 'antd';
// Local Imports

import Autocomplete from '../../components/Autocomplete';
import { Locations } from '../../data/locations';

import './UserRegister.scss';
import { api } from '../../conf';
import { strings } from '../../strings/StringsProvider';
import { setLoading } from '../../actions/status.actions';

import { getRegions, getUsersAction } from '../../actions/users.actions';
import { addNewMessageAction } from '../../actions/message.actions';

function UserRegister() {
  const [username, setUsername] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [region, setRegion] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [usernameValid, setUsernameValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [confirmPasswordValid, setConfirmPasswordValid] = useState(false);
  const [phoneNumberValid, setPhoneNumberValid] = useState(false);
  const [role, setRole] = useState('');
  const [displayRole, setDisplayRole] = useState('');

  const dispatch = useDispatch();
  const changePage = location => dispatch(push(location));

  const regions = useSelector(state => state.users.regions);

  useEffect(() => {
    dispatch(getRegions());
  }, [dispatch]);

  const register = async () => {
    dispatch(setLoading(true));

    const reqBody = {
      username: username,
      name: name,
      password: password,
      email: email,
      phoneNumber: phoneNumber,
      city: city,
      street: street,
      region: region,
      role: role
    };

    if (role === 'omama') {
      reqBody.level = 1;
    }

    try {
      const { data } = await axios.post(api.registerUser, reqBody);
      dispatch(setLoading(false));

      if (data.success) {
        dispatch(getUsersAction());
        changePage('/admin/users');
      }
    } catch (error) {
      dispatch(addNewMessageAction(strings.userAlreadyExists, false));
      dispatch(setLoading(false));
    }
  };

  const validateEmail = inputEmail => {
    setEmail(inputEmail);
    const isEmailValid = inputEmail.match(/^([\w.%+-]+)@([\w-]+\.)+(\w{2,})$/i);
    isEmailValid
     ? setEmailValid(true)
     : setEmailValid(false);
  };

  const validateUsername = inputUsername => {
    setUsername(inputUsername);
    const isUsernameValid = inputUsername.match(/^[a-zA-Z0-9]{1,20}$/);
    isUsernameValid
     ? setUsernameValid(true)
     : setUsernameValid(false);
  };

  const validatePassword = inputPassword => {
    setPassword(inputPassword);
    const isPasswordValid = inputPassword.match(/^(?=.*\d)(?=.*[A-Z])(?=.{8,})[0-9a-zA-Z!@#$%^&*)(+=._-]{8,}$/);
    isPasswordValid
     ? setPasswordValid(true)
     : setPasswordValid(false);
  };

  const validateConfirmPassword = inputConfirmPassword => {
    setConfirmPassword(inputConfirmPassword);
    inputConfirmPassword === password
     ? setConfirmPasswordValid(true)
     : setConfirmPasswordValid(false);
  };

  const validatePhoneNumber = inputPhoneNumber => {
    setPhoneNumber(inputPhoneNumber);
    const isPhoneNumberValid = inputPhoneNumber.match(/^(\+421\s?|\+420\s?)(\s?[0-9]){9}$/);
    isPhoneNumberValid
     ? setPhoneNumberValid(true)
     : setPhoneNumberValid(false);
  };

  const formValid = () => {
    return (emailValid && usernameValid && passwordValid && confirmPasswordValid && phoneNumberValid && 
      name.length && username.length && role.length);
  };

  const onRoleChange = ({ item }) => {
    setRole(item.props.eventKey);
    setDisplayRole(item.props.children);
  };

  const roleOptions = (
    <Menu onClick={onRoleChange}>
      <Menu.Item key="mentor">{strings.mentor}</Menu.Item>
      <Menu.Item key="omama">{strings.omama}</Menu.Item>
      <Menu.Item key="admin">{strings.admin}</Menu.Item>
      <Menu.Item key="supervisor">{strings.supervision1}</Menu.Item>
    </Menu>
  );
  
  const regionList = (
    <div>
      <div>
        <b>{strings.existingRegions}</b>
      </div>
      {regions.map(region => (
        <div key={region}>{region}</div>
      ))}
    </div>
  );

  return (
    <div className="user-register-background">
      <div className="addNewactivityFormContainer">
        <div className="register_client_header">
          <h3
            style={{ cursor: 'pointer' }}
            onClick={() => changePage('/admin/users')}
          >
            <div className="back-button" data-test-id="register-backButton">
              <Icon type="left" /> {strings.back1}
            </div>
          </h3>
          <h2 data-test-id="register-headline">{strings.userRegistration}</h2>
        </div>
        <div className="forms" data-test-id="register-formular">
          <div className="registerForm" data-test-id="register-registerForm">
            <div data-test-id="register-nameSurnameInput">
              <Input
                onChange={(e) => setName(e.target.value)}
                value={name}
                placeholder={strings.nameAndSurname}
              />
            </div>
            <div data-test-id="register-emailInput">
              <Input
                onChange={(e) => validateEmail(e.target.value)}
                value={email}
                placeholder={strings.email}
              />
            </div>
            {email && !emailValid && (
              <div  className='registerUserErrorMsg'>
                <p className="showErrors">{strings.wrongEmailFormat}</p>
              </div>
            )}
            <div className='selectRegion' data-test-id="register-regionInput">
              <Input
                onChange={(e) => setRegion(e.target.value)}
                value={region}
                placeholder={strings.region}
              />
              <Popover content={regionList} placement="right">
                <Icon
                  type="info-circle"
                  style={{ fontSize: '25px', color: '#41aea6' }}
                />
              </Popover>
            </div>
            <div style={{ position: 'relative' }} className='citySelector' data-test-id="register-cityInput">
              <Autocomplete
                style={{ width: '100%' }}
                placeholder={strings.city}
                formValue={city}
                suggestions={Locations}
                fieldChange={value => setCity(value)}
              />
            </div>
            <div data-test-id="register-streetInput">
              <Input
                onChange={(e) => setStreet(e.target.value)}
                value={street}
                placeholder={strings.street}
              />
            </div>
          </div>
          <div className="passwordsForm" data-test-id="register-passwordForm">
            <div data-test-id="register-usernameInput">
              <Input
                onChange={(e) => validateUsername(e.target.value)}
                value={username}
                placeholder={strings.userName}
              />
            </div>
            {username && !usernameValid && (
              <div className='registerUserErrorMsg'>
                <p className="showErrors">{strings.usernameNotValid}</p>
              </div>
            )}
            <div data-test-id="register-passwordInput">
              <Input
                onChange={(e) => validatePassword(e.target.value)}
                value={password}
                placeholder={strings.password}
                type="password"
              />
            </div>
            {password && !passwordValid && (
              <div className='registerUserErrorMsg'>
                <p className="showErrors">{strings.passwordIsIncorrect}</p>
              </div>
            )}
            <div data-test-id="register-passwordConfirmInput">
              <Input
                onChange={(e) => validateConfirmPassword(e.target.value)}
                value={confirmPassword}
                placeholder={strings.repeatPassword}
                type="password"
              />
            </div>
            {confirmPassword && !confirmPasswordValid && (
              <div className='registerUserErrorMsg'>
                <p className="showErrors">{strings.passwordsAreNotSame}</p>
              </div>
            )}
            <div data-test-id="register-phoneNumberInput">
              <Input
                onChange={(e) => validatePhoneNumber(e.target.value)}
                value={phoneNumber}
                placeholder={strings.telNumber}
              />
            </div>
            {phoneNumber && !phoneNumberValid && (
              <div className='registerUserErrorMsg'>
                <p className="showErrors">{strings.phoneNumberNotValid}</p>
              </div>
            )}
            <div className="roleDropdown" data-test-id="register-role">
              <Dropdown
                trigger={['click']}
                className="actionsDropdown"
                overlay={roleOptions}
                placement="bottomCenter"
              >
                <div className="roleDropdown-placeholder">
                  {displayRole === '' ? strings.chooseRole : displayRole}
                  <Icon type="down" />
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="regButton" data-test-id="register-registerButton">
          <Button
            className="regButton"
            disabled={!formValid()}
            onClick={register}
          >
            {strings.register}
          </Button>
        </div>
      </div>
    </div>
  );
  
}

export default UserRegister;