import React from 'react';
import PropTypes from 'prop-types';
import { List, /* Button, */ Typography } from 'antd';
// import { useDispatch } from 'react-redux';
// import { deleteNotification } from 'slices/notifications';

function ListItem(props) {
  const { Title } = Typography;
  // const dispatch = useDispatch();

  return (
    <List.Item
      style={{
        ...props.style,
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        marginTop: '.5rem'
      }}
    >
      <div
        style={{
          width: '100%'
        }}
      >
        <Title level={props.new ? 3 : 4}>{props.title}</Title>
        <p>
          {new Date(props.created).toLocaleDateString('sk-SK', { year: 'numeric', month: 'numeric', day: 'numeric' })}
          {': '}
          {props.text}
        </p>
      </div>
      {/*<div>
        <Button
          type='danger'
          onClick={()=>dispatch(deleteNotification(props._id))}
        >
          X
        </Button>
      </div> */}
    </List.Item>
  );
}

ListItem.propTypes = {
  _id: PropTypes.string,
  created: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  style: PropTypes.object,
  new: PropTypes.bool
};

export default ListItem;
