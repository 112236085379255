import React from 'react';
import ErrorMessage from 'components/ErrorMessage';
import { message } from 'antd';

message.config({
  top: 100,
  duration: 10,
  maxCount: 1
});

export const showErrorMessage = (error, errorMessage) => {
  // eslint-disable-next-line no-console
  console.error(error);

  message.error(<ErrorMessage errorMessage={errorMessage} />);
};
