import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CSVLink } from 'react-csv';
import { Tabs, DatePicker, Select } from 'antd';
import Button from 'components/Button';
import moment from 'moment';
import axios from 'axios';

// Local imports
import { strings } from '../../strings/StringsProvider';
import { api } from '../../conf';
import {
  clientActionsReportHeader,
  omamaActionsReportHeader,
  parentalClubsReportHeader,
  allClientsReportHeader,
  notEnoughLessonsReportHeader,
  twoRedScreeningsReportHeader,
  mentorActionsReportHeader,
  omamasStatsReportHeader,
  parentEngagementReportHeader,
  trendsReportHeader,
  omamasActivitiesReportHeader,
  omamasRatingReportHeader,
  mentorsWorkRatingReportHeader,
  screeningsReportHeaderStart,
  screeningsReportHeaderEnd,
  screeningsDoneReportHeader,
  supervisionsReportHeader,
  interNDAMeasurementsReportHeader,
  preschoolScreeningReportHeader,
  accessoriesReportHeader
} from 'conf/reportHeaders';

import {
  getTimeReportAction,
  resetReportAction,
  getUsersReportAction,
  getClientsReportAction,
  getNotEnoughLessonsReportAction,
  getTwoRedScreeningsReportAction,
  getClientLectionsReportAction,
  getOmamaLectionsReportAction,
  getParentalClubsReportAction,
  getOmamasStatsReportAction,
  getParentEngagementReportAction,
  getTrendsReportAction,
  getOmamasActivitiesReportAction,
  getOmamasRatingReportAction,
  getScreeningsReportAction,
  getFilledOutScreeningsReportAction,
  getComprehensiveReportAction,
  getMeasurementsReportAction,
  getMentorsWorkRatingReportAction,
  getSupervisionsReportAction,
  getAccessoriesReportAction
} from '../../actions/report.actions';
import { getRegions } from '../../actions/users.actions';
import { getAllScreeningQuestions } from 'slices/screenings';
import './AdminReports.scss';

const { TabPane } = Tabs;
const { MonthPicker, RangePicker } = DatePicker;
const { Option } = Select;

function Reports() {
  const [clients, setClients] = useState([]);
  const [clientID, setClientID] = useState(null);
  const [omamas, setOmamas] = useState([]);
  const [mentors, setMentors] = useState([]);
  const [selectedMentor, setSelectedMentor] = useState('allMentors');
  const [supervisors, setSupervisors] = useState([]);
  const [omamaID, setOmamaID] = useState('all');
  const [yearpickerIsOpen, setYearpickerIsOpen] = useState(false);
  const [omamasRatingReportMode, setOmamasRatingReportMode] = useState(['month', 'month']);
  const [mentorsWorkRatingReportMode, setMentorsWorkRatingReportMode] = useState(['month', 'month']);
  const [supervisorsTimeReportDate, setSupervisorsTimeReportDate] = useState(null);
  const [selectedSupervisor, setSelectedSupervisor] = useState('allSupervisors');
  const [mentorsTimeReportDate, setMentorsTimeReportDate] = useState(null);
  const [omamasTimeReportDate, setOmamasTimeReportDate] = useState(null);
  const [clientActionsRange, setClientActionsRange] = useState([null, null]);
  const [omamaActionsRange, setOmamaActionsRange] = useState([null, null]);
  const [parentalClubsRange, setParentalClubsRange] = useState([null, null]);
  const [omamasRatingReportRange, setOmamasRatingReportRange] = useState([null, null]);
  const [mentorsWorkRatingReportRange, setMentorsWorkRatingReportRange] = useState([null, null]);
  const [comprehensiveReportRange, setComprehensiveReportRange] = useState([null, null]);
  const [comprehensiveReportRegion, setComprehensiveReportRegion] = useState('');
  const [interNDAMeasurementRegion, setInterNDAMeasurementRegion] = useState('');
  const [preschoolScreeningRegion, setPreschoolScreeningRegion] = useState('');
  const [screeningsReportYear, setScreeningsReportYear] = useState(null);
  const [screeningType, setScreeningType] = useState('');
  const [selectedCommunity, setSelectedCommunity] = useState('all');
  const [omamasStatsYear, setOmamasStatsYear] = useState(Number(moment().format('YYYY')));
  const [parentEngagementYear, setParentEngagementYear] = useState(Number(moment().format('YYYY')));

  const reports = useSelector(state => state.report.reports);
  const screeningTests = useSelector(state => state.screenings.screeningTests);
  const regions = useSelector(state => state.users.regions);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const { data: clients } = await axios.get(api.getClients());
      const { data: omamas } = await axios.get(api.getOmamas);
      const { data: mentors } = await axios.get(api.getMentors());
      const { data: supervisors } = await axios.get(api.getSupervisors());
      setMentors(mentors);
      setClients(clients.data);
      setOmamas(omamas.users);
      setSupervisors(supervisors.data);
      dispatch(getAllScreeningQuestions());
      dispatch(getRegions());
    };
    fetchData();
  }, [dispatch]);

  const reportButtons = useCallback(
    (reportName, reportCSVName, getReportFunction) => {
      let reportData = reports[reportName] || null;
      let headers = undefined;
      let readyToGenerate = true;
      switch (reportName) {
        case 'clientActionsReport':
          headers = clientActionsReportHeader;
          break;
        case 'omamaActionsReport':
          headers = omamaActionsReportHeader;
          break;
        case 'parentalClubsReport':
          headers = parentalClubsReportHeader;
          break;
        case 'allClientsReport':
        case 'activeClientsReport':
        case 'unactiveClientsReport':
          headers = allClientsReportHeader;
          break;
        case 'notEnoughLessonsReport':
          headers = notEnoughLessonsReportHeader;
          break;
        case 'supervisionsReport':
          headers = supervisionsReportHeader;
          break;
        case 'twoRedScreeningsReport':
          headers = twoRedScreeningsReportHeader;
          break;
        case 'mentorActionsReport':
          headers = mentorActionsReportHeader;
          break;
        case 'omamasStatsReport':
          headers = omamasStatsReportHeader;
          break;
        case 'parentEngagementReport':
          headers = parentEngagementReportHeader;
          break;
        case 'trendsReport':
          headers = trendsReportHeader;
          break;
        case 'omamasActivitiesReport':
          headers = omamasActivitiesReportHeader;
          break;
        case 'omamasRatingReport':
          headers = omamasRatingReportHeader;
          break;
        case 'mentorsWorkRatingReport':
          headers = mentorsWorkRatingReportHeader;
          if (!(mentorsWorkRatingReportRange[0] && mentorsWorkRatingReportRange[0])) {
            readyToGenerate = false;
          }
          break;
        case 'screeningsReport':
          headers = [...screeningsReportHeaderStart];
          headers.push(
            ...(screeningTests[screeningType]?.questions.map(question => {
              let label = question.id.split('_')[1];
              if (label.startsWith('0')) label = label.replace('0', 'O');
              return {
                label,
                key: `answers.${question.id.split('_')[1]}`
              };
            }) || [])
          );
          headers.push(...screeningsReportHeaderEnd);
          break;
        case 'filledOutScreeningsReport':
          headers = screeningsDoneReportHeader;
          break;
        case 'interNDAMeasurementsReport':
          headers = interNDAMeasurementsReportHeader;
          break;
        case 'preschoolScreeningReport':
          headers = preschoolScreeningReportHeader;
          break;
        case 'accessoriesReport':
          headers = accessoriesReportHeader;
          break;
        default:
          break;
      }

      if (reportData === null || (Array.isArray(reportData) && reportData.length === 0)) {
        return (
          <div>
            <Button onClick={getReportFunction} disabled={!readyToGenerate}>
              {strings.generate}
            </Button>
            {Array.isArray(reportData) ? <span className="no-data-notification">{strings.noDateForReport}</span> : ''}
          </div>
        );
      }

      return (
        <div>
          {reportData.path ? (
            <a className="ant-btn report-button report-csv" href={`${api.backendBaseUrl}${reportData.path}`} download>
              {strings.download}
            </a>
          ) : (
            <CSVLink
              className="ant-btn report-button report-csv"
              data={reportData}
              filename={`${reportCSVName}${moment().format()}.csv`}
              headers={headers}
            >
              {strings.download}
            </CSVLink>
          )}
          <Button type="secondary" onClick={() => dispatch(resetReportAction(reportName))}>
            {strings.restart}
          </Button>
        </div>
      );
    },
    [dispatch, reports, screeningTests, screeningType, mentorsWorkRatingReportRange]
  );

  const generateUserTab = useCallback(() => {
    return (
      <TabPane tab={strings.users} key="1" data-test-id="reports-users">
        <div data-test-id="reports-users-allOmamas">
          <h3>{strings.listOfAllOmams}</h3>
          {reportButtons('omamasReport', 'Omamy', () =>
            dispatch(getUsersReportAction({ role: 'omama' }, 'omamasReport'))
          )}
        </div>
        <div data-test-id="reports-users-allMentors">
          <h3>{strings.listOfAllMentors}</h3>
          {reportButtons('mentorReport', 'Mentori', () =>
            dispatch(getUsersReportAction({ role: 'mentor' }, 'mentorReport'))
          )}
        </div>
        <div data-test-id="reports-users-allSupervisors">
          <h3>{strings.listOfAllSupervisors}</h3>
          {reportButtons('supervisorReport', 'Supervizori', () =>
            dispatch(getUsersReportAction({ role: 'supervisor' }, 'supervisorReport'))
          )}
        </div>
        <div className="bottomPageReport" data-test-id="reports-users-allUsers">
          <h3>{strings.listOfAllUsers}</h3>
          {reportButtons('usersReport', 'Pouzivatelia', () => dispatch(getUsersReportAction({}, 'usersReport')))}
        </div>
      </TabPane>
    );
  }, [dispatch, reportButtons]);

  const changeSupervisorsTimeReportDate = useCallback(
    date => {
      setSupervisorsTimeReportDate(date);
      dispatch(resetReportAction('supervisorsTimeReport'));
    },
    [dispatch, setSupervisorsTimeReportDate]
  );

  const changeMentorsTimeReportDate = useCallback(
    date => {
      setMentorsTimeReportDate(date);
      dispatch(resetReportAction('mentorsTimeReport'));
    },
    [dispatch, setMentorsTimeReportDate]
  );

  const changeOmamasTimeReportDate = useCallback(
    date => {
      setOmamasTimeReportDate(date);
      dispatch(resetReportAction('omamasTimeReport'));
    },
    [dispatch, setOmamasTimeReportDate]
  );

  const changeClientActionsTimeReportDate = useCallback(
    date => {
      setClientActionsRange([moment(date[0]), moment(date[1])]);
      dispatch(resetReportAction('clientActionsReport'));
    },
    [dispatch, setClientActionsRange]
  );

  const changeOmamaActionsTimeReportDate = useCallback(
    date => {
      setOmamaActionsRange([moment(date[0]), moment(date[1])]);
      dispatch(resetReportAction('omamaActionsReport'));
    },
    [dispatch, setOmamaActionsRange]
  );

  const changeParentalClubsTimeReportDate = useCallback(
    date => {
      setParentalClubsRange([moment(date[0]), moment(date[1])]);
      dispatch(resetReportAction('parentalClubsReport'));
    },
    [dispatch, setParentalClubsRange]
  );

  const changeOmamasRatingReportDate = useCallback(
    date => {
      setOmamasRatingReportRange([moment(date[0]).startOf('month'), moment(date[1]).endOf('month')]);
      dispatch(resetReportAction('omamasRatingReport'));
    },
    [dispatch, setOmamasRatingReportRange]
  );

  const changeMentorsWorkRatingReportDate = useCallback(
    date => {
      setMentorsWorkRatingReportRange([moment(date[0]).startOf('month'), moment(date[1]).endOf('month')]);
      dispatch(resetReportAction('mentorsWorkRatingReport'));
    },
    [dispatch, setMentorsWorkRatingReportRange]
  );

  const handleOmamasRatingReportPanelChange = useCallback(
    (date, mode) => {
      setOmamasRatingReportRange([moment(date[0]).startOf('month'), moment(date[1]).endOf('month')]);
      setOmamasRatingReportMode([mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]]);
    },
    [setOmamasRatingReportRange, setOmamasRatingReportMode]
  );

  const handleMentorsWorkPanelChange = useCallback(
    (date, mode) => {
      setMentorsWorkRatingReportRange([moment(date[0]).startOf('month'), moment(date[1]).endOf('month')]);
      setMentorsWorkRatingReportMode([mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]]);
    },
    [setMentorsWorkRatingReportRange, setMentorsWorkRatingReportMode]
  );

  const changeComprehensiveReportDate = useCallback(
    date => {
      setComprehensiveReportRange([moment(date[0]).startOf('day'), moment(date[1])]);
      dispatch(resetReportAction('comprehensiveReport'));
    },
    [dispatch, setComprehensiveReportRange]
  );

  const changeScreeningsReportYear = useCallback(
    date => {
      setScreeningsReportYear(date);
      dispatch(resetReportAction('screeningsReport'));
    },
    [dispatch, setScreeningsReportYear]
  );

  const renderSelectOptionsForMentors = useCallback(() => {
    const { Option } = Select;
    const tempOptions = [
      <Option className="selectRow" key="allMentors">
        {strings.allMentors}
      </Option>
    ];
    tempOptions.push(
      ...mentors.map(mentor => (
        <Option className="selectRow" key={mentor._id}>
          {mentor.name}
        </Option>
      ))
    );
    return tempOptions;
  }, [mentors]);

  const generateTimeReportTab = useCallback(() => {
    return (
      <TabPane tab={strings.monthlyReports} key="2" data-test-id="reports-monthReport">
        <div data-test-id="reports-monthReport-omamas">
          <h3>{strings.omamy}</h3>
          <div className="timeReport">
            <MonthPicker
              value={omamasTimeReportDate}
              onChange={changeOmamasTimeReportDate}
              placeholder={strings.chooseMonthAndYear}
            />

            <div className="timeReportField">
              {reportButtons(
                'omamasTimeReport',
                'omamyvykazprace',
                () => omamasTimeReportDate && dispatch(getTimeReportAction(omamasTimeReportDate, 'omamasTimeReport'))
              )}
            </div>
          </div>
        </div>

        <div data-test-id="reports-monthReport-mentors">
          <h3>{strings.mentors}</h3>
          <div className="timeReport">
            <MonthPicker
              value={mentorsTimeReportDate}
              onChange={changeMentorsTimeReportDate}
              placeholder={strings.chooseMonthAndYear}
            />

            <div className="timeReportField">
              {reportButtons(
                'mentorsTimeReport',
                'mentorkyvykazprace',
                () => mentorsTimeReportDate && dispatch(getTimeReportAction(mentorsTimeReportDate, 'mentorsTimeReport'))
              )}
            </div>
          </div>
        </div>

        <div className="bottomPageReport" data-test-id="reports-monthReport-supervisors">
          <h3>{strings.supervisors}</h3>
          <div className="timeReport">
            <MonthPicker
              value={supervisorsTimeReportDate}
              onChange={changeSupervisorsTimeReportDate}
              placeholder={strings.chooseMonthAndYear}
            />

            <div className="timeReportField">
              {reportButtons(
                'supervisorsTimeReport',
                'supervizorivykazprace',
                () =>
                  supervisorsTimeReportDate &&
                  dispatch(getTimeReportAction(supervisorsTimeReportDate, 'supervisorsTimeReport'))
              )}
            </div>
          </div>
        </div>
      </TabPane>
    );
  }, [
    dispatch,
    changeMentorsTimeReportDate,
    changeOmamasTimeReportDate,
    changeSupervisorsTimeReportDate,
    reportButtons,
    mentorsTimeReportDate,
    omamasTimeReportDate,
    supervisorsTimeReportDate
  ]);

  const generateClientTab = useCallback(() => {
    if (clients && omamas) {
      return (
        <TabPane tab={strings.clients} key="3" data-test-id="reports-clients">
          <div data-test-id="reports-clients-allClients">
            <h3>{strings.listOfAllClients}</h3>
            {reportButtons('allClientsReport', 'vsetciklienti', () =>
              dispatch(getClientsReportAction({ filter: {}, reportName: 'vsetci_klienti' }, 'allClientsReport'))
            )}
          </div>
          <div data-test-id="reports-clients-activeClients">
            <h3>{strings.activeClients1}</h3>
            {reportButtons('activeClientsReport', 'aktivniklienti', () =>
              dispatch(
                getClientsReportAction(
                  { filter: { active: true }, reportName: 'aktivni_klienti' },
                  'activeClientsReport'
                )
              )
            )}
          </div>
          <div data-test-id="reports-clients-nonActiveClients">
            <h3>{strings.nonActiveClients}</h3>
            {reportButtons('unactiveClientsReport', 'neaktivniklienti', () =>
              dispatch(
                getClientsReportAction(
                  {
                    filter: { active: false },
                    reportName: 'neaktivni_klienti'
                  },
                  'unactiveClientsReport'
                )
              )
            )}
          </div>
          <div data-test-id="reports-clients-noEnoughLessonsClients">
            <h3>{strings.clientWithNonEnoughLessons}</h3>
            {reportButtons('notEnoughLessonsReport', 'nedostatocnyPocetLekcii', () =>
              dispatch(getNotEnoughLessonsReportAction('notEnoughLessonsReport'))
            )}
          </div>
          <div data-test-id="reports-clients-redScreeningClients">
            <h3>{strings.redScreeningReport}</h3>
            {reportButtons('twoRedScreeningsReport', 'skriningyVCervenomPasme', () =>
              dispatch(getTwoRedScreeningsReportAction('twoRedScreeningsReport'))
            )}
          </div>
          <div data-test-id="reports-clients-results">
            <h3>{strings.screeningReportResult}</h3>
            <div className="screeningResultsReport">
              <DatePicker
                value={screeningsReportYear}
                open={yearpickerIsOpen}
                onChange={changeScreeningsReportYear}
                placeholder={strings.chooseYear}
                mode="year"
                format="YYYY"
                onOpenChange={status => setYearpickerIsOpen(!!status)}
                onPanelChange={value => {
                  setScreeningsReportYear(value);
                  setYearpickerIsOpen(false);
                }}
              />
              <Select
                onChange={value => {
                  setScreeningType(value);
                  dispatch(resetReportAction('screeningsReport'));
                }}
                className="report-select"
                placeholder={strings.chooseScreeningType}
              >
                {Object.keys(screeningTests).map(screening => (
                  <Select.Option key={screening} value={screening}>
                    {screening}
                  </Select.Option>
                ))}
              </Select>

              <div className="generateButton">
                {reportButtons(
                  'screeningsReport',
                  'vysledkySkriningov',
                  () =>
                    screeningsReportYear &&
                    screeningType &&
                    dispatch(
                      getScreeningsReportAction('screeningsReport', screeningsReportYear.get('year'), screeningType)
                    )
                )}
              </div>
            </div>
          </div>
          <div data-test-id="reports-clients-filled">
            <h3>{strings.screeningReportFilled}</h3>
            {reportButtons('filledOutScreeningsReport', 'vyplneneSkriningy', () =>
              dispatch(getFilledOutScreeningsReportAction('filledOutScreeningsReport'))
            )}
          </div>
          <div data-test-id="reports-clients-interNDA">
            <h3>{strings.interNDAMeasurement}</h3>
            <div className="choose-region">
              <label className="report-label">{strings.region}:</label>
              <Select
                style={{ width: 270 }}
                className="report-select"
                placeholder={strings.chooseRegion}
                optionFilterProp="children"
                value={interNDAMeasurementRegion}
                onChange={value => {
                  dispatch(resetReportAction('interNDAMeasurementsReport'));
                  setInterNDAMeasurementRegion(value);
                }}
              >
                <Option key={null} value="">
                  {strings.chooseRegion}
                </Option>
                {regions.map(region => (
                  <Option key={region} value={region}>
                    {region}
                  </Option>
                ))}
              </Select>
            </div>
            {reportButtons('interNDAMeasurementsReport', 'meraniaInterNDA', () =>
              dispatch(
                getMeasurementsReportAction('interNDAMeasurementsReport', 'inter-NDA', interNDAMeasurementRegion)
              )
            )}
          </div>
          <div className="bottomPageReport" data-test-id="reports-clients-preschool-screening">
            <h3>{strings.preschoolScreening}</h3>
            <div className="choose-region">
              <label className="report-label">{strings.region}:</label>
              <Select
                style={{ width: 270 }}
                className="report-select"
                placeholder={strings.chooseRegion}
                optionFilterProp="children"
                value={preschoolScreeningRegion}
                onChange={value => {
                  dispatch(resetReportAction('preschoolScreeningReport'));
                  setPreschoolScreeningRegion(value);
                }}
              >
                <Option key={null} value="">
                  {strings.chooseRegion}
                </Option>
                {regions.map(region => (
                  <Option key={region} value={region}>
                    {region}
                  </Option>
                ))}
              </Select>
            </div>
            {reportButtons('preschoolScreeningReport', 'predskolskeMeranie', () =>
              dispatch(
                getMeasurementsReportAction('preschoolScreeningReport', 'preschoolScreening', preschoolScreeningRegion)
              )
            )}
          </div>
        </TabPane>
      );
    }
  }, [
    dispatch,
    clients,
    omamas,
    screeningTests,
    screeningType,
    screeningsReportYear,
    yearpickerIsOpen,
    regions,
    interNDAMeasurementRegion,
    preschoolScreeningRegion,
    changeScreeningsReportYear,
    reportButtons,
    setScreeningType,
    setScreeningsReportYear,
    setYearpickerIsOpen
  ]);

  const generateActionsReportTab = useCallback(() => {
    let omamasCommunity = [];
    if (omamas) {
      let omamasCities = omamas.map(obj => {
        return obj.city;
      });
      omamasCommunity = [...new Set(omamasCities)];
    }
    return (
      <TabPane tab={strings.actionOneUpperCase} key="4" data-test-id="reports-lections">
        <div data-test-id="reports-lections-client">
          <h3>{strings.clientsLesson}</h3>
          <RangePicker
            onChange={changeClientActionsTimeReportDate}
            name="clientActionDate"
            defaultValue={[null, null]}
            value={clientActionsRange}
            className="adminReport_datepicker"
            placeholder={['Od', 'Do']}
          />
          <div>
            <label className="report-label">{strings.clientsName}:</label>
            {clients && (
              <Select
                showSearch
                style={{ width: 270 }}
                className="report-select"
                placeholder="Vyberte klienta"
                optionFilterProp="children"
                onChange={value => setClientID(value)}
                filterOption={(input, option) =>
                  option.props.children
                    .join('')
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {clients.map(client => (
                  <Option key={0} value={client._id}>
                    {client.firstName} {client.lastName}
                  </Option>
                ))}
              </Select>
            )}
          </div>
          <br />
          {reportButtons(
            'clientActionsReport',
            'lekcieKlienta',
            () =>
              clientActionsRange[0] &&
              clientActionsRange[1] &&
              clientID &&
              dispatch(
                getClientLectionsReportAction(
                  clientID,
                  'clientActionsReport',
                  clientActionsRange[0],
                  clientActionsRange[1]
                )
              )
          )}
        </div>
        <div data-test-id="reports-lections-omama">
          <h3>{strings.omamasLesson}</h3>
          <RangePicker
            onChange={changeOmamaActionsTimeReportDate}
            name="omamaActionsDate"
            defaultValue={[null, null]}
            value={omamaActionsRange}
            className="adminReport_datepicker"
            placeholder={['Od', 'Do']}
          />
          <div>
            <label className="report-label">{strings.omama}:</label>
            {omamas && (
              <Select onChange={value => setOmamaID(value)} className="report-select" value={omamaID}>
                <Select.Option key="all" value="all">
                  {strings.allOmamas}
                </Select.Option>
                {omamas.map(omama => (
                  <Select.Option key={0} value={omama._id}>
                    {omama.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </div>
          <br />
          {reportButtons(
            'omamaActionsReport',
            'lekcieOmamy',
            () =>
              omamaActionsRange[0] &&
              omamaActionsRange[1] &&
              omamaID &&
              dispatch(
                getOmamaLectionsReportAction(omamaID, 'omamaActionsReport', omamaActionsRange[0], omamaActionsRange[1])
              )
          )}
        </div>
        <div data-test-id="reports-supervisions">
          <h3>{strings.supervisions}</h3>
          <div>
            <label className="report-label">{strings.supervisor}:</label>
            {supervisors && (
              <Select
                onChange={value => setSelectedSupervisor(value)}
                className="report-select"
                value={selectedSupervisor}
              >
                <Select.Option key="allSupervisors" value="allSupervisors">
                  {strings.allSupervisors}
                </Select.Option>
                {supervisors.map(supervisor => (
                  <Select.Option key={0} value={supervisor._id}>
                    {supervisor.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </div>
          <br />
          {reportButtons(
            'supervisionsReport',
            'supervizie',
            () => selectedSupervisor && dispatch(getSupervisionsReportAction('supervisionsReport', selectedSupervisor))
          )}
        </div>
        <div className="bottomPageReport" data-test-id="reports-lections-parentClubs">
          <h3>{strings.parentalClub}</h3>
          <RangePicker
            onChange={changeParentalClubsTimeReportDate}
            name="parentalClubsDate"
            defaultValue={[null, null]}
            value={parentalClubsRange}
            className="adminReport_datepicker"
            placeholder={['Od', 'Do']}
          />
          <div>
            <label className="report-label">{strings.community}:</label>
            {omamasCommunity.sort() && (
              <Select
                onChange={value => setSelectedCommunity(value)}
                className="report-select"
                value={selectedCommunity}
              >
                <Select.Option key="all" value="all">
                  {strings.allCommunities}
                </Select.Option>
                {omamasCommunity.map(community => (
                  <Select.Option key={0} value={community}>
                    {community}
                  </Select.Option>
                ))}
              </Select>
            )}
          </div>
          <br />
          {reportButtons(
            'parentalClubsReport',
            'rodicovskeKluby',
            () =>
              parentalClubsRange[0] &&
              parentalClubsRange[1] &&
              selectedCommunity &&
              dispatch(
                getParentalClubsReportAction(
                  selectedCommunity,
                  'parentalClubsReport',
                  parentalClubsRange[0],
                  parentalClubsRange[1]
                )
              )
          )}
        </div>
      </TabPane>
    );
  }, [
    dispatch,
    changeClientActionsTimeReportDate,
    changeOmamaActionsTimeReportDate,
    changeParentalClubsTimeReportDate,
    reportButtons,
    clientActionsRange,
    clientID,
    clients,
    omamaActionsRange,
    omamaID,
    omamas,
    supervisors,
    selectedSupervisor,
    parentalClubsRange,
    selectedCommunity
  ]);

  const generateOmamasStatsReportTab = useCallback(() => {
    const yearOptions = [];
    let thisYear = moment().year();
    for (let i = 0; i < 20; i++) {
      yearOptions.push(thisYear - i);
    }
    return (
      <TabPane tab={strings.statistics} key="5" data-test-id="reports-statistics">
        <div data-test-id="reports-statistics-omamas">
          <h3>{strings.omamy}</h3>
          <Select
            style={{ width: 270 }}
            className="report-select"
            placeholder={strings.chooseYear}
            optionFilterProp="children"
            defaultValue={omamasStatsYear}
            onChange={value => setOmamasStatsYear(value)}
          >
            {yearOptions.map(year => (
              <Option key={0} value={year}>
                {year}
              </Option>
            ))}
          </Select>
          <br />
          <br />
          {reportButtons(
            'omamasStatsReport',
            'statistikyOmam',
            () => omamasStatsYear && dispatch(getOmamasStatsReportAction('omamasStatsReport', omamasStatsYear))
          )}
        </div>
        <div data-test-id="reports-statistics-work-mentors">
          <h3>{strings.ratingMentorsWork}</h3>
          <RangePicker
            onChange={changeMentorsWorkRatingReportDate}
            onPanelChange={handleMentorsWorkPanelChange}
            name="mentorsWorkRatingReportDate"
            mode={mentorsWorkRatingReportMode}
            format="YYYY-MM"
            defaultValue={[null, null]}
            value={mentorsWorkRatingReportRange}
            className="adminReport_datepicker"
            placeholder={['Od', 'Do']}
          />
          <div>
            <label className="report-label">{strings.mentorFeminine}:</label>
            <Select
              dropdownClassName="selectMenu"
              className="report-select"
              onChange={value => setSelectedMentor(value)}
              data-test-id="mentoringChooseOmamas"
              value={selectedMentor}
              style={{ marginLeft: '0px', width: '296px' }}
            >
              {renderSelectOptionsForMentors()}
            </Select>
          </div>
          <br />
          {reportButtons(
            'mentorsWorkRatingReport',
            'hodnoteniePraceMentoriek',
            () =>
              mentorsWorkRatingReportRange[0] &&
              mentorsWorkRatingReportRange[1] &&
              dispatch(
                getMentorsWorkRatingReportAction(
                  'mentorsWorkRatingReport',
                  mentorsWorkRatingReportRange[0],
                  mentorsWorkRatingReportRange[1],
                  selectedMentor
                )
              )
          )}
        </div>
        <div data-test-id="reports-statistics-parents">
          <h3>{strings.parentEngagement}</h3>
          <Select
            style={{ width: 270 }}
            className="report-select"
            placeholder={strings.chooseYear}
            optionFilterProp="children"
            defaultValue={parentEngagementYear}
            onChange={value => setParentEngagementYear(value)}
          >
            {yearOptions.map(year => (
              <Option key={0} value={year}>
                {year}
              </Option>
            ))}
          </Select>
          <br />
          <br />
          {reportButtons(
            'parentEngagementReport',
            'zapajanieRodicov',
            () =>
              parentEngagementYear &&
              dispatch(getParentEngagementReportAction('parentEngagementReport', parentEngagementYear))
          )}
        </div>
        <div data-test-id="reports-statistics-trends">
          <h3>{strings.trends}</h3>
          {reportButtons('trendsReport', 'trendy', () => dispatch(getTrendsReportAction('trendsReport')))}
        </div>
        <div data-test-id="reports-statistics-omamasActivities">
          <h3>{strings.omamsActivity}</h3>
          {reportButtons('omamasActivitiesReport', 'aktivnostOmam', () =>
            dispatch(getOmamasActivitiesReportAction('omamasActivitiesReport'))
          )}
        </div>
        <div data-test-id="reports-statistics-ratings">
          <h3>{strings.ratingOmams}</h3>
          <RangePicker
            onChange={changeOmamasRatingReportDate}
            onPanelChange={handleOmamasRatingReportPanelChange}
            name="omamasRatingReportDate"
            mode={omamasRatingReportMode}
            format="YYYY-MM"
            defaultValue={[null, null]}
            value={omamasRatingReportRange}
            className="adminReport_datepicker"
            placeholder={['Od', 'Do']}
          />
          <br />
          {reportButtons(
            'omamasRatingReport',
            'hodnotenieOmam',
            () =>
              omamasRatingReportRange[0] &&
              omamasRatingReportRange[1] &&
              dispatch(
                getOmamasRatingReportAction(
                  'omamasRatingReport',
                  omamasRatingReportRange[0],
                  omamasRatingReportRange[1]
                )
              )
          )}
        </div>
        <div data-test-id="reports-statistics-managmentReport">
          <h3>{strings.performanceManagement}</h3>
          <RangePicker
            onChange={changeComprehensiveReportDate}
            name="comprehensiveReportDate"
            defaultValue={[null, null]}
            value={comprehensiveReportRange}
            className="adminReport_datepicker"
            placeholder={['Od', 'Do']}
          />
          <div>
            <label className="report-label">{strings.region}:</label>
            <Select
              style={{ width: 270 }}
              className="report-select"
              placeholder={strings.chooseRegion}
              optionFilterProp="children"
              value={comprehensiveReportRegion}
              onChange={value => {
                dispatch(resetReportAction('comprehensiveReport'));
                setComprehensiveReportRegion(value);
              }}
            >
              <Option key={null} value="">
                {strings.chooseRegion}
              </Option>
              {regions.map(region => (
                <Option key={region} value={region}>
                  {region}
                </Option>
              ))}
            </Select>
          </div>
          <br />
          {reportButtons('comprehensiveReport', strings.performanceManagement, () =>
            dispatch(
              getComprehensiveReportAction(
                'comprehensiveReport',
                comprehensiveReportRange[0],
                comprehensiveReportRange[1],
                comprehensiveReportRegion
              )
            )
          )}
        </div>
        <div className="bottomPageReport" data-test-id="reports-statistics-accessories">
          <h3>{strings.helperThings}</h3>
          {reportButtons('accessoriesReport', 'pomocky', () =>
            dispatch(getAccessoriesReportAction('accessoriesReport'))
          )}
        </div>
      </TabPane>
    );
  }, [
    dispatch,
    changeComprehensiveReportDate,
    changeOmamasRatingReportDate,
    changeMentorsWorkRatingReportDate,
    handleOmamasRatingReportPanelChange,
    handleMentorsWorkPanelChange,
    renderSelectOptionsForMentors,
    reportButtons,
    comprehensiveReportRange,
    comprehensiveReportRegion,
    omamasRatingReportMode,
    omamasRatingReportRange,
    mentorsWorkRatingReportMode,
    selectedMentor,
    mentorsWorkRatingReportRange,
    omamasStatsYear,
    parentEngagementYear,
    regions
  ]);

  return (
    <div className="statsBackground">
      <div style={{ paddingTop: '40px' }} className="clientsBox">
        <div className="report-container">
          <Tabs type="card" data-test-id="reports-menu">
            {generateUserTab()}
            {generateTimeReportTab()}
            {generateClientTab()}
            {generateActionsReportTab()}
            {generateOmamasStatsReportTab()}
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default Reports;
