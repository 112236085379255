import React from 'react';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { strings } from '../../strings/StringsProvider';

const NoMatch = props => (
  <div className="background">
    <h1 style={{ textAlign: 'center', color: 'white' }}>
      {strings.errorPageNotFound}
    </h1>
    <div
      style={{ textAlign: 'center' }}
      className="link"
      onClick={() => {
        props.changePage();
      }}
    >
      {strings.login1}
    </div>
  </div>
);

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: () => push('/sign-in')
    },
    dispatch
  );

NoMatch.propTypes = {
  changePage: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NoMatch);
