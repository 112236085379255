import moment from 'moment';
import momentB from 'moment-business-days';
/*  
  s_date = start date
  e_date = end date
  args type = Date or String
  return difference between start and end date in object
*/
export const getDateDiff = (s_date, e_date) => {
  // eslint-disable-next-line eqeqeq
  if (s_date == null || e_date == null) return;
  if (typeof s_date === 'string') s_date = new Date(s_date);
  if (typeof e_date === 'string') e_date = new Date(e_date);

  let m = moment(e_date).diff(s_date, 'months');
  let d = moment(e_date).diff(moment(s_date).add(m, 'months'), 'days');
  let w = Math.ceil(d / 7);
  if (w === 0) w = 1;
  if (w > 4) w = 4;
  return {
    months: m,
    weeks: w
  };
};

/*  
  s_date = start date
  e_date = end date
  args type = new Date()
  return difference between start and end in weeks
*/
export const getChildsWeek = (s_date, e_date) => {
  const { months, weeks } = getDateDiff(s_date, e_date);
  return months * 4 + weeks;
};

const staticHolidays = [
  '01-01',
  '06-01',
  '01-05',
  '08-05',
  '05-07',
  '29-08',
  '01-09',
  '15-09',
  '01-11',
  '17-11',
  '24-12',
  '25-12',
  '26-12'
];

const calculateEasterHolidays = year => {
  const a = year % 19;
  const b = year % 4;
  const c = year % 7;
  const d = (19 * a + 24) % 30;
  const e = (5 + 2 * b + 4 * c + 6 * d) % 7;
  const march = 22 + d + e;
  const april = d + e - 9;

  const sunday =
    march > 31 || march < 22
      ? moment(`${year}-04-${april}`)
      : moment(`${year}-03-${march}`);

  return [
    sunday
      .clone()
      .subtract(2, 'day')
      .format('DD-MM-YYYY'),
    sunday.add(1, 'day').format('DD-MM-YYYY')
  ];
};

export const getMonthWorkingDays = (month, year) => {
  const yearHolidays = staticHolidays.map(date => `${date}-${year}`);

  momentB.updateLocale('sk', {
    holidays: [...yearHolidays, ...calculateEasterHolidays(year)],
    holidayFormat: 'DD-MM-YYYY'
  });
  
  return momentB(`${year}-${month < 10 ? `0${month}` : month}-01`, 'YYYY-MM-DD').monthBusinessDays().length;
};

export const calculateScreeningDate = (birthDate, dateDefinition = {}) => {
  Object.entries(dateDefinition).forEach(([key, value]) => birthDate.add(value, key));
  return birthDate;
};

export const formatChildWeek = (birthDate, actionDate) => {
  if (!actionDate) {
    const today = new Date();
    actionDate = today.toUTCString();
  }
  let b_date = new Date(birthDate);
  let a_date = new Date(actionDate);
  let { months, weeks } = getDateDiff(b_date, a_date);
  let childWeek = months + 'm/' + weeks + 't';
  return childWeek;
};

export const getDevelopmentStage = (birthDate, actionDate) => {
  const formatedClientAge = formatChildWeek(birthDate, actionDate);
  const month = Number(formatedClientAge.split('/')[0].slice(0, -1));
  const week = Number(formatedClientAge.split('/')[1].slice(0, -1));

  let developmentStage = '';

  if (month <= 2) {
    const weeks = (month * 4) + week;
    if (weeks <= 4) developmentStage = '2.PP'; else
    if (weeks <= 7) developmentStage = '3.PP'; else 
    if (weeks <= 10) developmentStage = '4.PP'; else
    developmentStage = '5.PP';
  } else {
    if (month <= 4) developmentStage = '5.PP'; else 
    if (month <= 6) developmentStage = '6.PP'; else
    if (month <= 8) developmentStage = '7.PP'; else
    if (month <= 10) developmentStage = '8.PP'; else
    if (month <= 12) developmentStage = '9.PP'; else
    if (month <= 18) developmentStage = '10.PP'; else
    if (month <= 35) developmentStage = '11.PP'; else
    developmentStage = '12.PP';
  }

  return developmentStage;
};
