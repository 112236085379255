export const HANDLE_DEACTIVATE_FORM = 'HANDLE_DEACTIVATE_FORM';

export const initialState = {
  data: {
    active: true,
    deactivatedOnDate: '',
  }
};

initialState.formFields = Object.keys(initialState.data);

export default (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_DEACTIVATE_FORM:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.field]: action.payload.date
        }
      };
    default:
      return state;
  }
};