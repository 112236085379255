import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Icon } from 'antd';
import PropTypes from 'prop-types';

import { userLogoutAction } from '../actions/menu.actions';
import { strings } from '../strings/StringsProvider';
import './Menu.scss';

const Menu = ({ onClickMenu }) => {
  const dispatch = useDispatch();

  const onClickChangePage = page => () => {
    onClickMenu(false);
    dispatch(push(page));
  };

  const signOut = () => {
    localStorage.removeItem('access-token');
    localStorage.removeItem('logs');
    dispatch(userLogoutAction());
    onClickMenu(false);
    dispatch(push('/sign-in'));
  };

  return (
    <div className="backgroundMenu">
      <p onClick={() => onClickMenu(false)}>
        <Icon type="close" className="closeIcon" />
      </p>
      <div className="navigationDiv">
        <div className="displayDiv">
          <p onClick={onClickChangePage('/omama/klienti')}>{strings.clients}</p>
          <p onClick={onClickChangePage('/omama/plan')}>{strings.weeklyPlan}</p>
          <p onClick={onClickChangePage('/omama/supervizie')}>{strings.supervisions}</p>
          <p onClick={onClickChangePage('/omama/vyvinoveStadia')}>{strings.developmentStages}</p>
          <p onClick={onClickChangePage('/omama/aktivity')}>{strings.activities}</p>
          {/* <p onClick={onClickChangePage('/omama/skolenia')}>Prehlad skoleni</p> */}
          <p onClick={onClickChangePage('/omama/galeria')}>{strings.gallery}</p>
          <p onClick={onClickChangePage('/omama/pomocky')}>{strings.borrowedTools}</p>
          <p onClick={onClickChangePage('/omama/zrkadlo')}>{strings.mirror}</p>
          <p onClick={onClickChangePage('/omama/report')}>{strings.workReport}</p>
          <p onClick={onClickChangePage('/omama/nastavenia')}>{strings.settings}</p>
          <p onClick={signOut} className="logoff">
            {strings.signOut}
          </p>
        </div>
      </div>
    </div>
  );
};

Menu.propTypes = {
  onClickMenu: PropTypes.func
};

export default Menu;
