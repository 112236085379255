import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import moment from 'moment';
import 'moment/locale/sk';
import { uniqBy } from 'lodash';

//Local Imports
// import './Action.scss';
// import './Client.scss';
import './DayActions.scss';
moment.locale('sk');

function DayActions({ showClientModal, showActionModal, day, actions, clients }) {
  const getDayOfTheMonth = () => {
    return moment(actions[0].date).format('dddd');
  };

  const openCustomModal = useCallback(
    action => {
      if (showClientModal || showActionModal) {
        if (action.client === true) {
          showClientModal(action._id, '', '');
        } else {
          showActionModal(action._id);
        }
      }
    },
    [showActionModal, showClientModal]
  );

  const groupClubs = () => {
    // Preschool clubs consist of multiple copies of the same action.
    // We group them by date and clubNames which makes them show
    // up in the plan as one action.
    return uniqBy(actions, action => {
      if (action.differentAction === 'club') {
        return action.date + action.clubNames;
      }
      return action._id;
    });
  };

  return (
    <>
      <div className="day">
        <div className="date">
          <b className="datum">{day}</b>
          <div className="den">{getDayOfTheMonth()}</div>
        </div>

        <div className="action">
          {groupClubs()
            .sort((a, b) => {
              return new Date(a.date).getTime() - new Date(b.date).getTime();
            })
            .map((action, i) => (
              <div key={i} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <div className="cas">{moment(action.date).format('HH:mm')}</div>
                <div
                  className={classNames(
                    'client',
                    { canceled: action.status.startsWith('canceled') },
                    { clubStyle: action.differentAction === 'club' },
                    { notDone: action.status === 'active' && action.client === true }
                  )}
                  onClick={() => openCustomModal(action)}
                >
                  {action.differentAction !== 'club' ? (
                    <span className="dayActions-actionName">
                      {clients[action.id] ? (
                        <>
                          {clients[action.id].name || `${clients[action.id].firstName} ${clients[action.id].lastName}`}
                          {action.isScreeningMissing && ' (Skríning)'}
                        </>
                      ) : (
                        action.id
                      )}
                    </span>
                  ) : (
                    <span className="dayActions-actionName">{action.clubNames}</span>
                  )}

                  {/* PRESUNUT DO VYSSIEHO RIADKU - AK SA TO POUZIJE */}
                  {/*  {action.status.startsWith("canceled") ? ` Zrušené ${action.status.substr(8)}` : null} */}
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

DayActions.propTypes = {
  day: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.object),
  clients: PropTypes.object,
  showClientModal: PropTypes.func,
  showActionModal: PropTypes.func
};

export default DayActions;
