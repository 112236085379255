import { keyBy } from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { goBack, push, replace } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  DatePicker,
  Input,
  Button,
  Dropdown,
  Icon,
  Slider,
  Upload,
  Menu,
  Select,
  Popover
} from 'antd';
import AvatarEditor from 'react-avatar-editor';
import Stepper from 'react-stepper-js';
import 'react-stepper-js/dist/index.css';

import Autocomplete from '../../components/Autocomplete';
import { Locations } from '../../data/locations';

import classNames from 'classnames';
// Local Imports
import './RegisterClient.scss';
import { getOmamasAction, getRegions } from '../../actions/users.actions.js';
import { strings } from '../../strings/StringsProvider';
import {
  updateCLientFormAction,
  resetClientForm,
  updateCLientFormActionButton,
  updateCLientFormActionDate,
  registerClientAction,
  editClientAction
} from '../../actions/clientForm.actions';
import Base64Image from '../../components/Base64Image';
import { sortNames } from '../../tools/utils';
import CustomButton from 'components/Button';

const requiredFields = [
  'firstName',
  'lastName',
  'street',
  'city',
  'birthDate',
  'gestacnyBirthDate',
  'inProgramFromDate'
];

function RegisterClient({ match }) {
  const history = useHistory();
  const [fileList, setFileList] = useState([]);
  const [stepperIndex, setStepperIndex] = useState(1);
  const [scale, setScale] = useState(1);

  const clientFormData = useSelector(state => state.clientForm.data);
  const clientFormFields = useSelector(state => state.clientForm.formFields);
  const omamas = useSelector(state => state.users.omamas);
  const omamasById = useSelector(state => keyBy(state.users.omamas, '_id'));
  const regions = useSelector(state => state.users.regions);

  const dispatch = useDispatch();

  const changePage = location => dispatch(push(location));
  const replacePage = location => dispatch(replace(location));
  const edit = match.path.includes('upravit');
  const editorRef = useRef(null);

  useEffect(() => {
    dispatch(getOmamasAction());
    dispatch(getRegions());
    if (!edit) {
      dispatch(resetClientForm());
    }

    return () => dispatch(resetClientForm());
  }, [dispatch, edit]);

  const fieldChangeHandler = name => event => {
    dispatch(updateCLientFormAction(name, event.target.value));
  };

  const cityFieldChangeHandler = name => value => {
    dispatch(updateCLientFormAction(name, value));
  };

  const changeDate = field => date => {
    dispatch(updateCLientFormActionDate(field, moment(date).format()));
  };

  const buttonToggle = (type, flag) => {
    dispatch(updateCLientFormActionButton(type, flag));
  };

  const getClientPhoto = () => {
    if (editorRef.current) {
      return editorRef.current.getImageScaledToCanvas().toDataURL();
    }
    return '';
  };

  const uploadProps = () => {
    return {
      onRemove: file => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
      },
      beforeUpload: file => {
        setFileList([file]);
        return false;
      },
      fileList
    };
  };

  const checkRequiredFields = () =>
    requiredFields.some(field => !clientFormData[field]);

  const addNewClient = async () => {
    const clientInfo = {};
    clientFormFields.forEach(item => {
      clientInfo[item] = clientFormData[item];
    });
    const photo = getClientPhoto();

    const reqBody = {
      ...clientInfo,
      birthDate: clientFormData.birthDate
        ? moment(clientFormData.birthDate)
            .startOf('day')
            .format()
        : '',
      gestacnyBirthDate: clientFormData.gestacnyBirthDate
        ? moment(clientFormData.gestacnyBirthDate)
            .startOf('day')
            .format()
        : '',
      inProgramFromDate: clientFormData.inProgramFromDate
        ? moment(clientFormData.inProgramFromDate)
            .startOf('day')
            .format()
        : '',
      motherBirthDate: clientFormData.motherBirthDate
        ? moment(clientFormData.motherBirthDate)
            .startOf('day')
            .format()
        : '',
      fatherBirthDate: clientFormData.fatherBirthDate
        ? moment(clientFormData.fatherBirthDate)
            .startOf('day')
            .format()
        : ''
    };

    if (edit) {
      dispatch(editClientAction(
        reqBody,
        clientFormData._id,
        photo,
        replacePage
      ));
    } else {
      reqBody.photo = photo;
      dispatch(registerClientAction(reqBody, changePage));
    }
  };

  const onOmamaChange = item => {
    dispatch(updateCLientFormAction('omamaID', item.key));
  };

  const omamaOptions = () => {
    const activeOmamas = [...omamas].filter(omama => omama.active);
    const sortedOmamas = sortNames(activeOmamas);

    return (
      <Menu onClick={onOmamaChange}>
        {sortedOmamas.map(omama => (
          <Menu.Item key={omama._id}>{omama.name}</Menu.Item>
        ))}
      </Menu>
    );
  };

  const onGenderChange = item => {
    dispatch(updateCLientFormAction('gender', item.key));
  };

  const genderOptions = () => (
    <Menu onClick={onGenderChange}>
      <Menu.Item key={strings.man}>{strings.man}</Menu.Item>
      <Menu.Item key={strings.woman}>{strings.woman}</Menu.Item>
    </Menu>
  );

  const solidFoodChange = value => {
    dispatch(updateCLientFormAction('solidFood', Number(value.key) + 1));
  };

  const solidFoodOptions = () => (
    <Menu onClick={solidFoodChange}>
      {['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'].map(
        (time, index) => (
          <Menu.Item key={index}>{time}</Menu.Item>
        )
      )}
    </Menu>
  );

  const getDateObject = date => {
    return moment(date).isValid() ? moment(date) : null;
  };

  const renderGrowthSelect = (label, formAction, defaultValue) => {
    let growthOptions = [
      <Select.Option key={0} value="">
        <span className="desktopOnly">{strings.notFilled}</span>
        <span className="mobileOnly" id="psychomotorLabelSize">
          {label}
        </span>
      </Select.Option>
    ];
    for (let i = 1; i <= 36; i++) {
      let value = '';
      if (i === 1) value = i + ' ' + strings.oneMonth;
      else if (i >= 5) value = i + ' ' + strings.zeroOrFiveMonths;
      else if (i >= 2 || i <= 4) value = i + ' ' + strings.twoTillFourMonths;
      growthOptions.push(
        <Select.Option key={i} value={value}>
          {value}
        </Select.Option>
      );
    }

    return (
      <div className="growth">
        <label className="growth-label desktopOnly">{label}</label>
        <Select
          onChange={value =>
            dispatch(updateCLientFormAction(formAction, value))
          }
          value={defaultValue}
          className="growth-select"
        >
          {growthOptions}
        </Select>
      </div>
    );
  };

  const renderTextarea = objectName => (
    <textarea
      className="inputMobile-textarea"
      onChange={fieldChangeHandler(objectName)}
      name={objectName === 'institution' ? 'visitingInstitution' : objectName}
      value={clientFormData[objectName]}
      maxLength="100"
    />
  );

  const renderYesNoQuestion = objectName => (
    <div className="yesNoAnswerHealth">
      <div
        onClick={() => buttonToggle(objectName, true)}
        className={classNames('yesNoButton', 'notSelected', {
          selected: clientFormData[objectName] === true
        })}
      >
        {strings.yes}
      </div>
      <div
        onClick={() => buttonToggle(objectName, false)}
        className={classNames('yesNoButton', 'notSelected', {
          selected: clientFormData[objectName] === false
        })}
      >
        {strings.no}
      </div>
    </div>
  );

  const changeFields = () => {
    if (clientFormData.regularExaminations === null) {
      if (clientFormData.examination === '') {
        clientFormData.regularExaminations = false;
      } else if (clientFormData.examination.length > 0) {
        clientFormData.regularExaminations = true;
        clientFormData.examinationOffice = clientFormData.examination;
      }
    }

    if (clientFormData.lastExamination)
      clientFormData.dateOfLastExamination = clientFormData.lastExamination;
    else clientFormData.dateOfLastExamination = '';

    if (clientFormData.breastFedDuration)
      clientFormData.breastFeedTime = clientFormData.breastFedDuration;

    if (clientFormData.headCicumference)
      clientFormData.birthHeadCircumference = clientFormData.headCicumference;

    if (clientFormData.defaultWeight)
      clientFormData.birthWeight = clientFormData.defaultWeight;

    if (clientFormData.defaultHeight)
      clientFormData.birthLength = clientFormData.defaultHeight;

    if (clientFormData.breastFed === '')
      clientFormData.breastFed = false;
    else if (typeof clientFormData.breastFed === 'string')
      clientFormData.breastFed = true;

    if (clientFormData.visitingInstitution === '') {
      clientFormData.visitingInstitution = false;
    } else if (
      typeof clientFormData.visitingInstitution === 'string'
    ) {
      clientFormData.institutionName = clientFormData.visitingInstitution;
      clientFormData.visitingInstitution = true;
    }
  };

  const setGDPRdefaults = () => {
    if (clientFormData.photosInApp === null)
      clientFormData.photosInApp = false;
    if (clientFormData.photosOnWeb === null)
      clientFormData.photosOnWeb = false;
    if (clientFormData.photosPrinted === null)
      clientFormData.photosPrinted = false;
    if (clientFormData.videoReports === null)
      clientFormData.videoReports = false;

    if (clientFormData.pediatricScreenings === null)
      clientFormData.pediatricScreenings = false;
    if (clientFormData.growthTracking === null)
      clientFormData.growthTracking = false;
    if (clientFormData.educationCarrierGrowth === null)
      clientFormData.educationCarrierGrowth = false;
    if (clientFormData.contactLater === null)
      clientFormData.contactLater = false;
  };

  const renderFormTextField = (objectName, placeholder, className) => (
    <Input
      onChange={fieldChangeHandler(objectName)}
      name={objectName}
      value={clientFormData[objectName]}
      className={`register_input ${className}`}
      placeholder={placeholder}
    />
  );

  const scrollWin = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const goToNextRegistrationStep = () => {
    setStepperIndex(stepperIndex => stepperIndex + 1);
    scrollWin();
  };

  const goToPreviousRegistrationStep = () => {
    setStepperIndex(stepperIndex => stepperIndex - 1);
    scrollWin();
  };

  const renderBottomMobilePageNavigation = () => (
    <>
      <div className="next_back_buttons">
        <span
          className="button-back"
          onClick={() => goToPreviousRegistrationStep()}
        >
          <Icon className="button-back-icon" type="left" />
          <span className="button-back-text">{strings.back}</span>
        </span>
        <span
          className="button-next"
          onClick={() => goToNextRegistrationStep()}
        >
          {strings.next}
          <Icon className="button-next-icon" type="right" />
        </span>
      </div>
      <div className="mobileOnly registration_button mobileRegisterButton">
        <Button
          disabled={checkRequiredFields()}
          onClick={addNewClient}
        >
          {strings.save}
        </Button>
      </div>
    </>
  );

  const {
    growthCooing,
    growthCrawling,
    growthRollingover,
    growthSitting,
    growthTalking,
    growthWalking
  } = clientFormData;

  // if the client record has no GDPR information, this sets it to default false values
  clientFormData.photosInApp ?? setGDPRdefaults();

  //  if the client record is old, this.changeFields() adds new fields and maps the old fields to the new ones
  clientFormData.regularExaminations ?? changeFields();
  /*
    dateOfLastExamination was autofilled with current date in some cases, issue was already fixed
    although some records were saved with the autofilled or no date
    the line below defaults it to '', so no date is filled
  */
  clientFormData.dateOfLastExamination ??
    (clientFormData.dateOfLastExamination = '');

  const regionList = (
    <div>
      <div>
        <b>{strings.existingRegions}</b>
      </div>
      {regions.map(region => (
        <div key={region}>{region}</div>
      ))}
    </div>
  );

  return (
    <div className="register-background">
      <div className="register_client_background">
        <div className="mobileOnly">
          <div
            className={`${
              stepperIndex === 1
                ? 'registration_client_firstStep'
                : 'registration_client_nextSteps'
            }`}
          >
            <span
              className={`${
                stepperIndex === 1 ? 'activeStep' : 'hiddenStep'
              }`}
              onClick={() => edit
                ? replacePage(`/admin/klienti/${clientFormData._id}`)
                : dispatch(goBack())
              }
            >
              <div className="back-button">
                <Icon type="left" />
                {strings.back1}
              </div>
            </span>
            <span>{strings.clientRegistration}</span>
          </div>
          <Stepper
            color="#41AEA6"
            fontSize="10px"
            steps={[{}, {}, {}, {}, {}, {}, {}]}
            currentStep={stepperIndex}
          />
        </div>
        <div
          className={`register_client_ui ${
            stepperIndex === 1 ? 'activeStep' : 'hiddenStep'
          }`}
          data-test-id="clientRegister-clientRegister"
        >
        <div className="submenu-container ">
          <div className="admin-clients-submenu clientDesktopView ">
            <CustomButton
              type="secondary"
              icon={<img src="/images/Icons/go-back.svg" alt="back-button" />}
              label={strings.goBack}
              className="go-back-button"
              onClick={history.goBack}
            />
          </div>
        </div>
          <div className="register_client_header mainHeader">
            <h2 style={{ fontSize: '30px' }}>
              {edit
                ? strings.editClient
                : strings.clientRegistration}
            </h2>
          </div>
          <div className="register_client">
            <div className="register_client_picture">
              {fileList[0] ? (
                <div>
                  <AvatarEditor
                    ref={editorRef}
                    image={fileList[0]}
                    value="Test"
                    width={250}
                    height={250}
                    border={20}
                    color={[255, 255, 255, 0.6]} // RGBA
                    scale={scale}
                    rotate={0}
                  />
                  <div className="icon-wrapper" style={{ width: '350px' }}>
                    <Icon type="zoom-out" />
                    <Slider
                      min={1}
                      max={10}
                      step={0.1}
                      value={scale}
                      onChange={newScale => setScale(newScale)}
                      style={{ marginLeft: 0, marginRight: 0 }}
                    />
                    <Icon type="zoom-in" />
                  </div>
                </div>
              ) : (
                <Base64Image
                  className="registerClientPhoto"
                  type="user"
                  imageID={clientFormData._id}
                  backUpImage="/images/cestavonLOGO_unofficial.png"
                />
              )}
              <div className="registerUpload">
                <Upload {...uploadProps()}>
                  <Button>
                    <Icon type="upload" /> {strings.choosePicture}
                  </Button>
                </Upload>
              </div>
            </div>
            <div className="register_client_right">
              <h3 className="mobileClientFormHeader">{strings.child}</h3>
              <div className="register_client_data">
                <div>
                  <div className="register-input-label">
                    {strings.firstName}
                  </div>
                  <Input
                    onChange={fieldChangeHandler('firstName')}
                    name="firstName"
                    value={clientFormData.firstName}
                    className="register_input"
                    placeholder={strings.firstName}
                  />
                </div>
                <div>
                  <div className="register-input-label">
                    {strings.lastName}
                  </div>
                  <Input
                    onChange={fieldChangeHandler('lastName')}
                    name="lastName"
                    value={clientFormData.lastName}
                    className="register_input"
                    placeholder={strings.lastName}
                  />
                </div>
                <div>
                  <div className="register-input-label">
                    {strings.gender}
                  </div>
                  <div className="genderDropdown">
                    <Dropdown overlay={genderOptions()} trigger={['click']}>
                      <div>
                        {clientFormData.gender
                          ? clientFormData.gender
                          : <span className='gender-placeholder'>{strings.gender}</span>}
                        <Icon type="down" />
                      </div>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="register_client_data">
                <div>
                  <div className="register-input-label">
                    {strings.region}{' '}
                    <Popover content={regionList} placement="right">
                      <Icon
                        type="info-circle"
                        style={{ fontSize: '20px', color: '#41aea6' }}
                      />
                    </Popover>
                  </div>
                  <Input
                    onChange={fieldChangeHandler('region')}
                    name="region"
                    value={clientFormData.region}
                    className="register_input"
                    placeholder={strings.region}
                  />
                </div>
                <div>
                  <div className="register-input-label">
                    {strings.homeStreet}
                  </div>
                  <Input
                    onChange={fieldChangeHandler('street')}
                    name="address"
                    value={clientFormData.street}
                    className="register_input"
                    placeholder={strings.homeStreet}
                  />
                </div>
                <div style={{ position: 'relative' }}>
                  <div className="register-input-label">
                    {strings.homeCity}
                  </div>
                  <Autocomplete
                    formValue={clientFormData.city}
                    suggestions={Locations}
                    fieldChange={cityFieldChangeHandler('city')}
                  />
                </div>
                <div>
                  <div className="register-input-label">
                    {strings.birthCity}
                  </div>
                  <Input
                    onChange={fieldChangeHandler('birthDateAdress')}
                    name="birthDateAdress"
                    value={clientFormData.birthDateAdress}
                    className="register_input"
                    placeholder={strings.birthCity}
                  />
                </div>
              </div>
              <div>
                <div className="register_client_data">
                  <div>
                    <div className="register-input-label">
                      {strings.birthDate}
                    </div>
                    <DatePicker
                      onChange={changeDate('birthDate')}
                      name="birthDate"
                      value={getDateObject(clientFormData.birthDate)}
                      className="register_datepicker"
                      placeholder={strings.birthDate}
                    />
                  </div>
                  <div>
                    <div className="register-input-label">
                      {strings.plannedDateOfBirth}
                    </div>
                    <DatePicker
                      onChange={changeDate('gestacnyBirthDate')}
                      name="gestacnyBirthDate"
                      value={getDateObject(clientFormData.gestacnyBirthDate)}
                      className="register_datepicker"
                      placeholder={strings.plannedDateOfBirth}
                    />
                  </div>
                  <div>
                    <div className="register-input-label">
                      {strings.inProgramFrom1}
                    </div>
                    <DatePicker
                      onChange={changeDate('inProgramFromDate')}
                      name="inProgramFromDate"
                      value={getDateObject(clientFormData.inProgramFromDate)}
                      className="register_datepicker"
                      placeholder={strings.inProgramFrom1}
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <div
                      className="yesNoQuestion"
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around'
                      }}
                      data-test-id="clientRegister-preschoolClubYN"
                    >
                      <h3 className="yesNoLabel">{strings.preschoolClub}</h3>
                      <div
                        onClick={() => buttonToggle('predskolskyKlub', true)}
                        className={classNames('yesNoButton', 'notSelected', {
                          selected:
                            clientFormData.predskolskyKlub === true
                        })}
                      >
                        {strings.yes}
                      </div>
                      <div
                        onClick={() =>buttonToggle('predskolskyKlub', false)}
                        className={classNames('yesNoButton', 'notSelected', {
                          selected:
                            clientFormData.predskolskyKlub === false
                        })}
                      >
                        {strings.no}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="omamaDropdown" id="selectOmama">
                <Dropdown overlay={omamaOptions()} trigger={['click']}>
                  <div>
                    {omamasById[clientFormData.omamaID]
                      ? omamasById[clientFormData.omamaID].name
                      : strings.chooseOmama}
                    <Icon type="down" />
                  </div>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="mobilePicture endOfStep">
            {fileList[0] ? (
              <div>
                <AvatarEditor
                  ref={editorRef}
                  image={fileList[0]}
                  value="Test"
                  width={173}
                  height={173}
                  border={20}
                  color={[255, 255, 255, 0.6]} // RGBA
                  scale={scale}
                  rotate={0}
                />
                <div className="icon-wrapper" style={{ width: '100%' }}>
                  <Icon type="zoom-out" />
                  <Slider
                    min={1}
                    max={10}
                    step={0.1}
                    value={scale}
                    onChange={newScale => setScale(newScale)}
                    style={{ marginLeft: 0, marginRight: 0 }}
                  />
                  <Icon type="zoom-in" />
                </div>
              </div>
            ) : (
              <Base64Image
                className="registerClientPhoto"
                type="user"
                imageID={clientFormData._id}
                backUpImage="/images/cestavonLOGO_unofficial.png"
              />
            )}
            <div className="registerUpload">
              <Upload {...uploadProps()}>
                <Button>
                  <Icon type="upload" /> {strings.choosePicture}
                </Button>
              </Upload>
            </div>
          </div>
          <div
            className="mobileOnly button-next"
            onClick={() => goToNextRegistrationStep()}
          >
            {strings.next}
            <Icon
              style={{ position: 'absolute', top: '16%' }}
              className="button-next-icon"
              type="right"
            />
          </div>
          <div className="mobileOnly endOfStep registration_button mobileRegisterButton">
            <Button
              disabled={checkRequiredFields()}
              onClick={addNewClient}
            >
              {strings.save}
            </Button>
          </div>
        </div>
        <div
          className={`register_client_ui ${
            stepperIndex === 2 ? 'activeStep' : 'hiddenStep'
          }`}
          data-test-id="clientRegister-family1"
        >
          <h2 className="sectionHeader" style={{ fontSize: '30px' }}>
            {strings.family}
          </h2>
          <div className="register_client_header">
            <h2 className="subsectionHeader">{strings.mother}</h2>
          </div>
          <div className="register_client_input">
            <div>
              <div className="register-input-label">
                {strings.nameAndSurname}
              </div>
              <Input
                onChange={fieldChangeHandler('motherName')}
                name="motherName"
                value={clientFormData.motherName}
                className="register_input"
                placeholder={strings.nameAndSurname}
              />
            </div>
            <div>
              <div className="register-input-label">{strings.birthDate}</div>
              <DatePicker
                onChange={changeDate('motherBirthDate')}
                name="motherBirthDate"
                value={getDateObject(clientFormData.motherBirthDate)}
                className="register_datepicker"
                placeholder={strings.birthDate}
              />
            </div>
            <div>
              <div className="register-input-label">
                {strings.phoneNumber}
              </div>
              <Input
                onChange={fieldChangeHandler('motherPhone')}
                name="motherPhone"
                value={clientFormData.motherPhone}
                className="register_input"
                placeholder={strings.phoneNumber}
              />
            </div>
          </div>
          <div className="register_client_input">
            <div style={{ width: '100%' }}>
              <div className="register-input-label">
                {strings.healthState}
              </div>
              <Input
                onChange={fieldChangeHandler('motherHealthState')}
                name="motherHealthState"
                value={clientFormData.motherHealthState}
                className="register_input"
                placeholder={strings.healthState}
                style={{ width: '100%' }}
              />
            </div>
          </div>
          <div
            className="register_client_input"
            style={{ marginBottom: '25px' }}
          >
            <div>
              <div className="register-input-label">
                {strings.educationEnded}
              </div>
              <Input
                onChange={fieldChangeHandler('motherEducation')}
                name="motherEducation"
                value={clientFormData.motherEducation}
                className="register_input"
                placeholder={strings.educationEnded}
              />
            </div>
            <div>
              <div className="register-input-label">{strings.class}</div>
              <Input
                onChange={fieldChangeHandler('motherEducationLevel')}
                name="motherEducationLevel"
                value={clientFormData.motherEducationLevel}
                className="register_input"
                placeholder={strings.class}
              />
            </div>
            <div>
              <div className="register-input-label">{strings.job}</div>
              <Input
                onChange={fieldChangeHandler('motherWork')}
                name="motherWork"
                value={clientFormData.motherWork}
                className="register_input"
                placeholder={strings.job}
              />
            </div>
          </div>
        </div>
        <div
          className={`register_client_ui ${
            stepperIndex === 2 ? 'activeStep' : 'hiddenStep'
          }`}
          data-test-id="clientRegister-family2"
        >
          <div className="register_client_header">
            <h2 className="subsectionHeader">{strings.father}</h2>
          </div>
          <div className="register_client_input">
            <div>
              <div className="register-input-label">
                {strings.nameAndSurname}
              </div>
              <Input
                onChange={fieldChangeHandler('fatherName')}
                name="fatherName"
                value={clientFormData.fatherName}
                className="register_input"
                placeholder={strings.nameAndSurname}
              />
            </div>
            <div>
              <div className="register-input-label">{strings.birthDate}</div>
              <DatePicker
                onChange={changeDate('fatherBirthDate')}
                name="fatherBirthDate"
                value={getDateObject(clientFormData.fatherBirthDate)}
                className="register_datepicker"
                placeholder={strings.birthDate}
              />
            </div>
            <div>
              <div className="register-input-label">
                {strings.phoneNumber}
              </div>
              <Input
                onChange={fieldChangeHandler('fatherPhone')}
                name="fatherPhone"
                value={clientFormData.fatherPhone}
                className="register_input"
                placeholder={strings.phoneNumber}
              />
            </div>
          </div>
          <div className="register_client_input">
            <div style={{ width: '100%' }}>
              <div className="register-input-label">
                {strings.healthState}
              </div>
              <Input
                onChange={fieldChangeHandler('fatherHealthState')}
                name="fatherHealthState"
                value={clientFormData.fatherHealthState}
                className="register_input"
                placeholder={strings.healthState}
              />
            </div>
          </div>
          <div
            className="register_client_input"
            style={{ marginBottom: '25px' }}
          >
            <div>
              <div className="register-input-label">
                {strings.educationEnded}
              </div>
              <Input
                onChange={fieldChangeHandler('fatherEducation')}
                name="fatherEducation"
                value={clientFormData.fatherEducation}
                className="register_input"
                placeholder={strings.educationEnded}
              />
            </div>
            <div>
              <div className="register-input-label">{strings.class}</div>
              <Input
                onChange={fieldChangeHandler('fatherEducationLevel')}
                name="fatherEducationLevel"
                value={clientFormData.fatherEducationLevel}
                className="register_input"
                placeholder={strings.class}
              />
            </div>
            <div>
              <div className="register-input-label">{strings.job}</div>
              <Input
                onChange={fieldChangeHandler('fatherWork')}
                name="fatherWork"
                value={clientFormData.fatherWork}
                className="register_input"
                placeholder={strings.job}
              />
            </div>
          </div>
        </div>
        <div
          className={`register_client_ui ${
            stepperIndex === 2 ? 'activeStep' : 'hiddenStep'
          }`}
          data-test-id="clientRegister-family3"
        >
          <div className="register_client_header">
            <h2 className="subsectionHeader">{strings.siblings}</h2>
          </div>
          <div className="register_client_input">
            <div
              className="register_client_input-mobileVersion"
              style={{ width: '40%' }}
            >
              <div className="register-input-label">
                {strings.numberOfSiblings}
              </div>
              <Input
                onChange={fieldChangeHandler('siblingsNumber')}
                name="siblingsNumber"
                value={clientFormData.siblingsNumber}
                className="register_input"
                placeholder={strings.numberOfSiblings}
              />
            </div>
            <div
              className="register_client_input-mobileVersion"
              style={{ width: '40%' }}
            >
              <div className="register-input-label">
                {strings.childIsBornInOrder}
              </div>
              <Input
                onChange={fieldChangeHandler('childBirthOrder')}
                name="childBirthOrder"
                value={clientFormData.childBirthOrder}
                className="register_input"
                placeholder={strings.childIsBornInOrder}
              />
            </div>
          </div>
          <div className="register_client_input inputDesktop">
            <div
              style={{
                width: '100%',
                marginTop: '20px',
                marginBottom: '25px'
              }}
            >
              <div className="register-input-label">{strings.languages}</div>
              <Input
                onChange={fieldChangeHandler('language')}
                name="language"
                value={clientFormData.language}
                className="register_input"
                placeholder={strings.languages}
              />
            </div>
          </div>
          <div className="inputMobile endOfStep">
            <p className="inputMobile-text">{strings.languages}</p>
            {renderTextarea('language')}
          </div>
          {renderBottomMobilePageNavigation()}
        </div>
        <div
          className={`register_client_ui ${
            stepperIndex === 3 ? 'activeStep' : 'hiddenStep'
          }`}
          data-test-id="clientRegister-pregnancyChildbirth"
        >
          <div className="komplikacie">
            <h2
              className="sectionHeader"
              style={{ fontSize: '30px', marginTop: '30px' }}
            >
              {strings.pregnancyAndBirth}
            </h2>
            <h2 className="subsectionHeader">{strings.pregnancy}</h2>
            <p className="mobileTextareaHeadline">{strings.birthStory}</p>
            <textarea
              onChange={fieldChangeHandler('pregnancy')}
              name="pregnancy"
              value={clientFormData.pregnancy}
              maxLength="150"
              style={{ marginBottom: '25px' }}
            />
          </div>
          <div className="tehotenstvo_ui">
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <div className="yesNoQuestion" data-test-id="clientRegister-smokingDuringPregnancyYN">
                <h2>{strings.smokingDuringPregnancy}</h2>
                {renderYesNoQuestion('smokingPregnancy')}
              </div>
              <div className="yesNoQuestion" data-test-id="clientRegister-alcoholDuringPregnancyYN">
                <h2>{strings.alcoholDuringPregnancy}</h2>
                {renderYesNoQuestion('alcoholPregnancy')}
              </div>
            </div>
          </div>
          <div className="tehotenstvo_ui">
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <div className="yesNoQuestion" data-test-id="clientRegister-drugsDuringPregnancyYN">
                <h2>{strings.drugsDuringPregnancy}</h2>
                {renderYesNoQuestion('drugsPregnancy')}
              </div>
              <div className="inputDesktop">
                <h2 id="pregnancyMedicineHeader">
                  {strings.pillsDuringPregnancy}
                </h2>
                <Input
                  onChange={fieldChangeHandler('medicinePregrancy')}
                  name="medicinePregrancy"
                  value={clientFormData.medicinePregrancy}
                  className="register_input"
                  style={{
                    width: '100%',
                    textAlign: 'center'
                  }}
                />
              </div>
              <p className="inputMobile-text">
                {strings.pillsDuringPregnancy}
              </p>
              {renderTextarea('medicinePregrancy')}
            </div>
          </div>
          <div className="tehotenstvo_ui">
            <div className="porod">
              <h2 className="subsectionHeader" style={{ color: '#41aea6' }}>
                {strings.birth}
              </h2>
              <div
                className="childbirthLabels"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div
                  className="childbirthLabels-section"
                  style={{ textAlign: 'center', maxWidth: '300px' }}
                >
                  <div
                    onClick={() => buttonToggle('birthInTerm', !clientFormData.birthInTerm)}
                    className={classNames('childbirthLabels-button', 'notSelected', {
                      selected: clientFormData.birthInTerm === true
                    })}
                  >
                    {strings.inDueDate}
                  </div>
                  <div
                    onClick={() => buttonToggle('prematureBirth', !clientFormData.prematureBirth)}
                    className={classNames('childbirthLabels-button', 'notSelected', {
                      selected: clientFormData.prematureBirth === true
                    })}
                  >
                    {strings.beforeDueDate}
                  </div>
                  <div
                    onClick={() => buttonToggle('lateBirth', !clientFormData.lateBirth)}
                    className={classNames('childbirthLabels-button', 'notSelected', {
                      selected: clientFormData.lateBirth === true
                    })}
                  >
                    {strings.afterDueDate}
                  </div>
                </div>
                <div
                  className="childbirthLabels-section"
                  style={{ textAlign: 'center', maxWidth: '300px' }}
                >
                  <div
                    onClick={() => buttonToggle('inducedBirth', !clientFormData.inducedBirth)}
                    className={classNames('childbirthLabels-button', 'notSelected', {
                      selected: clientFormData.inducedBirth === true
                    })}
                  >
                    {strings.artificialBirth}
                  </div>
                  <div
                    onClick={() => buttonToggle('spontaneousBirth', !clientFormData.spontaneousBirth)}
                    className={classNames('childbirthLabels-button', 'notSelected', {
                      selected: clientFormData.spontaneousBirth === true
                    })}
                  >
                    {strings.spontaniousBirth}
                  </div>
                </div>
                <div
                  className="childbirthLabels-section"
                  style={{ textAlign: 'center', maxWidth: '300px' }}
                >
                  <div
                    onClick={() => buttonToggle('csectionBirth', !clientFormData.csectionBirth)}
                    className={classNames('childbirthLabels-button', 'notSelected', {
                      selected: clientFormData.csectionBirth === true
                    })}
                  >
                    {strings.sekcionBirth}
                  </div>
                  <div
                    onClick={() => buttonToggle('naturalBirth', !clientFormData.naturalBirth)}
                    className={classNames('childbirthLabels-button', 'notSelected', {
                      selected: clientFormData.naturalBirth === true
                    })}
                  >
                    {strings.natural}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="komplikacie">
            <h2 className="mobileTextareaHeadline">
              {strings.complicationsDuringBirth}
            </h2>
            <p className="mobileTextareaHeadline">
              {strings.complicationsQuestion}
            </p>
            <textarea
              onChange={fieldChangeHandler('childbirthComplications')}
              name="childbirthComplications"
              value={clientFormData.childbirthComplications}
              maxLength="150"
              style={{ marginBottom: '25px' }}
            />
          </div>
          <div
            className="tehotenstvo_ui"
            style={{ display: 'flex', alignItems: 'flex-end' }}
          >
            <div className="textFormWrapper">
              <h2 className="textFormWrapper-noLabel">
                {strings.birthWeight1}
              </h2>
              {renderFormTextField('birthWeight', '', 'inputDesktop')}
              {renderFormTextField('birthWeight', strings.birthWeight1, 'inputMobile')}
            </div>
            <div className="textFormWrapper">
              <h2 className="textFormWrapper-noLabel">
                {strings.birthLength1}
              </h2>
              {renderFormTextField('birthLength', '', 'inputDesktop')}
              {renderFormTextField('birthLength', strings.birthLength1, 'inputMobile')}
            </div>
            <div className="textFormWrapper endOfStep">
              <h2 className="textFormWrapper-noLabel">
                {strings.birthHeadCircumference}
              </h2>
              {renderFormTextField('birthHeadCircumference', '', 'inputDesktop')}
              {renderFormTextField('birthHeadCircumference', strings.birthHeadCircumference, 'inputMobile')}
            </div>
          </div>
          {renderBottomMobilePageNavigation()}
        </div>
        <div
          className={`register_client_ui ${
            stepperIndex === 4 ? 'activeStep' : 'hiddenStep'
          }`}
          data-test-id="clientRegister-breastFeed"
        >
          <h2 className="sectionHeader" style={{ fontSize: '30px' }}>
            {strings.breastFeeding}
          </h2>
          <div>
            <div
              className="yesNoQuestion"
              style={{ display: 'flex', justifyContent: 'space-around' }}
              data-test-id="clientRegister-breastFeedYN"
            >
              <h3 style={{ fontSize: '20px', color: '#9999a0' }}>
                {strings.breatFedChild}
              </h3>
              {renderYesNoQuestion('breastFed')}
            </div>
          </div>
          <div
            className="yesNoQuestion"
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center'
            }}
            data-test-id="clientRegister-breastFeedLengthYN"
          >
            <div className="textFormWrapper">
              <h2
                className="textFormWrapper-noLabel"
                style={{ color: '#9999a0' }}
              >
                {strings.lengthOfBreastFeeding}
              </h2>
              {renderFormTextField('breastFeedTime', '', 'inputDesktop')}
              {renderFormTextField('breastFeedTime', strings.lengthOfBreastFeeding, 'inputMobile')}
            </div>
            <div className="textFormWrapper">
              <h3
                style={{
                  fontSize: '20px',
                  marginBottom: '20px',
                  color: '#9999a0',
                  textAlign: 'center'
                }}
              >
                {strings.solidFoodFromWhichMonth}
              </h3>
              <div className="omamaDropdown">
                <Dropdown
                  overlay={solidFoodOptions()}
                  trigger={['click']}
                >
                  <div>
                    {clientFormData.solidFood
                      ? clientFormData.solidFood
                      : strings.choose}
                    <Icon type="down" style={{ marginLeft: '5px' }} />
                  </div>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="tehotenstvo_ui">
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <div className="yesNoQuestion" data-test-id="clientRegister-smokingBreastFeedYN">
                <h2>{strings.smokingDuringBreastFeeding}</h2>
                {renderYesNoQuestion('smokingBreastFeeding')}
              </div>
              <div className="yesNoQuestion" data-test-id="clientRegister-alcoholBreastFeedYN">
                <h2>{strings.alcoholDuringBreastFeeding}</h2>
                {renderYesNoQuestion('alcoholBreastFeeding')}
              </div>
            </div>
            <div className="tehotenstvo_ui">
              <div
                style={{ display: 'flex', justifyContent: 'space-around' }}
              >
                <div className="yesNoQuestion" data-test-id="clientRegister-drugsBreastFeedYN">
                  <h2>{strings.drugsDuringBreastFeeding}</h2>
                  {renderYesNoQuestion('drugsBreastFeeding')}
                </div>
                <div className="yesNoQuestion inputDesktop" data-test-id="clientRegister-medicineBreastFeedYN">
                  <h2>{strings.pillsDuringBreastFeeding}</h2>
                  <Input
                    onChange={fieldChangeHandler('medicineBreastFeeding')}
                    name="medicineBreastFeeding"
                    value={clientFormData.medicineBreastFeeding}
                    className="register_input"
                    style={{
                      width: '100%',
                      textAlign: 'center'
                    }}
                  />
                </div>
                <div className="inputMobile endOfStep">
                  <p className="inputMobile-text">
                    {strings.pillsDuringBreastFeeding}
                  </p>
                  {renderTextarea('medicineBreastFeeding')}
                </div>
              </div>
            </div>
          </div>
          {renderBottomMobilePageNavigation()}
        </div>
        <div
          className={`register_client_ui ${
            stepperIndex === 5 ? 'activeStep' : 'hiddenStep'
          }`}
          data-test-id="clientRegister-psychomotoricallyDevelopedChild"
        >
          <div className="tehotenstvo_ui">
            <h2
              className="sectionHeader"
              id="psychomotorHeader"
              style={{ fontSize: '30px', marginBottom: '30px' }}
            >
              {strings.psychomotoricsOfChild}
            </h2>
            {renderGrowthSelect(
              strings.growthRollingover,
              'growthRollingover',
              growthRollingover
            )}
            {renderGrowthSelect(
              strings.growthSitting,
              'growthSitting',
              growthSitting
            )}
            {renderGrowthSelect(
              strings.growthCrawling,
              'growthCrawling',
              growthCrawling
            )}
            {renderGrowthSelect(
              strings.walking,
              'growthWalking',
              growthWalking
            )}
            {renderGrowthSelect(
              strings.growthCooing,
              'growthCooing',
              growthCooing
            )}
            {renderGrowthSelect(
              strings.growthTalking,
              'growthTalking',
              growthTalking
            )}
            <div className="tehotenstvo_ui">
              <h2
                className="sectionHeader"
                style={{
                  fontSize: '30px',
                  borderTop: '3px solid #41aea6',
                  marginTop: '30px'
                }}
              >
                {strings.generalHealth}
              </h2>
              <div
                style={{ display: 'flex', justifyContent: 'space-around' }}
              >
                <div className="yesNoQuestion" data-test-id="clientRegister-smokingDuringTwoYearYN">
                  <h2>{strings.smokingNearChildBelowTwoYears}</h2>
                  {renderYesNoQuestion('smokingNearChildBelowTwoYears')}
                </div>
                <div className="yesNoQuestion" data-test-id="clientRegister-alcoholDuringTwoYearYN">
                  <h2>{strings.alcoholNearChildBelowTwoYears}</h2>
                  {renderYesNoQuestion('alcoholNearChildBelowTwoYears')}
                </div>
              </div>
            </div>
            <div className="tehotenstvo_ui">
              <div
                style={{ display: 'flex', justifyContent: 'space-around' }}
              >
                <div className="yesNoQuestion" data-test-id="clientRegister-drugsDuringTwoYearYN">
                  <h2>{strings.drugsNearChildBelowTwoYears}</h2>
                  {renderYesNoQuestion('drugsNearChildBelowTwoYears')}
                </div>
                <div className="yesNoQuestion inputDesktop" data-test-id="clientRegister-medicineDuringTwoYearYN">
                  <h2>{strings.medicineNearChildBelowTwoYears}</h2>
                  <Input
                    onChange={fieldChangeHandler('medicineNearChildBelowTwoYears')}
                    name="medicineNearChildBelowTwoYears"
                    value={clientFormData.medicineNearChildBelowTwoYears}
                    className="register_input"
                    style={{
                      width: '100%',
                      textAlign: 'center'
                    }}
                  />
                </div>
                <p className="inputMobile-text">
                  {strings.medicineNearChildBelowTwoYears}
                </p>
                {renderTextarea('medicineNearChildBelowTwoYears')}
              </div>
            </div>
            <h2 className="mobileLabel">{strings.hospitalization1}</h2>
            <textarea
              onChange={fieldChangeHandler('hospitalization')}
              name="hospitalization"
              value={clientFormData.hospitalization}
              maxLength="150"
              style={{ marginBottom: '25px' }}
            />
            <h2 className="mobileLabel">{strings.injuries}</h2>
            <textarea
              onChange={fieldChangeHandler('injury')}
              name="injury"
              value={clientFormData.injury}
              maxLength="150"
              style={{ marginBottom: '25px' }}
            />
            <h2 className="mobileLabel">{strings.healthComplications}</h2>
            <textarea
              onChange={fieldChangeHandler('healthIssues')}
              name="healthIssues"
              value={clientFormData.healthIssues}
              maxLength="150"
              style={{ marginBottom: '25px' }}
            />
            <div className="yesNoQuestion" data-test-id="clientRegister-medicineStuffYN">
              <div
                className="mobileLayout"
                style={{ display: 'flex', justifyContent: 'space-around' }}
              >
                <div style={{ width: '50%' }} data-test-id="clientRegister-vaccinationYN">
                  <h2 className="mobileLabel boldLabel">
                    {strings.obligatoryVaccination}
                  </h2>
                  {renderYesNoQuestion('obligatoryVaccination')}
                </div>
                <div style={{ width: '50%' }} data-test-id="clientRegister-visitPaediatricianYN">
                  <h2 className="mobileLabel boldLabel">
                    {strings.regularExaminations}
                  </h2>
                  {renderYesNoQuestion('regularExaminations')}
                </div>
              </div>
              <div className="yesNoQuestion yesNoQuestionWrapper" data-test-id="clientRegister-examinationOfficeYN">
                <div data-test-id="clientRegister-ambulanceYN">
                  <h2 className="mobileLabel">
                    {strings.examinationOffice1}
                  </h2>
                  <Input
                    onChange={fieldChangeHandler('examinationOffice')}
                    name="examinationOffice"
                    value={clientFormData.examinationOffice}
                    className="register_input inputDesktop"
                    style={{
                      width: '100%',
                      textAlign: 'center'
                    }}
                  />
                  {renderTextarea('examinationOffice')}
                </div>
                <div
                  className="register_client_input"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'space-around'
                  }}
                  data-test-id="clientRegister-lastCheckupYN"
                >
                  <div
                    className="register-input-label"
                    style={{ fontSize: '20px' }}
                  >
                    {strings.dateOfLastExamination}
                  </div>
                  <DatePicker
                    id="examinationDate"
                    onChange={changeDate('dateOfLastExamination')}
                    name="lastExamination"
                    value={getDateObject(clientFormData.dateOfLastExamination)}
                    className="register_datepicker"
                    placeholder={strings.dateOfLastExamination}
                  />
                </div>
              </div>
              <div className="endOfStep">
                <h2
                  className="mobileLabel"
                  style={{ color: '#41aea6', fontSize: '22px' }}
                >
                  {strings.healthCondition1}
                </h2>
                <textarea
                  onChange={fieldChangeHandler('healthCondition')}
                  name="healthCondition"
                  value={clientFormData.healthCondition}
                  maxLength="150"
                />
              </div>
            </div>
          </div>
          {renderBottomMobilePageNavigation()}
        </div>
        <div
          className={`vyvin_text ${
            stepperIndex === 6 ? 'activeStep' : 'hiddenStep'
          }`}
          data-test-id="clientRegister-concernsAndHousing"
        >
          <h2
            className="sectionHeader"
            style={{ fontSize: '30px', marginTop: '30px', color: '#41aea6' }}
          >
            {strings.worries}
          </h2>
          <h2 className="mobileLabel">{strings.healthConditionQuestion}</h2>
          <div className="yesNoQuestionWrapper">
            <div className="yesNoQuestion" data-test-id="clientRegister-eyeSightIssueYN">
              <h2>{strings.eyesightIssueConcern}</h2>
              {renderYesNoQuestion('eyesightIssueConcern')}
            </div>
            <div className="yesNoQuestion" data-test-id="clientRegister-hearingIssueYN">
              <h2>{strings.hearingIssueConcern}</h2>
              {renderYesNoQuestion('hearingIssueConcern')}
            </div>
          </div>
          <div className="yesNoQuestionWrapper">
            <div className="yesNoQuestion" data-test-id="clientRegister-sleepingIssueYN">
              <h2>{strings.sleepIssueConcern}</h2>
              {renderYesNoQuestion('sleepIssueConcern')}
            </div>
            <div className="yesNoQuestion" data-test-id="clientRegister-growNurtureIssueYN">
              <h2>{strings.growNurtureIssueConcern}</h2>
              {renderYesNoQuestion('growNurtureIssueConcern')}
            </div>
          </div>
          <div className="yesNoQuestionWrapper">
            <div className="yesNoQuestion" data-test-id="clientRegister-healthIssueYN">
              <h2>{strings.healthIssueConcern} </h2>
              {renderYesNoQuestion('healthIssueConcern')}
            </div>
            <div className="yesNoQuestion" data-test-id="clientRegister-wholeGrowIssueYN">
              <h2>{strings.wholeGrowIssueConcern}</h2>
              {renderYesNoQuestion('wholeGrowIssueConcern')}
            </div>
          </div>
          <h2
            className="sectionHeader"
            style={{
              fontSize: '30px',
              marginTop: '30px',
              color: '#41aea6',
              borderTop: '3px solid #41aea6'
            }}
          >
            {strings.living}
          </h2>
          <div className="textFormWrapper">
            <h2 className="mobileLabel">{strings.houseMaterial1}</h2>
            {renderFormTextField('houseMaterial', '', 'inputDesktop')}
            {renderTextarea('houseMaterial')}
          </div>
          <div className="textFormWrapper">
            <h2 className="mobileLabel">{strings.houseRooms}</h2>
            {renderFormTextField('houseRooms', '', 'inputDesktop')}
            {renderTextarea('houseRooms')}
          </div>
          <div className="textFormWrapper">
            <h2 className="mobileLabel">{strings.houseInhabitants}</h2>
            {renderFormTextField('houseInhabitants', '', 'inputDesktop')}
            {renderTextarea('houseInhabitants')}
          </div>
          <div className="textFormWrapper">
            <h2 className="mobileLabel">{strings.houseFuel}</h2>
            {renderFormTextField('houseFuel', '', 'inputDesktop')}
            {renderTextarea('houseFuel')}
          </div>
          <div className="yesNoQuestionWrapper">
            <div className="yesNoQuestion" data-test-id="clientRegister-houseSameRoomYN">
              <h2>{strings.houseSameRoomForChildAndKitchen}</h2>
              {renderYesNoQuestion('houseSameRoomForChildAndKitchen')}
            </div>
            <div className="yesNoQuestion endOfStep">
              <h2>{strings.houseSeparateBathRoom}</h2>
              {renderYesNoQuestion('houseSeparateBathRoom')}
            </div>
          </div>
          {renderBottomMobilePageNavigation()}
        </div>
        <div
          className={`register_client_ui ${
            stepperIndex === 7 ? 'activeStep' : 'hiddenStep'
          } no-border`}
          data-test-id="clientRegister-schoolAndPrivacy"
        >
          <div className="yesNoQuestion" data-test-id="clientRegister-kindergartenYN">
            <h2 className="headline-materskaSkola sectionHeader">
              {strings.kindergarden}
            </h2>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <h3
                className="mobileLabel boldLabel"
                style={{ fontSize: '20px' }}
              >
                {strings.visitKindergarden}
              </h3>
              <div className="yesNoAnswer">
                <div
                  onClick={() => buttonToggle('visitingInstitution', true)}
                  className={classNames('yesNoButton', 'notSelected', {
                    selected: clientFormData.visitingInstitution === true
                  })}
                >
                  {strings.yes}
                </div>
                <div
                  onClick={() => buttonToggle('visitingInstitution', false)}
                  className={classNames('yesNoButton', 'notSelected', {
                    selected: clientFormData.visitingInstitution === false || clientFormData.visitingInstitution === ''
                  })}
                >
                  {strings.no}
                </div>
              </div>
            </div>
          </div>
          {clientFormData.visitingInstitution && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                flexDirection: 'column'
              }}
            >
              <div
                className="register-input-label kindergarten"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                  alignItems: 'center'
                }}
              >
                <h3 className="mobileLabel" style={{ fontSize: '20px' }}>
                  {strings.kindergardenName}
                </h3>
                <Input
                  onChange={fieldChangeHandler('institutionName')}
                  name="visitingInstitution"
                  value={clientFormData.institutionName}
                  className="register_input inputDesktop"
                  placeholder={strings.kindergardenName}
                  style={{ width: '60%' }}
                />
                {renderTextarea('institutionName')}
              </div>
              <div
                className="register-input-label kindergarten"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  alignItems: 'baseline'
                }}
              >
              </div>
              <div className="register_client_input">
                <div>
                  <div className="register-input-label">{strings.kindergardenStart}</div>
                  <DatePicker
                    onChange={changeDate('infantSchoolStartDate')}
                    name="infantSchoolStartDate"
                    value={getDateObject(clientFormData.infantSchoolStartDate)}
                    className="register_datepicker"
                    placeholder={strings.kindergardenStart}
                  />
                </div>
                <div>
                  <div className="register-input-label">{strings.kindergardenEnd}</div>
                  <DatePicker
                    onChange={changeDate('infantSchoolEndDate')}
                    name="infantSchoolEndDate"
                    value={getDateObject(clientFormData.infantSchoolEndDate)}
                    className="register_datepicker"
                    placeholder={strings.kindergardenEnd}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="yesNoQuestion" data-test-id="clientRegister-elementarySchoolYN">
            <h2 className="headline-materskaSkola sectionHeader">
              {strings.elementarySchool}
            </h2>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <h3
                className="mobileLabel boldLabel"
                style={{ fontSize: '20px' }}
              >
                {strings.visitingElementarySchool}
              </h3>
              {renderYesNoQuestion('elementarySchool')}
            </div>
            <div>
              {clientFormData.elementarySchool && (
                <div>
                  <div
                    className="register-input-label kindergarten"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-evenly',
                      alignItems: 'center'
                    }}
                  >
                    <h3 className="mobileLabel" style={{ fontSize: '20px' }}>
                      {strings.elementarySchoolName}
                    </h3>
                    <Input
                      onChange={fieldChangeHandler('elementarySchoolName')}
                      name="elementarySchoolName"
                      value={clientFormData.elementarySchoolName}
                      className="register_input inputDesktop"
                      placeholder={strings.elementarySchoolName}
                      style={{ width: '60%' }}
                    />
                    {renderTextarea('elementarySchoolName')}
                  </div>
                  <div
                    className="register-input-label kindergarten"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-evenly',
                      alignItems: 'center'
                    }}
                  >
                    <div style={{ display: 'flex', justifyContent: 'space-around' }} />
                    <h3
                      className="mobileLabel boldLabel"
                      style={{ fontSize: '20px', marginRight: '157px' }}
                    >
                      {strings.elementarySchoolType}
                    </h3>
                    <div className="yesNoAnswer">
                      <div
                        onClick={() => buttonToggle('specialSchool', false)}
                        className={classNames('yesNoButton', 'notSelected', {
                          selected: clientFormData.specialSchool === false
                        })}
                      >
                        {strings.common}
                      </div>
                      <div
                        onClick={() => buttonToggle('specialSchool', true)}
                        className={classNames('yesNoButton', 'notSelected', {
                          selected: clientFormData.specialSchool === true
                        })}
                      >
                        {strings.special}
                      </div>
                    </div>
                  </div>
                  <div className="register_client_input">
                    <div>
                      <div className="register-input-label">{strings.elementarySchoolStart}</div>
                      <DatePicker
                        onChange={changeDate('elementarySchoolStartDate')}
                        name="elementarySchoolStartDate"
                        value={getDateObject(clientFormData.elementarySchoolStartDate)}
                        className="register_datepicker"
                        placeholder={strings.elementarySchoolStart}
                      />
                    </div>
                    <div>
                      <div className="register-input-label">{strings.elementarySchoolEnd}</div>
                      <DatePicker
                        onChange={changeDate('elementarySchoolEndDate')}
                        name="elementarySchoolEndDate"
                        value={getDateObject(clientFormData.elementarySchoolEndDate)}
                        className="register_datepicker"
                        placeholder={strings.elementarySchoolEnd}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div data-test-id="clientRegister-privacy">
            <div className="register_client_header">
              <h2
                className="sectionHeader"
                style={{
                  color: '#41aea6',
                  fontSize: '30px',
                  fontWeight: 'bold',
                  borderTop: '3px solid #41aea6'
                }}
              >
                {strings.gdpr}
              </h2>
            </div>
            <div className="yesNoQuestionWrapper">
              <div 
                className="yesNoQuestion" 
                style={{ width: '500px' }} 
                data-test-id="clientRegister-photosInAppYN"
              >
                <h2 className="yesNoQuestion-header">
                  {strings.photosInApp}
                </h2>
                {renderYesNoQuestion('photosInApp')}
              </div>
              <div 
                className="yesNoQuestion" 
                style={{ width: '500px' }} 
                data-test-id="clientRegister-photosOnWebYN"
              >
                <h2 className="yesNoQuestion-header">
                  {strings.photosOnWeb}
                </h2>
                {renderYesNoQuestion('photosOnWeb')}
              </div>
            </div>
            <div className="yesNoQuestionWrapper">
              <div 
                className="yesNoQuestion" 
                style={{ width: '500px' }} 
                data-test-id="clientRegister-photosPrintedYN"
              >
                <h2 className="yesNoQuestion-header">
                  {strings.photosPrinted}
                </h2>
                {renderYesNoQuestion('photosPrinted')}
              </div>
              <div 
                className="yesNoQuestion" 
                style={{ width: '500px' }} 
                data-test-id="clientRegister-videoReportsYN"
              >
                <h2 className="yesNoQuestion-header">
                  {strings.videoReports}
                </h2>
                {renderYesNoQuestion('videoReports')}
              </div>
            </div>
            <div className="yesNoQuestionWrapper">
              <div 
                className="yesNoQuestion" 
                style={{ width: '500px' }} 
                data-test-id="clientRegister-pediatricianScreeningYN"
              >
                <h2 className="yesNoQuestion-header">
                  {strings.pediatricScreenings}
                </h2>
                {renderYesNoQuestion('pediatricScreenings')}
              </div>
              <div 
                className="yesNoQuestion" 
                style={{ width: '500px' }} 
                data-test-id="clientRegister-growthTrackingYN"
              >
                <h2 className="yesNoQuestion-header">
                  {strings.growthTracking}
                </h2>
                {renderYesNoQuestion('growthTracking')}
              </div>
            </div>
            <div className="yesNoQuestionWrapper">
              <div 
                className="yesNoQuestion" 
                style={{ width: '500px' }} 
                data-test-id="clientRegister-educationCarrierGrowthYN"
              >
                <h2 className="yesNoQuestion-header">
                  {strings.educationCarrierGrowth}
                </h2>
                {renderYesNoQuestion('educationCarrierGrowth')}
              </div>
              <div 
                className="yesNoQuestion" 
                style={{ width: '500px' }} 
                data-test-id="clientRegister-contactLaterYN"
              >
                <h2 className="yesNoQuestion-header">
                  {strings.contactLater}
                </h2>
                {renderYesNoQuestion('contactLater')}
              </div>
            </div>
          </div>
          <div className="next_back_buttons">
            <span
              className="button-back"
              onClick={() => goToPreviousRegistrationStep()}
            >
              <Icon
                className="button-back-icon"
                style={{ position: 'absolute', top: '18px' }}
                type="left"
              />
              <span className="button-back-text">{strings.back}</span>
            </span>
          </div>
          <div 
            className="mobileOnly endOfStep registration_button mobileRegisterButton" 
            data-test-id="clientRegister-saveAll-mobile"
          >
            <Button
              disabled={checkRequiredFields()}
              onClick={addNewClient}
            >
              {strings.save}
            </Button>
          </div>
        </div>
        <div className="registration_footer" data-test-id="clientRegister-saveAll-desktop">
          <Button
            disabled={checkRequiredFields()}
            onClick={addNewClient}
          >
            {strings.save}
          </Button>
        </div>
      </div>
    </div>
  );
}

RegisterClient.propTypes = {
  match: PropTypes.object
};

export default RegisterClient;
