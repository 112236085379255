import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './OmamaActivities.scss';
import { Modal, Input } from 'antd';
import ActivityInfo from '../OmamaActivities/activityInfo';
import { getAdminActivitiesAction } from '../../../actions/admin.actions.js';
import { strings } from '../../../strings/StringsProvider';

function OmamaActivities() {
  const [activityDetailVisible, setActivityDetailVisible] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState('');
  const [activityName, setActivityName] = useState('');
  const [activityCode, setActivityCode] = useState('');
  const [activityMonth, setActivityMonth] = useState('');
  const [activityWeek, setActivityWeek] = useState('');

  const activities = useSelector(state =>
    state.admin.activities.filter(
      activity => activity.code.toUpperCase() !== 'OLD'
    )
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdminActivitiesAction());
  }, [dispatch]);

  const filterActivitiesByName = (activities, filter) => {
    return activities.filter(act => {
      return act.name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toUpperCase()
        .includes(
          filter
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toUpperCase()
        );
    });
  };

  const filterActivitiesByCode = (activities, filter) => {
    return activities.filter(act => {
      let modifiedFilter =
        filter.substring(filter.length - 1) === '.'
          ? filter.substring(0, filter.length - 1)
          : filter;
      return (
        act.code
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toUpperCase() ===
          filter
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toUpperCase() ||
        act.code
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toUpperCase()
          .startsWith(
            modifiedFilter
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toUpperCase() + '.'
          )
      );
    });
  };

  const onChangeActivityName = e => setActivityName(e.target.value);

  const onChangeActivityCode = e => setActivityCode(e.target.value);

  const onChangeActivityMonth = e => {
    const value = e.target.value;
    if (value < 0) setActivityMonth(0);
    else setActivityMonth(value);
  };

  const onChangeActivityWeek = e => {
    const value = e.target.value;
    if (value === '') setActivityWeek('');
    else if (value > 4) setActivityWeek(4);
    else if (value < 1) setActivityWeek(1);
    else setActivityWeek(value);
  };

  const filterActivitiesByMonth = (activities, filter) => {
    filter = Number(filter);
    return activities.filter(e => {
      return e.mesiac === filter;
    });
  };

  const filterActivitiesByWeek = (activities, filter) => {
    filter = Number(filter);
    return activities.filter(e => e.tyzden === filter);
  };

  const selectActivity = id => () => {
    setActivityDetailVisible(true);
    setSelectedActivity(id);
  };

  const renderActivities = () => {
    const filteredActivitiesByName = filterActivitiesByName(
      activities,
      activityName
    );

    let filteredActivities = filteredActivitiesByName;
    filteredActivities =
      activityCode === ''
        ? filteredActivities
        : filterActivitiesByCode(filteredActivities, activityCode);
    filteredActivities =
      activityMonth === ''
        ? filteredActivities
        : filterActivitiesByMonth(filteredActivities, activityMonth);
    filteredActivities =
      activityWeek === ''
        ? filteredActivities
        : filterActivitiesByWeek(filteredActivities, activityWeek);

    filteredActivities.sort((a, b) => {
      if (a.name === strings.welcomeDiscusion) {
        return -1;
      } else {
        return Number(a.code) - Number(b.code);
      }
    });

    return (
      <div className="dropdownActivities">
        <br />
        <br />
        <div style={{ paddingBottom: '20px' }}>
          <Input
            className="activities-name"
            placeholder={strings.nameOfActivity}
            value={activityName}
            onChange={onChangeActivityName}
          />
          <Input
            className="activities-code"
            placeholder={strings.numberOfLecture}
            value={activityCode}
            onChange={onChangeActivityCode}
          />
          <br />
          <br />
          <Input
            type="number"
            placeholder={strings.month}
            className="number-input"
            value={activityMonth}
            onChange={onChangeActivityMonth}
          />
          <Input
            type="number"
            placeholder={strings.week}
            className="number-input"
            value={activityWeek}
            onChange={onChangeActivityWeek}
          />
        </div>
        {filteredActivities.map(activity => (
          <div className="keyos" key={activity._id}>
            <div
              className="activityName"
              onClick={selectActivity(activity._id)}
            >
              <span className="monthWeek">{`${activity.code} - ${activity.mesiac}m/${activity.tyzden}t - `}</span>{' '}
              {activity.name}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const closeActivityDetail = () => {
    setActivityDetailVisible(false);
    setSelectedActivity('');
  };

  const renderActivityTypes = () => {
    return (
      <div className="activities">
        <Modal
          title={strings.activityDetail}
          visible={activityDetailVisible}
          footer={null}
          onCancel={closeActivityDetail}
          onOk={closeActivityDetail}
        >
          {selectedActivity && (
            <ActivityInfo
              activity={activities.find(a => a._id === selectedActivity)}
              goBack={closeActivityDetail}
              mobileActivity={true}
            />
          )}
        </Modal>
        {renderActivities()}
      </div>
    );
  };

  return <div className="activitiesContainer">{renderActivityTypes()}</div>;
}

export default OmamaActivities;
