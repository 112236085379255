import React, { useState, useEffect } from 'react';
import './AdminPersonCard.scss';
import { Icon } from 'antd';
import PropTypes from 'prop-types';
import Base64Image from './Base64Image';
import { getDateDiff } from 'tools/date.tools';
import { strings } from '../strings/StringsProvider';

function AdminPersonCard({ birthDate, changePage, city, name, photo, type }) {
  const [info, setInfo] = useState(null);

  useEffect(() => {
    if (city !== undefined) {
      setInfo(city.charAt(0).toUpperCase() + city.slice(1));
    }
    if (birthDate !== undefined) {
      const { months } = getDateDiff(birthDate, new Date());

      switch (true) {
        case months === 1:
          setInfo(`${months} ${strings.oneMonth}`);
          break;
        case months === 0 || months >= 5:
          setInfo(`${months} ${strings.zeroOrFiveMonths}`);
          break;
        case months >= 2 || months <= 4:
          setInfo(`${months} ${strings.twoTillFourMonths}`);
          break;
        default:
          // TO DO REWORK
          setInfo('DEFAULT');
      }
    }
  }, [city, birthDate]);

  return (
    <div className="AdminPersonCard adminShadow" onClick={() => changePage()}>
      <Base64Image key={photo} className="AdminPersonCardImage" type={type} imageID={photo} />
      <div>
        <h3 className="AdminPersonCardImageName">{name}</h3>
        <p className="AdminPersonCardImageInfo">{info}</p>
      </div>
      <Icon className="AdminPersonCardArrow" type="right" />
    </div>
  );
}

AdminPersonCard.propTypes = {
  photo: PropTypes.string,
  name: PropTypes.string,
  birthDate: PropTypes.string,
  id: PropTypes.string,
  changePage: PropTypes.func,
  city: PropTypes.string,
  type: PropTypes.string
};

export default AdminPersonCard;
