import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'antd';
import './screeningQuestions.scss';
import { ScreeningButtonGroup } from './screeningButton';
import { strings } from '../../../strings/StringsProvider';

const renderHeader = (sectionTitle, sectionTitleLabel) => {
  return (
    <div style={{ marginLeft: '2vw', marginRight: '2vw' }}>
      <span style={{ flexDirection: 'row', display: 'flex' }}>
        <h4 style={{ fontSize: '17px' }}>{sectionTitleLabel}</h4>
        <h4
          style={{
            marginLeft: '5vw',
            color: '#41aea6',
            fontWeight: '800',
            fontSize: '17px'
          }}
        >
          {sectionTitle}
        </h4>
      </span>
    </div>
  );
};

export const ScreeningQuestionsList = ({
  questions,
  answers,
  changeQuestionStatus,
  sectionTitle,
  sectionLabel
}) => {
  return (
    <>
      <List
        header={renderHeader(sectionTitle, sectionLabel)}
        style={{ marginBottom: '10vw' }}
        itemLayout="horizontal"
        dataSource={questions}
        locale={{ emptyText: strings.noQuestions }}
        renderItem={question => (
          <List.Item className="screening-list-item">
            <List.Item.Meta key={question.id} style={{ flex: 0 }} />
            {answers && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  flex: '1',
                  textAlign: 'center'
                }}
              >
                <div>{question.question}</div>
                <ScreeningButtonGroup
                  selectedValue={
                    answers[question.id] && answers[question.id].wasClicked
                  }
                  leftLabel="Áno"
                  rightLabel={
                    [
                      strings.worries,
                      strings.specificBehaviour,
                      `${strings.specificBehaviour} - 11., 12. ${strings.screening2}`
                    ].includes(sectionTitle) &&
                    !['11.PP_Z1', '12.PP_Z1'].includes(question.id)
                      ? strings.no
                      : strings.notYet
                  }
                  onLeftClickFnc={() =>
                    changeQuestionStatus(
                      question.id,
                      strings.yes,
                      question.yesIsPositiveAnswer
                    )
                  }
                  onRightClickFnc={() =>
                    changeQuestionStatus(
                      question.id,
                      strings.no,
                      question.yesIsPositiveAnswer
                    )
                  }
                  positiveAnswerIsYes={question.yesIsPositiveAnswer}
                />
              </div>
            )}
          </List.Item>
        )}
      />
    </>
  );
};

ScreeningQuestionsList.propTypes = {
  questions: PropTypes.array,
  answers: PropTypes.object,
  changeQuestionStatus: PropTypes.func,
  sectionTitle: PropTypes.string,
  sectionLabel: PropTypes.string
};
