import React, { useEffect, useMemo } from 'react';
import { push } from 'connected-react-router';
import { useLocation, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { uniqBy } from 'lodash';
import { Table, Menu, Dropdown, Icon, Input, Tooltip } from 'antd';
import Button from 'components/Button';
import PropTypes from 'prop-types';
import jwt from 'jsonwebtoken';
import moment from 'moment';

// Local imports
import './Users.scss';
import { getUsersAction } from '../../actions/users.actions';
import { strings } from '../../strings/StringsProvider';
import Base64Image from '../../components/Base64Image';

import { stringSorter } from 'tools/utils';
import Stopwatch from 'components/Stopwatch';
import SearchBar from 'components/SearchBar';

const SHOW_ALL = strings.showAll;

const rolesMap = {
  supervisor: strings.supervision1,
  mentor: strings.mentor,
  admin: strings.admin,
  omama: strings.omama
};

const statuses = {
  ONLINE: 'ONLINE',
  IDLE: 'IDLE',
  OFFLINE: 'OFFLINE',
  UNKNOWN: 'UNKNOWN'
};

const getOnlineStatusFromLastActivityDatetime = datetime => {
  if (!datetime) {
    return statuses.UNKNOWN;
  }

  const now = moment.now();
  const threeMinutesAgo = moment(datetime)
    .add(3, 'minutes')
    .toDate();
  if (threeMinutesAgo > now) {
    return statuses.ONLINE;
  }

  const thirtyMinutesAgo = moment(datetime)
    .add(30, 'minutes')
    .toDate();
  if (thirtyMinutesAgo > now) {
    return statuses.IDLE;
  }

  return statuses.OFFLINE;
};

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

function Users({ changePage, getUsersAction, users }) {
  const userRole = jwt.decode(localStorage.getItem('access-token')).role;

  const history = useHistory();
  const searchParams = useQuery();
  const { name, role, page } = Object.fromEntries(searchParams.entries());

  useEffect(() => {
    if (users.length === 0) {
      getUsersAction();
    }
  }, [users, getUsersAction]);

  const setSearchParam = (paramName, paramValue) => {
    searchParams.set(paramName, paramValue);
    if (paramName !== 'page') {
      searchParams.set('page', 1);
    }
    history.replace(`?${searchParams.toString()}`);
  };

  const deleteSearchParam = paramName => {
    searchParams.delete(paramName);
    searchParams.set('page', 1);
    history.replace(`?${searchParams.toString()}`);
  };

  const onNameChange = e => {
    if (e.target.value !== '') {
      setSearchParam('name', e.target.value);
    } else {
      deleteSearchParam('name');
    }
  };

  const onRoleChange = ({ key }) => {
    if (key !== SHOW_ALL) {
      setSearchParam('role', key);
    } else {
      deleteSearchParam('role');
    }
  };

  const typOptions = (
    <Menu onClick={onRoleChange}>
      <Menu.Item key={SHOW_ALL}>{SHOW_ALL}</Menu.Item>
      <Menu.Item key="mentor">{strings.mentor}</Menu.Item>
      <Menu.Item key="omama">{strings.omama}</Menu.Item>
      <Menu.Item key="admin">{strings.admin}</Menu.Item>
      <Menu.Item key="supervisor">{strings.supervision1}</Menu.Item>
    </Menu>
  );

  const filterByName = (array, filter) => {
    if (name) {
      return array.filter(user =>
        user.name
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toUpperCase()
          .startsWith(
            filter
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toUpperCase()
          )
      );
    }

    return array;
  };

  const filterByRole = (array, filter) => {
    if (role) {
      return array.filter(user => user.role === filter);
    }

    return array;
  };

  const openUserScreen = record => {
    changePage('/admin/user/' + record._id + `/${record.role}`);
  };

  const renderOnlineStatus = user => {
    let status = 'unknown';
    let lastSeen = 'unknown';
    if ('onlineStatus' in user) {
      const lastActivity = user.onlineStatus.lastActivity;
      status = getOnlineStatusFromLastActivityDatetime(lastActivity);
      const lastSeenBefore = moment.duration(moment().diff(lastActivity));
      lastSeen = moment.utc(lastSeenBefore.as('milliseconds')).format('HH:mm:ss');
    }
    return (
      <Tooltip
        title={
          <>
            {`${strings.lastActivityBefore}: `}
            <Stopwatch startTime={lastSeen} />
          </>
        }
      >
        <span className={`dot ${status.toLowerCase()}`}></span>
      </Tooltip>
    );
  };

  const renderPopupWarnings = user => {
    const uniqLessonNotifications = uniqBy(user.photoOutOfTimeRange || [], notif => {
      return notif.lessonID + notif.photoMissing;
    }).sort((a, b) => {
      return moment(b.date).diff(a.date);
    });
    return (
      <>
        {user.missingHours && (
          <div className="bubble-popup warning-popup" onClick={event => event.stopPropagation()}>
            <Icon className="icon warning" type="exclamation-circle" />
            <div className="popup">
              <div className="popup-scroll">{strings.reportOfWorkHours}</div>
            </div>
          </div>
        )}
        {!!uniqLessonNotifications.length && (
          <div className="bubble-popup warning-popup" onClick={event => event.stopPropagation()}>
            <Icon className="icon warning" type="picture" />
            <div className="popup">
              <div className="popup-scroll">
                {uniqLessonNotifications.map((notif, i) => {
                  return (
                    <p key={i}>
                      {notif.photoMissing
                        ? `${strings.fromLectureOfOmama} ${moment(notif.lessonDate).format('DD. MM. YYYY HH:mm')} ${
                            strings.noInsertedPicture
                          }.`
                        : `${strings.inLectureWithOmama} ${moment(notif.lessonDate).format('DD. MM. YYYY HH:mm')} ${
                            strings.wrongDateAndTime
                          }.`}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const mobileColumn = [
    {
      title: '',
      dataIndex: '_id',
      key: 'user',
      render: (id, user) => (
        <div className="usersMobileList-item">
          <div className="userMobileList-item-leftCont">
            <Base64Image
              backUpImage="/images/cestavonLOGO_unofficial.png"
              className="userMobileList-item-leftCont-tablePhoto"
              type="user"
              imageID={id}
            />
            <div className="userMobileList-item-leftCont-userInfo">
              <div className="userMobileList-item-leftCont-userInfo-name">
                <span>{user.name}</span>
                {renderPopupWarnings(user)}
              </div>
              <p className="usersMobileList-item-leftCont-userInfo-city">{user.city}</p>
            </div>
          </div>
          <div className="userMobileList-item-rightCont">
            <p
              className="userMobileList-item-rightCont-role"
              style={{
                color: user.activeForTable === strings.isActive ? '#41aea6' : '#BDBDBD'
              }}
            >
              {user.role === 'supervisor' ? strings.supervision1.toLowerCase() : user.role}
            </p>
            <Icon className="userMobileList-item-rightCont-icon" type="right" />
          </div>
        </div>
      )
    }
  ];

  const columns = [
    {
      title: '',
      dataIndex: '_id',
      key: 'photo',
      render: id => (
        <Base64Image
          backUpImage="/images/cestavonLOGO_unofficial.png"
          className="tablePhoto"
          type="user"
          imageID={id}
        />
      )
    },
    {
      title: strings.firstName,
      dataIndex: 'name',
      key: 'name',
      sortDirections: ['descend', 'ascend'],
      sorter: stringSorter('name'),
      render: (name, row) => {
        return (
          <div>
            {name}
            {renderOnlineStatus(row)}
            {renderPopupWarnings(row)}
          </div>
        );
      }
    },
    {
      title: strings.email,
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: strings.phoneNumber,
      dataIndex: 'phoneNumber',
      key: 'phoneNumber'
    },
    {
      title: strings.region,
      dataIndex: 'region',
      key: 'region',
      sortDirections: ['descend', 'ascend'],
      sorter: stringSorter('region')
    },
    {
      title: strings.city,
      dataIndex: 'city',
      key: 'city',
      sortDirections: ['descend', 'ascend'],
      sorter: stringSorter('city')
    },
    {
      title: strings.role,
      dataIndex: 'role',
      key: 'role',
      sortDirections: ['descend', 'ascend'],
      render: records => rolesMap[records],
      sorter: stringSorter('role')
    },
    {
      title: strings.state,
      dataIndex: 'activeForTable',
      key: 'active',
      sortDirections: ['descend', 'ascend'],
      sorter: stringSorter('activeForTable')
    }
  ];

  let displayUsers = filterByName(users, name);
  displayUsers = filterByRole(displayUsers, role);

  const setRowClassName = record => {
    return record.active === false && 'inactiveTextColor';
  };

  return (
    <div className="statsBackground">
      <div className="clientsBox usersClientsBox">
        <div className="activities-main users-activities-main">
          <div className="activities-form usersForm" data-test-id="users-form">
            <div className="table-controls-group only-desktop">
              <SearchBar
                placeholder="Meno"
                className="users-search-bar"
                value={name}
                onChange={onNameChange}
                dataTestId="users-form-userInput"
              />
              {userRole === 'admin' && (
                <div className="adminDropdown" data-test-id="users-form-roleDropdown">
                  <Dropdown
                    trigger={['click']}
                    className="table-control-dropdown user-role-dropdown"
                    overlay={typOptions}
                    placement="bottomCenter"
                  >
                    <div className="usersDropdownValue">
                      <label style={{ fontWeight: 'normal' }}>{strings.role}: </label>
                      {role ? rolesMap[role] : SHOW_ALL}
                      <div className="dropdown-icon-container">
                        <img src="/images/Icons/down-icon.svg" alt="Dropdown icon" />
                      </div>
                    </div>
                  </Dropdown>
                </div>
              )}
            </div>
            <div className="table-controls-group only-desktop">
              <Button
                className="users-table-control-button"
                onClick={getUsersAction}
                type="secondary"
                data-test-id="users-form-refresh"
              >
                {strings.restore}
              </Button>
              {userRole === 'admin' && (
                <Button
                  className="users-table-control-button"
                  onClick={() => changePage('/admin/register')}
                  data-test-id="users-form-userRegister"
                >
                  {strings.add}
                </Button>
              )}
            </div>

            <div className="table-controls-mobile">
              <Input
                className="activities-input users-input"
                style={{ marginRight: '20px' }}
                placeholder="Meno"
                value={name}
                onChange={onNameChange}
                data-test-id="users-form-userInput"
              />
              {userRole === 'admin' && (
                <div className="adminDropdown" data-test-id="users-form-roleDropdown">
                  <Dropdown
                    trigger={['click']}
                    className="actionsDropdown"
                    overlay={typOptions}
                    placement="bottomCenter"
                    style={{ marginRight: '25px' }}
                  >
                    <div className="usersDropdownValue">
                      <label
                        className="activities-label usersDropdownLabel"
                        style={{ color: 'black', fontWeight: '100' }}
                      >
                        {strings.role}:{' '}
                      </label>
                      {role ? rolesMap[role] : SHOW_ALL} <Icon type="down" />
                    </div>
                  </Dropdown>
                </div>
              )}
              {userRole === 'admin' && (
                <img
                  onClick={() => changePage('/admin/register')}
                  className="ikona pridatButton usersAddButton"
                  src="/images/Icons/plus_icon.png"
                  alt="ikona"
                />
              )}
            </div>
          </div>
          <Table
            className="new-table users-table"
            rowKey="_id"
            dataSource={displayUsers}
            columns={columns}
            onRow={record => ({
              onClick: () => openUserScreen(record)
            })}
            rowClassName={record => setRowClassName(record)}
            pagination={{
              current: Number(page) || 1,
              onChange: pageNumber => setSearchParam('page', pageNumber)
            }}
            data-test-id="users-form-table-desktop"
          />
          <div className="userMobileList">
            <Table
              className="table users-table-mobile"
              rowKey="_id"
              dataSource={displayUsers}
              columns={mobileColumn}
              onRow={record => ({
                onClick: () => openUserScreen(record)
              })}
              rowClassName={record => setRowClassName(record)}
              pagination={{
                current: Number(page) || 1,
                onChange: pageNumber => setSearchParam('page', pageNumber)
              }}
              size="small"
              data-test-id="users-form-table-mobile"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ users }) => {
  const mappedUsers = users.byArr.map(user => ({
    ...user,
    activeForTable: user.active ? strings.isActive : strings.isNotActive
  }));
  return {
    users: mappedUsers
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUsersAction,
      changePage: location => push(location)
    },
    dispatch
  );

Users.propTypes = {
  getUsersAction: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.object),
  changePage: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users);
