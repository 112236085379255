import React from 'react';
import { Icon } from 'antd';

import './GoTop.scss';

export default function GoTop() {
  const goTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <div onClick={goTop} id="GoTopBtn" title="Go to top">
        <Icon type="up" style={{ fontSize: '25px', color: 'white' }} />
      </div>
    </div>
  );
}



