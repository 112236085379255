import {api} from './api';

const conf = {

};

export {
  conf,
  api
};
