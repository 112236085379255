import React, { useState, useCallback, useEffect } from 'react';
import { push } from 'connected-react-router';
import { Switch, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Icon, Table, Input, Menu, Checkbox, Modal } from 'antd';
import SearchBar from 'components/SearchBar';
import Button from 'components/Button';
import ReactMarkdown from 'react-markdown';
import NewActivity from './AddNewActivity';
import './Activities.scss';

// Local imports
import { getAdminActivitiesAction, changeActivityPageAction } from '../../actions/admin.actions.js';
import { strings } from '../../strings/StringsProvider';
import { populateActivityFormAction, removeActivityAction } from '../../actions/activityForm.actions';

const mapActivityType = {
  1: strings.conginitiveFunctions,
  2: strings.fineMotorSkills,
  3: strings.grossMotorSkills,
  4: strings.emotionalDevelopment,
  5: strings.readingBook,
  6: strings.homeworksForParents
};

function Activities({ match }) {
  const [showActivity, setShowActivity] = useState('');
  const [activityName, setActivityName] = useState('');
  const [activityCode, setActivityCode] = useState('');
  const [activityMonth, setActivityMonth] = useState('');
  const [activityWeek, setActivityWeek] = useState('');
  const activityType = 'all';
  const ageRange = strings.all1;
  const [removeVisible, setRemoveVisible] = useState(false);
  const [oldActivities, setOldActivities] = useState(false);

  const activities = useSelector(state => state.admin.activities || []);
  const activitiesCurrentPage = useSelector(state => state.admin.activitiesCurrentPage);

  const dispatch = useDispatch();

  const changePage = useCallback(location => dispatch(push(location)), [dispatch]);

  useEffect(() => {
    if (activities.length === 0) {
      dispatch(getAdminActivitiesAction());
    }

    dispatch(changeActivityPageAction(1));
  }, [activities.length, dispatch]);

  const filterActivitiesByName = useCallback((activities, filter) => {
    return activities.filter(act => {
      const query = filter
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toUpperCase();

      return act.name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toUpperCase()
        .includes(query);
    });
  }, []);

  const filterActivitiesByCode = useCallback((activities, filter) => {
    return activities.filter(act => {
      let modifiedFilter =
        filter.substring(filter.length - 1) === '.' ? filter.substring(0, filter.length - 1) : filter;
      return (
        act.code
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toUpperCase() ===
          filter
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toUpperCase() ||
        act.code
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toUpperCase()
          .startsWith(
            modifiedFilter
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toUpperCase() + '.'
          )
      );
    });
  }, []);

  const filterActivitiesByMonth = useCallback((activities, filter) => {
    return activities.filter(act => {
      let modifiedFilter =
        filter.substring(filter.length - 1) === '.' ? filter.substring(0, filter.length - 1) : filter;
      return (
        act.mesiac
          .toString()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toUpperCase() ===
          filter
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toUpperCase() ||
        act.mesiac
          .toString()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toUpperCase()
          .startsWith(
            modifiedFilter
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toUpperCase() + '.'
          )
      );
    });
  }, []);

  const filterActivitiesByWeek = useCallback((activities, filter) => {
    return activities.filter(act => {
      const query = filter
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toUpperCase();

      return act.tyzden
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toUpperCase()
        .startsWith(query);
    });
  }, []);

  const filterActivitiesByType = useCallback((activities, filter) => {
    if (filter === 'all') return activities;
    return activities.filter(act => String(act.type) === filter);
  }, []);

  const filterActivitiesByAge = useCallback((activities, filter) => {
    if (filter === strings.all1) return activities;
    return activities.filter(act => {
      return act.from + ' - ' + act.to === filter;
    });
  }, []);

  const editActivity = useCallback(
    activity => () => {
      dispatch(populateActivityFormAction(activity));
      changePage('/admin/aktivity/upravit');
    },
    [changePage, dispatch]
  );

  const removeActivity = useCallback(
    activityId => () => {
      dispatch(removeActivityAction(activityId));
      setShowActivity('');
      setRemoveVisible(false);
    },
    [dispatch]
  );

  const removeModalOpen = () => {
    setRemoveVisible(true);
  };

  const removeModalCancel = () => {
    setRemoveVisible(false);
  };

  const showEditActivityPage = useCallback(
    activity => {
      editActivity(activity)();
    },
    [editActivity]
  );

  const renderActivityInfo = useCallback(
    activity => {
      const youtubeLinks = activity.youtubeVideos
        ?.filter(url => !!url)
        .map(url => {
          const parsed = url.split('/');
          return parsed[parsed.length - 1];
        });

      return (
        <div className="admin-activityDetail" key={activity._id} data-test-id="adminActivities-activityDetail">
          <div className="doNotShowOnMobile" data-test-id="adminActivities-activityDetail-desktop">
            <div className="submenu">
              <Button
                type="secondary"
                icon={<img src="/images/Icons/go-back.svg" alt="back-button" />}
                label={strings.goBack}
                className="go-back-button"
                onClick={() => setShowActivity('')}
              />
            </div>
            <span className="activityNameText">{activity.name ? activity.name : null}</span>
            <div className="admin-activityDetail-buttons">
              <Button type="secondary" onClick={() => showEditActivityPage(activity)}>
                {strings.edit}
              </Button>
              <Button onClick={removeModalOpen}>{strings.delete1}</Button>
            </div>
          </div>
          <div
            className="admin-activityDetail-header showJustOnMobile"
            data-test-id="adminActivities-activityDetail-mobile"
          >
            <div
              className="admin-activityDetail-header-backIcon"
              style={{ cursor: 'pointer' }}
              onClick={() => setShowActivity('')}
            >
              <Icon type="left" onClick={() => setShowActivity('')} /> {strings.backToTheList}
            </div>
          </div>
          <h3
            className="admin-activityDetail-headingText showJustOnMobile"
            data-test-id="adminActivities-activityDetailText-mobile"
          >
            {activity.name} {activity.code}
          </h3>
          <Modal
            centered
            title={strings.deleteActivity}
            visible={removeVisible}
            onOk={removeActivity(activity._id)}
            okText={strings.delete1}
            onCancel={removeModalCancel}
            cancelText={strings.back1}
          >
            <p>{strings.deleteActivityPermanently}</p>
          </Modal>
          <h3>{strings.goal}</h3>
          <div>{activity.ciel}</div>
          <h3>{strings.helperThings}</h3>
          <div>{activity.pomocky}</div>
          <div className="flex">
            {activity.pomockyPhotos &&
              activity.pomockyPhotos.map(photo => (
                <div key={photo}>
                  <img src={photo} alt="Smiley face" width="240" />
                </div>
              ))}
          </div>
          <h3>{strings.story}</h3>
          <div style={{ whiteSpace: 'pre-wrap' }} className="markdownPreview">
            <ReactMarkdown>{activity.priebeh}</ReactMarkdown>
          </div>
          <div className="flex">
            {activity.priebehPhotos &&
              activity.priebehPhotos.map(photo => (
                <div key={photo}>
                  <img src={photo} alt="Smiley face" width="240" />
                </div>
              ))}
          </div>
          {activity.link && (
            <div>
              <Button onClick={() => window.open(activity.link, '_blank')}>PDF</Button>
            </div>
          )}
          <h3>Youtube</h3>
          {youtubeLinks &&
            youtubeLinks.map(link => (
              <iframe
                title="youtubevideo"
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${link}`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                key={link}
              ></iframe>
            ))}
          <div
            className="showJustOnMobile admin-activityDetail-buttons"
            data-test-id="adminActivities-activityDetail-search-mobile"
          >
            <Button className="admin-searchButton" onClick={() => showEditActivityPage(activity)}>
              {strings.edit}
            </Button>
            <Button className="admin-searchButton" onClick={removeModalOpen}>
              {strings.delete1}
            </Button>
          </div>
        </div>
      );
    },
    [removeActivity, removeVisible, showEditActivityPage]
  );

  const columns = [
    {
      title: strings.name,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => {
        return a.name.localeCompare(b.name);
      }
    },
    {
      title: strings.numberOfLecture,
      dataIndex: 'code',
      key: 'code',
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.code - b.code
    },
    {
      title: strings.month,
      dataIndex: 'mesiac',
      key: 'mesiac',
      sorter: (a, b) => a.mesiac - b.mesiac
    },
    {
      title: strings.week,
      dataIndex: 'tyzden',
      key: 'tyzden',
      sorter: (a, b) => a.tyzden - b.tyzden
    }
  ];

  let sortedActivities = activities.sort(function(a, b) {
    return Number(a.code) - Number(b.code);
  });
  let activitiesFname = filterActivitiesByName(sortedActivities, activityName);
  activitiesFname = activityCode === '' ? activitiesFname : filterActivitiesByCode(activitiesFname, activityCode);
  activitiesFname = activityMonth === '' ? activitiesFname : filterActivitiesByMonth(activitiesFname, activityMonth);
  activitiesFname = activityWeek === '' ? activitiesFname : filterActivitiesByWeek(activitiesFname, activityWeek);
  activitiesFname = filterActivitiesByAge(activitiesFname, ageRange);
  activitiesFname = filterActivitiesByType(activitiesFname, activityType);
  if (!oldActivities) {
    activitiesFname = activitiesFname.filter(activity => {
      if (activity.code) {
        return activity.code.toLowerCase() !== 'old';
      }
      return true;
    });
  }

  const dataSource = activitiesFname.map(act => ({
    ...act,
    type: mapActivityType[act.type]
  }));

  const ageSet = new Set(activitiesFname.map(act => act.from + ' - ' + act.to));
  ageSet.add('Všetky');

  let mapActivityTypeAll = { ...mapActivityType, ...{ all: 'Všetky' } };
  let activityTypes = [];
  for (let [key, value] of Object.entries(mapActivityTypeAll)) {
    activityTypes.push(<Menu.Item key={key}>{value}</Menu.Item>);
  }

  return (
    <>
      <Switch>
        <Route path={match.url + '/upravit'} component={NewActivity} />
        <Route path={match.url + '/pridat'} component={NewActivity} />
        <Route>
          <div className="statsBackground doNotShowOnMobile">
            <div className="clientsBox">
              <div className="activities-main">
                {showActivity ? (
                  <>{renderActivityInfo(activities.find(activity => activity._id === showActivity))}</>
                ) : (
                  <>
                    <div className="activities-form-desktop">
                      <div
                        className="activities-searchField-container-desktop"
                        data-test-id="activity-form-searchInputs"
                      >
                        <div
                          className="activities-searchField-group-desktop"
                          data-test-id="activity-form-searchInputs-group"
                        >
                          <div
                            className="activities-searchField-desktop"
                            data-test-id="activity-form-searchInputs-activityName"
                          >
                            <SearchBar
                              placeholder={strings.nameOfActivity}
                              value={activityName}
                              onChange={e => setActivityName(e.target.value)}
                            />
                          </div>
                          <div
                            className="activities-searchField-desktop"
                            data-test-id="activity-form-searchInputs-lectionNumber"
                          >
                            <SearchBar
                              placeholder={strings.numberOfLecture}
                              value={activityCode}
                              onChange={e => setActivityCode(e.target.value)}
                            />
                          </div>
                        </div>
                        <div
                          className="activities-searchField-group-desktop"
                          data-test-id="activity-form-searchInputs-group"
                        >
                          <div
                            className="activities-searchField-desktop"
                            data-test-id="activity-form-searchInputs-month"
                          >
                            <SearchBar
                              type="number"
                              min="1"
                              placeholder={strings.month}
                              value={activityMonth}
                              onChange={e => setActivityMonth(e.target.value)}
                            />
                          </div>
                          <div
                            className="activities-searchField-desktop"
                            data-test-id="activity-form-searchInputs-week"
                          >
                            <SearchBar
                              type="number"
                              min="1"
                              max="4"
                              placeholder={strings.week}
                              value={activityWeek}
                              onChange={e => setActivityWeek(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="new-table-control-checkbox">
                          <Checkbox
                            checked={oldActivities}
                            onChange={() => setOldActivities(!oldActivities)}
                            data-test-id="activity-form-checkbox"
                          >
                            {strings.showOldActivities}
                          </Checkbox>
                        </div>
                      </div>
                      <div className="activity-buttons" data-test-id="activity-form-buttons">
                        <div
                          className="activity-buttons-row"
                          style={{ marginLeft: '10px' }}
                          data-test-id="activity-form-buttons"
                        >
                          <Button
                            className="new-table-control-button"
                            type="secondary"
                            onClick={() => dispatch(getAdminActivitiesAction())}
                            data-test-id="activity-form-refreshButton"
                          >
                            {strings.restore}
                          </Button>
                          <Button
                            className="new-table-control-button"
                            type="primary"
                            onClick={() => changePage('/admin/aktivity/pridat')}
                            data-test-id="activity-form-registerActivity"
                          >
                            {strings.add}
                          </Button>
                        </div>
                      </div>
                    </div>
                    <Table
                      className="new-table activities-table"
                      rowKey="_id"
                      dataSource={dataSource}
                      columns={columns}
                      pagination={{
                        current: activitiesCurrentPage,
                        onChange: pageNumber => dispatch(changeActivityPageAction(pageNumber))
                      }}
                      onRow={record => ({
                        onClick: () => setShowActivity(record._id)
                      })}
                      data-test-id="activity-table"
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="activitiesMobile showJustOnMobile" data-test-id="adminActivities-activityInfo-mobile">
            <div className="clientsBox activitiesMobile-box">
              <div className="activities-main activitiesMobile-main">
                {showActivity ? (
                  renderActivityInfo(activities.find(activity => activity._id === showActivity))
                ) : (
                  <>
                    <div className="activities-form activitiesMobile-form">
                      <div className="activity-buttons activitiesMobile-button">
                        <img
                          onClick={() => changePage('/admin/aktivity/pridat')}
                          className="ikona pridatButton usersAddButton"
                          src="/images/Icons/plus_icon.png"
                          alt="ikona"
                        />
                      </div>
                      <div className="activities-searchField-container activitiesMobile-searchField-container">
                        <div className="activities-searchField activitiesMobile-searchField">
                          <Input
                            className="activities-input activitiesMobile-input"
                            placeholder={strings.nameOfActivity}
                            value={activityName}
                            onChange={e => setActivityName(e.target.value)}
                          />
                        </div>
                        <div className="activities-searchField activitiesMobile-searchField">
                          <Input
                            className="activities-input activitiesMobile-input"
                            placeholder={strings.numberOfLecture}
                            value={activityCode}
                            onChange={e => setActivityCode(e.target.value)}
                          />
                        </div>
                        <div className="activities-searchField activitiesMobile-searchField">
                          <Input
                            type="number"
                            min="1"
                            className="activities-input activitiesMobile-input"
                            placeholder={strings.month}
                            value={activityMonth}
                            onChange={e => setActivityMonth(e.target.value)}
                          />
                        </div>
                        <div className="activities-searchField activitiesMobile-searchField">
                          <Input
                            type="number"
                            min="1"
                            max="4"
                            className="activities-input activitiesMobile-input"
                            placeholder={strings.week}
                            value={activityWeek}
                            onChange={e => setActivityWeek(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    {activitiesFname.map(activity => (
                      <div className="keyos" key={activity._id}>
                        <div
                          className="activityName activitiesMobile-activityName"
                          onClick={() => setShowActivity(activity._id)}
                        >
                          <span className="monthWeek">
                            {`${activity.code} - ${activity.mesiac}m/${activity.tyzden}t - `}
                          </span>{' '}
                          {activity.name}
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </Route>
      </Switch>
    </>
  );
}

Activities.propTypes = {
  match: PropTypes.object
};

export default Activities;
