export const UPDATE_ACTIVITY_FORM = 'UPDATE_ACTIVITY_FORM';
export const POPULATE_ACTIVITY_FORM = 'POPULATE_ACTIVITY_FORM';
export const CLEAN_UP_ACTIVITY_FORM = 'CLEAN_UP_ACTIVITY_FORM';

const initialState = {
  type: 1,
  name: '',
  code: '',
  ciel: '',
  priebeh: '',
  pomocky: '',
  from: 0,
  to: 4,
  tyzden: 0,
  link: '',
  priebehPhotos: [],
  pomockyPhotos: [],
  youtubeVideos: [],
  loading: false,
  mesiac: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
      case UPDATE_ACTIVITY_FORM:
        return {
          ...state,
          [action.formField]: action.payload,
        };
      case POPULATE_ACTIVITY_FORM:
        return {
          ...initialState,
          ...action.payload
        };
      case CLEAN_UP_ACTIVITY_FORM:
        return initialState;
      default:
        return state;
  }
};
