import React from 'react';
import { Icon, Table, Checkbox, Input, Dropdown, Menu, Descriptions } from 'antd';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { strings } from '../strings/StringsProvider';

import './PreschoolScreeningTable.scss';

const grades = [1, 2, 3];

const PreschoolScreeningTable = React.memo(({ data, questions, id, adminRole, mobileSize, updateAnswers }) => {
  
  const onAnswerChange = ({ key }) => {
    const [group, questionKey, answer] = key.split('-');
    const path = `${group}.${questionKey}.value`;
    updateAnswers(path, Number(answer));
  };

  const gradeOptions = (group, key) => {
    return (
      <Menu onClick={onAnswerChange}>
        {grades.map(answer => (
          <Menu.Item key={`${group}-${key}-${answer}`}>{answer}</Menu.Item>
        ))}
      </Menu>
    );
  };
  
  const noteChangeHandler = (group, key) => event => {
    const path = `${group}.${key}.note`;
    updateAnswers(path, event.target.value);
  };

  const renderNoteInput = (record) => {
    return (
        <Input
          value={data[record.key]['note']}
          onChange={noteChangeHandler(record.group, record.key)}
          className='preschool-screening-note'
          readOnly={!adminRole}
          placeholder={mobileSize ? strings.note : ''}
        />
    );
  };

  const renderCheckbox = (record) => {
    if (record.group === 'fineMotor' && record.key === 'question5') {
      const grade = data[record.key]['value'] 
        ? data[record.key]['value']
        : '-';
      return (
        <div className='preschool-screening-dropdown-answer'>
          <Dropdown overlay={gradeOptions(record.group, record.key)} trigger={['click']}>
            <div className='preschool-screening-dropdown-answer-mobile'>
              {grade}
              <Icon type="down" style={{ paddingLeft: '10px' }} />
            </div>
          </Dropdown>
        </div>
      );
    }
    const isChecked =
      !isEmpty(data) &&
      data[record.key]['value'];

    if (mobileSize)
      return adminRole ? (
        <Checkbox
          checked={isChecked}
          onChange={val =>
            updateAnswers(
              `${record.group}.${record.key}.value`,
              val.target.checked ? true : false
            )
          }
        />
      ) : isChecked ? (
        <Icon type="check-circle" style={{ fontSize: 20 }} />
      ) : (
        <Icon type="cross-circle" style={{ fontSize: 20 }} />
      );
    
    else 
      return {
        props: {
          style: { 
            background: '#ccf1ee', 
            borderLeft: '1px solid #41aea6', 
            borderRight: '1px solid #41aea6' 
          }
        },
        children: adminRole ? (
          <Checkbox
            checked={isChecked}
            onChange={val =>
              updateAnswers(
                `${record.group}.${record.key}.value`,
                val.target.checked ? true : false
              )
            }
          />
        ) : isChecked ? (
          <Icon type="check-circle" style={{ fontSize: 20 }} />
        ) : (
          <Icon type="cross-circle" style={{ fontSize: 20 }} />
        )
      };
  };
  
  const renderColumns = () => {
    const tableColumns = [
      {
        title: '',
        dataIndex: 'text',
        key: 'text',
        width: '55%'
      },
      {
        title: strings.answer,
        dataIndex: 'abc',
        key: 'abc',
        width: '10%',
        align: 'center',
        render: (_, record) =>
          renderCheckbox(record)
      },
      {
        title: strings.note,
        dataIndex: 'abcd',
        key: 'abcd',
        width: '35%',
        render: (_, record) => (
          renderNoteInput(record)
        )
      }
    ];

    return tableColumns;
  };
  
  return (
    mobileSize ? (
      Object.keys(data).map((question, index) => {
        if (question !== 'comment') {
          return (
            <Descriptions
              size='small'
              bordered
              column={2}
              style={{ borderBottom: '1px solid #41aea6', overflow: 'hidden' }}
              key={`${id}-${index}`}
            >
              <Descriptions.Item className='preschool-screening-question-title-mobile'>
                {questions[index - 1].text}
              </Descriptions.Item>
              <Descriptions.Item className='preschool-screening-checkbox-mobile'>
                {renderCheckbox(questions[index - 1])}
              </Descriptions.Item>
              <Descriptions.Item span={2} className='preschool-screening-input-mobile'>
                {renderNoteInput(questions[index - 1])}
              </Descriptions.Item>
            </Descriptions>
          );
        }
        return <div key={id}></div>;
      })
    ) : (
      <Table
        key={id}
        size="small"
        className="table"
        pagination={false}
        dataSource={questions}
        columns={renderColumns()}
      />
    )
  );
}, areEqual);

function areEqual(prevProps, nextProps) {
  return JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data) && 
    prevProps.mobileSize === nextProps.mobileSize; 
}

PreschoolScreeningTable.propTypes = {
  data: PropTypes.object,
  questions: PropTypes.array,
  id: PropTypes.string,
  adminRole: PropTypes.bool,
  mobileSize: PropTypes.bool,
  updateAnswers: PropTypes.func
};

export default PreschoolScreeningTable;
