import React from 'react';
import { DatePicker } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

import './WeekSelector.scss';

const { WeekPicker } = DatePicker;
function WeekSelector({ value, onChangeHandler, format }) {
  return <WeekPicker className="plan-week-picker" value={moment(value)} onChange={onChangeHandler} format={format} />;
}

WeekSelector.propTypes = {
  value: PropTypes.string,
  onChangeHandler: PropTypes.func,
  format: PropTypes.string
};

export default WeekSelector;
