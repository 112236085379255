import React, { useState, useEffect, useRef } from 'react';
import { goBack } from 'connected-react-router';
import { Icon, Button, Modal, Input, Dropdown, Menu, DatePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import jwt from 'jsonwebtoken';
import { isEmpty } from 'lodash';
import { strings } from '../../../strings/StringsProvider';
import Stepper from 'react-stepper-js';
import 'react-stepper-js/dist/index.css';
import PreschoolScreeningTable from 'components/PreschoolScreeningTable';

import { 
  populatePreschoolScreening, 
  updateAnswersAction, 
  saveMeasurement, 
  removeMeasurement,
  getAdminClient
} from '../../../actions/admin.actions';
import './PreschoolScreening.scss';

const { MonthPicker } = DatePicker;

const nativeLanguages = [strings.slovak, strings.magyar, strings.romani, strings.otherOption];
const languageSkills = [strings.good, strings.notSoGood, strings.notGood];
const lateralities = [strings.right, strings.left, strings.neitherSide];

function PreschoolScreening() {
  const [edit, setEdit] = useState(false);
  const [removeWindowVisible, setRemoveWindowVisible] = useState(false);
  const [attendingKindergarden, setAttendingKindergarden] = useState(null);
  const [nativeLanguage, setNativeLanguage] = useState('');
  const [otherLanguage, setOtherLanguage] = useState('');
  const [languageSkill, setLanguageSkill] = useState('');
  const [laterality, setLaterality] = useState('');
  const [finalEvaluation, setFinalEvaluation] = useState('');
  const [weight, setWeight] = useState(0);
  const [height, setHeight] = useState(0);
  const [measurementDate, setMeasurementDate] = useState(null);
  const [stepperIndex, setStepperIndex] = useState(0);
  const adminRole = jwt.decode(localStorage.getItem('access-token')).role === 'admin';
  const userName = jwt.decode(localStorage.getItem('access-token')).username;
  const [mobileSize, setMobileSize] = useState(window.innerWidth < 768);
  const windowWidth = useRef(window.innerWidth);
  const { clientId } = useParams();

  const preschoolScreening = useSelector(state => state.measurements.preschoolScreening);
  const measurementQuestions = useSelector(state => state.measurements.preschoolScreeningQuestions);
  const measurementAnswers = useSelector(state => state.measurements.preschoolScreeningAnswers);
  const client = useSelector(state => state.admin.oneClient);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(populatePreschoolScreening(clientId));
  }, [dispatch, clientId]);

  useEffect(() => {
    if (preschoolScreening) {
      setEdit(true);
      if (preschoolScreening.info.attendingKindergardenFrom) {
        setAttendingKindergarden(moment(preschoolScreening.info.attendingKindergardenFrom));
      }
      setNativeLanguage(preschoolScreening.info.nativeLanguage);
      setLanguageSkill(preschoolScreening.info.languageSkill);
      setLaterality(preschoolScreening.info.laterality);
      setFinalEvaluation(preschoolScreening.info.finalEvaluation);
      setWeight(preschoolScreening.info.weight);
      setHeight(preschoolScreening.info.height);
      setMeasurementDate(preschoolScreening.date);
    }
  }, [preschoolScreening]);

  useEffect(() => {
    if (isEmpty(client)) {
      dispatch(getAdminClient(clientId));
    }
  }, [dispatch, client, clientId]);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 768 && windowWidth.current >= 768) {
        setMobileSize(true);
      } else if (window.innerWidth >= 768 && windowWidth.current < 768) {
        setMobileSize(false);
      }
      windowWidth.current = window.innerWidth;
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const updateAnswers = (path, value) => {
    dispatch(updateAnswersAction(path, value));
  };

  const onLanguageChange = ({ key }) => {
    setNativeLanguage(key);
  };

  const nativeLanguageOptions = () => {
    return (
      <Menu onClick={onLanguageChange}>
        {nativeLanguages.map(answer => (
          <Menu.Item key={answer}>{answer}</Menu.Item>
        ))}
      </Menu>
    );
  };

  const onLanguageSkillChange = ({ key }) => {
    setLanguageSkill(key);
  };

  const languageSkillOptions = () => {
    return (
      <Menu onClick={onLanguageSkillChange}>
        {languageSkills.map(answer => (
          <Menu.Item key={answer}>{answer}</Menu.Item>
        ))}
      </Menu>
    );
  };

  const onLateralityChange = ({ key }) => {
    setLaterality(key);
  };

  const lateralityOptions = () => {
    return (
      <Menu onClick={onLateralityChange}>
        {lateralities.map(answer => (
          <Menu.Item key={answer}>{answer}</Menu.Item>
        ))}
      </Menu>
    );
  };

  const savePreschoolScreening = () => {
    const clientNativeLanguage = nativeLanguage === strings.otherOption
      ? otherLanguage
      : nativeLanguage;
    const attendingKindergardenFrom = attendingKindergarden
      ? new Date(attendingKindergarden)
      : null;
    const body = {
      client: clientId,
      type: 'preschoolScreening',
      date: measurementDate,
      savedBy: userName,
      answers: measurementAnswers,
      info: {
        attendingKindergardenFrom: attendingKindergardenFrom,
        nativeLanguage: clientNativeLanguage,
        languageSkill: languageSkill,
        laterality: laterality,
        finalEvaluation: finalEvaluation,
        weight: Number(weight),
        height: Number(height)
      }
    };

    dispatch(saveMeasurement(body)).then(() => 
      dispatch(goBack())
    );
  };

  const openRemoveWindow = () => {
    setRemoveWindowVisible(true);
  };

  const closeRemoveWindow = () => {
    setRemoveWindowVisible(false);
  };

  const removePreschoolScreening = () => {
    setRemoveWindowVisible(false);
    dispatch(removeMeasurement('preschoolScreening', clientId)).then(() => 
      dispatch(goBack())
    );
  };

  const commentChangeHandler = group => event => {
    const path = `${group}.comment`;
    updateAnswers(path, event.target.value);
  };

  const scrollWin = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const goToNextRegistrationStep = () => {
    setStepperIndex(stepperIndex => stepperIndex + 1);
    scrollWin();
  };

  const goToPreviousRegistrationStep = () => {
    setStepperIndex(stepperIndex => stepperIndex - 1);
    scrollWin();
  };

  const renderBottomMobilePageNavigation = () => (
    <>
      <div className="next_back_buttons">
        {stepperIndex > 0 ? (
          <span
            className="button-back"
            onClick={() => goToPreviousRegistrationStep()}
          >
            <Icon className="button-back-icon" type="left" />
            <span className="button-back-text">{strings.back}</span>
          </span>
        ) : <span />}
        {stepperIndex < 10 ? (
          <span
            className="button-next"
            onClick={() => goToNextRegistrationStep()}
          >
            {strings.next}
            <Icon className="button-next-icon" type="right" />
          </span>
        ) : <span />}
      </div>
    </>
  );

  const checkFields = () => {
    if (
      !attendingKindergarden ||
      !measurementDate || 
      nativeLanguage === '' || 
      languageSkill === '' || 
      laterality === '' || 
      weight === 0 || 
      height === 0
    )
      return true;

    return false;
  };

  return (
    <div className="preschool-screening-form-background">
      <div className="preschool-screening-form-wrapper">
        <div 
          className="back-button"
          style={{ cursor: 'pointer' }}
          onClick={() => dispatch(goBack())}
        >
          <Icon type="left" /> {strings.back1}
        </div>
        <h2>{strings.preschoolScreeningFormTitle}</h2>
        {mobileSize && (
          <Stepper
            color="#41AEA6"
            fontSize="10px"
            steps={[{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
            currentStep={stepperIndex}
            
          />
        )}
        <div className={`${stepperIndex === 0 ? 'preschool-screening-client-info' : 'hiddenStep'}`}>
          {client && (
            <div>
              <div className="preschool-screening-form-grid col-4">
                <div className="form-item">
                  <p className="preschool-screening-info-label">{strings.firstName}</p>
                  <p className="preschool-screening-info-data">{`${client.firstName} ${client.lastName}`}</p>
                </div>
                <div className="form-item">
                  <p className="preschool-screening-info-label">{strings.gender}</p>
                  <p className="preschool-screening-info-data">{client.gender}</p>
                </div>
                <div className="form-item">
                  <p className="preschool-screening-info-label">{strings.birthDate}</p>
                  <p className="preschool-screening-info-data">{moment(client.birthDate).format('D. M. YYYY')}</p>
                </div>
                <div className="form-item">
                  <p className="preschool-screening-info-label">{strings.kindergardenStart}</p>
                  <MonthPicker
                    value={attendingKindergarden}
                    onChange={date => setAttendingKindergarden(date)}
                    placeholder={strings.chooseMonthAndYear}
                    format={'MMMM, YYYY'}
                    disabled={!adminRole}
                    className='preschool-screening-month-picker'
                  />
                </div>
              </div>
              <div className="preschool-screening-form-grid col-3">
                <div className="form-item">
                  <p className="preschool-screening-info-label">{strings.nativeLanguage}</p>
                  <div className='preschool-answer-dropdown'>
                    <Dropdown overlay={nativeLanguageOptions()} trigger={['click']} disabled={!adminRole}>
                      <div>
                        {nativeLanguage === '' ? strings.chooseLanguage : nativeLanguage}
                        <Icon type="down" style={{ paddingLeft: '10px' }} />
                      </div>
                    </Dropdown>
                  </div>
                  {nativeLanguage === strings.otherOption && (
                    <div className='choose-language'>
                      <Input
                        className='preschool-screening-info-input' 
                        value={otherLanguage}
                        onChange={(event) => setOtherLanguage(event.target.value)}
                        placeholder={strings.typeNativeLanguage}
                      />
                    </div>
                  )}
                </div>
                <div className="form-item">
                  <p className="preschool-screening-info-label">{strings.languageSkills}</p>
                  <div className='preschool-answer-dropdown'>
                    <Dropdown overlay={languageSkillOptions()} trigger={['click']} disabled={!adminRole}>
                      <div>
                        {languageSkill === '' ? strings.chooseLevel : languageSkill}
                        <Icon type="down" style={{ paddingLeft: '10px' }} />
                      </div>
                    </Dropdown>
                  </div>
                </div>
                <div className="form-item">
                  <p className="preschool-screening-info-label">{strings.laterality}</p>
                  <div className='preschool-answer-dropdown'>
                    <Dropdown overlay={lateralityOptions()} trigger={['click']} disabled={!adminRole}>
                      <div>
                        {laterality === '' ? strings.chooseSide : laterality}
                        <Icon type="down" style={{ paddingLeft: '10px' }} />
                      </div>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div style={{ padding: '10px 0' }}>
                <div className="form-item">
                  <div className="preschool-screening-info-label">
                    {strings.finalEvaluation}
                  </div>
                  <textarea
                    value={finalEvaluation}
                    onChange={event => setFinalEvaluation(event.target.value)}
                    readOnly={!adminRole}
                    className='preschool-screening-note'
                  />
                </div>
              </div>
              <div className="preschool-screening-form-grid col-2">
                <div className="form-item">
                  <p className="preschool-screening-info-label">{strings.weightKg}</p>
                  <Input
                    className='preschool-screening-info-input'
                    value={weight}
                    onChange={event => setWeight(event.target.value)}
                    type='number'
                    min='0'
                    max='200'
                    readOnly={!adminRole}
                  />
                </div>
                <div className="form-item">
                  <p className="preschool-screening-info-label">{strings.heightCm}</p>
                  <Input 
                    className='preschool-screening-info-input'
                    value={height}
                    onChange={event => setHeight(event.target.value)}
                    type='number'
                    min='0'
                    max='300'
                    readOnly={!adminRole}
                  />
                </div>
              </div>
              <div className="preschool-screening-form-grid measurement-date">
                <div className="form-item">
                  <p className="preschool-screening-info-label">{strings.measurementDate}</p>
                  {edit ? (
                    <p className="preschool-screening-info-data">{moment(measurementDate).format('D. M. YYYY')}</p>
                  ) : (
                    <DatePicker
                      value={measurementDate}
                      onChange={date => setMeasurementDate(date)}
                      placeholder={strings.chooseDate}
                      className='preschool-screening-month-picker'
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className='preschool-screening-questions'>
          {mobileSize && stepperIndex === 0 && (
            <div>
              {renderBottomMobilePageNavigation()}
            </div>
          )}
          {measurementQuestions.table && 
            measurementQuestions.table.map((category, i) => {
              const group = category.group;
              return (
                <div
                  className={`${
                    stepperIndex === i + 1 ? 'activeStep' : 'hiddenStep'
                  }`}
                  key={i}
                >
                  <h3 style={{ fontSize: '18px' }}>{category.title}</h3>
                  <div className='preschool-screening-questions-table-mobile'>
                    <PreschoolScreeningTable
                      id={group}
                      questions={category.questions}
                      data={measurementAnswers[group]}
                      adminRole={adminRole}
                      mobileSize={mobileSize}
                      updateAnswers={updateAnswers}
                    />
                  </div>
                  <div style={{ padding: '10px 0' }}>
                    <div className="form-item">
                      <div className="preschool-screening-info-label" style={{ color: 'black' }}>
                        {strings.recommendation}
                      </div>
                      <textarea
                        value={measurementAnswers ? measurementAnswers[group].comment : ''}
                        onChange={commentChangeHandler(group)}
                        readOnly={!adminRole}
                        className='preschool-screening-note'
                      />
                    </div>
                  </div>
                  {mobileSize && renderBottomMobilePageNavigation(stepperIndex)}
                </div>
              );
            })
          }
        </div>
        <div className="preschool-screening-footer">
          {adminRole && (
            <div>
              <Button
                onClick={savePreschoolScreening}
                className='save-measurement'
                disabled={checkFields()}
              >
                {strings.save}
              </Button>
              {edit && (
                <Button
                  onClick={openRemoveWindow}
                  className='remove-measurement'
                >
                  {strings.remove}
                </Button>
              )}
              <Modal
                centered
                title={strings.removeMeasurement}
                visible={removeWindowVisible}
                onCancel={closeRemoveWindow}
                onOk={() => removePreschoolScreening()}
              >
                {strings.removeMeasurementMessage}
              </Modal>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PreschoolScreening;
