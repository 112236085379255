import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import jwt from 'jsonwebtoken';

import Button from 'components/Button';
import {
  getOmamaReportAction,
  resetOmamaReportAction,
  getOmamaReportStatisticsAction
} from '../../actions/report.actions';
import { strings } from '../../strings/StringsProvider';
import { getOmamasAction } from '../../actions/users.actions';

import './OmamaReport.scss';
moment.locale('sk');

const months = moment.months();

function OmamaReport() {
  const omama = jwt.decode(localStorage.getItem('access-token'));

  const omamaReport = useSelector(state => state.report.omamaReport);
  const reportStatistics = useSelector(state => state.report.reportStatistics);
  const omamas = useSelector(state => state.users.omamas);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetOmamaReportAction());
    dispatch(getOmamasAction());
    dispatch(getOmamaReportStatisticsAction(moment().year()));
  }, [dispatch]);

  const {
    nevyplneneVykazy,
    doneLessons,
    doneClubs,
    canceledActions,
    mentoring,
    mentoringSenior,
    parentalClub,
    supervision,
    training,
    otherJob,
    education,
    vacation,
    doctorVisit,
    doctorRelative,
    workUnable,
    OCR,
    INTER_NDA,
    extraTimeOff,
    other
  } = reportStatistics;

  const omamaInfo = omamas.find(omamaInfo => omamaInfo._id === omama.username);

  const omamaLevel = omamaInfo && Number(omamaInfo.level);

  return (
    <div style={{ paddingTop: '50px' }}>
      <div className="omamaReport-main">
        <br />
        <h4>
          <b>
            {strings.noFilledReports}: <span style={{ color: '#41aea6' }}>{nevyplneneVykazy}</span>
          </b>
        </h4>
        <br />
        <h4>
          <b>
            {strings.doneLessons}: <span style={{ color: '#41aea6' }}>{doneLessons}</span>
          </b>
        </h4>
        <h4>
          <b>
            {strings.doneClubs}: <span style={{ color: '#41aea6' }}>{doneClubs}</span>
          </b>
        </h4>
        <h4>
          <b>
            {strings.canceledActions}: <span style={{ color: '#41aea6' }}>{canceledActions}</span>
          </b>
        </h4>
        <br />
        <h4>
          <b>{strings.otherWorkingActions}</b>
        </h4>
        <div>
          {strings.mentoring}:{' '}
          <span style={{ color: '#41aea6' }}>
            {mentoring} {strings.hour}
          </span>
        </div>
        {omamaLevel >= 3 && (
          <div>
            {strings.mentorTillSenior}:{' '}
            <span style={{ color: '#41aea6' }}>
              {mentoringSenior} {strings.hour}
            </span>
          </div>
        )}
        <div>
          {strings.parentalClub}:{' '}
          <span style={{ color: '#41aea6' }}>
            {parentalClub} {strings.hour}
          </span>
        </div>
        <div>
          {strings.supervision}:{' '}
          <span style={{ color: '#41aea6' }}>
            {supervision} {strings.hour}
          </span>
        </div>
        <div>
          {strings.training}:{' '}
          <span style={{ color: '#41aea6' }}>
            {training} {strings.hour}
          </span>
        </div>
        <div>
          {strings.interNDA}:{' '}
          <span style={{ color: '#41aea6' }}>
            {INTER_NDA} {strings.hour}
          </span>
        </div>
        <div>
          {strings.otherJob}:{' '}
          <span style={{ color: '#41aea6' }}>
            {otherJob} {strings.hour}
          </span>
        </div>
        <div>
          {strings.education}:{' '}
          <span style={{ color: '#41aea6' }}>
            {education} {strings.hour}
          </span>
        </div>
        <br />
        <h4>
          <b>{strings.notInWork}</b>
        </h4>
        <div>
          {strings.vacation}:{' '}
          <span style={{ color: '#41aea6' }}>
            {vacation} {strings.hour}
          </span>
        </div>
        <div>
          {strings.doctor}:{' '}
          <span style={{ color: '#41aea6' }}>
            {doctorVisit} {strings.hour}
          </span>
        </div>
        <div>
          {strings.familyDoctor}:{' '}
          <span style={{ color: '#41aea6' }}>
            {doctorRelative} {strings.hour}
          </span>
        </div>
        <div>
          {strings.workUnable}:{' '}
          <span style={{ color: '#41aea6' }}>
            {workUnable} {strings.hour}
          </span>
        </div>
        <div>
          {strings.OCR}:{' '}
          <span style={{ color: '#41aea6' }}>
            {OCR} {strings.hour}
          </span>
        </div>
        <div>
          {strings.extraTimeOff}:{' '}
          <span style={{ color: '#41aea6' }}>
            {extraTimeOff} {strings.hour}
          </span>
        </div>
        <div>
          {strings.other}:{' '}
          <span style={{ color: '#41aea6' }}>
            {other} {strings.hour}
          </span>
        </div>

        <h3
          style={{
            textAlign: 'center',
            color: '#41aea6',
            fontWeight: 'bold',
            borderBottom: '1px solid #41aea6',
            padding: '8px 0'
          }}
        >
          {strings.workingReport}
        </h3>
        <div className="omamaReport-generate-parent-container">
          {months.slice(0, moment().month()).map((month, i) => (
            <div key={i} className="omamaReport-generate-container">
              <Button
                type="secondary"
                className="omamaReport-generate-button"
                onClick={() =>
                  dispatch(
                    getOmamaReportAction(
                      moment(`${moment().year()}-${i + 1}-01`)
                        .startOf('month')
                        .format('YYYY-MM-DD'),
                      moment(`${moment().year()}-${i + 1}-01`)
                        .endOf('month')
                        .format('YYYY-MM-DD')
                    )
                  )
                }
              >
                {strings.generateReportFor} <br />
                <span style={{ textTransform: 'capitalize' }}>{month}</span>
              </Button>
              {omamaReport.report.length >= 0 && omamaReport.month === month ? (
                <CSVLink
                  className="ant-btn report-button omamaReport-generate-button omamaReport-download-button"
                  filename={`OMAMA vykaz ${month}.csv`}
                  data={omamaReport.report}
                >
                  <span>
                    {strings.downloadReportFor} <span style={{ textTransform: 'capitalize' }}>{month}</span>
                  </span>
                </CSVLink>
              ) : (
                <div className="omamaReport-generate-button"></div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default OmamaReport;
