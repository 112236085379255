import React from 'react';
import { useSelector } from 'react-redux';
import { List } from 'antd';
import ListItem from './ListItem';

function NotificationsList() {

  const state = useSelector(state => state.notifications);

  return (
    <>
      {
        state?.data?.popup?.length>0
        &&
        <div
          style={{fontWeight : 'bold'}}
        >
          <List
            style={{padding : '1rem'}}
            loading={state.status === 'loading'}
            dataSource={state?.data.popup}
            renderItem={
              item=>(
                <ListItem
                  {...item}
                  new = {false}
                />
              )
            }
          />
        </div>
      }

    </>
  );

}


export default NotificationsList;