import React from 'react';
import PropTypes from 'prop-types';
import { Descriptions, Divider } from 'antd';
import { strings } from 'strings/StringsProvider';

import './InterNDAScoreTable.scss';

const COGNITION_SCORE_LOW_LIMIT = 27.5;
const COGNITION_SCORE_HIGH_LIMIT = 38.5;
const FINE_MOTOR_SCORE_LOW_LIMIT = 17.6;
const FINE_MOTOR_SCORE_HIGH_LIMIT = 25.7;
const GROSS_MOTOR_SCORE_LOW_LIMIT = 31.2;
const GROSS_MOTOR_SCORE_HIGH_LIMIT = 51.7;
const LANGUAGE_SCORE_LOW_LIMIT = 12.2;
const LANGUAGE_SCORE_HIGH_LIMIT = 17.8;
const POSITIVE_BEHAVIOUR_SCORE_LOW_LIMIT = 37.9;
const POSITIVE_BEHAVIOUR_SCORE_HIGH_LIMIT = 51.4;
const NEGATIVE_BEHAVIOUR_SCORE_LOW_LIMIT = 50.0;
const NEGATIVE_BEHAVIOUR_SCORE_HIGH_LIMIT = 76.6;

function InterNDAScoreTable({
  cognitionScore,
  fineMotorScore,
  grossMotorScore,
  languageScore,
  positiveBehaviourScore,
  negativeBehaviourScore,
  showInMilestone = false
}) {
  const displayRiskOfDelay = (score, lowLimit, highLimit) => {
    if (score < lowLimit) {
      return <span className="high-risk">{strings.highRiskDelay}</span>;
    } else if (score > highLimit) {
      return <span className="low-risk">{strings.lowRiskDelay}</span>;
    } else {
      return <span className="moderate-risk">{strings.moderateRiskDelay}</span>;
    }
  };

  const displayRiskOfProblems = (score, lowLimit, highLimit, lowerIsBetter) => {
    if (score < lowLimit) {
      if (lowerIsBetter) {
        return <span className="low-risk">{strings.lowRiskProblems}</span>;
      } else {
        return <span className="high-risk">{strings.highRiskProblems}</span>;
      }
    } else if (score > highLimit) {
      if (lowerIsBetter) {
        return <span className="high-risk">{strings.highRiskProblems}</span>;
      } else {
        return <span className="low-risk">{strings.lowRiskProblems}</span>;
      }
    } else {
      return <span className="moderate-risk">{strings.moderateRiskProblems}</span>;
    }
  };

  const getColor = (score, lowLimit, highLimit, lowerIsBetter = false) => {
    if (score < lowLimit) {
      if (lowerIsBetter) {
        return 'low-risk';
      } else {
        return 'high-risk';
      }
    } else if (score > highLimit) {
      if (lowerIsBetter) {
        return 'high-risk';
      } else {
        return 'low-risk';
      }
    } else {
      return 'moderate-risk';
    }
  };

  return (
    <>
      {showInMilestone ? (
        <div className="interNDA-score-table show-in-milestone">
          <Descriptions layout="vertical" bordered size="small" column={{ xs: 2, sm: 2 }}>
            <Descriptions.Item label={strings.category}>
              <div className={getColor(cognitionScore, COGNITION_SCORE_LOW_LIMIT, COGNITION_SCORE_HIGH_LIMIT)}>
                {strings.cognition}
              </div>
              <Divider />
              <div className={getColor(fineMotorScore, FINE_MOTOR_SCORE_LOW_LIMIT, FINE_MOTOR_SCORE_HIGH_LIMIT)}>
                {strings.fineMotor}
              </div>
              <Divider />
              <div className={getColor(grossMotorScore, GROSS_MOTOR_SCORE_LOW_LIMIT, GROSS_MOTOR_SCORE_HIGH_LIMIT)}>
                {strings.grossMotor}
              </div>
              <Divider />
              <div className={getColor(languageScore, LANGUAGE_SCORE_LOW_LIMIT, LANGUAGE_SCORE_HIGH_LIMIT)}>
                {strings.languageInterNDA}
              </div>
              <Divider />
              <div
                className={getColor(
                  positiveBehaviourScore,
                  POSITIVE_BEHAVIOUR_SCORE_LOW_LIMIT,
                  POSITIVE_BEHAVIOUR_SCORE_HIGH_LIMIT
                )}
              >
                {strings.positiveBehaviour}
              </div>
              <Divider />
              <div
                className={getColor(
                  negativeBehaviourScore,
                  NEGATIVE_BEHAVIOUR_SCORE_LOW_LIMIT,
                  NEGATIVE_BEHAVIOUR_SCORE_HIGH_LIMIT,
                  true
                )}
              >
                {strings.negativeBehaviour}
              </div>
            </Descriptions.Item>
            <Descriptions.Item label={strings.score}>
              <div className={getColor(cognitionScore, COGNITION_SCORE_LOW_LIMIT, COGNITION_SCORE_HIGH_LIMIT)}>
                {cognitionScore}
              </div>
              <Divider />
              <div className={getColor(fineMotorScore, FINE_MOTOR_SCORE_LOW_LIMIT, FINE_MOTOR_SCORE_HIGH_LIMIT)}>
                {fineMotorScore}
              </div>
              <Divider />
              <div className={getColor(grossMotorScore, GROSS_MOTOR_SCORE_LOW_LIMIT, GROSS_MOTOR_SCORE_HIGH_LIMIT)}>
                {grossMotorScore}
              </div>
              <Divider />
              <div className={getColor(languageScore, LANGUAGE_SCORE_LOW_LIMIT, LANGUAGE_SCORE_HIGH_LIMIT)}>
                {languageScore}
              </div>
              <Divider />
              <div
                className={getColor(
                  positiveBehaviourScore,
                  POSITIVE_BEHAVIOUR_SCORE_LOW_LIMIT,
                  POSITIVE_BEHAVIOUR_SCORE_HIGH_LIMIT
                )}
              >
                {positiveBehaviourScore}
              </div>
              <Divider />
              <div
                className={getColor(
                  negativeBehaviourScore,
                  NEGATIVE_BEHAVIOUR_SCORE_LOW_LIMIT,
                  NEGATIVE_BEHAVIOUR_SCORE_HIGH_LIMIT,
                  true
                )}
              >
                {negativeBehaviourScore}
              </div>
            </Descriptions.Item>
          </Descriptions>
        </div>
      ) : (
        <div>
          <div className="interNDA-score-table desktopView">
            <Descriptions title={strings.results} layout="vertical" bordered>
              <Descriptions.Item label={strings.category}>
                {strings.cognition}
                <Divider />
                {strings.fineMotor}
                <Divider />
                {strings.grossMotor}
                <Divider />
                {strings.languageInterNDA}
                <Divider />
                {strings.positiveBehaviour}
                <Divider />
                {strings.negativeBehaviour}
              </Descriptions.Item>
              <Descriptions.Item label={strings.score}>
                <div data-test-id="interNDA-results-cognitionScore-desktop">{cognitionScore}</div>
                <Divider />
                <div data-test-id="interNDA-results-fineMotorScore-desktop">{fineMotorScore}</div>
                <Divider />
                <div data-test-id="interNDA-results-grossMotorScore-desktop">{grossMotorScore}</div>
                <Divider />
                <div data-test-id="interNDA-results-languageScore-desktop">{languageScore}</div>
                <Divider />
                <div data-test-id="interNDA-results-positiveBehaviourScore-desktop">{positiveBehaviourScore}</div>
                <Divider />
                <div data-test-id="interNDA-results-negativeBehaviourScore-desktop">{negativeBehaviourScore}</div>
              </Descriptions.Item>
              <Descriptions.Item label={strings.risk}>
                {displayRiskOfDelay(cognitionScore, COGNITION_SCORE_LOW_LIMIT, COGNITION_SCORE_HIGH_LIMIT)}
                <Divider />
                {displayRiskOfDelay(fineMotorScore, FINE_MOTOR_SCORE_LOW_LIMIT, FINE_MOTOR_SCORE_HIGH_LIMIT)}
                <Divider />
                {displayRiskOfDelay(grossMotorScore, GROSS_MOTOR_SCORE_LOW_LIMIT, GROSS_MOTOR_SCORE_HIGH_LIMIT)}
                <Divider />
                {displayRiskOfDelay(languageScore, LANGUAGE_SCORE_LOW_LIMIT, LANGUAGE_SCORE_HIGH_LIMIT)}
                <Divider />
                {displayRiskOfProblems(
                  positiveBehaviourScore,
                  POSITIVE_BEHAVIOUR_SCORE_LOW_LIMIT,
                  POSITIVE_BEHAVIOUR_SCORE_HIGH_LIMIT,
                  false
                )}
                <Divider />
                {displayRiskOfProblems(
                  negativeBehaviourScore,
                  NEGATIVE_BEHAVIOUR_SCORE_LOW_LIMIT,
                  NEGATIVE_BEHAVIOUR_SCORE_HIGH_LIMIT,
                  true
                )}
              </Descriptions.Item>
            </Descriptions>
          </div>
          <div className="interNDA-score-table mobileView">
            <Descriptions title={strings.cognition} bordered>
              <Descriptions.Item label={strings.score}>
                <div data-test-id="interNDA-results-cognitionScore-mobile">{cognitionScore}</div>
              </Descriptions.Item>
              <Descriptions.Item label={strings.risk}>
                {displayRiskOfDelay(cognitionScore, COGNITION_SCORE_LOW_LIMIT, COGNITION_SCORE_HIGH_LIMIT)}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions title={strings.fineMotor} bordered>
              <Descriptions.Item label={strings.score}>
                <div data-test-id="interNDA-results-fineMotorScore-mobile">{fineMotorScore}</div>
              </Descriptions.Item>
              <Descriptions.Item label={strings.risk}>
                {displayRiskOfDelay(fineMotorScore, FINE_MOTOR_SCORE_LOW_LIMIT, FINE_MOTOR_SCORE_HIGH_LIMIT)}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions title={strings.grossMotor} bordered>
              <Descriptions.Item label={strings.score}>
                <div data-test-id="interNDA-results-grossMotorScore-mobile">{grossMotorScore}</div>
              </Descriptions.Item>
              <Descriptions.Item label={strings.risk}>
                {displayRiskOfDelay(grossMotorScore, GROSS_MOTOR_SCORE_LOW_LIMIT, GROSS_MOTOR_SCORE_HIGH_LIMIT)}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions title={strings.languageInterNDA} bordered>
              <Descriptions.Item label={strings.score}>
                <div data-test-id="interNDA-results-languageScore-mobile">{languageScore}</div>
              </Descriptions.Item>
              <Descriptions.Item label={strings.risk}>
                {displayRiskOfDelay(languageScore, LANGUAGE_SCORE_LOW_LIMIT, LANGUAGE_SCORE_HIGH_LIMIT)}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions title={strings.positiveBehaviour} bordered>
              <Descriptions.Item label={strings.score}>
                <div data-test-id="interNDA-results-positiveBehaviourScore-mobile">{positiveBehaviourScore}</div>
              </Descriptions.Item>
              <Descriptions.Item label={strings.risk}>
                {displayRiskOfProblems(
                  positiveBehaviourScore,
                  POSITIVE_BEHAVIOUR_SCORE_LOW_LIMIT,
                  POSITIVE_BEHAVIOUR_SCORE_HIGH_LIMIT,
                  false
                )}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions title={strings.negativeBehaviour} bordered>
              <Descriptions.Item label={strings.score}>
                <div data-test-id="interNDA-results-negativeBehaviourScore-mobile">{negativeBehaviourScore}</div>
              </Descriptions.Item>
              <Descriptions.Item label={strings.risk}>
                {displayRiskOfProblems(
                  negativeBehaviourScore,
                  NEGATIVE_BEHAVIOUR_SCORE_LOW_LIMIT,
                  NEGATIVE_BEHAVIOUR_SCORE_HIGH_LIMIT,
                  true
                )}
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
      )}
    </>
  );
}

InterNDAScoreTable.propTypes = {
  cognitionScore: PropTypes.number,
  fineMotorScore: PropTypes.number,
  grossMotorScore: PropTypes.number,
  languageScore: PropTypes.number,
  positiveBehaviourScore: PropTypes.number,
  negativeBehaviourScore: PropTypes.number,
  showInMilestone: PropTypes.bool
};

export default InterNDAScoreTable;
