export const HANDLE_CLIENT_FORM = 'HANDLE_CLIENT_FORM';
export const RESET_CLIENT_FORM = 'RESET_CLIENT_FORM';
export const HANDLE_CLIENT_FORM_BUTTON = 'HANDLE_CLIENT_FORM_BUTTON';
export const HANDLE_CLIENT_FORM_DATE = 'HANDLE_CLIENT_FORM_DATE';
export const POPULATE_CLIENT_FORM = 'POPULATE_CLIENT_FORM';

export const initialState = {
  data: {
    firstName: '',
    lastName: '',
    gender: '',
    region: '',
    street: '',
    city: '',
    birthDateAdress: '',
    birthDate: '',
    inProgramFromDate: '',
    motherName: '',
    motherBirthDate: '',
    motherHealthState: '',
    motherEducation: '',
    motherEducationLevel: '',
    motherWork: '',
    motherPhone: '',
    fatherName: '',
    fatherBirthDate: '',
    fatherHealthState: '',
    fatherEducation: '',
    fatherEducationLevel: '',
    fatherWork: '',
    fatherPhone: '',
    siblingsNumber: '',
    childBirthOrder: '',
    language: '',
    pregnancy: '',
    smokingPregnancy: false,
    alcoholPregnancy: false,
    drugsPregnancy: false,
    medicinePregrancy: '',
    prematureBirth: false,
    birthInTerm: false,
    lateBirth: false,
    inducedBirth: false,
    spontaneousBirth: false,
    csectionBirth: false,
    naturalBirth: false,
    childbirthComplications: '',
    birthWeight: '',
    birthLength: '',
    birthHeadCircumference: '',
    breastFed: false,
    breastFeedTime: '',
    solidFood: '',
    smokingBreastFeeding: false,
    alcoholBreastFeeding: false,
    drugsBreastFeeding: false,
    medicineBreastFeeding: '',
    growthRollingover: '',
    growthSitting: '',
    growthCrawling: '',
    growthWalking: '',
    growthCooing: '',
    growthTalking: '',
    smokingNearChildBelowTwoYears: false,
    alcoholNearChildBelowTwoYears: false,
    drugsNearChildBelowTwoYears: false,
    medicineNearChildBelowTwoYears: '',
    hospitalization: '',
    injury: '',
    healthIssues: '',
    obligatoryVaccination: false,
    regularExaminations: false,
    examinationOffice: '',
    dateOfLastExamination: '',
    healthCondition: '',
    // Máte obavy o niektoré z nasledovných:
    eyesightIssueConcern: false,
    hearingIssueConcern: false,
    sleepIssueConcern: false,
    growNurtureIssueConcern: false,
    healthIssueConcern: false,
    wholeGrowIssueConcern: false,
    // Byvanie
    houseMaterial: '',
    houseRooms: '',
    houseInhabitants: '',
    houseFuel: '',
    houseSameRoomForChildAndKitchen: false,
    houseSeparateBathRoom: false,
    visitingInstitution: '',
    institutionName: '',
    visitingInstitutionFor: '',
    omamaID: '',
    predskolskyKlub: false,
    siblingsInfo: '',
    gestacnyBirthDate: '',

    photosInApp: false,
    photosOnWeb: false,
    photosPrinted: false,
    videoReports: false,
    pediatricScreenings: false,
    growthTracking: false,
    educationCarrierGrowth: false,
    contactLater: false,
    // Skoly
    infantSchoolStartDate: '',
    infantSchoolEndDate: '',
    elementarySchoolStartDate: '',
    elementarySchoolEndDate: '',
    specialSchool: '',
    elementarySchool: false,
    elementarySchoolName: ''
  }
};

// export const deactivateForm = {
//   data: {
//     active: false,
//     deactivatedOnDate: '',
//   }
// };


initialState.formFields = Object.keys(initialState.data);

export default (state = initialState, action) => {
  switch (action.type) {
      /* eslint-disable */
    case HANDLE_CLIENT_FORM:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.field]: action.payload.value
        }
      };
    case HANDLE_CLIENT_FORM_BUTTON:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.type]: action.payload.flag
        }
      };
    case HANDLE_CLIENT_FORM_DATE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.field]: action.payload.date
        }
      };
    case RESET_CLIENT_FORM:
      return {
        ...state,
        data: initialState.data
      };
    case POPULATE_CLIENT_FORM:
      return {
        ...state,
        data: action.payload
      };
    default:
      return state;
    /* eslint-enable */
  }
};