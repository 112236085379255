import React, { useState, useEffect, useCallback } from 'react';
import { replace } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import moment from 'moment';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import jwt from 'jsonwebtoken';
import SupervisorActions from '../../../containers/supervisor/SupervisorActions';
import Button from 'components/Button';

// Local imports
import './AdminAdmin.scss';
import { getAdminUser } from '../../../actions/admin.actions';

import { getSupervisorReportStatisticsAction } from '../../../actions/report.actions';
import { strings } from '../../../strings/StringsProvider';
import EditProfile from '../../../components/EditProfile';
import UserInfoPanel from './UserInfoPanel';

const subMenuItems = ['Akcie', 'Info'];

function AdminSupervizor({ match }) {
  const [edit, setEdit] = useState(false);
  const [tab, setTab] = useState(decodeURIComponent(window.location.hash.substr(1)));
  const [iconClicked, setIconClicked] = useState(!isEmpty(window.location.hash.substr(1)) ? true : false);
  const userRole = jwt.decode(localStorage.getItem('access-token')).role;
  const userName = jwt.decode(localStorage.getItem('access-token')).username;
  const { userId } = useParams();
  const history = useHistory();

  const user = useSelector(state => state.admin.oneUser);
  const reportSupervisorStatistics = useSelector(state => state.report.reportSupervisorStatistics);

  const dispatch = useDispatch();

  const replacePage = useCallback(location => dispatch(replace(location)), [dispatch]);

  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };

  const handleClick = useCallback(() => {
    if (iconClicked === true) {
      document.getElementsByTagName('body')[0].style.overflow = 'unset';
    } else if (iconClicked === false) {
      window.scrollTo(0, 0);
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    }
    setIconClicked(prevIconClicked => !prevIconClicked);
  }, [iconClicked]);

  const changeTab = useCallback(
    newTab => () => {
      switch (true) {
        case newTab === strings.actionOneUpperCase:
          setTab(newTab);
          replacePage(`#${newTab}`);
          break;
        case newTab === strings.info:
          try {
            dispatch(getSupervisorReportStatisticsAction(moment().year(), userId));
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
          }
          setTab(newTab);
          replacePage(`#${newTab}`);
          break;
        default:
      }
    },
    [dispatch, replacePage, userId]
  );

  const mobileChangeTab = useCallback(
    newTab => {
      changeTab(newTab)();
      if (iconClicked) setIconClicked(false);
      handleClick();
    },
    [changeTab, handleClick, iconClicked]
  );

  useEffect(() => {
    dispatch(getAdminUser(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (!isMobile() && isEmpty(window.location.hash.substr(1))) {
      window.history.replaceState(null, match.url, `#${strings.actionOneUpperCase}`);
      setTab(strings.actionOneUpperCase);
    }
  }, [match.url]);

  const fetchUserAfterUpdate = () => {
    dispatch(getAdminUser(userId));
  };

  const switchEdit = flag => {
    setEdit(flag);
  };

  const renderStatsSubmenu = () => {
    return (
      <div className="admin-omama-submenu">
        <Button
          type="secondary"
          icon={<img src="/images/Icons/go-back.svg" alt="back-button" />}
          label={strings.goBack}
          className="go-back-button"
          onClick={history.goBack}
        />
        <ul>
          {subMenuItems.map((item, i) => (
            <li
              key={i}
              className={classNames({ active: tab === item })}
              onClick={changeTab(item)}
              data-test-id={`adminSupervisor-${item}`}
            >
              <span className="submenu-item">{item}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderStatsSubmenuMobile = () => {
    return subMenuItems.map((item, i) => (
      <div key={i} className="admin-omama-tabsMobile_row" onClick={() => mobileChangeTab(item)}>
        <span>{item}</span>
        <Icon type="right" />
      </div>
    ));
  };

  const renderSupervisorAkcie = () => {
    return <SupervisorActions supervisor={user} match={match} />;
  };

  const renderSupervisorInfo = () => {
    if (reportSupervisorStatistics) {
      const {
        nevyplneneVykazy,
        supervision,
        training,
        mentoring,
        phoneCall,
        otherMentoring,
        miu,
        travel,
        INTER_NDA,
        otherJob,
        vacation,
        doctorVisit,
        doctorRelative,
        workUnable,
        OCR,
        extraTimeOff,
        other
      } = reportSupervisorStatistics;
      return (
        <div className="mentorReportAdmin-main">
          <br />
          <h4 data-test-id="adminSupervisor-incompleteReports">
            <b>
              {strings.noFilledReports}: <span style={{ color: '#41aea6' }}>{nevyplneneVykazy}</span>
            </b>
          </h4>
          <br />
          <h4 data-test-id="adminSupervisor-supervisionsCompletedThisYear">
            <b>
              {strings.supervisionsThisYear}: <span style={{ color: '#41aea6' }}>{supervision}</span>
            </b>
          </h4>
          <h4 data-test-id="adminSupervisor-trainingsCompletedThisYear">
            <b>
              {strings.trainingThisYear}: <span style={{ color: '#41aea6' }}>{training}</span>
            </b>
          </h4>
          <br />
          <h4 data-test-id="adminSupervisor-mentoringsCompletedThisYear">
            <b>
              {strings.mentoringsThisYear}: <span style={{ color: '#41aea6' }}>{mentoring}</span>
            </b>
          </h4>
          <h4 data-test-id="adminSupervisor-otherMentoringsCompletedThisYear">
            <b>
              {strings.phoneCalls}: <span style={{ color: '#41aea6' }}>{phoneCall}</span>
            </b>
          </h4>
          <h4 data-test-id="adminSupervisor-mentoringsCompletedThisYear">
            <b>
              {strings.otherMentoring1}: <span style={{ color: '#41aea6' }}>{otherMentoring}</span>
            </b>
          </h4>
          <br />
          <h4 data-test-id="adminSupervisor-otherWorksCompletedThisYear">
            <b>{strings.otherWorkingActions}</b>
          </h4>
          <div data-test-id="adminSupervisor-supervision">
            {strings.supervision}:{' '}
            <span style={{ color: '#41aea6' }}>
              {supervision} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminSupervisor-training">
            {strings.training}:{' '}
            <span style={{ color: '#41aea6' }}>
              {training} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminSupervisor-miu">
            {strings.miu}:{' '}
            <span style={{ color: '#41aea6' }}>
              {miu} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminSupervisor-travel">
            {strings.travel}:{' '}
            <span style={{ color: '#41aea6' }}>
              {travel} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminSupervisor-interNDA">
            {strings.interNDA}:{' '}
            <span style={{ color: '#41aea6' }}>
              {INTER_NDA} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminSupervisor-otherWorks">
            {strings.otherJob}:{' '}
            <span style={{ color: '#41aea6' }}>
              {otherJob} {strings.hour}
            </span>
          </div>
          <br />
          <h4 data-test-id="adminSupervisor-absences">
            <b>{strings.notBeingInTheJob}</b>
          </h4>
          <div data-test-id="adminSupervisor-vacation">
            {strings.vacation}:{' '}
            <span style={{ color: '#41aea6' }}>
              {vacation} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminSupervisor-doctor">
            {strings.doctor}:{' '}
            <span style={{ color: '#41aea6' }}>
              {doctorVisit} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminSupervisor-familyDoctor">
            {strings.familyDoctor}:{' '}
            <span style={{ color: '#41aea6' }}>
              {doctorRelative} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminSupervisor-workIncapacity">
            {strings.workUnable}:{' '}
            <span style={{ color: '#41aea6' }}>
              {workUnable} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminSupervisor-OCR">
            {strings.OCR}:{' '}
            <span style={{ color: '#41aea6' }}>
              {OCR} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminSupervisor-OCR">
            {strings.extraTimeOff}:{' '}
            <span style={{ color: '#41aea6' }}>
              {extraTimeOff} {strings.hour}
            </span>
          </div>
          <div data-test-id="adminSupervisor-other">
            {strings.other}:{' '}
            <span style={{ color: '#41aea6' }}>
              {other} {strings.hour}
            </span>
          </div>
        </div>
      );
    } else {
      return <div>{strings.noInfo}.</div>;
    }
  };

  if (user._id) {
    return (
      <>
        {edit && <EditProfile role={user.role} bckButton={switchEdit} fetchUser={fetchUserAfterUpdate} />}
        {!edit && (
          <div className="admin-userDetail">
            <UserInfoPanel user={user} userRole={userRole} userName={userName} switchEdit={switchEdit} />

            <div className="admin-generalUserInfo doNotShowOnMobile">
              <div>{renderStatsSubmenu()}</div>
              <div
                className={`admin-omama-bottompanel ${tab === strings.info ? 'white-background' : ''}`}
                data-test-id="adminSupervisor-actionCalendar"
              >
                {tab === strings.actionOneUpperCase && renderSupervisorAkcie()}
                {tab === strings.info && renderSupervisorInfo()}
              </div>
            </div>

            <div className="admin-omama-tabsMobile showJustOnMobile" data-test-id="adminSupervisor-statsSubmenu-mobile">
              {renderStatsSubmenuMobile()}
            </div>
            <div className="tab_modal showJustOnMobile" style={{ display: iconClicked ? 'block' : 'none' }}>
              <div
                className="tab_modal_menu showJustOnMobile"
                onClick={handleClick}
                data-test-id="adminSupervisor-closeButton-mobile"
              >
                {iconClicked ? <Icon type="close" className="tab_modal_menu-closeIcon closeIcon" /> : ''}
              </div>
              <div
                className="admin-omama-bottompanel admin-supervisor-botoompanel showJustOnMobile"
                data-test-id="adminSupervisor-headActions-mobile"
              >
                {tab === strings.actionOneUpperCase && renderSupervisorAkcie()}
                {tab === strings.info && renderSupervisorInfo()}
              </div>
            </div>
          </div>
        )}
      </>
    );
  } else return null;
}

AdminSupervizor.propTypes = {
  match: PropTypes.object
};

export default AdminSupervizor;
