import React from 'react';
import './ImageGalleryNav.scss';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import classNames from 'classnames';
import { strings } from '../strings/StringsProvider';

function ImageGalleryNav({
  name,
  folder,
  goBack,
  goToClient
}) {
  return (
    <div className="imageGalleryNav">
      {goToClient && (<div
        className="imageGalleryNavLevel mobileOnly"
        onClick={() => goToClient()}
      >
        <Icon className="imageGalleryNavArrow" type="left" />
        <div className="imageGalleryNavName">{strings.client}</div>
      </div>)}
      <div className="imageGalleryNavLevel" onClick={() => goBack('gallery')}>
        <Icon className="imageGalleryNavArrow" type="left" />
        <div className="imageGalleryNavName" data-test-id='clientSubmenuGalery'>{strings.gallery}</div>
      </div>
      {name && (
        <div
          className={classNames('imageGalleryNavLevel', {
            disabled: !folder
          })}
          onClick={() => folder && goBack('user')}
        >
          <Icon className="imageGalleryNavArrow" type="left" />
          <div className="imageGalleryNavName">{name}</div>
        </div>
      )}
      {folder && (
        <div className="imageGalleryNavLevel disabled">
          <Icon className="imageGalleryNavArrow" type="left" />
          <div className="imageGalleryNavName">{folder}</div>
        </div>
      )}
      {/* SETUP pridat fotku v galerii */}
      {/* <div className="imageGalleryAdd"><label htmlFor="galleryAdd">Pridať fotku</label></div>
      <input id="galleryAdd"
        value={[]}
        type="file"
        accept="image/*"
        multiple
        onChange={this.displayMultiple}
        style={{ display: 'none' }} ></input> */}
    </div>
  );
}

ImageGalleryNav.propTypes = {
  name: PropTypes.string,
  folder: PropTypes.string,
  goBack: PropTypes.func,
  goToClient: PropTypes.func
};

export default ImageGalleryNav;