import {
  GET_ADMIN_SUPERVISOR_ACTIONS,
  GET_SPECIFIC_SUPERVISOR_ACTION,
  ADMIN_SUPERVISOR_CHANGE_NEXT_MONTH,
  ADMIN_SUPERVISOR_CHANGE_PREVIOUS_MONTH,
  ADMIN_SUPERVISOR_CHANGE_DATE,
  SET_CHECKLISTS
} from '../reducers/supervisor.reducer';
import { orderBy } from 'lodash';
import axios from 'axios';
import moment from 'moment';

// Local imports
import { api } from '../conf';
import { setLoading } from './status.actions';

export const getAdminSupervisorActionsAction = (supervisorId, start, end) => {
  return async dispatch => {
    dispatch(setLoading(true));
    let startDate = start;
    let endDate = end;
    if (!(typeof start === 'string') || !(typeof end === 'string')) {
      startDate = moment(start).format('YYYY-MM-DD');
      endDate = moment(end).format('YYYY-MM-DD');
    }
    try {
      const { data } = await axios.get(api.getAdminSupervisorActions(supervisorId, startDate, endDate));

      await dispatch({
        type: GET_ADMIN_SUPERVISOR_ACTIONS,
        payload: {
          supervisorActions: orderBy(data.data, ['date'], ['desc'])
        }
      });
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
};

export const getSpecificSupervisorAction = actionId => {
  return async dispatch => {
    const { data } = await axios.get(api.getSpecificSupervisorAction(actionId));
    return dispatch({
      type: GET_SPECIFIC_SUPERVISOR_ACTION,
      payload: data.data
    });
  };
};

export const changeNextMonthSupervisor = (supervisorId, actualStart, actualEnd) => {
  return async dispatch => {
    const start = moment(actualStart)
      .add(1, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');
    const end = moment(actualEnd)
      .add(1, 'months')
      .endOf('month')
      .format('YYYY-MM-DD');
    dispatch({
      type: ADMIN_SUPERVISOR_CHANGE_NEXT_MONTH,
      payload: {
        start: start,
        end: end
      }
    });
    dispatch(
      getAdminSupervisorActionsAction(
        supervisorId,
        start,
        moment(end)
          .add(1, 'days')
          .format('YYYY-MM-DD')
      )
    );
  };
};

export const changePreviousMonthSupervisor = (supervisorId, actualStart, actualEnd) => {
  return async dispatch => {
    const start = moment(actualStart)
      .subtract(1, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');
    const end = moment(actualEnd)
      .subtract(1, 'months')
      .endOf('month')
      .format('YYYY-MM-DD');
    dispatch({
      type: ADMIN_SUPERVISOR_CHANGE_PREVIOUS_MONTH,
      payload: {
        start: start,
        end: end
      }
    });
    dispatch(
      getAdminSupervisorActionsAction(
        supervisorId,
        start,
        moment(end)
          .add(1, 'days')
          .format('YYYY-MM-DD')
      )
    );
  };
};

export const adminSupervisorChangeDate = date => {
  return async dispatch => {
    let from = null;
    let to = null;
    if (date[0] && date[1]) {
      from = moment(date[0]);
      to = moment(date[1]);
    }

    return dispatch({
      type: ADMIN_SUPERVISOR_CHANGE_DATE,
      payload: {
        supervisorFrom: from,
        supervisorTo: to
      }
    });
  };
};

export const deleteSupervisorAction = actionID => {
  return async dispatch => {
    dispatch(setLoading(true));
    const data = {
      id: actionID
    };
    try {
      await axios.post(api.deleteSupervisorAction, data);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
};

export const loadChecklistsInfo = () => async dispatch => {
  try {
    const { data } = await axios.get(api.getChecklistsInfo);
    dispatch({ type: SET_CHECKLISTS, payload: data.data });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const changeSupervisorComment = (supervisionID, comment, supervisorId, start, end) => {
  return async dispatch => {
    dispatch(setLoading(true));

    const body = { comment: comment };

    try {
      await axios.post(api.updateSupervision(supervisionID), body);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }

    if (supervisorId && start && end) dispatch(getAdminSupervisorActionsAction(supervisorId, start, end));
  };
};

export const changeSupervisorAction = (supervisionID, data, supervisorId, from, to) => {
  return async dispatch => {
    dispatch(setLoading(true));

    try {
      await axios.put(api.updateSupervisorAction(supervisionID), data);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }

    if (supervisorId && from && to) await dispatch(getAdminSupervisorActionsAction(supervisorId, from, to));
  };
};
