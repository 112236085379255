import React from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';

import './FormField.scss';

function Formfield(props) {
  return (
    <div>
      {props.type !== 'password' ? (
        <Input
          className="inputField"
          placeholder={props.placeholder}
          onChange={props.onChange}
          value={props.value}
          type={props.type}
          name={props.name}
        />
      ) : (
        <Input.Password
          className="inputField"
          placeholder={props.placeholder}
          onChange={props.onChange}
          value={props.value}
          name={props.name}
        />
      )}
    </div>
  );
}

Formfield.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string
};

export default Formfield;
