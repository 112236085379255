import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Helper function to parse time in HH:mm:ss format to seconds
function parseTime(timeString) {
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
}

// Helper function to add a second to the current time in seconds
function addSecond(currentTime) {
  return currentTime + 1;
}

// Helper function to format time in HH:mm:ss format
function formatTime(totalSeconds) {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
}

// Helper function to pad single digits with leading zero
function padZero(number) {
  return number.toString().padStart(2, '0');
}

const timeRegex = /^(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$/;

export default function Stopwatch({ startTime }) {
  const [time, setTime] = useState(parseTime(startTime));

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(prevTime => {
        const newTime = addSecond(prevTime);
        return newTime;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  if (!timeRegex.test(startTime)) return '';

  return (
    <span>{formatTime(time)}</span>
  );
}

Stopwatch.propTypes = {
  startTime: PropTypes.string
};
