import React from 'react';
import { Spin, Icon, Modal } from 'antd';
import './Loading.scss';
import { strings } from '../strings/StringsProvider';

function Loading() {
  return (
    <div>
      <Modal
        className="modal"
        visible={true}
        closable={false}
        centered={true}
        footer={null}
        maskStyle={{ backgroundColor: 'rgba(140, 140, 140, 0.65)' }}
      >
        <div>
          <Spin
            className="loading"
            size="large"
            indicator={<Icon type="loading" />}
            tip={strings.loading}
          />
        </div>
      </Modal>
    </div>
  );
}

export default Loading;
