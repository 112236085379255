import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import jwt from 'jsonwebtoken';
import preval from 'preval.macro';

// Local Imports
import './signin.scss';
import Formfield from '../../components/Formfield';
import { strings } from '../../strings/StringsProvider';
import { api } from '../../conf';
import { addNewMessageAction } from '../../actions/message.actions';
import { getOmamaTokenAction } from '../../actions/omama.actions';
import { getUserNotifications } from 'slices/notifications';
import { showBugButton } from 'conf/loggers';
import packageData from '../../../package.json';

const redirect = {
  omama: '/omama/plan',
  admin: '/admin',
  mentor: '/admin/users',
  supervisor: '/admin/users'
};

function SignIn() {
  const [username, setUsername] = useState('');
  const [impersonateUsername, setImpersonateUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const notifications = useSelector(state => state.notifications);

  const dispatch = useDispatch();

  const changePage = useCallback(location => () => dispatch(push(location)), [dispatch]);

  const removeOldLocalStorageItems = () => {
    localStorage.removeItem('logs');
    localStorage.removeItem('userName');
    localStorage.removeItem('role');
    localStorage.removeItem('displayRole');
    localStorage.removeItem('firstNameSearch');
    localStorage.removeItem('lastNameSearch');
    localStorage.removeItem('omama');
    localStorage.removeItem('status');
    localStorage.removeItem('statusName');
  };

  const signIn = async () => {
    setLoading(true);
    try {
      removeOldLocalStorageItems();
      showBugButton(false);
      const tfToken = localStorage.getItem(username);
      const { data } = await axios.post(
        api.signIn,
        { username, impersonateUsername, password },
        { headers: { tfToken } }
      );

      if (data.redirect) {
        changePage(data.redirect)();
      } else {
        localStorage.setItem('access-token', data.token);
        const tokenDecoded = jwt.decode(data.token);
        setLoading(false);

        dispatch(getOmamaTokenAction());
        changePage(redirect[tokenDecoded.role])();

        if (notifications.status === 'done') {
          dispatch(getUserNotifications(true));
        }
      }
    } catch (error) {
      if (error.message.includes('Request failed with status code 40')) {
        if (error.response.data.message.includes('Username not active')) {
          dispatch(addNewMessageAction(strings.userNotActive, false));
        } else if (error.response.data.message.includes('Geolocation is not allowed!')) {
          dispatch(addNewMessageAction(strings.deniedLocationLoginMessage, false));
        } else {
          dispatch(addNewMessageAction(strings.wrongPassword, false));
        }
      } else {
        // eslint-disable-next-line no-console
        console.error(error);
        dispatch(addNewMessageAction(strings.backendError, false));
      }

      setLoading(false);
    }
  };

  const changeUsername = event => {
    setUsername(event.target.value);
  };

  const changeImpersonateUsername = event => {
    setImpersonateUsername(event.target.value);
  };

  const changePassword = event => {
    setPassword(event.target.value);
  };

  const onPasswordKeyPress = async event => {
    if (event.key === 'Enter') {
      await signIn();
    }
  };

  return (
    <div className="background">
      <div className="centerDiv">
        <img className="logo" src="/images/cestavonLOGO_unofficial.png" alt="logo" />
        <div onKeyDown={onPasswordKeyPress}>
          <div className="inputDiv">
            <Formfield onChange={changeUsername} name="username" value={username} placeholder={strings.userName} />
            <Formfield
              onChange={changeImpersonateUsername}
              name="impersonateUsername"
              value={impersonateUsername}
              placeholder={strings.impersonateUsername}
            />
            <Formfield
              onChange={changePassword}
              name="password"
              value={password}
              placeholder={strings.password}
              type="password"
            />
          </div>
          <Button className="loginButton" shape="round" onClick={signIn} disabled={loading}>
            {strings.login}
          </Button>
        </div>
        <div style={{ position: 'absolute', bottom: 0 }}>
          Build Date: {preval`module.exports = new Date().toLocaleString();`}, v{packageData.version}
        </div>
      </div>
    </div>
  );
}

SignIn.propTypes = {};

export default SignIn;
