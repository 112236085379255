import React from 'react';
import { cloneDeep, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { strings } from '../strings/StringsProvider';

const tableNames = {
  '2.PP': `S-PMV 2  (do 4. ${strings.week2})`,
  '3.PP': `S-PMV 3  (5-7. ${strings.week})`,
  '4.PP': `S-PMV 4  (8-10. ${strings.week})`,
  '5.PP': `S-PMV 5  (3-4. ${strings.oneMonth})`,
  '6.PP': `S-PMV 6  (5-6. ${strings.oneMonth})`,
  '7.PP': `S-PMV 7  (7-8. ${strings.oneMonth})`,
  '8.PP': `S-PMV 8  (9-10. ${strings.oneMonth})`,
  '9.PP': `S-PMV 9  (11-12. ${strings.oneMonth})`,
  '10.PP': `S-PMV 10  (15-18. ${strings.oneMonth})`,
  '11.PP': `S-PMV 11  (26-35. ${strings.oneMonth})`,
  '12.PP': `S-PMV 12  (36-40. ${strings.oneMonth})`
};

function ScreeningAnswersStats({ screeningAnswers, screeningTestsByID, filledScreeningsByType }) {
  let screeningAnswersSlovak = {};
  let screeningAnswersSlovakOrdered = {};

  for (const key in screeningAnswers) {
    for (const otazka in screeningAnswers[key]) {
      const nazovOtazky = screeningTestsByID[key].questions.find(questionOriginal => {
        if (questionOriginal.id === otazka) return questionOriginal;
        return false;
      });
      if (!screeningAnswersSlovak[key]) screeningAnswersSlovak[key] = [];
      screeningAnswersSlovak[key].push({
        [nazovOtazky.question]: screeningAnswers[key][otazka]
      });
    }
  }

  for (const type in screeningAnswersSlovak) {
    let toSort = cloneDeep(screeningAnswersSlovak[type]);
    screeningAnswersSlovakOrdered[type] = toSort.sort((a, b) => {
      let A = a[Object.keys(a)[0]];
      let B = b[Object.keys(b)[0]];
      return B - A;
    });
  }

  const grammaticallyCorrectType = number => {
    try {
      const convertedNumber = Number(number);
      if (isNaN(convertedNumber)) return 'many';
      if (convertedNumber === 1) return 'one';
      if (convertedNumber < 5 && convertedNumber > 1) return 'few';
      return 'many';
    } catch (e) {
      return 'many';
    }
  };

  return (
    <div>
      <table border="1" style={{ margin: '15px auto' }}>
        <thead>
          <tr>
            <td className="action-answers-td" colSpan="1">
              {strings.screening1}
            </td>
            <td className="action-answers-td" colSpan="1">
              {strings.questionsBasedOnSuccess}
            </td>
          </tr>
        </thead>
        <tbody>
          {!isEmpty(screeningAnswersSlovakOrdered) &&
            Object.keys(screeningAnswers).map((type, i) => {
              return (
                <tr key={i}>
                  <th scope="row">
                    <div>{tableNames[type]}</div>
                    <div style={{ fontWeight: 'bold', color: '#41aea6' }}>
                      {strings.recorded[grammaticallyCorrectType(filledScreeningsByType[type])]}
                      <br />
                      {filledScreeningsByType[type]}{' '}
                      {strings.answers[grammaticallyCorrectType(filledScreeningsByType[type])]}
                    </div>
                  </th>
                  <td>
                    {screeningAnswersSlovakOrdered[type].map((question, i) => {
                      return (
                        <div
                          key={i}
                          style={{
                            textAlign: 'left',
                            maxWidth: '55vw',
                            display: 'flex'
                          }}
                        >
                          <span
                            style={{
                              color: '#41aea6',
                              marginRight: '10px',
                              marginLeft: '12px'
                            }}
                          >
                            {question[Object.keys(question)[0]]}
                          </span>
                          <div style={{ textAlign: 'left' }}>{Object.keys(question)[0]}</div>
                        </div>
                      );
                    })}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

ScreeningAnswersStats.propTypes = {
  screeningAnswers: PropTypes.object,
  screeningTestsByID: PropTypes.object,
  filledScreeningsByType: PropTypes.object
};

export default ScreeningAnswersStats;
