import {
  HANDLE_CLIENT_FORM,
  RESET_CLIENT_FORM,
  HANDLE_CLIENT_FORM_BUTTON,
  HANDLE_CLIENT_FORM_DATE,
  POPULATE_CLIENT_FORM,
} from '../reducers/clientForm.reducer';
import {
  HANDLE_DEACTIVATE_FORM
} from '../reducers/deactivateClient.reducer';
import axios from 'axios';

// Local imports
import { api } from '../conf';
import { setLoading } from './status.actions';



export const updateCLientFormAction = (field, value) => {
  return async (dispatch) => {
    return dispatch({
      type: HANDLE_CLIENT_FORM,
      payload: {
        field, 
        value
      }
    });
  };
};

export const updateCLientFormActionButton = (type, flag) => {
  return async (dispatch) => {
    return dispatch({
      type: HANDLE_CLIENT_FORM_BUTTON,
      payload: {
        type, 
        flag
      }
    });
  };
};

export const updateCLientFormActionDate = (field, date) => {
  return async (dispatch) => {
    return dispatch({
      type: HANDLE_CLIENT_FORM_DATE,
      payload: {
        field,
        date
      }
    });
  };
};

export const updateDeactivationClientDate = (field, date) => {
  return async (dispatch) => {
    return dispatch({
      type: HANDLE_DEACTIVATE_FORM,
      payload: {
        field,
        date
      }
    });
  };
};



export const resetClientForm = () => {
  return async (dispatch) => {
    return dispatch({
      type: RESET_CLIENT_FORM,
      payload: {}
    });
  };
};

export const populateClientFormAction = (payload) => {
  return (dispatch) => {
    return dispatch({
      type: POPULATE_CLIENT_FORM,
      payload
    });
  };
};

export const registerClientAction = (body, changePage) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axios.post(api.addNewClient, body);
      changePage('/admin/klienti');
      dispatch(setLoading(false));
      return dispatch({
        type: RESET_CLIENT_FORM,
        payload: {}
      });
    } catch (error) {
      dispatch(setLoading(false));

    }
  };
};

export const editClientAction = (body, id, photo, changePage) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      await axios.post(api.editClient, {id, set: body, photo});
      changePage(`/admin/klienti/${id}?obnovitUdaje=true`);
      dispatch(setLoading(false));
      return dispatch({
        type: RESET_CLIENT_FORM,
        payload: {}
      });
    } catch (error) {
      dispatch(setLoading(false));

    }
  };
};

export const deactivationClientForm = (field, date) => {
  return async (dispatch) => {
    return dispatch({
      type: HANDLE_DEACTIVATE_FORM,
      payload: {
        field,
        date
      }
    });
  };
};



