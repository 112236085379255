import React from 'react';
import { useSelector } from 'react-redux';
import { List, Divider } from 'antd';
import ListItem from './ListItem';

function NotificationsList() {

  const state = useSelector(state => state.notifications);

  return (
    <>
      {
        state.data.new?.length>0
        &&
        <div
          style={{fontWeight : 'bold'}}
        >
          <List
            style={{padding : '1rem'}}
            loading={state.status === 'loading'}
            dataSource={state?.data.new}
            renderItem={
              item=>(
                <ListItem
                  {...item}
                  new = {true}
                />
              )
            }
          />
        </div>
      }

      <Divider/>
      
      {
        state.data.seen?.length>0
        &&
        <div style={{opacity : '0.9'}}>
          <List
            style={{padding : '1rem'}}
            loading={state.status === 'loading'}
            dataSource={state?.data.seen}
            renderItem={
              item=>(
                <ListItem
                  {...item}
                />
              )
            }
          />
        </div>
      }
    </>
  );

}
export default NotificationsList;