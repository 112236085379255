import React from 'react';
import './PersonCard.scss';
import { Icon } from 'antd';
import PropTypes from 'prop-types';
import Base64Image from './Base64Image';
import { getDateDiff } from 'tools/date.tools';
import jwt from 'jsonwebtoken';
import classNames from 'classnames';
import { strings } from '../strings/StringsProvider';

function PersonCard({ id, photo, changePage, name, birthDate }) {
  const userRole = jwt.decode(localStorage.getItem('access-token')).role;

  const getFormatedAge = date => {
    const { months } = getDateDiff(date, new Date());
    switch (true) {
      case months === 1:
        return `${months} ${strings.oneMonth}`;
      case months === 0 || months >= 5:
        return `${months} ${strings.zeroOrFiveMonths}`;
      case months >= 2 || months <= 4:
        return `${months} ${strings.twoTillFourMonths}`;
      default:
        return `${months} ${strings.zeroOrFiveMonths}`;
    }
  };

  return (
    <div className="personCard" onClick={() => changePage()}>
      <Base64Image
        key={id}
        className="personCardImage"
        type="user"
        imageID={photo}
      />
      <h3 className="personCardImageName">{name}</h3>
      <p className="personCardImageInfo">
        {getFormatedAge(birthDate)}
      </p>
      <Icon
        className={classNames('personCardArrow', {
          greenArrow: userRole !== 'omama'
        })}
        type="right"
      />
    </div>
  );
}

PersonCard.propTypes = {
  photo: PropTypes.string,
  name: PropTypes.string,
  birthDate: PropTypes.string,
  id: PropTypes.string,
  changePage: PropTypes.func
};

export default PersonCard;
