/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Icon, Button, Dropdown, Menu, Modal, Input, TimePicker, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { getChildsWeek, getDateDiff } from 'tools/date.tools';
import { api } from '../conf';
import axios from 'axios';
import { intersection, difference } from 'lodash';
import { formatChildWeek, getDevelopmentStage } from 'tools/date.tools';

import {
  cancelClientAction,
  changeClientAction,
  getActivitiesAction,
  handleActionCheckBox,
  handleActionComment,
  postCheckedActivities
} from '../actions/clientActivities.action';

//Local imports
import { strings } from '../strings/StringsProvider';
import './Client.scss';
import CheckBox from './CheckBox';
import ClientHeader from 'components/Client/ClientHeader';
import ActivityHeader from 'components/Client/ActivityHeader';
import ActivityInfo from '../containers/omama/OmamaActivities/activityInfo';
import DevelopmentStageInfo from './DevelopmentStageInfo';
moment.locale('sk');

function Client({ action, actions, client, clients, showClientModal, showClientModalEnd }) {
  const [actionDate, setActionDate] = useState(null);
  const [actionTime, setActionTime] = useState(null);
  const [tab1ShowAll, setTab1ShowAll] = useState(false);
  const [visible, setVisible] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [activityDetailVisible, setActivityDetailVisible] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState('');
  const [activityName, setActivityName] = useState('');
  const [activityCode, setActivityCode] = useState('');
  const [activityMonth, setActivityMonth] = useState('');
  const [activityWeek, setActivityWeek] = useState('');
  const [edit, setEdit] = useState(false);
  const [month, setMonth] = useState(0);
  const [week, setWeek] = useState(0);
  const [displayAge, setDisplayAge] = useState(true);
  const [displayFilters, setDisplayFilters] = useState(false);
  const [count, setCount] = useState(0);
  const [displaySelected, setDisplaySelected] = useState('');
  const [displayAll, setDisplayAll] = useState('');
  const [startCheckedButton, setStartCheckedButton] = useState(false);
  const [clubIds, setClubIds] = useState([]);
  const [clubNames, setClubNames] = useState('');
  const [missingClient, setMissingClient] = useState({});
  const [developmentStageVisible, setDevelopmentStageVisible] = useState(false);
  const [clientDevelopmentStage, setClientDevelopmentStage] = useState('');

  const activitiesAll = useSelector(state =>
    state.clientActivities.activitiesAll.filter(activity => {
      if (activity.code) {
        return activity.code.toLowerCase() !== 'old';
      }
      return true;
    })
  );

  const loading = useSelector(state => state.status.loading);
  const startWeek = useSelector(state => state.omama.startWeek);
  const endWeek = useSelector(state => state.omama.endWeek);
  const actionID = useSelector(state => state.omama.actionID);

  const dispatch = useDispatch();

  const getClientMonthsWeeks = useCallback(
    client => {
      let b_date = new Date(client.birthDate);
      let a_date = new Date(action.date);
      let { months, weeks } = getDateDiff(b_date, a_date);

      setMonth(months);
      setWeek(weeks);
    },
    [action.date]
  );

  const getMissingClientData = useCallback(async () => {
    const { data } = await axios.get(api.getClient(action.id));
    const client = data.data;
    client.name = `${client.firstName} ${client.lastName}`;
    setMissingClient(data.data);
    getClientMonthsWeeks(data.data);
  }, [action.id, getClientMonthsWeeks]);

  useEffect(() => {
    dispatch(getActivitiesAction());
    // Initialize date and times fields for editing
    setActionDate(action.date);
    setActionTime(action.date);

    // Initialize fields for club editing modal
    if (action.differentAction === 'club') {
      setClubIds(action.clubIds);
      setClubNames(action.clubNames);

      //set states to open list of all activities on screen show
      if (action.checkedActivities.length === 0) {
        setDisplaySelected('');
        setTab1ShowAll(true);
        setDisplayAge(false);
        setDisplayFilters(true);
        setDisplayAll(strings.all1);
        setCount(0);
        setEdit(false);
        setStartCheckedButton(true);
        setActivityMonth('');
      } else {
        setDisplayAge(false);
        setDisplayFilters(false);
        setDisplaySelected(strings.chosen);
        setDisplayAll('');
        setCount(0);
        setEdit(true);
        setStartCheckedButton(true);
      }
    }

    if (!client) {
      getMissingClientData();
      return;
    }

    getClientMonthsWeeks(client);
  }, [
    action.checkedActivities.length,
    action.clubIds,
    action.clubNames,
    action.date,
    action.differentAction,
    client,
    dispatch,
    getClientMonthsWeeks,
    getMissingClientData
  ]);

  const closeActivityDetail = () => {
    setActivityDetailVisible(false);
    setSelectedActivity('');
  };

  const selectActivity = id => () => {
    setActivityDetailVisible(true);
    setSelectedActivity(id);
  };

  const showDevelopmentStageDetail = (birthDate, actionDate) => {
    const developmentStage = getDevelopmentStage(birthDate, actionDate);

    setDevelopmentStageVisible(true);
    setClientDevelopmentStage(developmentStage);
  };

  const developmentStageDetailClose = () => {
    setDevelopmentStageVisible(false);
    setClientDevelopmentStage('');
  };

  const menuDelete = (
    <Menu onClick={({ key }) => cancelAction(key)}>
      <Menu.Item key="Lekciu zrušil klient">
        {action.differentAction === 'club' ? `${strings.preschoolClub} ` : `${strings.lesson3} `}{' '}
        {strings.clientCanceled}
      </Menu.Item>
      <Menu.Item key="Natvrdo odstrániť lekciu">
        {strings.permanentlyDelete}
        {action.differentAction === 'club'
          ? ` ${strings.preschoolClub.toLowerCase()}`
          : ` ${strings.lesson3.toLowerCase()}`}
      </Menu.Item>
    </Menu>
  );

  const cancelAction = key => {
    if (key === 'Lekciu zrušil klient') {
      showCancelModal();
    } else {
      cancelActionClient(key);
    }
  };

  const cancelActionClient = key => {
    if (action.differentAction === 'club') {
      const arrActions = Object.values(actions);
      arrActions.forEach(e => {
        if (e.differentAction === 'club' && e.date === action.date && e.clubIds.join() === action.clubIds.join()) {
          dispatch(cancelClientAction(e, key, startWeek, endWeek));
          showClientModal('', e._id, 'client');
        }
      });
      return;
    }
    dispatch(cancelClientAction(action, key, startWeek, endWeek));
    showClientModal('', action._id, 'client');
  };

  const changeComment = action => e => {
    if (action[0]) {
      action.forEach(el => {
        dispatch(handleActionComment(el._id, e.target.value));
      });
    }
    dispatch(handleActionComment(actionID, e.target.value));
  };

  const showCancelModal = () => {
    setCancelModalVisible(true);
  };

  const handleCancelModalCancel = () => {
    setCancelModalVisible(false);
  };

  const showTimeModal = () => {
    setVisible(true);
  };

  const handleModalCancel = () => {
    setVisible(false);
  };

  const changeStateActionDate = value => {
    if (value && value.target && value.target.value !== undefined) {
      setActionDate(value.target.value);
    } else {
      setActionDate(value);
    }
  };

  const changeStateActionTime = value => {
    if (value && value.target && value.target.value !== undefined) {
      setActionTime(value.target.value);
    } else {
      setActionTime(value);
    }
  };

  const getClubAction = clientId => {
    if (!actions || !action) {
      return null;
    }

    const arrActions = Object.values(actions);
    return arrActions.find(arrAction => {
      return arrAction.id === clientId && arrAction.date === action.date && arrAction.clubNames === action.clubNames;
    });
  };

  const changeAction = () => {
    setVisible(false);

    const newActionDate = moment(actionDate).format('YYYY-MM-DD');
    const newActionTime = moment(actionTime).format('HH:mm');
    const date = moment(newActionDate + 'T' + newActionTime).format();
    const start = moment(newActionDate)
      .startOf('isoWeek')
      .format('YYYY-MM-DD');
    const end = moment(newActionDate)
      .endOf('isoWeek')
      .format('YYYY-MM-DD');

    // Compare previous and new clubIds in order
    // to properly update/remove/add corresponding omama_actions
    if (action.differentAction === 'club') {
      const toRemoveClientIds = difference(action.clubIds, clubIds);
      const toAddClientIds = difference(clubIds, action.clubIds);
      const toUpdateClientIds = intersection(action.clubIds, clubIds);

      toUpdateClientIds.forEach(id => {
        const action = getClubAction(id);
        dispatch(
          changeClientAction(
            action._id,
            {
              clubIds: clubIds,
              clubNames: clubNames,
              date
            },
            start,
            end
          )
        );
      });

      toRemoveClientIds.forEach(id => {
        const action = getClubAction(id);
        if (action) {
          axios.post(api.deleteAction, {
            id: action._id
          });
        }
      });

      toAddClientIds.forEach(id => {
        axios.post(api.addNewAction, {
          ...action,
          clubIds: clubIds,
          clubNames: clubNames,
          date,
          id: [id]
        });
      });
    } else {
      dispatch(changeClientAction(action._id, { date }, start, end));
    }
  };

  const checkNewActivities = () => {
    let valid;
    const actual = action.checkedActivities;
    const original = action.checkedActivitiesDefault;
    valid = actual?.toString() !== original?.toString();
    return valid;
  };

  const validSave = () => {
    return !!checkNewActivities();
  };

  const validNext = () => {
    let valid;
    if (action.checkedActivities.length === 0) {
      valid = false;
    } else {
      if (checkNewActivities()) {
        valid = false;
      } else {
        return true;
      }
    }
    return valid;
  };

  const onChangeActivityName = e => setActivityName(e.target.value);

  const onChangeActivityCode = e => setActivityCode(e.target.value);

  const onChangeActivityMonth = e => setActivityMonth(e.target.value);

  const onChangeActivityWeek = e => setActivityWeek(e.target.value);

  const filterActivitiesByName = (activities, filter) => {
    return activities.filter(act => {
      return act.name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toUpperCase()
        .includes(
          filter
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toUpperCase()
        );
    });
  };

  const filterActivitiesByCode = (activities, filter) => {
    return activities.filter(act => {
      let modifiedFilter =
        filter.substring(filter.length - 1) === '.' ? filter.substring(0, filter.length - 1) : filter;
      return (
        act.code
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toUpperCase() ===
          filter
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toUpperCase() ||
        act.code
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toUpperCase()
          .startsWith(
            modifiedFilter
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toUpperCase() + '.'
          )
      );
    });
  };

  const validateWeek = () => {
    if (activityWeek === '') return '';
    if (activityWeek > 4) setActivityWeek(4);
    if (activityWeek < 1) setActivityWeek(1);
    return activityWeek;
  };

  const validateMonth = () => {
    if (activityMonth === '') return '';
    if (activityMonth < 0) setActivityMonth(0);
    return activityMonth;
  };

  const filterActivitiesByMonth = (activities, filter) => {
    filter = Number(filter);
    return activities.filter(e => e.mesiac === filter);
  };

  const filterActivitiesByWeek = (activities, filter) => {
    filter = Number(filter);
    return activities.filter(e => e.tyzden === filter);
  };

  const renderActivities = (showAllActivities, setShowAllActivities) => {
    const isClub = action.differentAction === 'club';
    const childsWeek = getChildsWeek((client || missingClient).birthDate, action.date.split('T')[0]);
    const filteredActivitiesByName = filterActivitiesByName(activitiesAll, activityName);
    const filterByWeek = activitiesAll.filter(act => {
      let week = act.tyzden;
      if (act.mesiac > 0) {
        week = act.mesiac * 4 + act.tyzden;
      }
      let weekNum = Math.floor(week);
      return weekNum === childsWeek + count;
    });

    let isTrue = false;
    let countMonth = '';
    if (action.differentAction === 'club') {
      isTrue = true;
      showAllActivities = true;
      countMonth = 36;
    }

    let activities = showAllActivities ? filteredActivitiesByName : filterByWeek;
    activities = activityCode === '' ? activities : filterActivitiesByCode(activities, activityCode);
    activities = activityMonth === '' ? activities : filterActivitiesByMonth(activities, activityMonth);
    activities = activityWeek === '' ? activities : filterActivitiesByWeek(activities, activityWeek);
    showAllActivities = false;

    if (edit) {
      activities = activitiesAll.filter(activity => !!action.checkedActivities.find(item => item === activity._id));
    }

    if (isClub) {
      activities = activities.filter(act => {
        return Number(act.mesiac) >= 37;
      });
    }

    activities.sort((a, b) => {
      if (a.name === 'Úvodná diskusia') {
        return -1;
      } else {
        return Number(a.code) - Number(b.code);
      }
    });

    return (
      <div className="dropdownActivities">
        <Button
          shape="round"
          icon="unordered-list"
          className={isClub && !displaySelected ? 'activities-button clickedButton' : 'activities-button'}
          onClick={() => {
            setDisplaySelected('');
            setShowAllActivities(true);
            setDisplayAge(false);
            setDisplayFilters(true);
            setDisplayAll(strings.all1);
            setCount(0);
            setEdit(false);
            setStartCheckedButton(true);
          }}
        >
          {/* All activities */}
        </Button>
        {!isClub && (
          <Button
            shape="round"
            icon="filter"
            className={startCheckedButton ? 'activities-button' : 'clickedButton'}
            onClick={() => {
              setDisplaySelected('');
              setShowAllActivities(isTrue);
              setMonth(filterByWeek[0] ? filterByWeek[0].mesiac : month);
              setWeek(filterByWeek[0] ? filterByWeek[0].tyzden : week);
              setDisplayAge(true);
              setDisplayFilters(false);
              setEdit(false);
              setDisplayAll('');
              setStartCheckedButton(false);
              setActivityMonth(countMonth);
            }}
          >
            {/* Current age activities */}
          </Button>
        )}
        <Button
          shape="round"
          onClick={() => {
            setDisplayAge(false);
            setDisplayFilters(false);
            setDisplaySelected(strings.chosen);
            setDisplayAll('');
            setCount(0);
            setEdit(true);
            setStartCheckedButton(true);
          }}
          icon="check"
          className={isClub && displaySelected ? 'activities-button clickedButton' : 'activities-button'}
        >
          {/* Checked activities */}
        </Button>
        <span className="activitiesSign">
          {displayAll}
          {displayAge ? strings.givenAge : ''}
          {displaySelected}
        </span>
        <br />
        <br />
        {displayFilters && (
          <div style={{ paddingBottom: '20px' }}>
            <Input
              className="activities-name"
              placeholder={strings.nameOfActivity}
              value={activityName}
              onChange={onChangeActivityName}
            />
            <Input
              className="activities-code"
              placeholder={strings.numberOfLecture}
              value={activityCode}
              onChange={onChangeActivityCode}
            />
            <br />
            <br />
            <Input
              type="number"
              placeholder={strings.month}
              className="number-input"
              value={validateMonth()}
              onChange={onChangeActivityMonth}
            />
            <Input
              type="number"
              placeholder={strings.week}
              className="number-input"
              value={validateWeek()}
              onChange={onChangeActivityWeek}
            />
          </div>
        )}
        {activities.map(activity => (
          <div className="keyos" key={activity._id}>
            <div className="activityName" onClick={selectActivity(activity._id)}>
              <span className="monthWeek">{`${activity.code} - ${activity.mesiac}m/${activity.tyzden}t - `}</span>{' '}
              {activity.name}
            </div>
            <CheckBox
              activityId={activity._id}
              actionId={action._id}
              check={!!action.checkedActivities.find(item => item === activity._id)}
              handleActionCheckBox={(...props) => dispatch(handleActionCheckBox(...props))}
            />
          </div>
        ))}
        {activities.length === 0 && (
          <div style={{ textAlign: 'center', fontStyle: 'italic' }}>
            {displaySelected === strings.chosen ? strings.notSelectedActivites : strings.notFoundActivites}
          </div>
        )}
      </div>
    );
  };

  const minusOne = () => {
    if (month === 0 && week === 1) {
      return;
    } else {
      if (week > 1) {
        setWeek(week - 1);
      } else {
        setWeek(4);
        setMonth(month - 1);
      }

      setCount(count - 1);
    }
  };

  const plusOne = () => {
    if (week < 4) {
      setWeek(week + 1);
    } else {
      setWeek(1);
      setMonth(month + 1);
    }

    setCount(count + 1);
  };

  const renderSelectOptions = () => {
    const { Option } = Select;
    const children = [];
    Object.keys(clients).forEach(key => {
      const client = clients[key];
      if (client.predskolskyKlub) {
        children.push(
          <Option className="selectRow" key={client._id}>
            {client.name}
          </Option>
        );
      }
    });
    return children;
  };

  const handleClientsChange = value => {
    const updatedClubIds = [];
    const updatedClubNames = [];
    value.forEach(item => {
      updatedClubIds.push(item.key);
      updatedClubNames.push(item.label);
    });
    setClubIds(updatedClubIds);
    setClubNames(updatedClubNames.join(', '));
  };

  const birthDates = [];

  const isClub = action.differentAction === 'club';
  const canSaveClub = clubIds.length > 0;

  if (isClub) {
    const clientsArr = Object.values(clients);

    action.clubIds.forEach(id => {
      const client = clientsArr.find(client => client._id === id);
      // If client missing, display at least their id as name
      birthDates.push(
        client || {
          _id: id
        }
      );
    });
  }

  const actionVals = Object.values(actions);
  const actionIDs = actionVals.filter(e => {
    if (action.differentAction === 'club') {
      if (e.differentAction === 'club' && e.clubIds.join() === action.clubIds.join()) {
        return e._id;
      }
    }
    return false;
  });

  return (
    <div className="clientsActivities">
      <ClientHeader
        isClub={isClub}
        client={client || missingClient}
        showClientModal={showClientModal}
        action={action}
        birthDates={birthDates}
        formatChildWeek={formatChildWeek}
        showDevelopmentStageDetail={showDevelopmentStageDetail}
      />
      <Modal
        centered
        title={strings.developmentStageDescription}
        visible={developmentStageVisible}
        onCancel={developmentStageDetailClose}
        onOk={developmentStageDetailClose}
        footer={null}
      >
        {clientDevelopmentStage !== '' && <DevelopmentStageInfo type={clientDevelopmentStage} />}
      </Modal>
      <div className="comment">
        <span>
          {action.comment ? (isClub ? strings.preschoolClubCanceled : strings.lessonCanceled) + action.comment : ''}
        </span>
      </div>
      <div className="activities">
        <div className="clientButtons">
          <Button shape="round" onClick={showTimeModal}>
            {!isClub ? strings.changeTime : strings.changePreschoolClub}
          </Button>
          <Modal
            title={!isClub ? strings.changeLessonTime : strings.changePreschoolClub}
            visible={visible}
            onOk={() => changeAction()}
            okText={strings.confirm}
            okButtonProps={{
              disabled: !(typeof actionDate === 'string') || actionTime === null || (isClub && !canSaveClub)
            }}
            onCancel={handleModalCancel}
            cancelText={strings.cancelText}
          >
            <div className="client-pickerTitle">{strings.newDate}</div>
            <div className="client-changeDate">
              <Input
                type="date"
                value={moment(actionDate).format('YYYY-MM-DD')}
                onChange={changeStateActionDate}
                className="picker"
              />
            </div>
            <br />
            <div className="client-pickerTitle">{strings.newTime}</div>
            <div className="client-changeDate">
              <TimePicker
                className="client-picker"
                type="time"
                format={'HH:mm'}
                minuteStep={15}
                onChange={changeStateActionTime}
                value={actionTime && moment(actionTime)}
                placeholder={strings.chooseTime}
              />
            </div>
            <br />
            {isClub && (
              <>
                <div className="client-pickerTitle">{strings.clients}</div>
                <Select
                  mode="multiple"
                  defaultValue={clubIds.map(id => ({
                    key: id,
                    label: clients[id] ? clients[id].name : id
                  }))}
                  labelInValue
                  dropdownClassName="selectMenu"
                  className="SelectInputMulti"
                  placeholder={strings.chooseClients}
                  onChange={handleClientsChange}
                >
                  {renderSelectOptions()}
                </Select>
              </>
            )}
          </Modal>
          <Modal
            title={strings.activityDetail}
            visible={activityDetailVisible}
            footer={null}
            onCancel={closeActivityDetail}
            onOk={closeActivityDetail}
          >
            {selectedActivity && (
              <ActivityInfo
                activity={activitiesAll.find(a => a._id === selectedActivity)}
                goBack={closeActivityDetail}
                mobileActivity={true}
              />
            )}
          </Modal>
          <Dropdown trigger={['click']} overlay={menuDelete} placement="bottomCenter" className="clientDropdown">
            <div>
              {!isClub ? strings.deleteLesson : strings.deletePreschoolClub}
              <Icon type="down" style={{ fontSize: '20px' }} />
            </div>
          </Dropdown>
          <Modal
            title={!isClub ? strings.deleteLesson : strings.deletePreschoolClub}
            visible={cancelModalVisible}
            onOk={() => cancelActionClient(strings.lessonCanceledClient)}
            okText={strings.remove}
            onCancel={handleCancelModalCancel}
            cancelText={strings.back}
          >
            <div className="activityHeadline">{strings.comment}</div>
            <textarea
              onChange={changeComment(actionIDs)}
              maxLength="300"
              value={action.comment ? action.comment : ''}
            />
            <div className="charCount">
              {action.comment ? action.comment.length : 0}
              /300
            </div>
          </Modal>
        </div>
        {!isClub && (
          <ActivityHeader minusOne={minusOne} plusOne={plusOne} displayAge={displayAge} month={month} week={week} />
        )}
        {(client || missingClient).birthDate && renderActivities(tab1ShowAll, setTab1ShowAll)}

        {/* Buttons */}
        <div className="bottomButtons">
          <div className="clientButtonsFixed">
            <Button
              onClick={() => {
                //Fixed saving of selected activities in club activity
                if (isClub) {
                  const arrActions = Object.values(actions);
                  arrActions.forEach(e => {
                    if (e.differentAction === 'club' && e.clubIds.join() === action.clubIds.join()) {
                      e.checkedActivities = action.checkedActivities;
                      dispatch(postCheckedActivities(e, startWeek, endWeek));
                    }
                  });
                  return;
                }
                dispatch(postCheckedActivities(action, startWeek, endWeek));
              }}
              shape="round"
              disabled={!validSave() || loading}
            >
              {strings.save}
            </Button>
            <Button onClick={() => showClientModalEnd()} shape="round" disabled={!validNext()}>
              {strings.evaluate} {isClub ? strings.preschoolClub.toLowerCase() : strings.lesson2}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

Client.propTypes = {
  action: PropTypes.object,
  actions: PropTypes.object.isRequired,
  showClientModal: PropTypes.func,
  date: PropTypes.string,
  client: PropTypes.object,
  clients: PropTypes.object.isRequired,
  activities: PropTypes.arrayOf(PropTypes.object),
  activitiesType1: PropTypes.arrayOf(PropTypes.object),
  activitiesType2: PropTypes.arrayOf(PropTypes.object),
  activitiesType3: PropTypes.arrayOf(PropTypes.object),
  activitiesType4: PropTypes.arrayOf(PropTypes.object),
  activitiesType5: PropTypes.arrayOf(PropTypes.object),
  activitiesType6: PropTypes.arrayOf(PropTypes.object),
  showClientModalEnd: PropTypes.func,
  startWeek: PropTypes.string,
  endWeek: PropTypes.string,
  loading: PropTypes.bool,
  comment: PropTypes.string
};

export default Client;
