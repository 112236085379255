import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Icon, Tooltip } from 'antd';
import { strings } from 'strings/StringsProvider';

import './OmamaCheckbox.scss';

function OmamaCheckbox({ id, name, wasChecked, isChecked, updateOmamaIds, showRentedAccessoryHistory, newAccessory }) {
  let omamaCheckboxClassname = '';
  if (!wasChecked && !isChecked) omamaCheckboxClassname = 'omama-checkbox';
  else if (wasChecked && isChecked) omamaCheckboxClassname = 'omama-checkbox-checked';
  else if (!wasChecked && isChecked) omamaCheckboxClassname = 'omama-checkbox-add';
  else if (wasChecked && !isChecked) omamaCheckboxClassname = 'omama-checkbox-remove';

  return (
    <div className={omamaCheckboxClassname} onClick={() => updateOmamaIds(id, !isChecked, wasChecked)}>
      <Checkbox checked={isChecked} />
      <div className="omama-checkbox-name">{name}</div>
      <div
        onClick={e => {
          e.stopPropagation();
          showRentedAccessoryHistory(id, name);
        }}
        className="info-icon-container"
      >
        {!newAccessory && (
          <Tooltip title={strings.rentedAccessoryHistoryInfo}>
            <Icon type="history" className="info-icon" />
          </Tooltip>
        )}
      </div>
    </div>
  );
}

OmamaCheckbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  wasChecked: PropTypes.bool,
  isChecked: PropTypes.bool,
  updateOmamaIds: PropTypes.func,
  showRentedAccessoryHistory: PropTypes.func,
  newAccessory: PropTypes.bool
};

export default OmamaCheckbox;
