export const HANDLE_SUPERVISION_FORM = 'HANDLE_SUPERVISION_FORM';
export const RESET_SUPERVISION_FORM = 'RESET_SUPERVISION_FORM';
export const POPULATE_SUPERVISION_FORM = 'POPULATE_SUPERVISION_FORM';

export const initialState = {
  data:{
    omamaID: '',
    supervisorID: '',
    omama: null,
    client: null,
    supervisor: null,
    lessonsCount: null,
    supervisionIndex: null,
    community: '',
    lastSupervisionTarget: '',
    targetToNextSupervision: '',
    checkedActivities: [],
    status: '',
    questions: {}
  },
  form: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
      /* eslint-disable */
    case HANDLE_SUPERVISION_FORM:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.field]: action.payload.value
        }
      };
    case RESET_SUPERVISION_FORM:
      return {
        ...state,
        data: initialState.data
      };
    case POPULATE_SUPERVISION_FORM:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data
        },
        form: action.payload.definition
      };  
    default:
      return state;
    /* eslint-enable */
  }
};