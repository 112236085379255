import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Autocomplete.scss';
import { strings } from '../strings/StringsProvider';

function Autocomplete(props) {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userInput, setUserInput] = useState(props.formValue);

  const changeHandler = e => {
    const { suggestions, fieldChange } = props;
    const userInput = e.currentTarget.value;

    const filteredSuggestions = suggestions.filter(
      suggestion => suggestion.toLowerCase().indexOf(userInput.toLowerCase()) === 0
    );

    setFilteredSuggestions(filteredSuggestions);
    setShowSuggestions(true);
    setUserInput(e.currentTarget.value);
    fieldChange(e.currentTarget.value);
  };

  const clickHandler = e => {
    const { fieldChange } = props;

    setFilteredSuggestions([]);
    setShowSuggestions(false);
    setUserInput(e.currentTarget.innerText);
    fieldChange(e.currentTarget.innerText);
  };

  let suggestionsListComponent;
  if (showSuggestions && userInput) {
    if (filteredSuggestions.length) {
      suggestionsListComponent = (
        <div className="suggestions-container">
          <ul className="suggestions">
            {filteredSuggestions.map(suggestion => {
              return (
                <li key={suggestion} onClick={clickHandler}>
                  {suggestion}
                </li>
              );
            })}
          </ul>
        </div>
      );
    } else {
      suggestionsListComponent = (
        <div className="no-suggestions">
          <em>{strings.wrongLocation}</em>
        </div>
      );
    }
  }

  return (
    <>
      <input
        className={`mobileInput ${props.className}`}
        type="text"
        placeholder={props.placeholder || 'Mesto bydliska'}
        value={props.formValue}
        onChange={changeHandler}
        name="address"
        autoComplete="off"
        style={props.style}
      />
      {suggestionsListComponent}
    </>
  );
}

Autocomplete.propTypes = {
  style: PropTypes.object,
  formValue: PropTypes.string,
  placeholder: PropTypes.string,
  suggestions: PropTypes.arrayOf(PropTypes.string),
  fieldChange: PropTypes.func,
  className: PropTypes.string
};

export default Autocomplete;
