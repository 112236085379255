import React from 'react';
import PropTypes from 'prop-types';

function OmamaProfileStat(props) {

  const StatsContainerStyle = {
    display : 'flex',
    flexWrap : 'wrap',
  };

  const StatsNameStyle = {
    color : '#41aea6',
  };

  const StatsContentStyle = {
    marginLeft : '1rem',
  };

  return (
    <div style={StatsContainerStyle}>
      <div style={StatsNameStyle}>
        {props.title}
      </div>
      <div style={StatsContentStyle}>
        {props.content}
      </div>
    </div>
  );
}

OmamaProfileStat.propTypes = {
  title : PropTypes.string,
  content : PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
};

export default OmamaProfileStat;

