import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from 'antd';
import PropTypes from 'prop-types';
import { goBack } from 'connected-react-router';
import { VerticalTimeline } from 'react-vertical-timeline-component';
import { strings } from 'strings/StringsProvider';
import { getMilestonesData, resetMilestonesData } from 'actions/admin.actions';
import { isEmpty } from 'lodash';
import axios from 'axios';
import uuidv1 from 'uuid/v1';
import { api } from 'conf';
import ClientMilestone from './ClientMilestone';

import 'react-vertical-timeline-component/style.min.css';
import './ClientMilestones.scss';

function ClientMilestones({ client, interNDA, preschoolScreening }) {
  const [clientMilestones, setClientMilestones] = useState(null);
  const [currentMilestonesData, setCurrentMilestonesData] = useState({});

  const loadedMilestones = useSelector(state => state.admin.milestonesData);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(loadedMilestones) && !isEmpty(client)) {
      dispatch(getMilestonesData(client._id));
    } else {
      setCurrentMilestonesData(loadedMilestones);
    }
  }, [dispatch, client, loadedMilestones]);

  useEffect(() => {
    return () => {
      dispatch(resetMilestonesData());
    };
  }, [dispatch]);

  const getBirthData = useCallback(() => {
    let birthData = client.city;

    if (!isEmpty(client.birthWeight)) {
      birthData += `\n${strings.weight}: ${client.birthWeight}`;
    }
    if (!isEmpty(client.birthLength)) {
      birthData += `\n${strings.length}: ${client.birthLength}`;
    }

    return birthData;
  }, [client.city, client.birthWeight, client.birthLength]);

  useEffect(() => {
    if (!isEmpty(client)) {
      const milestones = [];
      milestones.push({
        id: 'birth',
        title: strings.clientBirth,
        subtitle: getBirthData(),
        date: client.birthDate,
        color: 'rgb(233, 30, 99)',
        iconType: 'baby.png'
      });
      milestones.push({
        id: 'programStart',
        title: strings.programStart,
        subtitle: '',
        date: client.inProgramFromDate,
        color: 'rgb(16, 204, 82)',
        iconType: 'program_start.png'
      });
      if (interNDA) {
        milestones.push({
          id: 'interNDAMeasurement',
          title: strings.interNDAMeasurement,
          subtitle: '',
          date: interNDA.date,
          color: 'rgb(33, 150, 243)',
          iconType: 'measurement.png'
        });
      }
      if (preschoolScreening) {
        milestones.push({
          id: 'preschoolScreening',
          title: strings.preschoolScreening,
          subtitle: '',
          date: preschoolScreening.date,
          color: 'rgb(33, 150, 243)',
          iconType: 'measurement.png'
        });
      }
      if (client.deactivatedOnDate) {
        milestones.push({
          id: 'programEnd',
          title: strings.programEnd,
          subtitle: '',
          date: client.deactivatedOnDate,
          color: 'rgb(233, 30, 99)',
          iconType: 'program_end.png'
        });
      }
      if (
        client.infantSchoolStartDate &&
        client.infantSchoolStartDate !== '' &&
        client.infantSchoolStartDate !== 'Invalid date'
      ) {
        milestones.push({
          id: 'kindergardenStart',
          title: strings.kindergardenStart,
          subtitle: client.institutionName,
          date: client.infantSchoolStartDate,
          color: 'rgb(16, 204, 82)',
          iconType: 'kindergarten.png'
        });
      }
      if (
        client.elementarySchoolStartDate &&
        client.elementarySchoolStartDate !== '' &&
        client.elementarySchoolStartDate !== 'Invalid date'
      ) {
        milestones.push({
          id: 'elementarySchoolStart',
          title: strings.elementarySchoolStart,
          subtitle: client.elementarySchoolName,
          date: client.elementarySchoolStartDate,
          color: 'rgb(16, 204, 82)',
          iconType: 'elementary_school_start.png'
        });
      }
      if (
        client.elementarySchoolEndDate &&
        client.elementarySchoolEndDate !== '' &&
        client.elementarySchoolEndDate !== 'Invalid date'
      ) {
        milestones.push({
          id: 'elementarySchoolEnd',
          title: strings.elementarySchoolEnd,
          subtitle: client.elementarySchoolName,
          date: client.elementarySchoolEndDate,
          color: 'rgb(233, 30, 99)',
          iconType: 'elementary_school_end.png'
        });
      }

      const sortedMilestones = milestones.sort((a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });

      setClientMilestones(sortedMilestones);
    }
  }, [dispatch, client, interNDA, preschoolScreening, getBirthData]);

  const updateClientMilestones = async (clientId, updatedMilestones, changePhoto) => {
    try {
      if (changePhoto.addPhoto) {
        const imageId = uuidv1();
        updatedMilestones[changePhoto.milestone].photo = imageId;
        const body = {
          clientId: changePhoto.clientId,
          photo: changePhoto.newPhoto,
          imageId
        };
        await axios.post(api.addMilestonePhoto, body);
      } else if (changePhoto.removePhoto) {
        delete updatedMilestones[changePhoto.milestone].photo;
        const body = {
          clientID: changePhoto.clientId,
          photoID: changePhoto.oldPhoto
        };
        await axios.post(api.removeMilestonePhoto, body);
      } else if (changePhoto.changePhoto) {
        const imageId = uuidv1();
        updatedMilestones[changePhoto.milestone].photo = imageId;
        let body = {};
        body = {
          clientID: changePhoto.clientId,
          photoID: changePhoto.oldPhoto
        };
        await axios.post(api.removeMilestonePhoto, body);

        body = {
          clientId: changePhoto.clientId,
          photo: changePhoto.newPhoto,
          imageId
        };
        await axios.post(api.addMilestonePhoto, body);
      }
      await axios.put(api.updateClientMilestones, {
        clientId,
        updatedMilestones
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const updateMilestones = async (updatedMilestone, changePhoto) => {
    const updatedMilestones = {
      ...currentMilestonesData,
      ...updatedMilestone
    };

    changePhoto.clientId = client._id;
    await updateClientMilestones(client._id, updatedMilestones, changePhoto);
    setCurrentMilestonesData(updatedMilestones);
  };

  return (
    <div>
      <div className="client-milestones">
        <div className="goBack" onClick={() => dispatch(goBack())}>
          <Icon type="left" />
          <span>{strings.back1}</span>
        </div>
        {clientMilestones && (
          <VerticalTimeline lineColor="#D6E1E0" layout="1-column-left">
            {clientMilestones.map(milestone => {
              return (
                <ClientMilestone
                  milestoneInfo={milestone}
                  milestoneData={currentMilestonesData}
                  key={milestone.id}
                  birthDate={client.birthDate}
                  clientID={client._id}
                  preschoolScreening={preschoolScreening}
                  interNDA={interNDA}
                  updateMilestones={updateMilestones}
                />
              );
            })}
          </VerticalTimeline>
        )}
      </div>
    </div>
  );
}

ClientMilestones.propTypes = {
  client: PropTypes.object,
  interNDA: PropTypes.object,
  preschoolScreening: PropTypes.object
};

export default ClientMilestones;
