export const GET_MENU = 'GET_MENU';

const initialState = {
  showMenu: false
};

export default (state = initialState, action) => {
  switch (action.type) {
      case GET_MENU:
        return {
          ...state,
          showMenu: action.payload
        };
      default:
        return state;
  }
};
