import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Descriptions } from 'antd';
import jwt from 'jsonwebtoken';
import { strings } from '../../../strings/StringsProvider';
import { getAdminUser } from '../../../actions/admin.actions';
import EditProfile from '../../../components/EditProfile';
import UserInfoPanel from './UserInfoPanel';
import Button from 'components/Button';

// Local imports
import './AdminAdmin.scss';

function AdminAdmin() {
  const [edit, setEdit] = useState(false);
  const userRole = jwt.decode(localStorage.getItem('access-token')).role;
  const userName = jwt.decode(localStorage.getItem('access-token')).username;
  const { userId } = useParams();
  const history = useHistory();

  const user = useSelector(state => state.admin.oneUser);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdminUser(userId));
  }, [dispatch, userId]);

  const fetchUserAfterUpdate = () => {
    dispatch(getAdminUser(userId));
  };

  const switchEdit = flag => {
    setEdit(flag);
  };

  if (user._id) {
    return (
      <>
        {edit && <EditProfile role={user.role} bckButton={switchEdit} fetchUser={fetchUserAfterUpdate} />}
        {!edit && (
          <div className="admin-userDetail">
            <UserInfoPanel user={user} userRole={userRole} userName={userName} switchEdit={switchEdit} />
            <div className="admin-generalUserInfo doNotShowOnMobile">
              {/* <h3 style={{ cursor: 'pointer' }} onClick={history.goBack}>
                <div className="back-button">
                  <Icon type="left" /> {strings.back1}
                </div>
              </h3> */}
              <div className="admin-omama-submenu">
                <Button
                  type="secondary"
                  icon={<img src="/images/Icons/go-back.svg" alt="back-button" />}
                  label={strings.goBack}
                  className="go-back-button"
                  onClick={history.goBack}
                />
              </div>
              <Descriptions title={strings.generalKnowledge} bordered border size="small">
                <Descriptions.Item label={`${strings.phoneNumber}:`}>{user.phoneNumber}</Descriptions.Item>
                <Descriptions.Item label={`${strings.email}:`}>{user.email}</Descriptions.Item>
              </Descriptions>
            </div>
          </div>
        )}
      </>
    );
  } else return null;
}

export default AdminAdmin;
