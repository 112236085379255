/* eslint-disable no-unused-vars */
import { groupBy, keyBy, isEmpty } from 'lodash';
import { isDebuggerStatement } from '@babel/types';
import moment from 'moment';
// WATCHOUT
moment.locale('sk');

export const GET_ADMIN_ACTIVITIES = 'GET_ADMIN_ACTIVITIES';
export const GET_ADMIN_CLIENTS = 'GET_ADMIN_CLIENTS';
export const SET_ACTIVE_ACTIVITY_GROUP = 'SET_ACTIVE_ACTIVITY_GROUP';
export const GET_SCREENINGS_FOR_CLIENT = 'GET_SCREENINGS_FOR_CLIENT';
export const GET_SCREENING_QUESTION_DETAILS = 'GET_SCREENING_QUESTION_DETAILS';
export const GET_INTERNDA_QUESTIONS = 'GET_INTERNDA_QUESTIONS';
export const GET_CLIENT_INTERNDA = 'GET_CLIENT_INTERNDA';
export const GET_ADMIN_OMAMA_CLIENTS = 'GET_ADMIN_OMAMA_CLIENTS';
export const GET_ADMIN_OMAMA_ACTIONS = 'GET_ADMIN_OMAMA_ACTIONS';
export const GET_ADMIN_CLIENT_ACTIONS = 'GET_ADMIN_CLIENT_ACTIONS';
export const GET_ADMIN_CLIENT_ACTIONS_COUNT = 'GET_ADMIN_CLIENT_ACTIONS_COUNT';
export const GET_ADMIN_CLIENT = 'GET_ADMIN_CLIENT';
export const ADMIN_SAVE_CLIENT = 'ADMIN_SAVE_CLIENT';
export const ADMIN_SAVE_FILTEREDCLIENT = 'ADMIN_SAVE_FILTEREDCLIENT';
export const GET_ADMIN_USER = 'GET_ADMIN_USER';
export const ADMIN_CLEAR_CLIENT = 'ADMIN_CLEAR_CLIENT';
export const CLEAN_ADMIN_ACTIVITIES = 'CLEAN_ADMIN_ACTIVITIES';
export const HANDLE_DEACTIVATION_DATE = 'HANDLE_DEACTIVATION_DATE';
export const HANDLE_DEACTIVATION_REASON = 'HANDLE_DEACTIVATION_REASON';
export const GET_MILESTONES_DATA = 'GET_MILESTONES_DATA';
export const RESET_MILESTONES_DATA = 'RESET_MILESTONES_DATA';
export const GET_ACCESSORIES = 'GET_ACCESSORIES';
export const GET_MISSING_ACCESSORIES = 'GET_MISSING_ACCESSORIES';
export const GET_MISSING_ACCESSORIES_COUNT = 'GET_MISSING_ACCESSORIES_COUNT';
export const GET_RENTED_ACCESSORY_HISTORY = 'GET_RENTED_ACCESSORY_HISTORY';
export const GET_DEVELOPMENT_STAGE = 'GET_DEVELOPMENT_STAGE';
export const ADMIN_CLIENT_CHANGE_DATE = 'ADMIN_CLIENT_CHANGE_DATE';
export const ADMIN_OMAMA_CHANGE_DATE = 'ADMIN_OMAMA_CHANGE_DATE';
export const ADMIN_OMAMA_CHANGE_NEXT_WEEK = 'ADMIN_OMAMA_CHANGE_NEXT_WEEK';
export const ADMIN_OMAMA_CHANGE_PREVIOUS_WEEK = 'ADMIN_OMAMA_CHANGE_PREVIOUS_WEEK';
export const ADMIN_OMAMA_CHANGE_WEEK = 'ADMIN_OMAMA_CHANGE_WEEK';
export const ADMIN_OMAMA_CHANGE_NEXT_MONTH = 'ADMIN_OMAMA_CHANGE_NEXT_MONTH';
export const ADMIN_OMAMA_CHANGE_PREVIOUS_MONTH = 'ADMIN_OMAMA_CHANGE_PREVIOUS_MONTH';
export const ADMIN_REMOVE_PHOTO = 'ADMIN_REMOVE_PHOTO';
export const ADMIN_CHANGE_ACTIVITY_PAGE = 'ADMIN_CHANGE_ACTIVITY_PAGE';
export const ADMIN_CHANGE_CLIENTS_PAGE = 'ADMIN_CHANGE_CLIENTS_PAGE';
export const GET_ALL_OMAMA_SUPERVISIONS = 'GET_ALL_OMAMA_SUPERVISIONS';

const initialState = {
  activities: [],
  activitiesCurrentPage: 1,
  clientsCurrentPage: 1,
  clients: [],
  oneClient: {},
  currentActivityId: null,
  oneFilteredClient: [],
  oneUser: {},
  omamaClientsById: {},
  clientsByStatus: {},
  allActions: [],
  allActionsByDay: [],
  clientActionsByDay: [],
  actionsByDay: [],
  activitiesByTypeAndPeriod: {},
  screeningsByClientId: {},
  screeningQuestionsByScreeningId: {},
  startWeek: moment()
    .startOf('isoWeek')
    .format('YYYY-MM-DD'),
  endWeek: moment()
    .endOf('isoWeek')
    .format('YYYY-MM-DD'),
  omamaStartMonth: moment()
    .startOf('month')
    .format('YYYY-MM-DD'),
  omamaEndMonth: moment()
    .endOf('month')
    .format('YYYY-MM-DD'),
  singleClientActions: [],
  clientFrom: null,
  clientTo: null,
  omamaFrom: null,
  omamaTo: null,
  counts: {
    lessons: null,
    clubs: null
  },
  omamaSupervisions: [],
  interNDAQuestions: [],
  interNDA: {},
  milestonesData: null,
  accessories: null,
  missingAccessories: null,
  missingAccessoriesCount: null,
  rentedAccessoryHistory: null,
  developmentStage: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    /* eslint-disable */
    case GET_ADMIN_ACTIVITIES: {
      return {
        ...state,
        activities: action.payload,
        activitiesByTypeAndPeriod: mapActivityByTypeAndDate(action.payload),
        activitiesById: keyBy(action.payload, '_id')
      };
    }
    case CLEAN_ADMIN_ACTIVITIES: {
      return {
        ...state,
        activities: [],
        activitiesByTypeAndPeriod: {},
        activitiesById: {}
      };
    }
    case GET_ADMIN_CLIENTS:
      return {
        ...state,
        clients: action.payload.data,
        totalClients: action.payload.totalCount
      };

    case ADMIN_REMOVE_PHOTO:
      const { clientID, photoID } = action.payload;
      const clients = [...state.clients];
      const oneClient = { ...state.oneClient };
      const i = clients.findIndex(client => client._id === clientID);
      if (clients[i]) {
        clients[i].gallery = clients[i].gallery.filter(image => {
          return image !== photoID;
        });
        oneClient.gallery = oneClient.gallery.filter(image => {
          return image !== photoID;
        });
      }
      return {
        ...state,
        clients,
        oneClient
      };

    case SET_ACTIVE_ACTIVITY_GROUP: {
      return {
        ...state,
        activeGroup: action.activityType
      };
    }

    case GET_SCREENINGS_FOR_CLIENT: {
      return {
        ...state,
        screeningsByClientId: {
          ...state.screeningsByClientId,
          ...groupBy(action.payload.data, 'clientID')
        }
      };
    }

    case GET_SCREENING_QUESTION_DETAILS: {
      const { data } = action.payload;
      if (data !== null) {
        const { _id: screeningId } = action.payload.data;
        return {
          ...state,
          screeningQuestionsByScreeningId: {
            ...state.screeningQuestionsByScreeningId,
            [screeningId]: action.payload.data.questions
          }
        };
      } else return state;
    }

    case GET_INTERNDA_QUESTIONS: {
      return {
        ...state,
        interNDAQuestions: action.payload.data.questions
      };
    }

    case GET_CLIENT_INTERNDA: {
      return {
        ...state,
        interNDA: action.payload.data
      };
    }

    case GET_ADMIN_OMAMA_CLIENTS:
      return {
        ...state,
        omamaClientsById: keyBy(action.payload.allOmamaClients, '_id'),
        clientsByStatus: action.payload.clientsByStatus
      };

    case GET_ADMIN_OMAMA_ACTIONS: {
      return {
        ...state,
        allActions: action.payload.allActions,
        clientActionsByDay: action.payload.clientActionsByDay,
        actionsByDay: action.payload.actionsByDay,
        allActionsByDay: action.payload.allActionsByDay,
        actionsById: keyBy(action.payload.allActions, '_id')
      };
    }

    case GET_ADMIN_CLIENT_ACTIONS_COUNT: {
      return {
        ...state,
        counts: action.payload.data
      };
    }

    case GET_ADMIN_CLIENT_ACTIONS: {
      return {
        ...state,
        singleClientActions: action.payload // array of objects
      };
    }
    case ADMIN_CLIENT_CHANGE_DATE:
      return {
        ...state,
        clientFrom: action.payload.clientFrom,
        clientTo: action.payload.clientTo
      };

    case ADMIN_OMAMA_CHANGE_DATE:
      return {
        ...state,
        omamaFrom: action.payload.omamaFrom,
        omamaTo: action.payload.omamaTo
      };

    case ADMIN_OMAMA_CHANGE_NEXT_WEEK:
      return {
        ...state,
        startWeek: action.payload.start,
        endWeek: action.payload.end
      };

    case ADMIN_OMAMA_CHANGE_PREVIOUS_WEEK:
      return {
        ...state,
        startWeek: action.payload.start,
        endWeek: action.payload.end
      };

    case ADMIN_OMAMA_CHANGE_WEEK:
      return {
        ...state,
        startWeek: action.payload.start,
        endWeek: action.payload.end
      };

    case ADMIN_OMAMA_CHANGE_NEXT_MONTH:
      return {
        ...state,
        omamaStartMonth: action.payload.start,
        omamaEndMonth: action.payload.end
      };

    case ADMIN_OMAMA_CHANGE_PREVIOUS_MONTH:
      return {
        ...state,
        omamaStartMonth: action.payload.start,
        omamaEndMonth: action.payload.end
      };

    case GET_ADMIN_CLIENT:
      return {
        ...state,
        oneClient: action.payload
      };

    case ADMIN_SAVE_CLIENT:
      return {
        ...state,
        oneClient: action.payload
      };

    case ADMIN_SAVE_FILTEREDCLIENT:
      return {
        ...state,
        oneFilteredClient: action.payload
      };

    case ADMIN_CLEAR_CLIENT:
      return {
        ...state,
        oneClient: {}
      };

    case GET_ADMIN_USER:
      return {
        ...state,
        oneUser: action.payload
      };

    case ADMIN_CHANGE_ACTIVITY_PAGE:
      return {
        ...state,
        activitiesCurrentPage: action.payload
      };

    case ADMIN_CHANGE_CLIENTS_PAGE:
      return {
        ...state,
        clientsCurrentPage: action.payload
      };

    case HANDLE_DEACTIVATION_DATE:
      return {
        ...state,
        oneClient: {
          ...state.oneClient,
          deactivatedOnDate: action.payload.date
        }
      };
    case HANDLE_DEACTIVATION_REASON:
      return {
        ...state,
        oneClient: {
          ...state.oneClient,
          deactivateReason: action.payload.reason,
          otherReason: action.payload.description
        }
      };
    case GET_ALL_OMAMA_SUPERVISIONS:
      return {
        ...state,
        omamaSupervisions: action.payload
      };
    case GET_MILESTONES_DATA:
      return {
        ...state,
        milestonesData: action.payload
      };
    case RESET_MILESTONES_DATA:
      return {
        ...state,
        milestonesData: {}
      };
    case GET_ACCESSORIES:
      return {
        ...state,
        accessories: action.payload
      };
    case GET_MISSING_ACCESSORIES:
      return {
        ...state,
        missingAccessories: action.payload
      };
    case GET_MISSING_ACCESSORIES_COUNT:
      return {
        ...state,
        missingAccessoriesCount: action.payload
      };
    case GET_RENTED_ACCESSORY_HISTORY:
      return {
        ...state,
        rentedAccessoryHistory: action.payload
      };

    case GET_DEVELOPMENT_STAGE:
      return {
        ...state,
        developmentStage: action.payload
      };
    default:
      return state;
    /* eslint-enable */
  }
};

function mapActivityByTypeAndDate(activities) {
  let activitiesByType = groupBy(activities, 'type');
  let byTypeAndPeriod = {};

  Object.keys(activitiesByType).forEach(type => {
    let activitiesGroup = activitiesByType[type];
    //TODO - map only ids
    byTypeAndPeriod = {
      ...byTypeAndPeriod,
      [type]: groupBy(activitiesGroup, byPeriodMapper)
    };
  });

  return byTypeAndPeriod;
}

function byPeriodMapper(activity) {
  const { from, to } = activity;
  return from + '-' + to;
}
