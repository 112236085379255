export const GET_REPORT_INIT = 'GET_REPORT_INIT';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const RESET_REPORT = 'RESET_REPORT';
export const RESET_REPORTS = 'RESET_REPORTS';
export const GET_OMAMA_REPORT = 'GET_OMAMA_REPORT';
export const RESET_OMAMA_REPORT = 'RESET_OMAMA_REPORT';
export const ADD_NEW_REPORT_DATA = 'ADD_NEW_REPORT_DATA';
export const GET_CLIENT_SCREENINGS_FOR_REPORT = 'GET_CLIENT_SCREENINGS_FOR_REPORT';
export const GET_OMAMA_REPORT_STATISTICS = 'GET_OMAMA_REPORT_STATISTICS';
export const GET_MENTOR_REPORT_STATISTICS = 'GET_MENTOR_REPORT_STATISTICS';
export const GET_SUPERVISOR_REPORT_STATISTICS = 'GET_SUPERVISOR_REPORT_STATISTICS';

const initialState = {
  loading: false,
  reports: {},
  reportData: [],
  omamaReport: {
    month: '',
    report: []
  },
  screeningReport: [],
  reportStatistics: {},
  reportMentorStatistics: null,
};

export default (state = initialState, action) => {
  /* eslint-disable */
  switch (action.type) {
    case ADD_NEW_REPORT_DATA:
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.reportName]: action.payload
        }
      }
    case RESET_REPORT:
      return {
        ...state,
        reports: { ...state.reports, [action.payload]: null }
      }
    case GET_REPORT_INIT:
      return {
        ...state,
        loading: true
      };
    case GET_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        reportData: action.payload
      };
    case RESET_REPORTS:
      return {
        ...state,
        reports: []
      }
    case GET_OMAMA_REPORT:
      return {
        ...state,
        omamaReport: {
          ...state.omamaReport,
          month: action.payload.month,
          report: action.payload.report
        }
      };

    case RESET_OMAMA_REPORT:
      return {
        ...state,
        omamaReport: {
          month: '',
          report: []
        }
      };

    case GET_CLIENT_SCREENINGS_FOR_REPORT:
      return {
        ...state,
        screeningReport: action.payload
      };

    case GET_OMAMA_REPORT_STATISTICS:
      return {
        ...state,
        reportStatistics: action.payload
      };

    case GET_MENTOR_REPORT_STATISTICS:
      return {
        ...state,
        reportMentorStatistics: action.payload
      };
      
    case GET_SUPERVISOR_REPORT_STATISTICS:
      return {
        ...state,
        reportSupervisorStatistics: action.payload
      };


    default:
      return state;
  }
  /* eslint-enable */
};
