const localeString = process.env.REACT_APP_LANGUAGE.toLowerCase();

export const stringSorter = field => (a, b) => {
  const fieldA = a[field] || '';
  const fieldB = b[field] || '';
  return fieldA.localeCompare(fieldB, 'sk', { sensitivity: 'accent' });
};

export const stringSorterBy = (field, sortBy) => (a, b) => {
  const fieldA = sortBy[a[field]] || '';
  const fieldB = sortBy[b[field]] || '';
  return fieldA.localeCompare(fieldB, 'sk', { sensitivity: 'accent' });
};

export const sortNames = names => {
  names.sort((a, b) => {
    const firstUserLastname = a.name.split(' ').at(-1);
    const secondUserLastname = b.name.split(' ').at(-1);

    if (firstUserLastname.localeCompare(secondUserLastname, localeString) < 0) return -1;
    else if (firstUserLastname.localeCompare(secondUserLastname, localeString) > 0) return 1;
    else {
      const firstUserFirstname = a.name.split(' ').at(0);
      const secondUserFirstname = b.name.split(' ').at(0);

      if (firstUserFirstname.localeCompare(secondUserFirstname, localeString) < 0) return -1;
      else return 1;
    }
  });

  return names;
};

export const deepSum = obj => {
  if (typeof obj === 'object') {
    const array = Array.isArray(obj) ? obj : Object.values(obj);
    return array.reduce(
      (sum, add) => {
        const addSum = deepSum(add);
        return { value: sum.value + addSum.value, max: sum.max + addSum.max };
      },
      { value: 0, max: 0 }
    );
  }
  return { value: obj, max: 1 };
};

export const emptySorter = () => {
  // Empty sorting function. It's purpose is just for antd table to display sorting order
};
