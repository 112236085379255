import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Descriptions, Input, Button } from 'antd';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { strings } from 'strings/StringsProvider';

import './AccessoryStatusDetail.scss';

const { TextArea } = Input;

function AccessoryStatusDetail({ name, request, closeModal, save }) {
  const [comment, setComment] = useState('');

  const checkData = () => {
    if (request) {
      if (isEmpty(comment)) return true;
    }

    return false;
  };

  const saveAccessoryStatus = () => {
    const body = {
      status: request ? 'missing' : 'rented',
      note: comment
    };

    save(body);
  };

  return (
    <div>
      <Descriptions className="descriptions">
        <Descriptions.Item className="accessory-detail-item" label={strings.toolName}>
          {name}
        </Descriptions.Item>

        <Descriptions.Item className="accessory-detail-item" label={strings.date}>
          {moment().format('D. M. YYYY')}
        </Descriptions.Item>

        <Descriptions.Item className="accessory-detail-item" label={strings.comment}>
          <div className="accessory-detail-textarea">
            <TextArea
              value={comment}
              onChange={e => setComment(e.target.value)}
              placeholder={request ? strings.mandatoryComment : strings.optionalComment}
              autoSize={{ minRows: 3, maxRows: 6 }}
            />
          </div>
        </Descriptions.Item>
      </Descriptions>

      <div className="accessory-status-detail-buttons">
        <Button className="rented-accessories-active-button" onClick={saveAccessoryStatus} disabled={checkData()}>
          {strings.save}
        </Button>

        <Button className="rented-accessories-inactive-button" onClick={closeModal}>
          {strings.cancel}
        </Button>
      </div>
    </div>
  );
}

AccessoryStatusDetail.propTypes = {
  name: PropTypes.string,
  request: PropTypes.bool,
  closeModal: PropTypes.func,
  save: PropTypes.func
};

export default AccessoryStatusDetail;
