import React from 'react';
import PropTypes from 'prop-types';

import './ActionDetailStat.scss';

function ActionDetailStat(props) {
  const { title, firstInput, secondInput } = props;
  return (
    <div className="actionDetail-info">
      <span className="actionDetail-info-title">{title}</span>
      <span className="actionDetail-info-firstInput">{firstInput}</span>
      <span className="actionDetail-info-secondInput">{secondInput}</span>
    </div>
  );
}

ActionDetailStat.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string
};

export default ActionDetailStat;
