import { keyBy } from 'lodash';

export const GET_USERS = 'GET_USERS';
export const GET_OMAMAS = 'GET_OMAMAS';
export const GET_REGIONS = 'GET_REGIONS';
export const GET_BIRTHDAY_MODAL = 'GET_BIRTHDAY_MODAL';
export const GET_CLOSED_BIRTHDAY_MODAL = 'GET_CLOSED_BIRTHDAY_MODAL';

const initialState = {
  byArr: [],
  byId: {},
  loading: false,
  omamas: [],
  regions: [],
  displayBirthdayModal: false,
  shouldCloseBirthdayModal: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        byArr: action.payload,
        byId: keyBy(action.payload, '_id')
      };
    case GET_OMAMAS:
      return {
        ...state,
        omamas: action.payload
      };
    case GET_REGIONS:
      return { ...state, regions: action.payload };
    case GET_BIRTHDAY_MODAL:
      return { ...state, displayBirthdayModal: action.payload };
    case GET_CLOSED_BIRTHDAY_MODAL:
      return { ...state, shouldCloseBirthdayModal: action.payload };
    default:
      return state;
  }
};
