import {
  SET_LOADING,
} from '../reducers/status.reducer';


export const setLoading = (flag) => {
  return async (dispatch) => {
    return dispatch({
      type: SET_LOADING,
      payload: {
        loading: flag
      }
    });
  };
};