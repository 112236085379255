import React from 'react';
import PropTypes from 'prop-types';
import { strings } from 'strings/StringsProvider';
import './BirthdayPopUp.scss';

const BirthdayPopUp = ({ onClose }) => {
  const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];

  return (
    <>
      <div className="birthday-container">
        <div className="birthday-container_close-button" onClick={() => onClose(true)}></div>
        <h2>{strings.birthdayWish}</h2>
      </div>
      {arr.map(element => (
        <div key={element} className="balloon-container">
          <div className="balloon-bottom">▲</div>
          <div className="balloon"></div>
          <div className="balloon-line">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 10">
              <path d="M0 5 Q30 1 60 5 Q90 9 120 5" stroke="black" strokeWidth="2" fill="none" strokeOpacity="0.7" />
            </svg>
          </div>
        </div>
      ))}
    </>
  );
};

BirthdayPopUp.propTypes = {
  onClose: PropTypes.func
};

export default BirthdayPopUp;
