import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Icon, Button, Modal } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import jwt from 'jsonwebtoken';
import { strings } from 'strings/StringsProvider';
import { providedByMap } from 'data/enums';
import AccessoryStatusDetail from 'components/AccessoryStatusDetail';
import {
  getAccessoryStatus,
  getAccessoryInfo,
  changeOmamaAccessoryStatus,
  resetAccessoryDetail
} from 'actions/omama.actions';

import './RentedAccessoryDetail.scss';

function RentedAccessoryDetail() {
  const [accessoryRequestModalOpen, setAccessoryRequestModalOpen] = useState(false);
  const [accessoryConfirmationModalOpen, setAccessoryConfirmationModalOpen] = useState(false);
  const userName = jwt.decode(localStorage.getItem('access-token')).username;
  const history = useHistory();
  const { accessoryId, userId } = useParams();
  const omamaId = userId ? userId : userName;

  const rentedAccessoryStatus = useSelector(state => state.omama.rentedAccessoryStatus);
  const rentedAccessoryInfo = useSelector(state => state.omama.rentedAccessoryInfo);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAccessoryStatus(accessoryId, omamaId));
    dispatch(getAccessoryInfo(accessoryId));

    return () => dispatch(resetAccessoryDetail());
  }, [dispatch, accessoryId, omamaId]);

  const openRequestModal = () => {
    if (rentedAccessoryStatus.status === 'rented') {
      setAccessoryRequestModalOpen(true);
    }
  };

  const openConfirmationModal = () => {
    if (rentedAccessoryStatus.status === 'missing') {
      setAccessoryConfirmationModalOpen(true);
    }
  };

  const closeRequestModal = () => {
    setAccessoryRequestModalOpen(false);
  };

  const closeConfirmationModal = () => {
    setAccessoryConfirmationModalOpen(false);
  };

  const sendRequest = body => {
    dispatch(changeOmamaAccessoryStatus(accessoryId, omamaId, body));
    setAccessoryRequestModalOpen(false);
  };

  const sendConfirmation = body => {
    dispatch(changeOmamaAccessoryStatus(accessoryId, omamaId, body));
    setAccessoryConfirmationModalOpen(false);
  };

  return (
    <div className="container">
      <div className="container-inner">
        <div className="back" onClick={history.goBack}>
          <Icon className="icon" type="left" /> {strings.backToTheList}
        </div>

        <>
          {!isEmpty(rentedAccessoryStatus) && !isEmpty(rentedAccessoryInfo) && (
            <div className="rented-accessories-detail-container">
              <div>
                <span className="accessory-info-label">{strings.toolName}:</span>
                <span>{rentedAccessoryInfo.name}</span>
              </div>
              <div>
                <span className="accessory-info-label">{strings.onePhoto}:</span>
                <div className="accessory-detail-photo-container">
                  <div className="accessory-detail-photo">
                    <div className="uploadedImage">
                      <img alt="Fotka" src={rentedAccessoryInfo.photoUrl} />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <span className="accessory-info-label">{strings.providedBy}:</span>
                <span>{providedByMap[rentedAccessoryInfo.providedBy]}</span>
              </div>
              <div>
                <span className="accessory-info-label">{strings.modifiedDate}:</span>
                <span>
                  {rentedAccessoryStatus.updatedAt
                    ? moment(rentedAccessoryStatus.updatedAt).format('D. M. YYYY')
                    : moment(rentedAccessoryStatus.createdAt).format('D. M. YYYY')}
                </span>
              </div>
              <div>
                <span className="accessory-info-label">{strings.state}:</span>
                <div style={{ display: 'inline-block', paddingBottom: '10px' }}>
                  {rentedAccessoryStatus.status === 'rented' ? (
                    <div style={{ color: '#41aea6' }}>{strings.possess}</div>
                  ) : (
                    <div style={{ color: 'gray' }}>{strings.miss}</div>
                  )}
                </div>
              </div>
              <div>
                <span className="accessory-info-label">{strings.action}:</span>
                <div style={{ display: 'inline-block', paddingBottom: '10px' }}>
                  {rentedAccessoryStatus.status === 'rented' ? (
                    <Button className="rented-accessories-active-button" onClick={() => openRequestModal()}>
                      <img src="/images/Icons/request_white.svg" alt="" height={20} className="icon" />
                      {strings.accessoryRequestTitle}
                    </Button>
                  ) : (
                    <Button className="rented-accessories-active-button" onClick={() => openConfirmationModal()}>
                      <img src="/images/Icons/receive_white.svg" alt="" height={20} className="icon" />
                      {strings.accessoryConfirmationTitle}
                    </Button>
                  )}
                </div>
              </div>
              <div>
                <span className="accessory-info-label">{strings.comment}:</span>
                <span>{rentedAccessoryStatus.note}</span>
              </div>
            </div>
          )}
        </>
        <Modal
          centered
          title={strings.accessoryRequestTitle}
          visible={accessoryRequestModalOpen}
          onCancel={closeRequestModal}
          onOk={closeRequestModal}
          footer={null}
        >
          {accessoryRequestModalOpen && (
            <AccessoryStatusDetail
              name={rentedAccessoryInfo.name}
              request={true}
              closeModal={closeRequestModal}
              save={sendRequest}
            />
          )}
        </Modal>
        <Modal
          centered
          title={strings.accessoryConfirmationTitle}
          visible={accessoryConfirmationModalOpen}
          onCancel={closeConfirmationModal}
          onOk={closeConfirmationModal}
          footer={null}
        >
          {accessoryConfirmationModalOpen && (
            <AccessoryStatusDetail
              name={rentedAccessoryInfo.name}
              request={false}
              closeModal={closeConfirmationModal}
              save={sendConfirmation}
            />
          )}
        </Modal>
      </div>
    </div>
  );
}

export default RentedAccessoryDetail;
