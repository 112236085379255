import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { push as routerPush } from 'connected-react-router';
import jwt from 'jsonwebtoken';

// Local Imports
import './registerdevice.scss';
import Formfield from '../../components/Formfield';
import { api } from '../../conf';
import { addNewMessageAction } from '../../actions/message.actions';
import { getOmamaTokenAction } from '../../actions/omama.actions';
import { getUserNotifications } from 'slices/notifications';
import { strings } from '../../strings/StringsProvider';
import { showBugButton } from 'conf/loggers';

const redirect = {
  omama: '/omama/plan',
  admin: '/admin',
  mentor: '/admin/users',
  supervisor: '/admin/users'
};

function RegisterDevice({ location }) {
  const dispatch = useDispatch();
  const notificationStatus = useSelector(store => store.notifications.status);
  const [pinCode, setPinCode] = useState('');

  const push = link => () => {
    dispatch(routerPush(link));
  };

  const registerDevice = async () => {
    const username = new URLSearchParams(location.search).get('user');
    try {
      const { data } = await axios.post(api.registerDevice, {
        username,
        pinCode
      });

      localStorage.setItem('access-token', data.token);
      localStorage.setItem(username, data.tfToken);

      const tokenDecoded = jwt.decode(data.token);

      dispatch(getOmamaTokenAction());
      showBugButton(false);
      push(redirect[tokenDecoded.role])();

      if (notificationStatus === 'done') {
        dispatch(getUserNotifications(true));
      }
    } catch (error) {
      if (error.message.includes('Request failed with status code 40')) {
        if (error.response.data.message.includes('Redirect to signIn')) {
          push('/sign-in')();
          dispatch(addNewMessageAction(strings.invalidPinWithRedirect, false));
        } else if (error.response.data.message.includes('User was deactivated')) {
          push('/sign-in')();
          dispatch(addNewMessageAction(strings.invalidPinWithDeactivation, false));
        } else {
          dispatch(addNewMessageAction(strings.invalidPin, false));
        }
      }
    }
  };

  const onPasswordKeyPress = async event => {
    if (event.key === 'Enter') {
      await registerDevice();
    }
  };

  return (
    <div className="background">
      <div className="centerDiv">
        <img className="logo" src="/images/cestavonLOGO_unofficial.png" alt="logo" />
        <div onKeyDown={onPasswordKeyPress}>
          <div className="inputDiv">
            <Formfield onChange={event => setPinCode(event.target.value)} value={pinCode} placeholder="pin kód" />
            <div className="link" onClick={push('/sign-in')}>
              <div>{strings.pinMessageInfo}</div>
            </div>
          </div>
          <Button className="loginButton" shape="round" onClick={registerDevice}>
            {strings.registerDevice}
          </Button>
        </div>
      </div>
    </div>
  );
}

RegisterDevice.propTypes = {
  location: PropTypes.object
};

export default RegisterDevice;
