import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Divider } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import jwt from 'jsonwebtoken';

import { ScreeningQuestionsList } from './screeningQuestionsList';
import { getScreeningClient } from 'slices/clients';
import { strings } from '../../../strings/StringsProvider';
import { calculateScreeningDate } from 'tools/date.tools';

export function ScreeningSections(props) {
  const screeningClient = useSelector(store => store.clients.screeningClient);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getScreeningClient(props.clientId));
  }, [dispatch, props.clientId]);

  const renderQuestionsWithType = (questionType, sectionTitle) => {
    const { questions: allQuestions, answers, changeQuestionStatus } = props;

    let filteredQuestions =
      allQuestions &&
      allQuestions.filter(q => q.screeningQuestionType === questionType);
    return (
      !isEmpty(filteredQuestions) && (
        <>
          <ScreeningQuestionsList
            questions={filteredQuestions}
            answers={answers}
            sectionTitle={sectionTitle}
            sectionLabel={strings.part + ': '}
            changeQuestionStatus={changeQuestionStatus}
          />
          <Divider style={{ border: '1px solid #41aea6' }} />
        </>
      )
    );
  };

  const style = {
    marginTop: '1vw'
  };

  const disabledStyle = {
    ...style,
    pointerEvents: 'none',
    cursor: 'not-allowed'
  };

  const fromDate = calculateScreeningDate(
    moment(screeningClient?.data?.gestacnyBirthDate),
    props.screening?.fromDay
  );
  const daysUntil = fromDate.diff(moment(), 'days');

  const isDisabled =
    daysUntil > 0 &&
    props.screening?.fromDay &&
    daysUntil < fromDate.diff(moment(screeningClient?.data?.gestacnyBirthDate));
  const userRole = jwt.decode(localStorage.getItem('access-token')).role;
  const isAdminSupervisorMentor = userRole === 'admin' || userRole === 'supervisor' || userRole === 'mentor';

  return (
    <div style={isDisabled || isAdminSupervisorMentor ? disabledStyle : style}>
      {isDisabled && (
        <div style={{ textAlign: 'center' }}>
          <h2 style={{ color: 'red' }}>
            {strings.screening1} {props.screening._id} {strings.notAbleToFill}
            <br />
            {strings.willBeAbleToBeFilled} {daysUntil} {strings.days} (
            {fromDate.format('D. M. YYYY')}).
          </h2>
        </div>
      )}
      {renderQuestionsWithType(QuestionType.motorika, strings.motorics)}
      {renderQuestionsWithType(
        QuestionType.adaptivneSpravanie,
        strings.adaptiveBehaviour
      )}
      {renderQuestionsWithType(QuestionType.komunikacia, strings.communication)}
      {renderQuestionsWithType(
        QuestionType.specifickeSpravanie,
        strings.specificBehaviour
      )}
      {renderQuestionsWithType(
        QuestionType.emocnaZrelost,
        strings.emotionalGrowth
      )}
      {renderQuestionsWithType(QuestionType.porozumenie, strings.understanding)}
      {renderQuestionsWithType(QuestionType.rec, strings.speech)}
      {renderQuestionsWithType(QuestionType.samostatnost, strings.independence)}
      {renderQuestionsWithType(
        QuestionType.spefificke_spravanie_11_12_skrining,
        strings.specificBehaviour + ' - 11., 12. ' + strings.screening2
      )}
      {renderQuestionsWithType(QuestionType.obavy, strings.worries)}
    </div>
  );
}

ScreeningSections.propTypes = {
  questions: PropTypes.array,
  answers: PropTypes.object,
  changeQuestionStatus: PropTypes.func,
  clientId: PropTypes.string,
  screening: PropTypes.object
};

const QuestionType = {
  motorika: 'motorika', // M1
  adaptivneSpravanie: 'adaptivne_spravanie', // S@
  komunikacia: 'komunikacia', // K
  specifickeSpravanie: 'specificke_spravanie', //Z
  obavy: 'obavy', // O1

  emocnaZrelost: 'emocna_zrelost', // SS
  porozumenie: 'porozumenie', // PR
  rec: 'rec', // K1
  samostatnost: 'samostatnost', //SE,
  spefificke_spravanie_11_12_skrining: 'specificke_spravanie_11_12_skrining' // SS
};
