import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';


function ActivityHeader(props) {
  return (
    <div className="activityHeader">
      <h1 style={{ paddingTop: '2px' }}>
        Aktivity
      </h1>
      {
        props.displayAge && (
          <h1>
            <div
              className="cursorPointer"
              style={{ display: 'inline', verticalAlign: 'text-bottom' }}
              onClick={() => props.minusOne()}>
              <Icon type="caret-left" />
            </div>
            <div
              style={{
                display: 'inline',
                verticalAlign: 'middle',
                fontSize: '15px',
                fontWeight: 'bold'
              }}>
              {` ${props.month}m/${props.week}t `}
            </div>
            <div
              className="cursorPointer"
              style={{ display: 'inline', verticalAlign: 'text-bottom' }}
              onClick={() => props.plusOne()}>
              <Icon type="caret-right" />
            </div>
          </h1>
        )
      }
    </div>
  );
}

ActivityHeader.propTypes = {
  minusOne : PropTypes.func.isRequired,
  displayAge : PropTypes.bool.isRequired,
  plusOne : PropTypes.func.isRequired,
  month : PropTypes.number.isRequired,
  week : PropTypes.number.isRequired,
};

export default ActivityHeader;

