import { strings } from '../strings/StringsProvider';

export const clientActionsReportHeader = [
  { label: strings.date, key: 'date' },
  { label: strings.time, key: 'time' },
  { label: strings.clientsName, key: 'clientName' },
  { label: strings.gender, key: 'gender' },
  { label: strings.age, key: 'age' },
  { label: strings.omama, key: 'omamaName' },
  { label: strings.parentJoined, key: 'question1' },
  { label: strings.parentPraised, key: 'question2' },
  { label: strings.parentDevoted, key: 'question3' },
  { label: strings.activities, key: 'activities' }
];

export const omamaActionsReportHeader = [
  { label: strings.date, key: 'date' },
  { label: strings.time, key: 'time' },
  { label: strings.clientsNameAndSurname, key: 'clientName' },
  { label: strings.gender, key: 'gender' },
  { label: strings.age, key: 'age' },
  { label: strings.typeGeneral, key: 'type' },
  { label: strings.parentJoined, key: 'question1' },
  { label: strings.parentPraised, key: 'question2' },
  { label: strings.parentDevoted, key: 'question3' },
  { label: strings.activities, key: 'activities' },
  { label: strings.omama, key: 'omamaName' }
];

export const supervisionsReportHeader = [
  { label: strings.date, key: 'date' },
  { label: strings.time, key: 'time' },
  { label: strings.supervisor, key: 'supervisorName' },
  { label: strings.omamaName, key: 'omamaName' },
  { label: strings.community, key: 'city' },
  { label: strings.region, key: 'region' },
  { label: strings.amountOfPoints, key: 'points' },
  { label: strings.result1, key: 'percentage' },
  { label: strings.comment, key: 'comment' },
  { label: strings.spokeScoreOfLesson, key: 'textEvaluation' },
  { label: strings.goalTillNextSupervision, key: 'targetToNextSupervision' }
];

export const parentalClubsReportHeader = [
  { label: strings.date, key: 'date' },
  { label: strings.time, key: 'time' },
  { label: strings.omama, key: 'omamaName' },
  { label: strings.community, key: 'city' },
  { label: strings.comment, key: 'comment' }
];

export const allClientsReportHeader = [
  { label: strings.firstName, key: 'firstName' },
  { label: strings.lastName, key: 'lastName' },
  { label: strings.gender, key: 'gender' },
  { label: strings.omama, key: 'omamaID' },
  { label: strings.region, key: 'region' },
  { label: strings.street, key: 'street' },
  { label: strings.city, key: 'city' },
  { label: strings.birthCity, key: 'birthDateAdress' },
  { label: strings.birthDate, key: 'birthDate' },
  { label: strings.age, key: 'age' },
  { label: `0-5 ${strings.zeroOrFiveMonths}`, key: '5month' },
  { label: `6-11 ${strings.zeroOrFiveMonths}`, key: '11month' },
  { label: `12-17 ${strings.zeroOrFiveMonths}`, key: '17month' },
  { label: `18-23 ${strings.zeroOrFiveMonths}`, key: '23month' },
  { label: `24-29 ${strings.zeroOrFiveMonths}`, key: '29month' },
  { label: `30-35 ${strings.zeroOrFiveMonths}`, key: '35month' },
  { label: `36-41 ${strings.zeroOrFiveMonths}`, key: '41month' },
  { label: `42-47 ${strings.zeroOrFiveMonths}`, key: '47month' },
  { label: `48+ ${strings.zeroOrFiveMonths}`, key: '48plus' },
  { label: strings.plannedBirth, key: 'gestacnyBirthDate' },
  { label: strings.inProgramFrom, key: 'inProgramFromDate' },
  //{ label: strings.inProgramFrom, key: 'inProgramFirstDate' },
  { label: strings.status, key: 'active' },
  { label: strings.deactivatedOnDate, key: 'deactivatedOnDate' },
  { label: strings.created, key: 'created' },
  { label: strings.lastModified, key: 'lastModified' },
  { label: strings.visitsPreschoolClub, key: 'predskolskyKlub' },
  { label: strings.motherName, key: 'motherName' },
  { label: strings.motherBirthDate, key: 'motherBirthDate' },
  { label: strings.motherHealthState, key: 'motherHealthState' },
  { label: strings.motherEducation, key: 'motherEducation' },
  { label: strings.motherEducationLevel, key: 'motherEducationLevel' },
  { label: strings.motherWork, key: 'motherWork' },
  { label: strings.motherPhone, key: 'motherPhone' },
  { label: strings.fatherName, key: 'fatherName' },
  { label: strings.fatherBirthDate, key: 'fatherBirthDate' },
  { label: strings.fatherHealthState, key: 'fatherHealthState' },
  { label: strings.fatherEducation, key: 'fatherEducation' },
  { label: strings.fatherEducationLevel, key: 'fatherEducationLevel' },
  { label: strings.fatherWork, key: 'fatherWork' },
  { label: strings.fatherPhone, key: 'fatherPhone' },
  { label: strings.siblingsNumber, key: 'siblingsNumber' },
  //{ label: strings.siblingsNumber, key: 'siblingsCount' },
  { label: strings.childBirthOrder, key: 'childBirthOrder' },
  { label: strings.siblingsInfo, key: 'siblingsInfo' },
  { label: strings.language, key: 'language' },
  { label: strings.smokingPregnancy, key: 'smokingPregnancy' },
  { label: strings.smokingBreastFeeding, key: 'smokingBreastFeeding' },
  { label: strings.alcoholPregnancy, key: 'alcoholPregnancy' },
  { label: strings.alcoholBreastFeeding, key: 'alcoholBreastFeeding' },
  { label: strings.breastFeedTime, key: 'breastFeedTime' },
  { label: strings.pregnancy, key: 'pregnancy' },
  { label: strings.childbirth, key: 'childbirth' }, //
  { label: strings.birthInTerm, key: 'birthInTerm' },
  { label: strings.prematureBirth, key: 'prematureBirth' },
  { label: strings.lateBirth, key: 'lateBirth' },
  { label: strings.inducedBirth, key: 'inducedBirth' },
  { label: strings.spontaneousBirth, key: 'spontaneousBirth' },
  { label: strings.csectionBirth, key: 'csectionBirth' },
  { label: strings.naturalBirth, key: 'naturalBirth' },
  { label: strings.childbirthComplications, key: 'childbirthComplications' },
  // old data
  { label: strings.weight, key: 'weight' },
  { label: strings.height, key: 'height' },
  //
  { label: strings.birthWeight, key: 'birthWeight' },
  { label: strings.birthLength, key: 'birthLength' },
  //{ label: strings.birthWeight, key: 'defaultWeight' },
  //{ label: 'Výška pri narodení dieťaťa', key: 'defaultHeight' },
  {
    label: `${strings.weightIn} 12 ${strings.zeroOrFiveMonths}`,
    key: 'weight12'
  },
  {
    label: `${strings.heightIn} 12 ${strings.zeroOrFiveMonths}`,
    key: 'height12'
  },
  {
    label: `${strings.weightIn} 24 ${strings.zeroOrFiveMonths}`,
    key: 'weight24'
  },
  {
    label: `${strings.heightIn} 24 ${strings.zeroOrFiveMonths}`,
    key: 'height24'
  },
  {
    label: `${strings.weightIn} 36 ${strings.zeroOrFiveMonths}`,
    key: 'weight36'
  },
  {
    label: `${strings.heightIn} 36 ${strings.zeroOrFiveMonths}`,
    key: 'height36'
  },

  { label: strings.growthRollingover, key: 'growthRollingover' },
  { label: strings.growthSitting, key: 'growthSitting' },
  { label: strings.growthCrawling, key: 'growthCrawling' },
  { label: strings.growthWalking, key: 'growthWalking' },
  { label: strings.growthCooing, key: 'growthCooing' },
  { label: strings.growthTalking, key: 'growthTalking' },
  { label: strings.hospitalization, key: 'hospitalization' },
  { label: strings.injury, key: 'injury' },
  //{ label: strings.regularExaminations, key: 'examination' },
  {
    label: strings.regularExaminations,
    key: 'regularExaminations'
  },
  { label: strings.healthCondition, key: 'healthCondition' },
  // NEW - february 2020
  { label: strings.drugsPregnancy, key: 'drugsPregnancy' },
  { label: strings.medicinePregrancy, key: 'medicinePregrancy' },
  { label: strings.drugsBreastFeeding, key: 'drugsBreastFeeding' },
  { label: strings.medicineBreastFeeding, key: 'medicineBreastFeeding' },
  {
    label: strings.smokingNearChildBelowTwoYears,
    key: 'smokingNearChildBelowTwoYears'
  },
  {
    label: strings.alcoholNearChildBelowTwoYears,
    key: 'alcoholNearChildBelowTwoYears'
  },
  {
    label: strings.drugsNearChildBelowTwoYears,
    key: 'drugsNearChildBelowTwoYears'
  },
  {
    label: strings.medicineNearChildBelowTwoYears,
    key: 'medicineNearChildBelowTwoYears'
  },

  { label: strings.obligatoryVaccination, key: 'obligatoryVaccination' },
  { label: strings.healthIssues, key: 'healthIssues' },
  //obavy
  { label: strings.eyesightIssueConcern, key: 'eyesightIssueConcern' },
  { label: strings.hearingIssueConcern, key: 'hearingIssueConcern' },
  { label: strings.sleepIssueConcern, key: 'sleepIssueConcern' },
  { label: strings.growNurtureIssueConcern, key: 'growNurtureIssueConcern' },
  { label: strings.healthIssueConcern, key: 'healthIssueConcern' },
  { label: strings.wholeGrowIssueConcern, key: 'wholeGrowIssueConcern' },
  //Byvanie
  {
    label: strings.houseMaterial,
    key: 'houseMaterial'
  },
  { label: strings.houseRooms, key: 'houseRooms' },
  { label: strings.houseInhabitants, key: 'houseInhabitants' },
  {
    label: strings.houseSameRoomForChildAndKitchen,
    key: 'houseSameRoomForChildAndKitchen'
  },
  { label: strings.houseFuel, key: 'houseFuel' },
  { label: strings.houseSeparateBathRoom, key: 'houseSeparateBathRoom' },

  //new others
  { label: strings.visitingInstitution, key: 'visitingInstitution' },
  { label: strings.currentWeight, key: 'currentWeight' },
  { label: strings.currentHeight, key: 'currentHeight' },
  { label: strings.birthHeadCircumference, key: 'birthHeadCircumference' },
  { label: strings.breastFed, key: 'breastFed' },
  { label: strings.solidFood, key: 'solidFood' },
  { label: strings.institutionName, key: 'institutionName' },
  {
    label: strings.visitingInstitutionFor,
    key: 'visitingInstitutionFor'
  },
  { label: strings.examinationOffice, key: 'examinationOffice' },
  {
    label: strings.dateOfLastExamination,
    key: 'dateOfLastExamination'
  },

  //GDPR fields
  { label: strings.photosInApp, key: 'photosInApp' },
  { label: strings.photosOnWeb, key: 'photosOnWeb' },
  { label: strings.photosPrinted, key: 'photosPrinted' },
  { label: strings.videoReports, key: 'videoReports' },
  { label: strings.pediatricScreenings, key: 'pediatricScreenings' },
  { label: strings.growthTracking, key: 'growthTracking' },
  { label: strings.educationCarrierGrowth, key: 'educationCarrierGrowth' },
  {
    label: strings.contactLater,
    key: 'contactLater'
  }
];

export const notEnoughLessonsReportHeader = [
  { label: strings.client, key: 'clientName' },
  { label: strings.gender, key: 'gender' },
  { label: strings.birthDate, key: 'birthDate' },
  { label: strings.city, key: 'city' },
  { label: strings.omama, key: 'omamaName' },
  { label: 'Lekcie za posledné 2 mesiace', key: 'actions' }
];

export const twoRedScreeningsReportHeader = [
  { label: strings.client, key: 'clientName' },
  { label: strings.gender, key: 'gender' },
  { label: strings.birthDate, key: 'birthDate' },
  { label: strings.city, key: 'city' },
  { label: strings.omama, key: 'omamaName' },
  { label: strings.screeningType, key: 'screeningType' },
  { label: strings.lastScreeningDate, key: 'lastScreeningDate' }
];

export const mentorActionsReportHeader = [
  { label: strings.date, key: 'date' },
  { label: strings.time, key: 'time' },
  { label: strings.clientsNameAndSurname, key: 'clientName' },
  { label: strings.age, key: 'age' },
  { label: strings.parentJoined, key: 'question1' },
  { label: strings.parentPraised, key: 'question2' },
  { label: strings.parentDevoted, key: 'question3' },
  { label: strings.conginitiveFunctions, key: 'kognitivneFunkcie' },
  { label: strings.fineMotorSkills, key: 'jemnaMotorika' },
  { label: strings.grossMotorSkills, key: 'hrubaMotorika' },
  { label: strings.emotionalDevelopment, key: 'emocionalnyRozvoj' },
  { label: strings.readingBook, key: 'citanieKnihy' },
  { label: strings.omama, key: 'omamaName' }
];

export const omamasStatsReportHeader = [
  { label: strings.omama, key: 'name' },
  { label: strings.region, key: 'region' },
  { label: strings.location, key: 'city' },
  { label: strings.inProgramFrom, key: 'created' },
  { label: strings.status, key: 'active' },
  { label: strings.level, key: 'level' },
  { label: strings.thatTimeActiveClients, key: 'activeClientsThatTime' },
  { label: strings.currentActiveClients, key: 'activeClients' },
  { label: strings.currentInactiveClients, key: 'inactiveClients' },
  {
    label: strings.allTimeSupervisionRatingAverage,
    key: 'allTimeSupervisionRatingAverage'
  },
  { label: strings.allTimeLessons, key: 'allTimeLessons' },
  { label: strings.allTimePreschoolClubs, key: 'allTimePreschoolClubs' },
  { label: strings.allTimeParentClubs, key: 'allTimeParentClubs' },
  { label: strings.allTimeSupervisions, key: 'allTimeSupervisions' },

  {
    label: strings.supervisionRatingAverage,
    key: 'supervisionRatingAverage'
  },

  { label: strings.notCancelledLessons, key: 'lessons' },
  { label: strings.january, key: 'byMonths.0.lessons' },
  { label: strings.february, key: 'byMonths.1.lessons' },
  { label: strings.march, key: 'byMonths.2.lessons' },
  { label: strings.april, key: 'byMonths.3.lessons' },
  { label: strings.may, key: 'byMonths.4.lessons' },
  { label: strings.june, key: 'byMonths.5.lessons' },
  { label: strings.july, key: 'byMonths.6.lessons' },
  { label: strings.august, key: 'byMonths.7.lessons' },
  { label: strings.september, key: 'byMonths.8.lessons' },
  { label: strings.october, key: 'byMonths.9.lessons' },
  { label: strings.november, key: 'byMonths.10.lessons' },
  { label: strings.december, key: 'byMonths.11.lessons' },

  {
    label: strings.notCancelledLessonsInPercentage,
    key: 'actionsPercentage'
  },
  { label: strings.january, key: 'byMonths.0.actionsPercentage' },
  { label: strings.february, key: 'byMonths.1.actionsPercentage' },
  { label: strings.march, key: 'byMonths.2.actionsPercentage' },
  { label: strings.april, key: 'byMonths.3.actionsPercentage' },
  { label: strings.may, key: 'byMonths.4.actionsPercentage' },
  { label: strings.june, key: 'byMonths.5.actionsPercentage' },
  { label: strings.july, key: 'byMonths.6.actionsPercentage' },
  { label: strings.august, key: 'byMonths.7.actionsPercentage' },
  { label: strings.september, key: 'byMonths.8.actionsPercentage' },
  { label: strings.october, key: 'byMonths.9.actionsPercentage' },
  { label: strings.november, key: 'byMonths.10.actionsPercentage' },
  { label: strings.december, key: 'byMonths.11.actionsPercentage' },

  { label: strings.preschoolClubs, key: 'preschoolClubs' },
  { label: strings.january, key: 'byMonths.0.preschoolClubs' },
  { label: strings.february, key: 'byMonths.1.preschoolClubs' },
  { label: strings.march, key: 'byMonths.2.preschoolClubs' },
  { label: strings.april, key: 'byMonths.3.preschoolClubs' },
  { label: strings.may, key: 'byMonths.4.preschoolClubs' },
  { label: strings.june, key: 'byMonths.5.preschoolClubs' },
  { label: strings.july, key: 'byMonths.6.preschoolClubs' },
  { label: strings.august, key: 'byMonths.7.preschoolClubs' },
  { label: strings.september, key: 'byMonths.8.preschoolClubs' },
  { label: strings.october, key: 'byMonths.9.preschoolClubs' },
  { label: strings.november, key: 'byMonths.10.preschoolClubs' },
  { label: strings.december, key: 'byMonths.11.preschoolClubs' },

  { label: strings.parentClubs, key: 'parentClubs' },
  { label: strings.january, key: 'byMonths.0.parentClubs' },
  { label: strings.february, key: 'byMonths.1.parentClubs' },
  { label: strings.march, key: 'byMonths.2.parentClubs' },
  { label: strings.april, key: 'byMonths.3.parentClubs' },
  { label: strings.may, key: 'byMonths.4.parentClubs' },
  { label: strings.june, key: 'byMonths.5.parentClubs' },
  { label: strings.july, key: 'byMonths.6.parentClubs' },
  { label: strings.august, key: 'byMonths.7.parentClubs' },
  { label: strings.september, key: 'byMonths.8.parentClubs' },
  { label: strings.october, key: 'byMonths.9.parentClubs' },
  { label: strings.november, key: 'byMonths.10.parentClubs' },
  { label: strings.december, key: 'byMonths.11.parentClubs' },

  { label: strings.supervisedVisitsSoFarThisYear, key: 'supervisions' },
  { label: strings.january, key: 'byMonths.0.supervisions' },
  { label: strings.february, key: 'byMonths.1.supervisions' },
  { label: strings.march, key: 'byMonths.2.supervisions' },
  { label: strings.april, key: 'byMonths.3.supervisions' },
  { label: strings.may, key: 'byMonths.4.supervisions' },
  { label: strings.june, key: 'byMonths.5.supervisions' },
  { label: strings.july, key: 'byMonths.6.supervisions' },
  { label: strings.august, key: 'byMonths.7.supervisions' },
  { label: strings.september, key: 'byMonths.8.supervisions' },
  { label: strings.october, key: 'byMonths.9.supervisions' },
  { label: strings.november, key: 'byMonths.10.supervisions' },
  { label: strings.december, key: 'byMonths.11.supervisions' }
];

export const parentEngagementReportHeader = [
  { label: strings.omama, key: 'omamaName' },
  {
    label: `${strings.parentJoined} - ${strings.notAtAll}`,
    key: 'question1.notAtAll'
  },
  {
    label: `${strings.parentJoined} - ${strings.sometimes}`,
    key: 'question1.sometimes'
  },
  {
    label: `${strings.parentJoined} - ${strings.veryOften}`,
    key: 'question1.often'
  },
  {
    label: `${strings.parentPraised} - ${strings.notAtAll}`,
    key: 'question2.notAtAll'
  },
  {
    label: `${strings.parentPraised} - ${strings.sometimes}`,
    key: 'question2.sometimes'
  },
  {
    label: `${strings.parentPraised} - ${strings.veryOften}`,
    key: 'question2.often'
  },
  {
    label: `${strings.parentDevoted} - ${strings.notAtAll}`,
    key: 'question3.notAtAll'
  },
  {
    label: `${strings.parentDevoted} - ${strings.sometimes}`,
    key: 'question3.sometimes'
  },
  {
    label: `${strings.parentDevoted} - ${strings.veryOften}`,
    key: 'question3.often'
  }
];

export const trendsReportHeader = [
  { label: strings.year, key: 'year' },
  { label: strings.countDoneLections, key: 'stats.data.countDoneLections' },
  { label: strings.countDoneClubs, key: 'stats.data.countDoneClubs' },
  { label: strings.countParentalClub, key: 'stats.data.countParentalClub' },
  {
    label: `${strings.developmentalScreening} - ${strings.greenZone}`,
    key: 'screenings.green'
  },
  {
    label: `${strings.developmentalScreening} - ${strings.orangeZone}`,
    key: 'screenings.orange'
  },
  {
    label: `${strings.developmentalScreening} - ${strings.redZone}`,
    key: 'screenings.red'
  },
  {
    label: `${strings.developmentalScreening} - ${strings.worries}`,
    key: 'screenings.worries'
  },
  {
    label: `${strings.parentEngagement} - ${strings.parentJoined} ${strings.notAtAll}`,
    key: 'parentEngagement.question1.notAtAll'
  },
  {
    label: `${strings.parentEngagement} - ${strings.parentJoined} ${strings.sometimes}`,
    key: 'parentEngagement.question1.sometimes'
  },
  {
    label: `${strings.parentEngagement} - ${strings.parentJoined} ${strings.veryOften}`,
    key: 'parentEngagement.question1.often'
  },
  {
    label: `${strings.parentEngagement} - ${strings.parentPraised} ${strings.notAtAll}`,
    key: 'parentEngagement.question2.notAtAll'
  },
  {
    label: `${strings.parentEngagement} - ${strings.parentPraised} ${strings.sometimes}`,
    key: 'parentEngagement.question2.sometimes'
  },
  {
    label: `${strings.parentEngagement} - ${strings.parentPraised} ${strings.veryOften}`,
    key: 'parentEngagement.question2.often'
  },
  {
    label: `${strings.parentEngagement} - ${strings.parentDevoted} ${strings.notAtAll}`,
    key: 'parentEngagement.question3.notAtAll'
  },
  {
    label: `${strings.parentEngagement} - ${strings.parentDevoted} ${strings.sometimes}`,
    key: 'parentEngagement.question3.sometimes'
  },
  {
    label: `${strings.parentEngagement} - ${strings.parentDevoted} ${strings.veryOften}`,
    key: 'parentEngagement.question3.often'
  }
];

export const omamasActivitiesReportHeader = [
  { label: strings.omama, key: 'name' },
  { label: strings.employmentType, key: 'employmentType' },
  { label: strings.workingHours, key: 'workingHours' },
  { label: strings.expectedClientCount, key: 'expectedClientCount' },
  { label: strings.idealMaxLessons, key: 'idealMaxLessons' },
  { label: strings.clientActions, key: 'clientActions' },
  { label: strings.nonClientActions, key: 'nonClientActions' },
  {
    label: strings.hoursToSubstract,
    key: 'hoursToSubstract'
  },
  {
    label: strings.calculatedIdealMaxLessons,
    key: 'calculatedIdealMaxLessons'
  },
  { label: '%', key: 'percentage' }
];

export const omamasRatingReportHeader = [
  { label: strings.omama, key: 'name' },
  { label: strings.clientPercentage, key: 'clientPercentage' },
  { label: strings.supervisionsRatingPercentage, key: 'supervisionsRatingPercentage' },
  { label: strings.lessonsPercentage, key: 'lessonsPercentage' },
  { label: strings.parentClubsPercentage, key: 'parentClubsPercentage' },
  { label: strings.result, key: 'result' },
  { label: strings.calculatedIdealMaxLessons, key: 'maxLessons' }
];

export const mentorsWorkRatingReportHeader = [
  { label: strings.date, key: 'date' },
  { label: strings.time, key: 'time' },
  { label: strings.mentor, key: 'mentorName' },
  { label: strings.mentorID, key: 'mentorID' },
  { label: strings.action, key: 'id' },
  { label: strings.timeSpent, key: 'timeSpent' },
  { label: strings.comment, key: 'comment' }
];

export const screeningsReportHeaderStart = [
  { label: strings.type, key: 'type' },
  { label: strings.childName, key: 'client.name' },
  { label: strings.gender, key: 'client.gender' },
  { label: strings.birthDate, key: 'birthDate' },
  { label: strings.corigatedAge, key: 'age' },
  { label: strings.premature, key: 'premature' },
  { label: strings.dateOfCreation, key: 'created' },
  { label: strings.createdBy, key: 'createdBy' },
  { label: strings.pediater, key: strings.pediater }
];

export const screeningsReportHeaderEnd = [
  {
    label: `${strings.score} (1. ${strings.part})`,
    key: 'pasma.vyvinovehoSkore.body'
  },
  {
    label: `${strings.score} (2. ${strings.part})`,
    key: 'pasma.specifickeSpravanie.body'
  },
  { label: `${strings.score} (3. ${strings.part})`, key: 'pasma.obavy.body' },
  { label: `${strings.score} (${strings.all})`, key: 'overallScore' }
];

export const screeningsDoneReportHeader = [
  { label: strings.client, key: 'name' },
  { label: strings.gender, key: 'gender' },
  { label: strings.omama, key: 'omama' },
  { label: strings.birthDate, key: 'birthDate' },
  { label: `2.PP (do 4 ${strings.week3})`, key: '2.PP' },
  { label: strings.filled, key: '2.PPdone' },
  { label: `3. PP (5-7.${strings.week4})`, key: '3.PP' },
  { label: strings.filled, key: '3.PPdone' },
  { label: `4.PP (8-10.${strings.week4})`, key: '4.PP' },
  { label: strings.filled, key: '4.PPdone' },
  { label: `5.PP (3-4.${strings.oneMonth})`, key: '5.PP' },
  { label: strings.filled, key: '5.PPdone' },
  { label: `6.PP (5-6.${strings.oneMonth})`, key: '6.PP' },
  { label: strings.filled, key: '6.PPdone' },
  { label: `7.PP (7-8.${strings.oneMonth})`, key: '7.PP' },
  { label: strings.filled, key: '7.PPdone' },
  { label: `8.PP (9-10.${strings.oneMonth})`, key: '8.PP' },
  { label: strings.filled, key: '8.PPdone' },
  { label: `9.PP (11-12.${strings.oneMonth})`, key: '9.PP' },
  { label: strings.filled, key: '9.PPdone' },
  { label: `10.PP (15-18.${strings.oneMonth})`, key: '10.PP' },
  { label: strings.filled, key: '10.PPdone' },
  { label: `11.PP (26-35.${strings.oneMonth})`, key: '11.PP' },
  { label: strings.filled, key: '11.PPdone' },
  { label: `12.PP (36-40.${strings.oneMonth})`, key: '12.PP' },
  { label: strings.filled, key: '12.PPdone' }
];

export const interNDAMeasurementsReportHeader = [
  { label: strings.firstName, key: 'name' },
  { label: strings.birthDate, key: 'birthDate' },
  { label: strings.region, key: 'region' },
  { label: strings.cognition, key: 'cognition' },
  { label: strings.risk, key: 'cognitionRisk' },
  { label: strings.fineMotor, key: 'fineMotor' },
  { label: strings.risk, key: 'fineMotorRisk' },
  { label: strings.grossMotor, key: 'grossMotor' },
  { label: strings.risk, key: 'grossMotorRisk' },
  { label: strings.languageInterNDA, key: 'language' },
  { label: strings.risk, key: 'languageRisk' },
  { label: strings.positiveBehaviour, key: 'positiveBehaviour' },
  { label: strings.risk, key: 'positiveBehaviourRisk' },
  { label: strings.negativeBehaviour, key: 'negativeBehaviour' },
  { label: strings.risk, key: 'negativeBehaviourRisk' },
  { label: 'Q1', key: 'answers.0.value' },
  { label: 'Q2', key: 'answers.1.value' },
  { label: 'Q3', key: 'answers.2.value' },
  { label: 'Q4', key: 'answers.3.value' },
  { label: 'Q5', key: 'answers.4.value' },
  { label: 'Q6', key: 'answers.5.value' },
  { label: 'Q7', key: 'answers.6.value' },
  { label: 'Q8', key: 'answers.7.value' },
  { label: 'Q9', key: 'answers.8.value' },
  { label: 'Q10', key: 'answers.9.value' },
  { label: 'Q11', key: 'answers.10.value' },
  { label: 'Q12', key: 'answers.11.value' },
  { label: 'Q13', key: 'answers.12.value' },
  { label: 'Q14', key: 'answers.13.value' },
  { label: 'Q15', key: 'answers.14.value' },
  { label: 'Q16', key: 'answers.15.value' },
  { label: 'Q17', key: 'answers.16.value' },
  { label: 'Q18', key: 'answers.17.value' },
  { label: 'Q19', key: 'answers.18.value' },
  { label: 'Q20', key: 'answers.19.value' },
  { label: 'Q21', key: 'answers.20.value' },
  { label: 'Q22', key: 'answers.21.value' },
  { label: 'Q23', key: 'answers.22.value' },
  { label: 'Q24', key: 'answers.23.value' },
  { label: 'Q25', key: 'answers.24.value' },
  { label: 'Q26', key: 'answers.25.value' },
  { label: 'Q27', key: 'answers.26.value' },
  { label: 'Q28', key: 'answers.27.value' },
  { label: 'Q29', key: 'answers.28.value' },
  { label: 'Q30', key: 'answers.29.value' },
  { label: 'Q31', key: 'answers.30.value' },
  { label: 'Q32', key: 'answers.31.value' },
  { label: 'Q33', key: 'answers.32.value' },
  { label: 'Q34', key: 'answers.33.value' },
  { label: 'Q35', key: 'answers.34.value' },
  { label: 'Q36', key: 'answers.35.value' },
  { label: 'Q37', key: 'answers.36.value' }
];

export const preschoolScreeningReportHeader = [
  { label: strings.firstName, key: 'name' },
  { label: strings.birthDate, key: 'birthDate' },
  { label: strings.region, key: 'region' },
  { label: strings.nativeLanguage, key: 'info.nativeLanguage' },
  { label: strings.languageSkills, key: 'info.languageSkill' },
  { label: strings.laterality, key: 'info.laterality' },
  { label: strings.weightKg, key: 'info.weight' },
  { label: strings.heightCm, key: 'info.height' },
  {
    label: 'Hrubá motorika - Beh s koordinovaným pohybom rúk',
    key: 'answers.grossMotor.question1'
  },
  {
    label: 'Hrubá motorika - Stoj na jednej nohe - výdrž 10-15 sekúnd',
    key: 'answers.grossMotor.question2'
  },
  {
    label: 'Hrubá motorika - Vie skákať na jednej nohe',
    key: 'answers.grossMotor.question3'
  },
  {
    label: 'Hrubá motorika - Pri chôdzi po schodoch (hore aj dole) strieda nohy',
    key: 'answers.grossMotor.question4'
  },
  {
    label: 'Hrubá motorika - Vie hodiť a chytiť loptu',
    key: 'answers.grossMotor.question5'
  },
  {
    label: 'Hrubá motorika - Vie preskočiť nízku prekážku - znožmo',
    key: 'answers.grossMotor.question6'
  },
  {
    label: 'Hrubá motorika - Vie preskočiť nízku prekážku - jednou nohou dopredu',
    key: 'answers.grossMotor.question7'
  },
  {
    label: 'Jemná motorika - Zapne si gombíky a zips',
    key: 'answers.fineMotor.question1'
  },
  {
    label: 'Jemná motorika - Vie strihať nožnicami',
    key: 'answers.fineMotor.question2'
  },
  {
    label: 'Jemná motorika - Pri jedení správne používa príbor',
    key: 'answers.fineMotor.question3'
  },
  {
    label: 'Jemná motorika - (Pomerne) správne drží ceruzku',
    key: 'answers.fineMotor.question4'
  },
  {
    label: 'Jemná motorika - Vie nakresliť dom strom a postavu',
    key: 'answers.fineMotor.question5'
  },
  {
    label: 'Reprodukcia grafických tvarov (grafomotorika) - Vie napodobniť čiary rôznymi smermi',
    key: 'answers.graphomotorics.question1'
  },
  {
    label: 'Reprodukcia grafických tvarov (grafomotorika) - Vie napodobniť lomené línie',
    key: 'answers.graphomotorics.question2'
  },
  {
    label: 'Reprodukcia grafických tvarov (grafomotorika) - Vie napodobniť kríž',
    key: 'answers.graphomotorics.question3'
  },
  {
    label: 'Reprodukcia grafických tvarov (grafomotorika) - Vie napodobniť horný oblúk',
    key: 'answers.graphomotorics.question4'
  },
  {
    label: 'Reprodukcia grafických tvarov (grafomotorika) - Vie napodobniť dolný oblúk',
    key: 'answers.graphomotorics.question5'
  },
  {
    label: 'Reprodukcia grafických tvarov (grafomotorika) - Vie napodobniť hornú slučku',
    key: 'answers.graphomotorics.question6'
  },
  {
    label: 'Reprodukcia grafických tvarov (grafomotorika) - Vie napodobniť dolnú slučku',
    key: 'answers.graphomotorics.question7'
  },
  {
    label: 'Emocionálna oblasť - Dokáže prejaviť svoje pocity a potreby',
    key: 'answers.emotional.question1'
  },
  {
    label: 'Emocionálna oblasť - Sústredí sa na činnosť (10-15 min.)',
    key: 'answers.emotional.question2'
  },
  {
    label: 'Emocionálna oblasť - Sústredí sa na počúvanie príbehu/rozprávky',
    key: 'answers.emotional.question3'
  },
  {
    label: 'Emocionálna oblasť - Vie ovládať svoje negatívne emócie (hnev)',
    key: 'answers.emotional.question4'
  },
  {
    label: 'Emocionálna oblasť - Dokáže byť pokojný bez rodičov počas určenej doby (v novom prostredí v MŠ)',
    key: 'answers.emotional.question5'
  },
  {
    label: 'Emocionálna oblasť - Je primerane sebavedomý',
    key: 'answers.emotional.question6'
  },
  {
    label: 'Sociálna oblasť - Hrá sa s druhými deťmi (zapojí sa do hry)',
    key: 'answers.social.question1'
  },
  {
    label: 'Sociálna oblasť - Dodržiava pravidlá',
    key: 'answers.social.question2'
  },
  {
    label: 'Sociálna oblasť - Pomôže druhému',
    key: 'answers.social.question3'
  },
  {
    label: 'Sociálna oblasť - Podelí sa o niečo',
    key: 'answers.social.question4'
  },
  {
    label: 'Sociálna oblasť - Prejavuje pochopenie pre pocity druhých',
    key: 'answers.social.question5'
  },
  {
    label: 'Sociálna oblasť - Vie sa prispôsobiť zmenám',
    key: 'answers.social.question6'
  },
  {
    label: 'Sociálna oblasť - Prejavuje záujem dozvedieť sa niečo nové',
    key: 'answers.social.question7'
  },
  {
    label: 'Sociálna oblasť - V prípade potreby vyhľadá pomoc dospelého',
    key: 'answers.social.question8'
  },
  {
    label: 'Jazykový rečový vývin - Komunikuje plynule v materinskom jazyku',
    key: 'answers.languageDevelopment.question1'
  },
  {
    label: 'Jazykový rečový vývin - Dieťa z inojazyčného prostredia ovláda aspoň základné pojmy vo vyučovacom jazyku',
    key: 'answers.languageDevelopment.question2'
  },
  {
    label: 'Jazykový rečový vývin - Vie povedať príbeh podľa obrázkov',
    key: 'answers.languageDevelopment.question3'
  },
  {
    label: 'Jazykový rečový vývin - Zopakuje počutú vetu',
    key: 'answers.languageDevelopment.question4'
  },
  {
    label: 'Jazykový rečový vývin - Dokáže opísať svoj nedávny zážitok',
    key: 'answers.languageDevelopment.question5'
  },
  {
    label: 'Jazykový rečový vývin - Dokáže identifikovať začiatočnú hlásku v známych slovách',
    key: 'answers.languageDevelopment.question6'
  },
  {
    label: 'Jazykový rečový vývin - Vie rozlíšiť významové slová a pseudoslová',
    key: 'answers.languageDevelopment.question7'
  },
  {
    label: 'Jazykový rečový vývin - Vie identifikovať začiatočnú hlásku a správne zopakovať počuté',
    key: 'answers.languageDevelopment.question8'
  },
  {
    label: 'Jazykový rečový vývin - Vie idetifikovať podobnosti a rozdiely medzi predmetmi',
    key: 'answers.languageDevelopment.question9'
  },
  {
    label: 'Jazykový rečový vývin - Vie zatriediť do kategórií známe predmety slová',
    key: 'answers.languageDevelopment.question10'
  },
  {
    label: 'Jazykový rečový vývin - Pozná niektoré všeobecné pojmy',
    key: 'answers.languageDevelopment.question11'
  },
  {
    label: 'Jazykový rečový vývin - Vie vyhláskovať krátke slovo',
    key: 'answers.languageDevelopment.question12'
  },
  {
    label: 'Vývin poznávacích procesov - Vie svoje meno a priezvisko',
    key: 'answers.cognitiveProcesses.question1'
  },
  {
    label: 'Vývin poznávacích procesov - Vie svoj vek',
    key: 'answers.cognitiveProcesses.question2'
  },
  {
    label: 'Vývin poznávacích procesov - Vie svoju adresu',
    key: 'answers.cognitiveProcesses.question3'
  },
  {
    label: 'Vývin poznávacích procesov - Plní základné veku primerané pokyny',
    key: 'answers.cognitiveProcesses.question4'
  },
  {
    label: 'Vývin poznávacích procesov - Prejavuje záujem o učenie',
    key: 'answers.cognitiveProcesses.question5'
  },
  {
    label: 'Vývin poznávacích procesov - Pozná využitie predmetov a účel vecí (zo svojho prostredia)',
    key: 'answers.cognitiveProcesses.question6'
  },
  {
    label: 'Vývin poznávacích procesov - Pozná a pomenuje farby',
    key: 'answers.cognitiveProcesses.question7'
  },
  {
    label: 'Vývin poznávacích procesov - Vie zoradiť obrázky podľa počutého príbehu',
    key: 'answers.cognitiveProcesses.question8'
  },
  {
    label: 'Vývin poznávacích procesov - Kladie otázky',
    key: 'answers.cognitiveProcesses.question9'
  },
  {
    label: 'Pamäť - Pozná a vie povedať krátke básničky riekanky',
    key: 'answers.memory.question1'
  },
  {
    label: 'Pamäť - Pozná a vie povedať krátke pesničky',
    key: 'answers.memory.question2'
  },
  {
    label: 'Pamäť - Pozná a vie povedať krátke časti príbehov',
    key: 'answers.memory.question3'
  },
  {
    label: 'Pamäť - Pozná a vie povedať zobrazené predmety',
    key: 'answers.memory.question4'
  },
  {
    label: 'Pozornosť - Vie sa sústrediť na predkladané zvukové podnety',
    key: 'answers.attention.question1'
  },
  {
    label: 'Pozornosť - Vie sa sústrediť na predkladané vizuálne podnety',
    key: 'answers.attention.question2'
  },
  {
    label: 'Pozornosť - Vie sa sústrediť na predkladané manipulačné podnety',
    key: 'answers.attention.question3'
  },
  {
    label: 'Matematické predstavy - Vie počítať do 10',
    key: 'answers.math.question1'
  },
  {
    label: 'Matematické predstavy - Vie priradiť počet - predmet/-y',
    key: 'answers.math.question2'
  },
  {
    label: 'Matematické predstavy - Vie určiť viac-menej',
    key: 'answers.math.question3'
  },
  {
    label: 'Matematické predstavy - Vie určiť väčší-menší',
    key: 'answers.math.question4'
  },
  {
    label: 'Matematické predstavy - Pozná kruh',
    key: 'answers.math.question5'
  },
  {
    label: 'Matematické predstavy - Pozná štvorec',
    key: 'answers.math.question6'
  },
  {
    label: 'Matematické predstavy - Pozná trojuholník',
    key: 'answers.math.question7'
  },
  {
    label: 'Matematické predstavy - Vie určiť hore-dolu',
    key: 'answers.math.question8'
  },
  {
    label: 'Matematické predstavy - Vie určiť vpravo-vľavo',
    key: 'answers.math.question9'
  },
  {
    label: 'Matematické predstavy - Vie určiť pred-za',
    key: 'answers.math.question10'
  },
  {
    label: 'Matematické predstavy - Vie určiť nad-pod',
    key: 'answers.math.question11'
  },
  {
    label: 'Matematické predstavy - Vie poskladať skladačku podľa predlohy',
    key: 'answers.math.question12'
  }
];

export const accessoriesReportHeader = [
  { label: strings.region, key: 'region' },
  { label: strings.providedBy, key: 'providedBy' },
  { label: strings.omama, key: 'omama' },
  { label: strings.accessory, key: 'accessory' },
  { label: strings.comment, key: 'note' },
  { label: strings.missingRequestDate, key: 'missingDate' },
  { label: strings.missingResponseDate, key: 'returnDate' },
  { label: strings.state, key: 'status' }
];
