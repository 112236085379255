import React from 'react';
import './GalleryFolders.scss';
import PropTypes from 'prop-types';
import { omamaGalleryFoldersMap } from 'data/enums';

function GalleryFolders({ folders, clickHandler, omamaFolders }) {
  return Object.keys(folders || []).map(key => (
    <div key={key} onClick={() => clickHandler(key)} className="galleryFolder adminShadow">
      {omamaFolders ? <span>{omamaGalleryFoldersMap[key]}</span> : <span>{key}</span>}
    </div>
  ));
}

GalleryFolders.propTypes = {
  folders: PropTypes.object,
  clickHandler: PropTypes.func,
  omamaFolders: PropTypes.bool
};

export default GalleryFolders;
