import React from 'react';

// Local imports
import SupervisorActions from './SupervisorActions';
import './SupervisorPlan.scss';

const SupervisorPlan = () => {
  return (
    <div className="statsBackground">
      <div className="clientsBox">
        <div className="activities-main supervisorPlanMobileVersion">
          <SupervisorActions />
        </div>
      </div>
    </div>
  );
};

SupervisorPlan.propTypes = {};

export default SupervisorPlan;
