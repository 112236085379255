import moment from 'moment';
moment.locale('sk');
/* eslint-disable no-unused-vars */
export const GET_MENTOR_CLIENTS = 'GET_MENTOR_CLIENTS';
export const ADMIN_MENTOR_CHANGE_NEXT_MONTH = ' ADMIN_MENTOR_CHANGE_NEXT_MONTH';
export const ADMIN_MENTOR_CHANGE_PREVIOUS_MONTH = ' ADMIN_MENTOR_CHANGE_PREVIOUS_MONTH';
export const GET_ADMIN_MENTOR_ACTIONS = 'GET_ADMIN_MENTOR_ACTIONS';
export const GET_SPECIFIC_MENTOR_ACTION = 'GET_SPECIFIC_MENTOR_ACTION';
export const ADMIN_MENTOR_CHANGE_DATE = 'ADMIN_MENTOR_CHANGE_DATE';

const initialState = {
  mentor: [],
  mentorStartMonth: moment()
    .startOf('month')
    .format('YYYY-MM-DD'),
  mentorEndMonth: moment()
    .endOf('month')
    .format('YYYY-MM-DD'),
  mentorFrom: null,
  mentorTo: null,
  mentorActions: [],
  mentorAction: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
      case GET_MENTOR_CLIENTS:
        return {
          ...state,
          mentor: action.payload
        };

      case ADMIN_MENTOR_CHANGE_NEXT_MONTH:
        return {
          ...state,
          mentorStartMonth: action.payload.start,
          mentorEndMonth: action.payload.end
        };

      case ADMIN_MENTOR_CHANGE_PREVIOUS_MONTH:
        return {
          ...state,
          mentorStartMonth: action.payload.start,
          mentorEndMonth: action.payload.end
        };
      case ADMIN_MENTOR_CHANGE_DATE:
        return {
          ...state,
          mentorFrom: action.payload.mentorFrom,
          mentorTo: action.payload.mentorTo
        };

      case GET_ADMIN_MENTOR_ACTIONS:
        return {
          ...state,
          mentorActions: action.payload.mentorActions
        };

      case GET_SPECIFIC_MENTOR_ACTION:
        return {
          ...state,
          mentorAction: action.payload
        };

      default:
        return state;
  }
};
