import React from 'react';
import { strings } from 'strings/StringsProvider';
import PropTypes from 'prop-types';
import jwt from 'jsonwebtoken';

import './ErrorMessage.scss';

function ErrorMessage({ errorMessage }) {
  const { role } = jwt.decode(localStorage.getItem('access-token')) || {};

  return (
    <>
      <div className="error-message">
        <div>{strings.unexpectedError}</div>
        <div>{errorMessage}</div>
      </div>
      {role && (
        <span
          className="report-error"
          onClick={() => {
            window.location.href = `/${role === 'omama' ? 'omama' : 'admin'}/nastavenia/problem`;
          }}
        >
          {strings.reportError}
        </span>
      )}
    </>
  );
}

ErrorMessage.propTypes = {
  errorMessage: PropTypes.string
};

export default ErrorMessage;
