import {
  ADD_NEW_MESSAGE,
  CLEAN_MESSAGE
} from '../reducers/message.reducer';

export const addNewMessageAction = (text, result) => {
  return async(dispatch) => {
    return  dispatch({
      type: ADD_NEW_MESSAGE,
      payload: {text, result}
    });
  };
};

export const cleanMessageAction = () => {
  return async(dispatch) => {
    return  dispatch({
      type: CLEAN_MESSAGE
    });
  };
};