import { strings } from '../strings/StringsProvider';

export const employmentTypesMap = {
  '1': strings.enums.fullTime,
  '2': strings.enums.halfTime,
  '3': strings.enums.threeQuarterTime
};

export const employmentTypes = [
  { value: strings.enums.fullTime, key: 1 },
  { value: strings.enums.halfTime, key: 2 },
  { value: strings.enums.threeQuarterTime, key: 3 }
];

export const omamaLevelMap = {
  '1': strings.enums.beginner,
  '2': strings.enums.junior,
  '3': strings.enums.senior,
  '4': strings.enums.expert
};

export const nonClientActionsMap = {
  [strings.vacation]: true,
  [strings.doctor]: true,
  [strings.familyDoctor]: true,
  [strings.workUnable]: true,
  [strings.OCR]: true,
  [strings.other]: true,
  [strings.training]: true,
  [strings.otherJob]: true,
  [strings.interNDA]: true,
  [strings.extraTimeOff]: true
};

export const providedByMap = {
  centralCoordinator: strings.centralCoordinator,
  regionalCoordinator: strings.regionalCoordinator,
  omamaOrParent: strings.omamaParent
};

export const activeOptionMap = {
  true: strings.activeUpper,
  false: strings.nonActiveUpper
};

export const levels = [
  {
    level: strings.enums.beginner,
    key: '1'
  },
  {
    level: strings.enums.junior,
    key: '2'
  },
  {
    level: strings.enums.senior,
    key: '3'
  },
  {
    level: strings.enums.expert,
    key: '4'
  }
];

export const accessoryStatusMap = {
  rented: strings.rented,
  missing: strings.missing,
  removed: strings.removed
};

export const omamaGalleryFoldersMap = {
  parentalClub: strings.parentalClub,
  otherWork: strings.otherJob
};

export const parentEngagementAnswerMap = {
  notAtAll: strings.notAtAll,
  sometimes: strings.sometimes,
  often: strings.veryOften
};
