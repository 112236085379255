import React, { useEffect } from 'react';
// import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Icon } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

// Local imports
import { strings } from '../../strings/StringsProvider';
import {
  getActionsAction,
  getOmamaClientsAction,
  getOmamaTokenAction,
  showClientModal,
  showClientModalEnd,
  showActionModal,
  showActiontModalEnd,
  changeNextWeek,
  changePreviousWeek,
  changeWeek
} from '../../actions/omama.actions';

import {
  handleActionComment,
  handleClientEndXButton,
  handleClientXButton
} from '../../actions/clientActivities.action';

import DayActions from '../../components/DayActions';
import Client from '../../components/Client';
import Action from '../../components/Action';
import ClientEnd from '../../components/ClientEnd';
import WeekSelector from 'components/WeekSelector';
import './Plan.scss';

moment.locale('sk');

function Plan({
  changePage,
  getActionsAction,
  activities,
  allActionsByDay,
  getOmamaClientsAction,
  clients,
  actionsById,
  showClientModal,
  showClientModalEnd,
  actionID,
  handleActionComment,
  clientModalEnd,
  clientModal,
  startWeek,
  endWeek,
  changeNextWeek,
  changePreviousWeek,
  changeWeek,
  omama,
  getOmamaToken,
  actionModal,
  showActionModal,
  showActiontModalEnd
}) {
  useEffect(() => {
    if (!omama.username) {
      getOmamaToken();
    }

    getActionsAction(
      startWeek ||
        moment()
          .startOf('isoWeek')
          .format('YYYY-MM-DD'),
      endWeek ||
        moment()
          .endOf('isoWeek')
          .format('YYYY-MM-DD')
    );
    getOmamaClientsAction();
  }, [omama, getOmamaToken, getOmamaClientsAction, getActionsAction, startWeek, endWeek]);

  const countLekcie = () => {
    let pocetLekcii = 0;
    const lekcie = activities;
    for (let lekcia of lekcie) {
      if (lekcia.client === true) pocetLekcii++;
    }
    switch (true) {
      case pocetLekcii === 1:
        return { count: pocetLekcii, label: strings.oneLesson };
      case pocetLekcii === 0:
        return { count: pocetLekcii, label: strings.moreThenFiveLessons };
      case pocetLekcii >= 5:
        return { count: pocetLekcii, label: strings.moreThenFiveLessons };
      case pocetLekcii >= 2 || pocetLekcii <= 4:
        return { count: pocetLekcii, label: strings.twoTillFourLessons };
      default:
        return { count: pocetLekcii, label: strings.twoTillFourLessons };
    }
  };

  const countSkolenia = () => {
    let pocetSkoleni = 0;
    const skolenia = activities;
    for (let skolenie of skolenia) {
      if (skolenie.client === false) pocetSkoleni++;
    }
    switch (true) {
      case pocetSkoleni === 1:
        return { count: pocetSkoleni, label: strings.actionOne };
      case pocetSkoleni === 0 || pocetSkoleni >= 5:
        return { count: pocetSkoleni, label: strings.actionOne };
      case pocetSkoleni >= 2 || pocetSkoleni <= 4:
        return { count: pocetSkoleni, label: strings.actionOne };
      default:
        return { count: pocetSkoleni, label: strings.actionOne };
    }
  };

  // const countKlienti = () => {
  //   return Object.keys(clients).length;
  // };

  const openClientModal = () =>
    actionID !== '' && actionsById[actionID] && actionsById[actionID].status !== 'done' && clientModal;

  const openActionModal = () => {
    if (actionID !== '') {
      // TODO mozno prerobime vytunime
      // actionsById[actionID].status != 'done' &&
      return actionModal;
    } else {
      return false;
    }
  };

  const openClientModalEnd = () => {
    return clientModalEnd;
  };

  const openClientModalDone = () => {
    if (actionID !== '') {
      if (actionsById[actionID] && actionsById[actionID].status === 'done' && clientModal) {
        return true;
      } else {
        return false;
      }
    }
  };

  const changeComment = action => e => {
    if (action[0]) {
      action.forEach(el => {
        handleActionComment(el._id, e.target.value);
      });
    }
    handleActionComment(actionID, e.target.value);
  };

  const nextWeek = () => {
    changeNextWeek(startWeek, endWeek);
  };

  const previousWeek = () => {
    changePreviousWeek(startWeek, endWeek);
  };

  const onWeekChange = date => {
    changeWeek(date);
  };

  const weekFormat = `${moment(startWeek).format('YYYY-MM-DD')}  ~  ${moment(endWeek).format('YYYY-MM-DD')}`;

  return (
    <div className="plan-main" style={{ paddingTop: '50px' }}>
      <div className="plan-topPanel">
        <div className="user_activities">
          <div className="lekcie">
            <div className="count">{countLekcie().count}</div>
            <div>{countLekcie().label}</div>
          </div>
          <div className="skolenia">
            <div className="count">{countSkolenia().count}</div>
            <div>{countSkolenia().label}</div>
          </div>
        </div>
        <img
          onClick={() => changePage('/omama/pridat')}
          className="ikona pridatButton"
          src="/images/Icons/plus_icon.png"
          alt="ikona"
        />
      </div>
      <div className="weekPicker omamaWeekPicker">
        <div className="omamaWeek">{strings.week}</div>
        <div className="week-selector-container">
          <Icon type="left-circle" className="week-navigation-left big " onClick={previousWeek} />
          <WeekSelector value={startWeek} onChangeHandler={onWeekChange} format={weekFormat} />
          <Icon type="right-circle" className="week-navigation-right big" onClick={nextWeek} />
        </div>
      </div>
      <div className="actionsContainer">
        {/* Vsetky akcie - klientske aj obyc. */}
        {allActionsByDay.map(action => (
          <DayActions
            key={action.day}
            day={action.day}
            clients={clients}
            actions={action.Actions}
            showClientModal={showClientModal}
            showActionModal={showActionModal}
          />
        ))}
      </div>

      {openClientModal() && (
        <Client
          showClientModal={showClientModal}
          action={actionsById[actionID]}
          actions={actionsById}
          client={clients[actionsById[actionID].id]}
          clients={clients}
          showClientModalEnd={showClientModalEnd}
        />
      )}
      {/* SKOLENIA - AKCIE*/}
      {openActionModal() && (
        <Action
          // showClientModal={showClientModal} // Potential refactor
          action={actionsById[actionID]}
          showActiontModalEnd={showActiontModalEnd}
        />
      )}

      {(openClientModalDone() || openClientModalEnd()) && (
        <ClientEnd
          showClientModal={showClientModal}
          actions={actionsById}
          action={actionsById[actionID]}
          clients={clients}
          client={clients[actionsById[actionID].id]}
          changeComment={changeComment}
        />
      )}
    </div>
  );
}

const mapStateToProps = ({ omama }) => {
  //to do actions refactor

  return {
    activities: omama.activities,
    clients: omama.clients,
    clientActionsByDay: omama.clientActionsByDay,
    actionsByDay: omama.actionsByDay,
    actionsById: omama.actionsById,
    allActionsByDay: omama.allActionsByDay,
    omama: omama.omama,
    actionID: omama.actionID,
    clientModal: omama.clientModal,
    actionModal: omama.actionModal,
    clientModalEnd: omama.clientModalEnd,
    startWeek: omama.startWeek,
    endWeek: omama.endWeek
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getActionsAction,
      getOmamaClientsAction,
      getOmamaToken: () => dispatch(getOmamaTokenAction()),
      handleActionComment,
      handleClientEndXButton,
      handleClientXButton,
      showClientModal,
      showClientModalEnd,
      showActionModal,
      showActiontModalEnd,
      changeNextWeek,
      changePreviousWeek,
      changeWeek,
      changePage: location => push(location)
    },
    dispatch
  );

Plan.propTypes = {
  changePage: PropTypes.func,
  getActionsAction: PropTypes.func,
  activities: PropTypes.arrayOf(PropTypes.object),
  actionsByDay: PropTypes.arrayOf(PropTypes.object),
  clientActionsByDay: PropTypes.arrayOf(PropTypes.object),
  allActionsByDay: PropTypes.arrayOf(PropTypes.object),
  getOmamaClientsAction: PropTypes.func,
  clients: PropTypes.object,
  actionsById: PropTypes.object,
  handleActionComment: PropTypes.func,
  handleClientEndXButton: PropTypes.func,
  handleClientXButton: PropTypes.func,
  showClientModal: PropTypes.func,
  showClientModalEnd: PropTypes.func,
  actionID: PropTypes.string,
  clientModalEnd: PropTypes.bool,
  clientModal: PropTypes.bool,
  startWeek: PropTypes.string,
  endWeek: PropTypes.string,
  changeNextWeek: PropTypes.func,
  changePreviousWeek: PropTypes.func,
  changeWeek: PropTypes.func,
  omama: PropTypes.object,
  getOmamaToken: PropTypes.func,
  actionModal: PropTypes.bool,
  showActionModal: PropTypes.func,
  showActiontModalEnd: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Plan);
