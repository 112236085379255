import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Pagination } from 'antd';
import './ClientImageGallery.scss';
import jwt from 'jsonwebtoken';
import { useParams } from 'react-router-dom';

// Local imports
import { getOmamaActionGallery, removeActionPhotoAction } from '../../actions/omama.actions';
import { strings } from '../../strings/StringsProvider';
import ImageGalleryNav from '../../components/ImageGalleryNav';
import ImageGallery from '../../components/ImageGallery';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function OmamaImageGallery() {
  const [pageNumber, setPageNumber] = useState(1);
  const { actionType } = useParams();
  const navName = actionType === 'parentalClub' ? strings.parentalClub : strings.otherJob;
  const omamaID = jwt.decode(localStorage.getItem('access-token')).username;
  const history = useHistory();
  const pageSize = 9;

  const actionGallery = useSelector(state => state.omama.actionGallery);
  const actionPhotos = actionGallery?.[actionType] || [];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOmamaActionGallery(omamaID));
  }, [dispatch, omamaID]);

  const goBackInNavigation = useCallback(() => {
    history.goBack();
    setPageNumber(1);
  }, [history]);

  const changeGalleryPage = useCallback(page => {
    setPageNumber(page);
  }, []);

  const galleryPageImages = useCallback(
    gallery => {
      const reversedGallery = [...gallery].reverse();
      return reversedGallery.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    },
    [pageNumber, pageSize]
  );

  const removeGalleryImage = useCallback(
    photoID => {
      dispatch(removeActionPhotoAction(omamaID, photoID, '', actionType)).then(() => {
        if (actionPhotos.length === 1) {
          goBackInNavigation();
        } else {
          dispatch(getOmamaActionGallery(omamaID));
        }
      });
    },
    [dispatch, omamaID, actionType, actionPhotos, goBackInNavigation]
  );

  return (
    <div className="galleryContainer">
      <div className="clientGalleryWrapper">
        <ImageGalleryNav name={navName} goBack={goBackInNavigation} />
        <ImageGallery gallery={galleryPageImages(actionPhotos)} removeImage={removeGalleryImage} />
        <div className="paginationDiv">
          {actionPhotos && actionPhotos.length !== 0 && (
            <Pagination
              className="pagination"
              size="small"
              current={pageNumber}
              defaultPageSize={9}
              total={actionPhotos.length}
              onChange={changeGalleryPage}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default OmamaImageGallery;
